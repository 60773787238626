.accepted-application {
    background: linear-gradient(270deg, rgba(120, 198, 241, 0) 9%, rgba(120, 198, 241, 0.15) 93%),
        linear-gradient(157deg, #2266d0, #0c2c63);
    color: $COLOR_V3_WHITE;
    font-family: "Apercu Regular Pro";
    position: relative;
    width: 100%;
    height: 100%;

    .congrats-container {
        font-size: 14px;
        line-height: 14px;
        padding-top: 15px;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            padding-top: 20px;
        }

        p {
            margin-bottom: 0px;

            &.congrats {
                margin-bottom: 3px;
                opacity: 0.61;
            }

            &.student-name {
                font-weight: $FONT_WEIGHT_MEDIUM;
                text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
            }
        }
    }

    .main-container {
        // some magic to add a little vertical margin when tall enough
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            top: 46%;
        }
    }

    .stripe {
        background: linear-gradient(90deg, rgba(170, 207, 249, 0.39), #aacff9);
        height: 132px;
        opacity: 0.15;
        position: absolute;
        top: -23px;
        transform: rotate(-2.5deg);
        width: 110%;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            height: 158px;
            top: -21px;
            transform: rotate(-1deg);
        }
    }

    .you-have-been {
        font-family: "Apercu Bold Pro";
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 8px;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 8px;
        }
    }

    .accepted {
        font-family: "Apercu Bold Pro";
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 7px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            font-size: 65px;
            line-height: 65px;
            margin-bottom: 4px;
        }
    }

    .badge {
        width: 123px;
        height: 114px;
        margin-bottom: 8px;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            margin-bottom: 18px;
        }
    }

    .to-the {
        font-size: 15px;
        line-height: 18px;
        margin: 0 auto 12px;
        opacity: 0.61;
        width: 244px;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 16px;
            width: 326px;
        }
    }

    .program-title {
        font-family: "Apercu Bold Pro";
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 20px;
        }
    }

    .wordmark {
        max-width: 150px;
        margin-bottom: 20px;
    }

    hr.above-program {
        opacity: 0.5;
        width: 56px;
        align: center;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .date-class {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 0px;
        text-transform: uppercase;
        opacity: 0.6;
    }

    .bottom-container {
        position: fixed;
        bottom: 15px;
        left: 0;
        right: 0;

        @media (min-width: $screen-sm-min) and (min-height: 600px) {
            bottom: 30px; // see also: iphonex.scss
        }

        .get-started {
            background: linear-gradient(to bottom right, #336dc0, $COLOR_V3_BLUE_DARK) !important;
            font-size: 20px;
            width: calc(100% - 30px);
            height: 50px;
            border-radius: 0px;

            &:hover {
                box-shadow: 0 8px 11px rgba(0, 0, 0, 0.5);
            }

            // at wider sizes, don't fill the screen horizontally
            @media (min-width: $screen-sm-min) {
                width: 250px;
            }
        }
    }
}
