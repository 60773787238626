.launch-verification-modal {
    width: 600px;
}

user-id-verification-form {
    text-align: center;

    h1 {
        font-size: 24px;
        color: $COLOR_V3_BLACK;
        margin: 0px 0px 20px;
    }

    p {
        font-size: 15px;
        margin: 0px auto;

        a {
            color: $COLOR_V3_BLUE;
        }
    }

    .subtitle {
        color: $COLOR_V3_GREY_DARKER;
        margin-bottom: 26px;
    }

    form {
        max-width: 410px;
        margin: 0 auto;
    }

    .email-or-phone-label {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 16px;
        color: $COLOR_V3_BLACK;
        text-align: left;
        display: block;
        padding: 0px 0px 4px 0px;
    }

    input[name="email"] {
        width: 100%;
    }

    .toggle-contact-method {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 14px;
        text-decoration: underline;
        margin-bottom: 18px;
    }

    button[type="submit"] {
        display: block;
        margin: 0px auto 11px;
        width: 129px;
    }

    .front-royal-spinner {
        margin: 71px auto;
    }

    .if-you-are-unable {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        max-width: 410px;
        margin: 0 auto 11px;
    }

    .why-we-do-this {
        color: $COLOR_V3_BLUE;
    }

    .result-icon {
        margin: 43px auto 63px;
    }

    .important-tips {
        @extend .clearfix;
        background: $COLOR_V3_BEIGE_LIGHT;
        border: solid $COLOR_V3_BEIGE_MIDDARK;
        border-width: 1px 0;
        padding: 20px 5px 5px;
        margin: 0 -30px 30px;
        font-size: 14px;

        h3 {
            text-align: center;
            margin: -31px 0 10px;

            span {
                display: inline-block;
                background: $COLOR_V3_WHITE;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 14px;
                text-transform: uppercase;
                border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
                border-radius: 3px;
                padding: 2px 10px;
            }
        }

        img {
            margin: 10px 0;

            &.bump {
                @media (min-width: $screen-sm-min) {
                    margin-top: 26px;
                }
            }
        }

        ul {
            margin: 0 0 20px;
            padding: 0;
        }

        li {
            margin: 5px 0;
            padding: 0;
            list-style: none;
        }
    }
}
