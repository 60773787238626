//
// Onboard modal.
//

// Body.
.onboarding_modal__open {
    overflow: hidden;

    .onboarding_wrapper__blur {
        filter: blur(3px);

        transition: filter 0.6s 0.45s ease;
    }
}

// Wrapper.
.onboarding_wrapper {
    display: block;
    filter: blur(0);

    will-change: filter;

    transition: filter 0.2s ease;
}

// Modal.
.onboarding_modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1032;

    width: 100vw;
    height: 100vh;
    padding: 0 20px;
    background-color: rgba(#000, 0.5);

    transform: translateZ(0);

    // Transitions.
    &.ng-enter {
        transition: opacity 0.6s cubic-bezier(0.86, 0, 0.1, 1), background-color 0.6s ease;

        .onboarding_modal__dialog {
            transition: transform 0.6s cubic-bezier(0.86, 0, 0.1, 1);
        }
    }

    &.ng-leave {
        transition: opacity 0.6s 0.1s cubic-bezier(0.86, 0, 0.1, 1), background-color 0.3s ease;

        .onboarding_modal__dialog {
            transition: transform 0.6s 0.1s cubic-bezier(0.86, 0, 0.1, 1);
        }
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        background-color: rgba(#000, 0);
        opacity: 0;

        .onboarding_modal__dialog {
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        background-color: rgba(#000, 0.5);
        opacity: 1;

        .onboarding_modal__dialog {
            transform: translate3d(-50%, -65%, 0);
        }
    }
}

// Dialog.
.onboarding_modal__dialog {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 1034;

    max-width: 460px;
    min-height: 200px;
    padding: 25px 30px;

    background-color: #fff;
    border-radius: 8px;

    will-change: transform;

    transform: translate3d(-50%, -65%, 0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:focus {
        outline: none;
    }
}

// Header.
.onboarding_modal__header {
    h2 {
        font-size: 24px;
        color: #615a52;
    }
}

// Body.
.onboarding_modal__body {
    p {
        margin-bottom: 15px;
        font-size: 16px;
        color: #b5b5b5;
    }
}

.onboarding_modal__forgot-password,
.onboarding_modal__login,
.onboarding_modal__register {
    position: relative;
    padding: 0 10px;
    z-index: 1;

    &,
    * {
        display: block;
    }

    .onboarding_modal__dialog {
        position: relative;
        left: 0;
        top: 0;

        display: block;

        max-width: 560px;
        max-height: 100%;
        height: auto;
        margin: -80px auto 0;
        padding: 20px 20px 25px;

        border-radius: 4px;

        transform: translate3d(0, 0, 0);
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

        .form-container-inner {
            border: none;
            padding: 0;

            h2:first-of-type {
                margin-top: 0px;
            }

            .soc-btns {
                .soc-btn {
                    display: inline;

                    span {
                        display: inline;
                    }
                }
            }
        }
    }

    .form-group,
    input {
        width: 100%;
    }

    .btn-submit {
        width: 100%;
        height: 56px;

        color: #fff;
        font-weight: $FONT_WEIGHT_BOLD;
        font-size: 16px;

        background-color: #14cca7;
        border-radius: 4px;

        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

        &.disabled {
            opacity: 0.4;
        }
    }
}

.onboarding_modal__forgot-password {
    .onboarding_modal__dialog {
        margin: -130px auto 0;

        .form-container-inner {
            h2 {
                font-size: 22px;
                margin-bottom: 10px;
            }

            p {
                color: #b5b5b5;
                margin-bottom: 30px;
                padding: 0 10px;
            }

            .form {
                .form-group {
                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

//
// Submit buttons.
//

// Submit.
.onboarding_modal__forgot-password,
.onboarding_modal__login {
    .forgot_password_link {
        color: #ff4d63;
    }

    .forgot_container {
        padding-left: 20px;
        margin-bottom: 15px;

        font-size: 16px;
        text-align: left;
    }

    .btn-submit {
        background-color: #ff4d63;
    }
}

// Register
.onboarding_modal__register {
    .btn-submit {
        background-color: #14cca7;
    }
}

//
// Media queries.
//

// Medium screens.

@media (min-width: $screen-sm-min) {
    .onboarding_modal {
        padding: 0;
    }

    .onboarding_modal__dialog {
        padding: 25px 70px 30px;
    }

    .onboarding_modal__forgot-password,
    .onboarding_modal__login,
    .onboarding_modal__register {
        padding: 0;

        .onboarding_modal__dialog {
            margin: -160px auto 0;
            padding: 40px 40px 33px;

            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

            .form-container-inner {
                border: none;
                padding: 0;

                h2:first-of-type {
                    margin-top: 0px;
                }

                .soc-btns {
                    .soc-btn {
                        display: inline;

                        span {
                            display: inline;
                        }
                    }
                }
            }
        }

        .btn-submit {
            width: 100%;
            height: 56px;

            font-size: 18px;
        }
    }

    .onboarding_modal__login {
        .forgot_container {
            padding-left: 0px;
        }
    }
}
