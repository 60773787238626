student-dashboard-program-box {
    $iconMobileWidth: 28px;
    $iconMobileHeight: 25px;
    $iconDesktopWidth: 45px;
    $iconDesktopHeight: 40px;
    $paddingRightXS: 130px;
    $paddingRightMobile: 175px;

    .faq-link {
        color: $COLOR_V3_WHITE !important;
        font-weight: $FONT_WEIGHT_BOLD;
        text-decoration: underline;
        cursor: pointer;
    }

    .sidebar-box.taller {
        @media (min-width: $screen-md-min) {
            padding-bottom: 75px; // make room for button and progress bar
        }
    }

    .sidebar-box {
        @include sidebar-box(true);
        background-image: linear-gradient(146deg, #2266d0 0%, #0c2c63 96%);

        &,
        p,
        span {
            color: $COLOR_V3_WHITE;
        }

        // since it will be pinned to bottom on mobile, remove margin and border radius
        @media (max-width: $screen-sm-max) {
            border-radius: 0px;
            margin: 0;
        }

        .title {
            position: relative;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 15px;
            line-height: 1em;
            padding-left: $iconMobileWidth + 10px;
            padding-right: $paddingRightXS;
            margin: 0px;
            min-height: $iconMobileHeight;
            display: table;

            @media (min-width: $screen-xs-min) {
                padding-right: $paddingRightMobile;
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
                width: 50%;
            }

            .title-text {
                display: table-cell;
                vertical-align: middle;
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: $iconMobileWidth + 10px;
                padding-left: $paddingRightXS;

                @media (min-width: $screen-xs-min) {
                    padding-right: $iconMobileWidth + 10px;
                    padding-left: $paddingRightMobile;
                }
            }

            @media (min-width: $screen-md-min) {
                padding-left: 0px;
                padding-right: $iconDesktopWidth + 10px;
                margin-bottom: 10px;
                min-height: $iconDesktopHeight;
                display: block;

                span {
                    font-size: 20px;
                    line-height: 20px;
                }

                .title-text {
                    display: inline;
                    vertical-align: none;
                }

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 0px;
                    padding-left: $iconDesktopWidth + 10px;
                }
            }
        }

        .sub-title {
            display: inline-block;
            margin: 0;
            line-height: 1.2;

            span,
            p {
                font-size: 12px;

                @media (min-width: $screen-md-min) {
                    font-size: 15px;
                }
            }

            strong {
                font-size: inherit;
                color: $COLOR_V3_WHITE;
            }

            p:not(:last-of-type) {
                margin-bottom: 1.4em;
            }

            a[href] {
                color: inherit;
                border: 0;
                text-decoration: underline;
                font-size: inherit;
                font-weight: inherit;
                &:after {
                    display: none;
                }
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                $smallWidth: 280px;
                display: block;
                position: absolute;
                top: 50%;
                left: calc(50% - #{$smallWidth * 0.5});
                text-align: center;
                width: $smallWidth;
                line-height: 12px;
                transform: translateY(-50%);

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: calc(50% - #{$smallWidth * 0.5});
                }
            }
        }

        .mba-icon {
            position: absolute;
            display: inline-block;
            top: 3px;
            left: 0px;
            width: $iconMobileWidth;
            height: $iconMobileHeight;
            background-image: url("~images/shield_quantic.png");
            background-size: 100%;
            background-repeat: no-repeat;

            &.valar {
                background-image: url("~images/shield_valar.png");
            }

            &.smartly {
                background-image: url("~images/shield.png");
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: 0px;
            }

            @media (min-width: $screen-md-min) {
                top: 0px;
                right: 0px;
                left: auto;
                width: $iconDesktopWidth;
                height: $iconDesktopHeight;

                // rtl-language overrides
                html[dir="rtl"] & {
                    right: auto;
                    left: 0px;
                }
            }
        }

        .special-container {
            position: absolute;
            right: 10px;
            top: 10px;

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: 10px;
            }

            .apply-button,
            .faq-button,
            .register-button {
                display: inline-block;
                padding: 6px 15px 5px 15px;
                margin-top: 3px;
                font-size: 12px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;

                &,
                .no-touchevents &:focus,
                .touchevents &:focus {
                    box-shadow: 0px 0px 0px 1px $COLOR_V3_WHITE;
                }

                .no-touchevents &:hover,
                .no-touchevents &:active,
                .touchevents &:active {
                    color: $COLOR_V3_BLUE_DARK !important;
                }

                @media (min-width: $screen-sm-min) {
                    font-size: 15px;
                    margin-top: 5px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: 0px;
                    min-width: 200px;
                    padding: 9px 15px 7px 15px;
                }
            }

            // message that appears when you can log on desktop to doanload certificate
            .certificate-help-text {
                width: 130px;
                font-size: 12px;
                line-height: 1;
                text-align: right;

                @media (min-width: $screen-xs-min) {
                    width: 175px;
                }

                @media (min-width: $screen-sm-min) {
                    padding-top: 5px;
                }

                @media (min-width: $screen-md-min) {
                    padding-top: 0;
                    text-align: left;
                    width: 100%;
                    font-size: 14px;
                    line-height: 1.2;
                }

                html[dir="rtl"] & {
                    text-align: left;
                }
            }

            // pre_accepted mobile status
            .pre-accepted-message {
                line-height: 1em;
                text-align: right;
                min-width: 200px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    text-align: left;
                    padding-left: 40px;
                }

                span {
                    font-size: 12px;
                }
            }

            @media (min-width: $screen-md-min) {
                display: block;
                width: calc(100% - 30px);
                top: auto;
                bottom: 20px;
                left: 15px;
                text-align: center;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 15px;
                }
            }
        }
    }

    .sidebar-box .special-container,
    .box-container .content-container .content-header {
        .application-progress {
            $segmentDiameterMobile: 12.5px;
            $segmentDiameterDesktop: 25px;
            $fillGapMobile: 1px;
            $fillGapDesktop: 3px;
            $barHeightMobile: 5px;
            $barHeightDesktop: 10px;

            $widthMobile: $paddingRightMobile - 10px;

            margin-top: -5px;
            margin-right: 10px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: 0px;
                margin-left: 10px;
            }

            @media (min-width: $screen-sm-min) {
                margin-top: 0px;
            }

            @media (min-width: $screen-md-min) {
                margin: 0px;
            }

            // status message that appears on mobile above the bar
            .mobile-status {
                display: inline-block;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 5px;

                span {
                    font-size: 12px;
                    line-height: 1em;
                    color: rgba($COLOR_V3_WHITE, 0.75);
                }
            }

            // the bar holds the entire progress bar
            .bar {
                display: inline-block;
                width: $widthMobile;
                height: 30px;
                position: relative;

                @media (min-width: $screen-md-min) {
                    width: calc(100% - 30px);
                    margin: 0 auto;
                }
            }

            // a segment is a rectangular piece of the progress bar
            .segment {
                position: absolute;
                display: inline-block;
                top: 0px;
                height: $barHeightMobile;
                width: calc(50% + 2px);
                background-color: $COLOR_V3_WHITE;

                @media (min-width: $screen-md-min) {
                    height: $barHeightDesktop;
                }

                // The label beneath each circular ball
                .bar-label {
                    position: absolute;
                    font-size: 10px;
                    line-height: 1em;
                    max-width: 100px;
                    display: none;

                    @media (min-width: $screen-md-min) {
                        top: calc(100% + #{$segmentDiameterDesktop * 0.5 + 5px});
                        display: inline-block;
                    }
                }

                // positioning for the segment and bar label
                &.left {
                    left: 0px;

                    .bar-label {
                        left: -10px;
                    }

                    &:after {
                        z-index: 3;
                    }
                }
                &.right {
                    width: 5px;
                    right: 0px;

                    .bar-label {
                        right: -10px;
                    }
                    .circle {
                        right: -$segmentDiameterMobile * 0.5;
                        left: auto;

                        @media (min-width: $screen-md-min) {
                            right: -$segmentDiameterDesktop * 0.5;
                        }
                    }
                    &:after {
                        display: none;
                    }
                }
                &.middle {
                    left: 50%;

                    .bar-label {
                        left: 0;
                        transform: translateX(-50%);
                    }
                }

                // the rectangular fill for a segment
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0px;
                    top: $fillGapMobile;
                    height: $barHeightMobile - 2 * $fillGapMobile;
                    width: 100%;

                    @media (min-width: $screen-md-min) {
                        top: $fillGapDesktop;
                        height: $barHeightDesktop - 2 * $fillGapDesktop;
                    }
                }

                // The circular ball at each step in the progress bar
                .circle {
                    position: absolute;
                    display: block;
                    left: -$segmentDiameterMobile * 0.5;
                    top: ($barHeightMobile - $segmentDiameterMobile) * 0.5;
                    width: $segmentDiameterMobile;
                    height: $segmentDiameterMobile;
                    background-color: $COLOR_V3_WHITE;
                    border: 0px solid transparent;
                    border-radius: 50%;

                    padding: $fillGapMobile;

                    @media (min-width: $screen-md-min) {
                        left: -$segmentDiameterDesktop * 0.5;
                        top: ($barHeightDesktop - $segmentDiameterDesktop) * 0.5;
                        width: $segmentDiameterDesktop;
                        height: $segmentDiameterDesktop;
                        padding: $fillGapDesktop;
                    }

                    // the circular fill for the ball
                    .fill {
                        display: block;
                        width: 100%;
                        height: 100%;
                        border: 0px solid transparent;
                        border-radius: 50%;
                        display: none;
                        background-color: $COLOR_V3_BLUE;
                    }
                }

                // fill states
                &.filled,
                &.active {
                    .circle {
                        .fill {
                            display: block;
                        }
                    }
                }
                &.filled {
                    &:after {
                        background: $COLOR_V3_BLUE;
                    }
                }
                &.active {
                    &:after {
                        @include gradient-horizontal($COLOR_V3_BLUE, $COLOR_V3_WHITE);
                    }
                }
            }
        }
    }

    .box-container .content-container .content-header {
        .application-progress {
            margin-top: -9px;
            margin-right: 6px;

            .mobile-status {
                margin-bottom: -12px;
            }

            .bar {
                margin-top: -5px;
                width: 135px;
                height: 5px;

                .segment {
                    top: 3px;
                }
            }
        }
    }

    .no-right.sidebar-box .sub-title {
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            &,
            html[dir="rtl"] & {
                left: auto;
                right: 15px;
                text-align: right;
            }
        }
    }
}
