.lesson-start-screen-footer,
lesson-finish-screen-footer {
    width: 100%;
    margin: 0px auto;
    position: absolute;
    bottom: 0px;
    display: block;

    @media (max-width: $screen-xs-max) {
        bottom: 0px;
    }

    button.continue h2 {
        font-weight: $FONT_WEIGHT_SEMIBOLD;

        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }

        @media (min-width: $screen-sm-min) {
            font-size: 20px;
        }
    }

    // the lesson-start-scree-footer and the
    // lesson-finish-screen-footer on mobile
    // shows up in the footer
    .front-royal-footer & {
        button.continue {
            width: 100%;
            max-width: 448px;
            min-height: $CONTINUE_BUTTON_HEIGHT;
            display: block;
            margin: 0px auto;
            text-transform: uppercase;
        }

        @media (max-width: $screen-xs-max) {
            bottom: 0px;

            button.continue {
                border-radius: 0px;
            }
        }

        @media (min-width: $screen-sm-min) {
            bottom: $CONTINUE_BUTTON_DESKTOP_PADDING;
        }
    }

    transition: all 0.5s ease 0s;
    opacity: 1;
    transform: translateY(0px);

    &.ng-enter-hacked-up,
    &.ng-leave-hacked-up,
    .ng-enter-hacked-up &,
    .ng-leave-hacked-up & {
        transform: translateY(50px);
        transition: all 0.5s ease 0s;
        opacity: 0;
    }
}
