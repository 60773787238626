.full-profile {
    padding: 0 20px 5px;

    h4 {
        font-size: 12px;
        line-height: 1;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        text-transform: uppercase;
        color: $COLOR_V3_GREY_DARK;
        margin: 20px 0 12px;
        display: table;
        white-space: nowrap;
        overflow: hidden;

        &::before,
        &::after {
            content: "";
            border-top: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            display: table-cell;
            position: relative;
            top: 0.33em;
            width: 50%;
        }

        &::before {
            right: 10px;
        }

        &::after {
            left: 10px;
        }
    }
}

.experience-bars {
    ul {
        margin: 15px 0 0;
        padding: 0;
    }

    li {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;

        @media (max-width: $screen-xs-max) {
            padding-bottom: 20px;
        }

        .role-label {
            display: inline-block;
            width: 100px;
            font-size: 12px;
            line-height: 12px;
            vertical-align: middle;
            padding-right: 3px;
        }

        .role-bar-container {
            display: inline-block;
            width: calc(100% - 100px);
            background: url("~images/dot-bg.png") repeat-x 100% 50%;
        }

        .role-bar {
            max-width: 100%;
            background: $COLOR_V3_BLUE;
            border-radius: 4px;
            line-height: 20px;
            padding: 0 5px;
            color: $COLOR_V3_WHITE;
            vertical-align: middle;
            font-size: 12px;

            span {
                @media (max-width: $screen-xs-max) {
                    color: $COLOR_V3_BLUE;
                    display: block;
                    transform: translateY(20px);
                }
            }
        }
    }

    p {
        font-size: 12px;
    }
}

.experience {
    @extend .clearfix;
    margin-bottom: 15px;
    font-size: 15px;
    color: $COLOR_V3_GREY_DARKER;
    word-wrap: break-word;

    &.center {
        font-size: 14px;
    }

    .title,
    .date {
        line-height: 1;
    }

    .title {
        width: 67%;
        float: left;

        b {
            font-size: 14px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        div {
            font-size: 14px;
            color: $COLOR_V3_GREY_DARK;
        }
    }

    .date {
        width: 33%;
        float: right;
        text-align: right;

        .date-subtext {
            font-size: 12px;
        }
    }

    ul {
        clear: both;
        padding: 5px 20px 0;
        font-size: 14px;
        color: $COLOR_V3_GREY_DARK;
    }
}
