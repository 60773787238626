.card-list-item student-profile-list-card {
    .has-tooltip,
    .current-experience {
        display: block;
        width: 100%;
    }

    .tooltip {
        transform: none !important;
        white-space: normal;
        height: fit-content;

        @media (min-width: $screen-sm-min) {
            width: 432px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
        }

        &::before,
        &::after {
            left: 50%;
        }
    }

    header {
        position: relative;
        min-height: 120px;

        @media (min-width: $screen-xs-min) {
            min-height: 0;
        }

        &.has-contact {
            @media (min-width: $screen-xs-min) {
                padding-right: 120px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 10px;
                    padding-left: 120px;
                }
            }

            @media (min-width: $screen-sm-min) {
                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 20px;
                }
            }
        }

        .status:hover {
            background: none;
        }

        .contact {
            position: absolute;
            top: 80px;
            left: 5px;
            padding: 4px 0 2px;
            background: none;
            border: 2px solid $COLOR_V3_BLUE;
            border-radius: 4px;
            color: $COLOR_V3_BLUE;
            font-size: 12px;
            line-height: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-transform: uppercase;
            width: 80px;
            text-align: center;
            transition: 0.25s ease;
            outline: none;
            transform: scale(0.85);

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: 5px;
            }

            &:hover {
                color: $COLOR_V3_BLUE_DARK;
                border-color: $COLOR_V3_BLUE_DARK;
            }

            @media (min-width: $screen-xs-min) {
                top: 50%;
                left: auto;
                right: 20px;
                transform: translateY(-50%);

                // rtl-language overrides
                html[dir="rtl"] & {
                    right: auto;
                    left: 20px;
                }
            }
        }

        .links {
            margin: 6px 0 6px 80px;

            @media (min-width: $screen-sm-min) {
                margin-left: 100px;
            }

            a {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: $COLOR_V3_BLUE;
                text-transform: uppercase;

                &:hover {
                    color: $COLOR_V3_BLUE_DARK;
                }

                i {
                    margin-right: 3px;
                }
            }
        }
    }

    .body {
        .programs {
            img {
                display: block;
            }

            dd {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                line-height: 18px;
                padding: 0 0 4px 0;

                &:first-of-type {
                    padding-top: 8px;
                }
            }
        }

        dd.interests {
            margin-bottom: -5px;
        }

        dd.education {
            padding-top: 9px;
        }

        dd.looking {
            span {
                overflow-wrap: break-word;
            }
        }
    }

    .interest {
        display: inline-block;
        margin: 0 5px 5px 0;
        border: 1px solid $COLOR_V3_BEIGE_DARKER;
        border-radius: 4px;
        color: $COLOR_V3_BEIGE_DARKEST;
        padding: 3px 5px;
        transition: 0.25s ease;
        cursor: pointer;

        &::before {
            content: "#";
            font-family: Arial, sans-serif;
            margin-right: 5px;
        }

        &:hover {
            color: $COLOR_V3_MAP_BLUE_DARK;
            border-color: $COLOR_V3_MAP_BLUE_DARK;
        }

        // rtl-language overrides
        html[dir="rtl"] & {
            margin: 0 0 5px 5px;

            &::before {
                margin: 0 0 0 5px;
            }
        }
    }
}
