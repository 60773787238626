onboarding-hybrid-start {
    width: 100%;
    height: 100%;
    position: absolute;
    &::-webkit-scrollbar {
        display: none !important;
    }
    * {
        display: inline-block;
        box-sizing: border-box;
    }
    #skip_button {
        position: absolute;
        right: 5px;
        z-index: 120;
        font-size: 13px;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: white;
        padding: 15px;
    }
    #nav {
        width: 62px;
        height: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: fixed;
        left: 50%;
        bottom: 31px; // see also: iphonex.scss
        transform: translateX(-50%);
        z-index: 100;
        .nav__item {
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #e5e5e5;
            float: left;
        }
        .nav__item--active {
            background-color: $color_smartly_red;
        }
        &.__first-page {
            .nav__item {
                background-color: rgba(255, 255, 255, 0.3);
                &.nav__item--active {
                    background-color: white;
                }
            }
        }
    }
    .animate {
        transition: transform 0.3s ease-out;
    }
    .slider {
        width: 100%;
        height: 100%;
        overflow: hidden;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    .holder {
        width: 500%;
        max-height: 100%;
        height: 100%;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    .slide-wrapper {
        width: 20%;
        height: 100%;
        position: relative;
        background-color: white;
        float: left;
        text-align: center;

        .inner_page_wrapper {
            background-repeat: no-repeat;
            background-size: contain;
        }

        img {
            margin: auto;
        }
        .inner_page_wrapper {
            height: 568px;
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .page__hexagon_wrapper {
            width: 100%;
            height: 321px;
            position: relative;
            text-align: center;

            img.hexagon {
                width: 251px;
                margin-top: 49px;
            }
            /* .lights is the first page illustration over the hexagon */
            .lights {
                position: absolute;
                z-index: 100;
                top: 77px;
                left: 50%;
                transform: translateX(-50%);
                height: 160px;
            }
            .certificate {
                width: 139px;
                height: 88px;
                position: absolute;
                top: 112px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 150;
            }
            .iphone_wrapper {
                position: absolute;
                z-index: 100;
                width: 251px;
                top: 3px;
                left: 50%;
                transform: translateX(-50%);
            }
            .iphone {
                position: absolute;
                z-index: 100;
                top: 73px;
                left: 50%;
                height: 203px;
            }
            .iphone_picture {
                width: 24px;
                height: 24px;
                position: absolute;
                z-index: 110;
                top: 119px;
                left: 89px;
            }
            .staggered_hexagon_profile_pictures {
                width: 251px;
                height: 100%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0px;
                img {
                    transform: scale(0.1);
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    &:nth-child(1) {
                        top: 77px;
                        left: 7px;
                    }
                    &:nth-child(2) {
                        top: 22px;
                        left: 101px;
                    }
                    &:nth-child(3) {
                        top: 77px;
                        left: 195px;
                    }
                    &:nth-child(4) {
                        top: 183px;
                        left: 195px;
                    }
                    &:nth-child(5) {
                        top: 238px;
                        left: 101px;
                    }
                    &:nth-child(6) {
                        left: 7px;
                        top: 183px;
                    }
                }
            }
            .no_cost_wrapper {
                width: 251px;
                position: absolute;
                top: 0px;
                left: 50%;
                z-index: 170;
                transform: translateX(-50%);
            }
            .no_cost {
                width: 56px;
                height: 56px;
                border-radius: 28px;
                position: absolute;
                top: 86px;
                left: 174px;
                z-index: 160;
                display: none;
                background-color: $color_smartly_red;
                color: white;
                line-height: 56px;
                font-weight: $FONT_WEIGHT_MEDIUM;
                font-size: 26px;
            }
        }
        h2 {
            font-size: 18px;
            margin: 0px auto;
            font-weight: $FONT_WEIGHT_MEDIUM;
        }
        p.page__text {
            max-width: 260px;
            margin: auto;
            margin-top: 58px;
            font-size: 15px;
            line-height: 22px;
            &.page__text--last {
                margin-top: 26px;
            }
        }
        &:nth-of-type(1) {
            background-color: $color_smartly_red;
            .inner_page_wrapper {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Trail-0"));
                background-position: 0px 288px;
            }
            .logo {
                margin-top: 139px;
            }
            h1.page__title {
                width: 170px;
                height: 50px;
                margin: 30px auto 0px auto;
                font-size: 16px;
                line-height: 25px;
                color: white;
            }
        }
        &:nth-of-type(2) {
            .inner_page_wrapper {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Trail-1"));
                background-position: -6px 288px;
            }
            z-index: 120;
        }
        &:nth-of-type(3) {
            .inner_page_wrapper {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Trail-2"));
                background-position: 0px 300px;
            }
        }
        &:nth-of-type(4) {
            .inner_page_wrapper {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Trail-4"));
                background-position: 0px 288px;
            }
        }
        &:nth-of-type(5) {
            .inner_page_wrapper {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Trail-5"));
                background-size: 50%;
                background-position: 8px 289px;
            }
            .page__hexagon_wrapper {
                z-index: -1;
            }
            img {
                position: relative;
                z-index: -1;
            }

            a.call_to_action {
                display: inline-block;
                box-sizing: border-box;
                height: 46px;
                width: 260px;
                margin-top: 36px;
                background-color: $color_smartly_red;
                border-radius: 4px;
                color: white;
                font-size: 16px;
                line-height: 46px;

                // short devices get special treatment
                @media (max-height: $onboarding_short_device_max_height) {
                    margin-top: 15px;
                }
            }
            h5 {
                max-width: 260px;
                padding: 20px 0px;
                margin: 0px auto;

                // short devices get special treatment
                @media (max-height: $onboarding_short_device_max_height) {
                    padding-top: 5px;
                }

                span {
                    float: left;
                    font-size: 13px;
                    line-height: 16px;
                    color: #b5b5b5;
                    font-weight: 300;
                }
                a {
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: $FONT_WEIGHT_BOLD;
                    letter-spacing: -0.1px;
                    color: #949494;
                    padding: 0px 3px;
                }
            }
        }
    }
}
