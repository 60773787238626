.has-tooltip {
    position: relative;

    .tooltip {
        position: absolute;
        display: block;
        bottom: calc(100% + 10px);
        left: -130px;
        width: 280px;
        background: $COLOR_V3_BEIGE_DARKER;
        color: $COLOR_V3_WHITE;
        padding: 5px 10px;
        border-radius: 4px;
        margin-top: -10px;
        transform: size(0);
        transition: opacity 0.25s ease, margin-top 0.25s ease;

        &::before {
            content: "";
            border: 10px solid transparent;
            border-top-color: $COLOR_V3_BEIGE_DARKER;
            position: absolute;
            bottom: -20px;
            left: 125px;
        }

        @media (min-width: 400px) {
            left: -20px;
            width: 440px;
            max-width: 80vw;
            top: calc(100% + 10px);
            bottom: auto;

            &::before {
                top: -20px;
                bottom: auto;
                left: 15px;
                border-bottom-color: $COLOR_V3_BEIGE_DARKER;
                border-top-color: transparent;
            }
        }
    }

    &:hover .tooltip {
        opacity: 1;
        margin-top: 0;
        transform: size(1);
    }
}
