/*
    These are the button skins used for answer selection.
    TODO: https://trello.com/c/2CQOe9Hb/928-chore-rename-primary-buttons-to-something-more-semantic


    Rules about button sizing:

    If there are "few" buttons (<=2 rows) then we get the tall_buttons or tall_image_buttons size
    If there are "many" buttons (>2 rows) then we get the short_buttons or short_image_buttons size

    Those classes set the min-height on all buttons on the page to be:

    ______________| text            | image                         |
    short_buttons | 2 lines of text | desktop=100px/mobile=smaller  |
    tall_buttons  | 3 lines of text | desktop=160px/mobile=smaller  |

    Since the font-size is dependent upon the breakpoint, text buttons will be a bit shorter
    on mobile than on desktop, but this is always defined as a number of lines.
*/

@use "sass:math";

button.primary {
    $imageButtonHeight: 160px;
    $imageButtonHeightSmall: 100px;
    $xsButtonScale: math.div(45, 64);
    $fontSizeButtonRegular: 18px;
    $fontSizeButtonSmMin: 16px;
    $buttonShift: 3px;
    $longButtonWidth: 310px;
    $borderRadius: 3px;
    $borderWidth: 3px;
    $transitionStyle: 0.15s ease-out;

    transition: color $transitionStyle, background-color $transitionStyle, border $transitionStyle,
        transform $transitionStyle, box-shadow $transitionStyle;

    position: relative;
    display: inline-block;
    user-select: none;

    border: $borderWidth solid transparent;

    border-radius: $borderRadius;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
    outline: 0;

    background: $COLOR_V3_WHITE;
    color: $COLOR_V3_EGGPLANT;

    margin: 5px; // I had to remove an !important from here. Not sure what I broke
    padding: 0px; // do not add any padding. it is handled by n_lines_high_button
    width: $longButtonWidth;

    font-size: $fontSizeButtonRegular;
    line-height: $fontSizeButtonRegular + 1px;
    vertical-align: top;
    text-align: left;

    transform: translateZ(0);

    // rtl-content overrides
    div[dir="rtl"] & {
        text-align: right;
    }

    .tall_buttons,
    .short_buttons,
    .tall_image_buttons,
    .short_image_buttons {
        text-align: center;
    }

    .single_column & {
        width: 80%;

        @media (max-width: $screen-xs-max) {
            width: calc(100% - 10px);
        }
    }

    /* if a button is inside an element with the .tall_buttons class ... */
    .tall_buttons & {
        @include n_lines_high_button(
            3,
            $fontSizeButtonRegular,
            // font-size desktop
            $fontSizeButtonSmMin,
            // font-size mobile
            2 * $borderWidth
        );
    }

    /* if a button is inside an element with the .short_buttons class ... */
    .short_buttons & {
        font-size: $FONT_SIZE_CONTENT_SMALLER;
        line-height: $FONT_SIZE_CONTENT_SMALLER + 1px;

        @include n_lines_high_button(
            2,
            $fontSizeButtonRegular,
            // font-size desktop
            $fontSizeButtonSmMin,
            // font-size mobile
            2 * $borderWidth
        );
    }

    /* if a button is inside an element with the .tall_buttons class ... */
    .tall_image_buttons & {
        @media (min-width: $screen-sm-min) {
            min-height: $imageButtonHeight;
        }
        @media (max-width: $screen-xs-max) {
            min-height: $xsButtonScale * $imageButtonHeight;
        }
    }

    /* if a button is inside an element with the .short_buttons class ... */
    .short_image_buttons & {
        font-size: $FONT_SIZE_CONTENT_SMALLER;
        line-height: $FONT_SIZE_CONTENT_SMALLER + 1px;

        @media (min-width: $screen-sm-min) {
            min-height: $imageButtonHeightSmall;
        }
        @media (max-width: $screen-xs-max) {
            min-height: $xsButtonScale * $imageButtonHeightSmall;
        }
    }

    // hover styles look shitty on mobile.  disable them with no-touch
    .no-touchevents &:hover:enabled {
        //border-color: $COLOR_V3_CORAL;
        box-shadow: 0px 0px 13px 0px $COLOR_V3_CORAL; //0px 0px 13px 0px rgba(0, 0, 0, 0.30);

        .button_index {
            // NOTE: if we ever want to show indices (see above), we need to enable the following:
            // display: block;
        }
    }

    .no-touchevents &:active:enabled,
    .touchevents &:active:enabled {
        background-color: $COLOR_V3_BEIGE_DARKER; //$COLOR_V3_CORAL;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
        //border-color: $COLOR_V3_CORAL;
        color: $COLOR_V3_WHITE;

        .button_index {
            // NOTE: if we ever want to show indices (see above), we need to enable the following:
            // display: block;
        }
    }

    // wrapper for both the index and the label
    .button_text {
        display: block;

        .button_label {
            display: inline-block;
            width: 100%;

            cf-ui-component {
                display: inline-block;
                width: 100%;

                cf-text {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    div.icon {
        position: absolute;
        top: -18px;
        right: -15px;
        opacity: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 44px;
        height: 44px;
    }

    &:disabled {
        opacity: 0.5;
    }

    //-------------------------
    // Correct Answer Styling
    //-------------------------

    &.correct {
        background-color: $COLOR_ANSWER_CORRECT;
        border-color: $COLOR_ANSWER_CORRECT;
        color: $COLOR_V3_WHITE;

        div.icon {
            opacity: 1;
            background-image: url("~images/hexagon_correct.png");
        }

        p span {
            color: $COLOR_V3_WHITE; // ensures that colored text in correct buttons changes to white for better readability
        }

        &:disabled,
        &:hover,
        &:active,
        &:focus {
            background-color: $COLOR_ANSWER_CORRECT;
            border-color: $COLOR_ANSWER_CORRECT;
            color: $COLOR_V3_WHITE;
            opacity: 1;
            .button_index {
                display: none;
            }
        }

        // since all challenge validators immediately disable, and we have a special
        // disabled state for correct buttons, actually animate the transition from
        // light-green to the darker variant, along with icon fading
        &:disabled {
            transition: none; // ios seemed to be having trouble with a transition and an animation on one element. see https://trello.com/c/ttdBr4LS/66-bug-odd-button-styling-on-check-many-in-ios-app
            animation-duration: $ANSWER_VALIDATION_DURATION;
            animation-name: correct_button_disabled; // animations.scss

            color: $COLOR_V3_WHITE;
            border-color: $COLOR_V3_TURQUOISE_DARK;
            background-color: $COLOR_V3_TURQUOISE_DARK;
            box-shadow: none;
            cursor: default;

            div.icon {
                animation-duration: $ANSWER_VALIDATION_DURATION;
                animation-name: pulse-then-fade-out; // animations.scss
                opacity: 0;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -5px;
            bottom: -5px;
        }
        &:active:after {
            top: -5px;
            left: -5px;
            right: -2px;
            bottom: -2px;
        }
    }

    //-------------------------
    // Incorrect Answer Styling
    //-------------------------

    &.incorrect {
        background-color: $COLOR_ANSWER_INCORRECT;
        border-color: $COLOR_ANSWER_INCORRECT;
        color: $COLOR_V3_WHITE;

        div.icon {
            background-image: url("~images/hexagon_incorrect.png");
            animation-duration: $ANSWER_VALIDATION_DURATION;
            animation-name: pulse-then-fade-out; // animations.scss
            opacity: 0;
        }

        p span {
            color: $COLOR_V3_WHITE; // ensures that colored text in incorrect buttons changes to white for better readability
        }

        &:disabled,
        &:hover,
        &:active,
        &:focus {
            opacity: 1;
            background-color: $COLOR_ANSWER_INCORRECT;
            border-color: $COLOR_ANSWER_INCORRECT;
            color: $COLOR_V3_WHITE;
            .button_index {
                display: none;
            }
        }
    }

    //-------------------------
    // Selected Answer Styling
    //-------------------------

    &.selected:not(.incorrect):not(.correct) {
        color: $COLOR_V3_WHITE;
        box-shadow: none;

        &:disabled,
        &:hover:enabled,
        &:active:enabled,
        &:focus:enabled {
            color: $COLOR_V3_WHITE;
            .button_index {
                display: none;
            }
        }
    }

    //-------------------------
    // Responsive Tweaks
    //-------------------------

    @media (max-width: $screen-xs-max) {
        width: calc(50% - 10px);
        font-size: $FONT_SIZE_CONTENT_SCREEN_SMALL_MIN; // took out an important here
        line-height: 14px; // took out an important here
        padding: 2px 2px;

        span.button_index {
            display: none !important;
        }
    }
}
