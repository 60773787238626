@mixin filter-label-content($text) {
    .selectize-control .selectize-input {
        &,
        &.dropdown-active {
            &:before {
                content: $text;
                display: inline-block;
                float: left;
                padding-right: 5px;
                position: static;
            }
        }
    }
}

.filter-bar {
    margin-bottom: -6px;

    .input-wrapper {
        &:not(.no-minimum) {
            min-width: 160px;
        }

        min-height: 37px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
        text-align: left; // undo the right alight in .right wrappers

        // rtl-language overrides
        html[dir="rtl"] & {
            margin-right: 0px;
            margin-left: 5px;
        }

        // styling for text filters
        .input-group {
            .input-group-addon {
                color: $COLOR_V3_BEIGE_DARKEST;
                border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                background-color: transparent;
                font-size: 15px;
                line-height: 29px;
                padding: 0px 10px 0px 10px;
                cursor: default;

                &.selected {
                    background-color: $COLOR_V3_BEIGE !important;
                }

                &.faux-button {
                    cursor: pointer;
                }
            }
        }

        // styling for list filters
        .selectize-input,
        .styled-as-input {
            padding-left: 10px;
            padding-right: 25px; // make room for little arrow

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 25px; // make room for little arrow
                padding-right: 10px;
                display: flex;
            }

            > input {
                // rtl-language overrides
                html[dir="rtl"] & {
                    margin: 0 -2px 0 auto !important;
                }
            }
        }

        .selectize-dropdown {
            margin-top: 5px;
            width: auto !important;
            min-width: 160px;
        }

        &:last-of-type {
            margin-right: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0px;
            }
        }

        > input {
            width: 100%;
        }

        &.has-value {
            .selectize-input,
            .selectize-control.multi .selectize-input .item,
            .selectize-input input,
            .styled-as-input,
            .input-group .input-group-addon {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: $COLOR_V3_EGGPLANT;
                background-color: $COLOR_V3_BEIGE_LIGHTER;
            }

            .selectize-control.multi .selectize-input {
                .item {
                    background-color: $COLOR_V3_BEIGE_MIDDARK;
                    font-weight: $FONT_WEIGHT_NORMAL;

                    &.active {
                        background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        color: $COLOR_V3_WHITE;
                    }
                }
            }
        }

        &.date-zoom {
            min-width: 100px;
        }

        &.date-range {
            min-width: 160px;
        }

        .radio.small label {
            font-size: 12px !important;
            padding-top: 5px;
            input[type="radio"] {
                top: -5px;
            }
        }
    }

    .input-group .form-control:last-child {
        // rtl-language overrides
        html[dir="rtl"] & {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }
    }

    .input-group .input-group-addon:first-child {
        // rtl-language overrides
        html[dir="rtl"] & {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: 1px solid;
        }
    }
}
