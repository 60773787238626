.icon {
    &.pointer {
        cursor: pointer;
    }

    &.pending {
        &:before {
            @include font-awesome-icon("\f070"); // fa-eye-slash
        }
        &.small {
            margin-left: -4px;
            display: inline-block;
            position: relative;
            top: -2px;
        }
    }

    &.available {
        &:before {
            @include font-awesome-icon("\f06e"); // fa-eye
        }
        &.small {
            margin-left: -4px;
            display: inline-block;
            position: relative;
            top: -2px;
        }
    }

    &.remove {
        &:before {
            @include font-awesome-icon("\f2ed", $FONT_AWESOME_REGULAR); // fa-trash-alt
            margin-left: -4px;
        }
    }

    &.link {
        &:before {
            @include font-awesome-icon("\f08e"); // fa-external-link
            margin-left: -4px;
        }
    }

    &.arrow-up {
        &:before {
            @include font-awesome-icon("\f062"); // fa-arrow-up
            margin-left: -4px;
        }
    }

    &.arrow-down {
        &:before {
            @include font-awesome-icon("\f063"); // fa-arrow-down
            margin-left: -4px;
        }
    }

    &.delete {
        &:before {
            @include font-awesome-icon("\f057");
        }
    }
}
