form[name="work"],
form[name="education"] {
    .form-group.row .experience-detail {
        position: relative;
        padding: 20px 20px 10px;
        margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;
        background-color: $COLOR_V3_BEIGE_LIGHTER;

        input,
        select {
            margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;
            font-size: 16px;
        }
        select {
            padding-left: $SMARTLY_FORM_MARGIN_DEFAULT;
        }

        p.sub-text {
            margin-bottom: 2px;
        }

        // trash icon
        > .img-icon-container.pull-right {
            position: absolute;
            top: 29px;
            right: calc(4.15% - 7.5px); // centered within a virtual col-xs-1

            [dir="rtl"] & {
                right: auto;
                left: calc(4.15% - 7.5px); // centered within a virtual col-xs-1
            }
        }

        &.education {
            .institution-container {
                position: relative;
            }

            .performance-container {
                position: relative;
            }

            .degree-tooltip {
                display: none;
                font-size: 12px;
                border: none;
                background-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
                color: $COLOR_V3_WHITE;
                margin: 0;
                top: -42px;
                right: auto;
                left: -10px;
                height: 38px;

                .admin & {
                    top: -12px;
                }

                &.show {
                    display: block;
                }

                @media (min-width: $screen-sm-min) {
                    top: -42px;
                    left: 0px;
                }

                &,
                .popover-content {
                    max-width: 275px;
                    width: 275px;

                    @media (max-width: $screen-xs-max) {
                        right: -10px;
                    }
                }

                &.popover.top > .arrow:after {
                    border-top-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
                }
            }

            organization-autocomplete {
                input {
                    display: inline-block; // overrides the form-control class's display: block setting
                }
            }
        }

        &.work {
            choose-a-date select {
                width: calc(50% - 5px);
                margin-top: 0px;
                &:first-of-type {
                    html:not([dir="rtl"]) & {
                        margin-right: 5px;
                    }
                    html[dir="rtl"] & {
                        margin-left: 5px;
                    }
                }
                &:last-of-type {
                    html:not([dir="rtl"]) & {
                        margin-left: 5px;
                    }
                    html[dir="rtl"] & {
                        margin-right: 5px;
                    }
                }
            }

            choose-a-role,
            choose-an-item {
                padding: 0;
                select {
                    width: 100%;
                }
            }

            [name="current-container"] {
                display: inline-block;
                margin-left: 2px;

                @media (min-width: $screen-sm-min) {
                    .form-group {
                        margin-bottom: 0px;

                        label {
                            padding-top: 0px;

                            .current-span {
                                font-weight: $FONT_WEIGHT_MEDIUM;
                            }
                        }
                    }
                }
            }

            .add-an-item {
                margin-top: $SMARTLY_FORM_MARGIN_DEFAULT;

                .item {
                    background-color: $COLOR_V3_WHITE;
                }
            }
        }

        &.education {
            // selectize will pass down whatever is in [class] to .selectize-control,
            // but doesn't pass conditional classes from [ng-class]
            .detail-input {
                &,
                & + .selectize-control {
                    display: block;
                    float: left;
                    width: calc(33% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                    margin-right: $SMARTLY_FORM_MARGIN_DEFAULT;

                    &:last-child {
                        width: calc(34% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                        margin-right: 0;
                    }

                    @media (max-width: $screen-xs-max) {
                        max-width: inherit;
                        width: 100% !important;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                    }
                }

                & + .selectize-control {
                    margin-bottom: 10px;

                    .selectize-input {
                        display: block;
                        padding-left: 8px;
                    }
                }
            }

            .detail-input-small {
                &,
                & + .selectize-control {
                    display: block;
                    float: left;
                    width: calc(22% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                    margin-right: $SMARTLY_FORM_MARGIN_DEFAULT;

                    &:last-child {
                        width: calc(23% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                        margin-right: 0;
                    }

                    @media (max-width: $screen-xs-max) {
                        max-width: inherit;
                        width: 100% !important;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                    }
                }
            }

            .detail-input-half,
            .detail-input-half + .selectize-control.detail-input {
                &,
                &:last-child {
                    width: calc(50% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                }
            }

            .detail-input-full {
                &,
                &:last-child {
                    width: calc(100% - #{$SMARTLY_FORM_MARGIN_DEFAULT});
                }

                @media (max-width: $screen-xs-max) {
                    max-width: inherit;
                    width: 100% !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }

            .detail-input-double {
                &,
                &:last-child {
                    width: calc(66% - #{$SMARTLY_FORM_MARGIN_DEFAULT} / 2);
                }

                @media (max-width: $screen-xs-max) {
                    max-width: inherit;
                    width: 100% !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }

            p.detail-input {
                font-size: 14px;
                color: $COLOR_V3_BEIGE_DARKEST;

                @media (min-width: $screen-sm-min) {
                    padding-top: 4px;
                }
            }

            .institution-container {
                .selectize-control {
                    display: inline-block;
                    vertical-align: top;

                    input {
                        font-size: 16px;
                    }
                    .selectize-input .item {
                        font-size: 16px;
                        color: $COLOR_V3_BLACK;
                    }
                }

                [uib-typeahead-popup] {
                    .option-icon-default {
                        background-image: url("~vectors/graduationcap.svg");
                    }
                }
            }
        }
    }

    .company-input {
        width: 100%;
    }

    .scores-container {
        margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;

        label {
            margin-bottom: 2px;
        }
        p.sub-text {
            margin-top: 0px;
        }
        div {
            padding-left: 8px;
            padding-right: 8px;
        }
        input[type="number"] {
            width: 100%;
        }

        // remove spinner arrows
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }

        // remove spinner arrows
        input[type="number"] {
            appearance: textfield; /* Firefox */
        }

        .gmat-container,
        .act-container {
            input {
                width: 100%;
            }
            &.top-margin {
                margin-top: $SMARTLY_FORM_MARGIN_DEFAULT;
                margin-bottom: 0px;
            }
        }

        .sat-container {
            margin: ($SMARTLY_FORM_MARGIN_DEFAULT * 2) 0;
        }

        .score-on-sat {
            display: inline-block;
            width: calc(50% - 30px);
            vertical-align: top;

            .error-message {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .out-of {
            display: inline-block;
            vertical-align: top;
            width: 60px;
            text-align: center;
            color: $COLOR_V3_BEIGE_DARKEST;
            line-height: 48px;
        }

        .sat-max-score {
            width: calc(50% - 30px);
            vertical-align: top;
        }

        .gre-input {
            width: calc(33% - 2px);
            margin-right: 5px;
            display: inline-block;
            padding-left: 0;
            padding-right: 0;
            vertical-align: top;

            .error-message {
                padding-left: 0;
                padding-right: 0;
            }

            &:last-of-type {
                margin-right: 0px;
            }

            @media (max-width: $screen-xs-max) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 5px;
            }
        }

        .error-message {
            font-size: 14px;
            color: $COLOR_V3_RED;
            padding-top: 5px;
        }
    }
}
