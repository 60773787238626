offline-mode-consent-modal .offline-consent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: $screen-md-min) {
        padding: 0 20px;

        p.sub-text br {
            display: none;
        }
    }

    .offline-mode-icon {
        margin-bottom: 15px;

        &::before {
            font-size: 20px;
            padding: 8px;
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_ORANGE;
            border-radius: 100%;
            @include font-awesome-icon("\f072");
        }
    }

    p {
        margin-bottom: 0;
    }

    .switch-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        cursor: pointer;

        p.status {
            margin-right: 10px;
            width: 62px;
            text-align: center;
        }

        // We want to disable the default pointer events from the label
        // so that the user can click on the entire .switch-wrapper
        // (status text included) to trigger the ng-click.
        label {
            pointer-events: none;
        }
    }

    .btn {
        width: 100%;
        margin-top: 10px;
    }
}
