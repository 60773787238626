add-to-calendar-widget {
    .calendar-button {
        color: $COLOR_V3_CORAL;
    }

    .calendar-select {
        color: $COLOR_V3_CORAL;
        background: none;
        cursor: pointer;
    }

    [name="desktop-calendar"] {
        position: relative;
    }

    .calendar-options-wrapper {
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        background-color: $COLOR_V3_WHITE;
        color: $COLOR_V3_BLACK;
        font-weight: normal;
        min-width: 200px;
        border: 0px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 2px 15px rgba($COLOR_V3_BLACK, 0.25);
        z-index: 1000; // ensure the shadow is on top when the event is shorter
        font-size: 15px;

        .calendar-option {
            position: relative;
            padding: 4px 10px 4px 40px;
            text-align: left;
            cursor: pointer;

            &:first-of-type {
                margin-top: 4px;
            }
            &:last-of-type {
                margin-bottom: 4px;
            }

            i {
                position: absolute;
                display: block;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                opacity: 0.5;
                width: 20px;
                text-align: center;
            }

            &:hover {
                color: $COLOR_V3_CORAL;
                i {
                    color: $COLOR_V3_BLACK;
                    opacity: 1;
                }
            }
        }
    }

    &.calendar-options-left {
        .calendar-options-wrapper {
            transform: unset;
            left: -10px;
        }
    }
}
