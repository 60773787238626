.app-menu-mobile {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: $APP_MENU_MOBILE_HEIGHT;
    background-color: $COLOR_V3_CORAL;
    z-index: $APP_HEADER_Z_INDEX - 1;
    cursor: pointer;

    &.valar {
        background-color: $COLOR_V3_BLUE;
    }

    @media (max-width: $screen-sm-min) {
        border-top: 1px solid rgba($COLOR_V3_CORAL_DARK, 0.5);

        &.valar {
            border-top: 1px solid rgba($COLOR_V3_BLUE_DARK, 0.5);
        }
    }

    .btn-group {
        height: 100%;

        div.icon-group {
            color: $COLOR_V3_WHITE;

            > div {
                position: relative;
                width: 25px;
                margin: auto;

                & div.connection-number {
                    right: -8px;
                    top: -3px;
                }
            }

            img {
                margin: -5px 0 0 -2px;
            }

            p {
                font-size: 11.5px;
                letter-spacing: 0.5px;
                margin: -2px auto 0 auto;
                width: fit-content;

                html:lang(it) &,
                html:lang(ar) & {
                    @media (max-width: 360px) {
                        font-size: 9px;
                    }
                }

                html:lang(am) & {
                    @media (max-width: 370px) {
                        font-size: 9px;
                    }
                }
            }
        }
    }

    .active:not(.disabled) {
        &.btn-group {
            div.icon-group {
                p::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: white;
                    position: relative;
                    margin-top: -1px;
                    bottom: -3px;
                }
            }
        }
    }

    .disabled {
        opacity: 0.5;
        cursor: default;
    }

    img {
        height: 20px;
    }

    // don't know of a particularly better way of dealing with android
    // keyboard and viewport resizes
    @media only screen and (max-height: 350px) {
        visibility: hidden;
        display: none;
    }
}
