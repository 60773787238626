.cf_answer_list {
    /* markdown will add p tags to button text */
    p {
        display: inline;
    }

    &[renderer="poll"] {
        button {
            $fontSize: 18px; // web
            $fontSizeXS: 12px; // mobile

            $borderVerticalSpace: 6px; // 3px border on top and bottom
            @include n_lines_high_button(3, $fontSize, $fontSizeXS, $borderVerticalSpace);
            $height: n_lines_button_height(3, $fontSize, $borderVerticalSpace);
            $heightXS: n_lines_button_height(3, $fontSizeXS, $borderVerticalSpace);

            $fillColor: $COLOR_V3_BLUE_LIGHT;
            $transitionDuration: 1.2s;
            $borderColor: #507efb;
            $borderWidth: 3px;

            $borderRadius: $height * 0.5;
            $borderRadiusXS: $heightXS * 0.5;

            border-radius: $borderRadius;
            width: 373px;
            min-height: $height;
            position: relative;
            display: inline-block;

            // no border or padding on the button, only on outline and index
            border-width: 0px;
            padding: 0px;
            @include remove-outlines;
            margin-bottom: 6px;

            background: $COLOR_V3_WHITE;

            .frame-container.lte-xs & {
                border-radius: $borderRadiusXS;
                width: 100%;
                min-height: $heightXS;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }

            outline {
                width: 100%;
                height: 100%;
                z-index: 1; // behind button_index, in front of results bar
                position: absolute;
                top: 0px;
                left: 0px;
                border-radius: $borderRadius;

                /* Rectangle 1: */
                border: $borderWidth solid $borderColor;
                box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.3);

                .frame-container.lte-xs & {
                    border-radius: $borderRadiusXS;
                }
            }

            index {
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 2; // in front of outline
                display: block;
                width: $height; // we need to make it a liitle smaller
                height: 100%; // in case the button stretches taller, we need 100% instead of $height
                line-height: $height - 6px; //center vertically. Not sure I understand why the 6px makes it look better
                text-align: center;
                background-color: $COLOR_V3_WHITE;

                /* B: */
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 36px;
                transition: font-size 300ms ease;
                color: $borderColor;
                /* Oval 1: */
                border: $borderWidth solid #96b1fc;
                border-radius: $borderRadius;

                .frame-container.lte-xs & {
                    font-size: 24px;
                    width: $heightXS;
                    line-height: $heightXS - 6px;
                    border-radius: $borderRadiusXS;
                }
            }

            // this is the bar that fills up, showing the % of people who have
            // selected this answer
            .fill-bar {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 2 * $borderRadius; // do not start from 0
                // transform-origin: 0px 0px;
                // transform: scale(0, 1); // will be changed in javascript to animate larger
                height: 100%;
                background-color: $fillColor;
                z-index: 0;
                transition: width $transitionDuration;
                border-radius: $borderRadius;

                .frame-container.lte-xs & {
                    width: 2 * $borderRadiusXS; // do not start from 0
                    border-radius: $borderRadiusXS;
                }
            }

            .button_text {
                position: relative;
                font-weight: $FONT_WEIGHT_NORMAL;
                padding-right: $borderRadius;
                color: $COLOR_V3_EGGPLANT;
                display: block;
                text-align: left;
                margin-left: $height;
                white-space: normal;
                z-index: 1; //in front of results bar (defined in :after below)
                text-shadow: 0px 1px 1px #ffffff;

                .frame-container.lte-xs & {
                    margin-left: $heightXS;
                }

                // rtl-content overrides
                div[dir="rtl"] & {
                    padding-right: $nLineButtonTextPadding;
                    padding-left: $borderRadius;
                    margin-left: 0px;
                    margin-right: $height;
                    text-align: right;

                    .frame-container.lte-xs & {
                        margin-left: 0px;
                        margin-right: $heightXS;
                    }
                }
            }

            // rtl-content overrides
            div[dir="rtl"] & {
                index,
                outline,
                .fill-bar {
                    left: auto;
                    right: 0px;
                }
            }

            &:hover:enabled {
                index {
                    border-color: $borderColor;
                }
            }

            &:active:enabled {
                index {
                    border-color: $borderColor;
                    background-color: $borderColor;
                    color: $COLOR_V3_WHITE;
                }
            }

            &:disabled {
                opacity: 1;
            }

            &.challenge-complete {
                outline {
                    //border-width: 0px;
                    border-color: transparent;
                    transition: border-color $transitionDuration;
                    // transition on the border-width looks bad, because it can only go one pixel at a time
                }

                index {
                    font-size: 25px;
                    transition: border-color $transitionDuration, background $transitionDuration;

                    .frame-container.lte-xs & {
                        font-size: 20px;
                    }
                }

                &.selected {
                    outline {
                        transition: border-color $transitionDuration, background $transitionDuration;
                    }

                    index {
                        border-color: $fillColor;
                        background-color: $borderColor;
                        color: $COLOR_V3_WHITE;
                        transition: border-color $transitionDuration, background $transitionDuration;
                    }
                }
            }

            .message-preview {
                position: absolute;
                top: calc(50% - 10px);
                right: 3px;
                z-index: 100;

                // rtl-content overrides
                div[dir="rtl"] & {
                    right: auto;
                    left: 3px;
                }

                .lesson_popover {
                    color: $COLOR_V3_BEIGE_DARKER;
                    font-size: 13px;
                    line-height: 1em;
                    padding: 1px 4px;
                    background-color: transparent;
                    border: none;
                    .tile-prompt-board & {
                        color: rgba($COLOR_V3_WHITE, 0.8);
                    }
                }
            }

            .button_text {
                display: block;

                .button_label {
                    display: inline-block;
                    width: 100%;

                    cf-ui-component {
                        display: inline-block;
                        width: 100%;

                        cf-text {
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
