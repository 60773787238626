cf-challenge-blank {
    display: inline;
}

// must be an <a> tag so it can have an active state
a.cf-challenge-blank {
    position: relative;

    &,
    span {
        box-sizing: border-box;
        // ensure that embedded mathjax gets set back to its normal box-sizing
        span[class^="mjx-"],
        span[class*=" mjx-"] {
            box-sizing: initial;
        }
    }

    // prevent unsightly underlines
    &:hover {
        text-decoration: none;
    }

    &.stretch-to-fit {
        width: 100%;
        height: 100%;
        // This is for proper positioning of the completed text
        // (but we don't want it to apply to the hint button.  That needs to
        //  be text-align center)
        .stretch {
            display: inline-block !important;
            width: 100% !important;
            height: 100% !important;
            text-align: left !important;
            line-height: 1em !important;
            vertical-align: middle;

            // rtl-content overrides
            div[dir="rtl"] & {
                text-align: right !important;
            }
        }
    }

    // in order for the click-target to work,
    // we need to be either block or inline-block.
    // If we're inside a cf-text, then we're inline,
    // otherwise we're .._on_image
    display: block;

    .cf-text & {
        display: inline-block;

        // Upon completion, undo all display: inline-block so the thing will wrap correctly
        &.completed {
            display: inline; // challenge-blank a tag
            > span,                     // blank-wrapper
            > span > span,              // ng-switch span
            > span > span > span {
                // inner text span
                display: inline !important;
            }
        }
    }

    input,
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 1px;
        resize: none; // no little resize icon
        overflow: hidden;
        border: 1px solid #d9d9d9;

        // no scroll bar
        &:focus {
            border-color: $COLOR_V3_CORAL !important;
            border-style: solid;
            border-width: 2px;
            outline-color: transparent;
            outline-width: 0px;
            padding: 0px;
        }
    }

    // on a mobile device, let the user know that they
    // successfully clicked, so that even if it takes
    // a moment for the keyboward to popup, they know it worked
    .touchevents &:active {
        input,
        textarea {
            // we need the !important so that we win the fight with
            // cf-challenge-overlay-blank
            background-color: $COLOR_V3_BEIGE_LIGHT !important;
        }
    }
    // this just exists so that it can be
    // z-indexed above the click-target, so that clicks within the
    // blank, for example clicks on the hint button, will
    // still work.
    .blank-wrapper {
        position: relative;
        z-index: 2;
        display: inline-block;
        // necessary to make resetDimensions() work
    }

    .multiple_choice.blank {
        display: inline-block;
        box-shadow: $FILL_IN_THE_BLANK_UNSELECTED_BOX_SHADOW;
        vertical-align: middle;
        width: 85px;
        overflow: hidden;
        white-space: nowrap;

        // Hide the blank even if it's colored text
        color: transparent;

        // Hide span and code elements within the blank until they are completed (see '.completed' below)
        span,
        code {
            visibility: hidden;
        }

        border: 0px solid transparent;
        border-radius: 3px;

        &.selected {
            box-shadow: $FILL_IN_THE_BLANK_SELECTED_BOX_SHADOW;
            background-color: rgba($BLANK_UNDERLINE_SELECTED_COLOR, 0.1);

            span,
            code {
                visibility: hidden;
            }
        }

        &.completed {
            background-color: transparent;
            font-weight: $FONT_WEIGHT_BOLD;
            width: auto;
            overflow: visible;
            color: $COLOR_ANSWER_CORRECT;
            box-shadow: none;
            text-align: center;
            border: none;
            vertical-align: 0;
            white-space: normal;

            span,
            code {
                visibility: visible;
            }

            // Tricky: in MathJax, elements can be made invisible with the .mjx-mphantom class
            // This allows things like invisible characters that still take up space. It's
            // helpful in matrices to line up elements, for example. This rule ensures those
            // elements remain invisible when they're part of a blank that is completed.
            .mjx-mphantom {
                span,
                code {
                    visibility: hidden;
                }
            }

            code & {
                vertical-align: baseline;
                font-family: $font-family-monospace;
                font-weight: bold;
                color: $code-color;
                white-space: pre;
                white-space: pre-wrap;
            }
        }
    }

    .user_input.blank {
        display: inline-block;

        &.incomplete.shows-correctness-during-input,
        &.completed,
        &.editable {
            color: $COLOR_ANSWER_CORRECT;
        }

        &.incorrect.shows-correctness-during-input {
            color: $COLOR_ANSWER_INCORRECT;
        }

        &.completed,
        &.editable {
            font-weight: $FONT_WEIGHT_BOLD;
            white-space: normal;

            code & {
                vertical-align: baseline;
                font-family: $font-family-monospace;
                font-weight: bold;
                color: $code-color;
                white-space: pre;
                white-space: pre-wrap;
            }
        }
    }

    &.inline {
        height: $line-height-base * 1em;

        .multiple_choice.blank {
            height: $line-height-base * 1em;
        }

        .user_input.blank {
            height: $line-height-base * 1em;

            input,
            textarea {
                height: $line-height-base * 1em;
            }
        }
    }

    [within-mathjax] & {
        &.completed {
            color: inherit;
        }

        // Mathjax should not be made inline when completed, or its positioning will get messed up
        .cf-text & {
            &.inline.completed {
                display: inline-block;
            }
        }

        /*
            Normally, all multiple choice blanks are a particular width.
            That does not work well for blanks wihin mathjax, so we go
            back to auto for those.
        */
        .multiple_choice.blank {
            width: auto;
            height: auto;
            box-shadow: none;
            background-color: transparent;

            &:after {
                content: "";
                width: 100%;
                height: 1em; // Don't make it too tall
                background-color: rgba($COLOR_V3_WHITE, 0.6);
                box-shadow: $FILL_IN_THE_BLANK_UNSELECTED_BOX_SHADOW;
                min-width: 10px; // Keep the blank visible, even if it's small
                position: absolute;
                top: calc(50% - 0.75em); // vertical center, minus 0.25em offset
                left: 50%;
                transform: translateX(-50%);
                border: 0px solid transparent;
                border-radius: 3px;
            }

            &.selected:not(.completed):after {
                box-shadow: $FILL_IN_THE_BLANK_SELECTED_BOX_SHADOW;
                background-color: rgba(
                    $BLANK_UNDERLINE_SELECTED_COLOR,
                    0.2
                ); // 0.2, since we don't have a background color on the underlying blank
            }

            &.completed:after {
                box-shadow: none;
                background-color: transparent;
            }

            &.completed {
                height: auto;
                white-space: nowrap;

                // This fixes a weird alignment issue that occurs in Firefox only (weird) when using vertical-align:top (which is set above)
                // See https://trello.com/c/y0ccKBoc
                vertical-align: unset;

                .mjx-mstyle.mjx-extension-color & {
                    color: inherit;
                }
            }
        }
        &.inline {
            height: auto;

            .multiple_choice.blank:after {
                height: 1.428571429em;
            }
        }

        .user_input.blank {
            position: relative;
            height: auto;

            input,
            textarea {
                position: absolute;
                display: block;
                bottom: 0px;
                top: -0.25em;
                left: 0;
                height: $line-height-base * 1em;

                // Reduce height of compose blanks a little bit so it doesn't butt up against things above when inside a fraction
                .mjx-mfrac & {
                    vertical-align: middle !important;
                    font-size: calc(1em - 2px) !important;
                }

                // if we're inside of an Arabic flipped block of MathJax, unflip ourselves
                .mfliph & {
                    transform: scaleX(-1);
                }
            }

            .complete_container {
                white-space: nowrap;

                &.ng-hide {
                    visibility: hidden;
                    display: block !important; // we actually want to force this element to layout the math appropriately
                }
            }

            // rtl-content overrides
            div[dir="rtl"] & {
                input,
                textarea {
                    direction: rtl;
                    text-align: right;
                }
            }
        }

        .blank {
            // do not override Mathjax's font,
            // and do not make the text bold
            &.completed,
            &.editable {
                font-family: inherit;
                font-weight: normal;
            }
        }
    }
}
