.tuition-and-registration {
    a {
        color: $COLOR_V3_EGGPLANT;
    }

    strong,
    b {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }

    registered-manage-billing,
    cohort-registration-no-plan-selection,
    cohort-registration-full-scholarship,
    cohort-registration-plan-selection,
    cohort-registration-multiple-section-configs {
        p,
        span {
            color: $COLOR_V3_BLACK;
        }
    }

    .registration-header {
        h2 {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 24px;
            margin-top: 30px;
            text-align: center;
        }
    }

    .scholarship-info {
        background: rgba($COLOR_PIXELMATTERS_GREY_LIGHT, 0.1);
        border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;
        padding: 18px 15px 18px 15px;

        @media (min-width: $screen-sm-min) {
            padding: 20px 15px 20px 15px;
        }

        .ribbon-container {
            float: left;
            width: 22px;
            height: 28px;
            margin: 0px 12px 91px 0px;

            @media (min-width: $screen-sm-min) {
                width: 30px;
                height: 38px;
                margin: 0px 17px 56px 16px;
            }
        }

        h2 {
            font-size: 24px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            margin-top: 0px;
        }

        p {
            color: $COLOR_PIXELMATTERS_GREY_DARK;
            font-size: 14px;
            line-height: 22px;
            font-weight: $FONT_WEIGHT_NORMAL;
            margin-bottom: 0px;

            @media (min-width: $screen-lg-min) {
                font-size: 15px;
            }
        }

        .scholarship-amount {
            height: 23px;
            background: rgba($COLOR_PIXELMATTERS_GREEN_DARKER, 0.2);
            border: 1px solid rgba($COLOR_PIXELMATTERS_GREEN_DARKER, 0.4);
            border-radius: 4px;
            color: $COLOR_PIXELMATTERS_GREEN_DARKER;

            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 16px;
            white-space: nowrap;

            @media (min-width: $screen-sm-min) {
                font-size: 17px;
            }
        }
    }

    .registration-subheader {
        text-align: center;
        margin: 0 15px 20px 15px;
        font-size: 14px;

        @media (min-width: $screen-sm-min) {
            width: 507px;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        &.restarting-subscription-container {
            @media (min-width: $screen-sm-min) {
                width: 555px;
            }
        }

        .restarting-subscription {
            .billed-at {
                margin-bottom: 0px;

                b {
                    text-decoration: underline;
                    font-size: 14px;
                    color: $COLOR_PIXELMATTERS_GREEN_DARKER;
                }
            }

            .made-x-payments {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 15px;
                margin-bottom: 16px;
            }
        }
    }

    .registration-body {
        text-align: center;

        .register-button-container {
            .register-btn {
                align-self: flex-end;
            }
        }

        .register-btn {
            display: block;
            margin: 0 auto;
            margin-bottom: 30px;
            width: 210px;
            font-size: 20px;
            padding: 7.5px 16px;
            min-height: 40px;

            .register-btn-text {
                color: inherit;
            }

            .register-btn-icon {
                display: inline-block;
                margin: 0 0 0 5px;
                color: inherit;
            }

            @media (min-width: $screen-sm-min) {
                width: 200px;
            }
        }

        .allow-visible-profile-checkbox-container {
            width: 270px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            text-align: center;

            label {
                padding: 0;
            }
        }

        .new-browser {
            margin: 30px 15px 0px 15px;
        }
    }

    .registration-footer {
        border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;

        .sub-text {
            text-align: center;
            font-size: 12px;
            line-height: 15px;
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    paid-program-welcome-package {
        margin: 0 15px;

        @media (min-width: $screen-sm-min) {
            margin: 0 30px;
        }
    }

    .registration-graphic {
        background: url("~vectors/scholarship_ribbon.svg") no-repeat 0 0 / 40px 54px;
        padding: 7px 0 0 55px;
        margin-bottom: 20px;
        line-height: 22px;
        min-height: 54px;

        @media (min-width: $screen-sm-min) {
            position: absolute;
            right: 20px;
            height: 195px;
            top: 20px;
            margin: 0;
            width: auto;

            &.bump {
                top: 50px;
            }
        }

        .graphic-title {
            font-size: 18px;
            color: $COLOR_V3_GREY_DARKER;
            min-width: 160px; // ensure it doesn't get too narrow

            strong {
                font-size: 32px;
            }
        }

        .graphic-subtitle {
            color: $COLOR_V3_BEIGE_DARKEST;
        }
    }

    .registration-narrative {
        @media (min-width: $screen-sm-min) {
            padding-right: 260px;
        }
    }

    .registration-body-footer {
        @extend .clearfix;
        position: relative;
        background: $COLOR_V3_BEIGE_LIGHTEST;
        margin: 20px -20px -20px;
        padding: 20px;
        padding-bottom: 50px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 40px;
        }

        .confirm-message {
            color: $COLOR_V3_GREY_DARKER;
            font-size: 14px;
            margin-bottom: 61px;
            margin-top: 14px;

            @media (min-width: $screen-sm-min) {
                padding-right: 260px;
                margin-top: 0px;
                margin-bottom: -10px;
                min-height: 41px;
            }

            &.supports-multi-plan {
                @media (min-width: $screen-sm-min) {
                    margin-top: 30px;
                    padding-right: 0;
                    text-align: center;
                    min-height: 0;
                }
            }
        }

        &.bump {
            @media (max-width: $screen-xs-max) {
                .register-btn {
                    top: 175px;
                }

                .checkbox-group {
                    top: 220px;
                }
            }
        }
    }

    .sub-text.warning {
        color: $COLOR_V3_CORAL;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }

    .payment-message-container {
        &.row.form-group {
            padding-top: 15px;
        }

        .payment-message {
            @extend .clearfix;
            background: $COLOR_V3_BEIGE_LIGHTEST;
            border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            border-radius: 4px;
            padding: 20px;
            margin-top: 15px;

            button {
                width: 100%;
                margin-bottom: 20px;

                @media (min-width: $screen-sm-min) {
                    width: 226px;
                    float: right;
                    margin: 0 0 0 35px;
                }
            }

            .sub-text {
                margin-bottom: 0;

                &.single-line {
                    @media (min-width: $screen-sm-min) {
                        line-height: 41.33px;
                    }

                    @media (max-width: $screen-xs-max) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .past-due-payment-container {
        &.row.form-group {
            padding-top: 15px;
        }

        .past-due-payment {
            border: 1px solid rgba($COLOR_V3_CORAL, 0.5);
            border-radius: 4px;
            background: rgba($COLOR_V3_CORAL, 0.1);
            margin-top: 15px;
            padding: 0px 15px 0px 15px;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                position: relative;
                padding: 0px;
                text-align: left;
            }

            h2 {
                text-align: center;
                color: $COLOR_V3_CORAL;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 18px;

                @media (min-width: $screen-sm-min) {
                    margin: 18px 0px 1px 48px;
                    width: 375px;
                    font-size: 20px;
                    text-align: left;
                }
            }

            p:nth-of-type(1) {
                font-size: 14px;

                @media (min-width: $screen-sm-min) {
                    margin: 0px 0px 6px 48px;
                    width: 405px;
                    font-size: 16px;
                }
            }

            p:nth-of-type(2) {
                font-size: 13px;
                color: $COLOR_PIXELMATTERS_GREY_DARK;
                margin-bottom: 20px;

                @media (min-width: $screen-sm-min) {
                    margin-left: 48px;
                    width: 385px;
                    font-size: 14px;
                    text-align: left;
                }
            }

            button {
                display: block;
                margin: 0 auto;
                margin-bottom: 14px;
                width: 180px;

                @media (min-width: $screen-sm-min) {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    margin: 20px 20px 62px 0px;
                }

                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                    position: relative;
                    margin: 20px 60px;
                }
            }
        }

        i {
            color: $COLOR_V3_CORAL;
            display: inline-block;
            margin: 0 auto;
            margin-top: 14px;
            font-size: 20px;

            @media (min-width: $screen-sm-min) {
                float: left;
                margin: 20px 18px 83px 20px;
            }
        }
    }

    .incomplete-payment-container {
        &.row.form-group {
            padding-top: 15px;
        }

        .incomplete-payment {
            border: 1px solid rgba($COLOR_V3_YELLOW, 0.5);
            border-radius: 4px;
            background: rgba($COLOR_V3_YELLOW, 0.1);
            margin-top: 15px;
            padding: 0px 15px 0px 15px;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                position: relative;
                padding: 0px;
                text-align: left;
            }
        }

        h2 {
            color: $COLOR_V3_YELLOW;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 18px;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                margin: 18px 0px 1px 48px;
                font-size: 20px;
                text-align: left;
            }
        }

        p:nth-of-type(1) {
            font-size: 14px;

            @media (min-width: $screen-sm-min) {
                margin: 0px 0px 6px 48px;
                font-size: 16px;
            }
        }

        p:nth-of-type(2) {
            font-size: 13px;
            color: $COLOR_PIXELMATTERS_GREY_DARK;
            margin-bottom: 20px;

            @media (min-width: $screen-sm-min) {
                margin-left: 48px;
                width: 385px;
                font-size: 14px;
            }
        }

        i {
            color: $COLOR_V3_YELLOW;
            display: inline-block;
            margin: 0 auto;
            margin-top: 14px;
            font-size: 20px;

            @media (min-width: $screen-sm-min) {
                float: left;
                margin: 20px 18px 83px 20px;
            }
        }

        div[name="payment-footer"] {
            text-align: center;

            @media (min-width: $screen-sm-min) {
                text-align: left;
            }
        }
    }

    .new-browser {
        color: $COLOR_V3_BLACK;
        font-size: 14px;
    }

    .main-content.disabled {
        opacity: 0.5;
    }

    // Custom radio button
    .custom-radio-container *,
    .custom-radio-container *::before,
    .custom-radio-container *::after {
        box-sizing: content-box !important;
    }

    .custom-radio-container input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .custom-radio-container {
        span {
            padding-left: 3px;
        }

        &.show-as-buttons {
            span {
                padding-left: 0px;
            }
        }
    }

    .custom-radio-container {
        display: table;
        position: relative;
        padding-left: 2rem;
        cursor: pointer;
        margin-bottom: 0.5rem;
        &.show-as-buttons {
            display: inline-flex;
        }
    }

    .custom-radio-container input[type="radio"] ~ .custom-radio-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 0.85rem;
        width: 0.85rem;
        background: rgba(239, 239, 239, 1);
        transition: background 250ms;
        border: 3px solid rgba(227, 227, 227, 1);
        border-radius: 3rem;

        &.show-as-buttons {
            position: relative;
            align-self: center;
        }
    }

    .custom-radio-container input[type="radio"] ~ .custom-radio-input::after {
        content: "";
        position: absolute;
        display: none;
        left: 0.16rem;
        top: 0.16rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 2rem;
        background: rgba(84, 222, 111, 1);
        transition: background 250ms;

        &.show-as-buttons {
            position: relative;
            align-self: center;
        }
    }

    .custom-radio-container input:disabled ~ .custom-radio-input::after {
        border-color: rgba(135, 149, 161, 1);
    }

    .custom-radio-container input:checked ~ .custom-radio-input::after {
        display: block;
    }

    .custom-radio-container:hover input ~ .custom-radio-input,
    .custom-radio-container input:focus ~ .custom-radio-input {
        background: rgba(246, 246, 246, 1);
    }

    .custom-radio-container input:focus ~ .custom-radio-input {
        box-shadow: 0 0 0 0px rgba(17, 203, 61, 0.23);
    }

    .custom-radio-container input:checked ~ .custom-radio-input {
        background: rgba(17, 203, 61, 0.23);
        border-color: rgba(84, 222, 111, 1);
    }

    .custom-radio-container input[type="radio"]:disabled ~ .custom-radio-input {
        background: rgba(241, 245, 248, 1);
        border-color: rgba(184, 194, 204, 0.28);
        opacity: 0.6;
        cursor: not-allowed;
    }

    .custom-radio-container input[type="radio"]:disabled ~ .custom-radio-input::after {
        background: rgba(135, 149, 161, 1);
    }

    .custom-radio-container input:checked:focus ~ .custom-radio-input,
    .custom-radio-container:hover input:not([disabled]):checked ~ .custom-radio-input {
        background: rgba(17, 203, 61, 0.23);
        border-color: rgba(84, 222, 111, 1);
    }

    .custom-radio-container .custom-radio-input::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin-left: -0.57rem;
        margin-top: -0.55rem;
        background: rgba(84, 222, 111, 1);
        border-radius: 2rem;
        opacity: 0.6;
        z-index: 99999;
        transform: scale(0);
    }

    @keyframes b-ripple {
        0% {
            transform: scale(0);
        }

        20% {
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1);
        }
    }

    @keyframes b-ripple-duplicate {
        0% {
            transform: scale(0);
        }

        30% {
            transform: scale(1);
        }

        60% {
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1);
        }
    }

    .custom-radio-container input + .custom-radio-input::before {
        animation: b-ripple 250ms ease-out;
    }

    .custom-radio-container input:checked + .custom-radio-input::before {
        animation-name: b-ripple-duplicate;
    }

    .custom-radio-container .custom-radio-input::before {
        visibility: hidden;
    }

    .custom-radio-container input:focus + .custom-radio-input::before {
        visibility: visible;
    }

    .custom-radio-container:first-child .custom-radio-input::before {
        visibility: hidden;
    }
}
