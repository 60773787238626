dashboard-footer {
    display: block;
    margin-top: 35px;

    @media (min-width: $screen-sm-min) {
        margin-top: 55px;
    }

    .spacer-for-cordova {
        display: block;
        height: 30px;
    }

    footer {
        padding: 35px 0 50px;
        background-color: #fff;

        @media (min-width: $screen-md-min) {
            padding: 50px 0 25px;

            > .dashboard-container {
                padding: 0;
            }
        }
    }

    .footer-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "logo logo logo logo"
            "app-links app-links app-links app-links"
            "hr hr hr hr"
            "disclaimer disclaimer disclaimer disclaimer"
            "copyright copyright copyright copyright";
        align-items: center;
        justify-items: center;

        hr {
            grid-area: hr;
            display: block;
            margin: 10px 0;
            width: 100%;
        }

        @media (min-width: $screen-md-min) {
            grid-template-areas:
                "logo logo app-links app-links"
                "hr hr hr hr"
                "disclaimer disclaimer disclaimer disclaimer"
                "copyright copyright copyright copyright";
            justify-items: normal;
        }

        .logo {
            margin-bottom: 25px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 15px;
            }
        }

        .app-links {
            margin-bottom: 15px;
        }

        .logo {
            grid-area: logo;

            a {
                display: inline-block;
            }

            img,
            svg {
                height: 30px;
                width: auto;

                @media (min-width: $screen-md-min) {
                    height: 35px;
                }
            }
        }

        .app-links {
            grid-area: app-links;

            text-align: center;

            @media (min-width: $screen-md-min) {
                text-align: right;
            }

            a {
                padding: 10px 0;
                filter: brightness(100%);
                transition: 250ms all ease-in-out;

                &:hover {
                    filter: brightness(70%);
                }

                &:first-of-type {
                    margin-right: 25px;
                }
            }
        }

        .copyright {
            grid-area: copyright;
        }

        .disclaimer {
            grid-area: disclaimer;
            padding: 0 20px;

            small {
                font-size: 10px;
            }

            @media (min-width: $screen-md-min) {
                small {
                    max-width: 800px;
                    font-size: 13px;
                }
            }
        }

        .copyright,
        .disclaimer {
            text-align: center;
            display: flex;
            justify-content: center;
            margin-top: 15px;

            small {
                color: $COLOR_MARKETING_LIGHT_GRAY;

                a {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    color: $COLOR_MARKETING_LIGHT_GRAY;
                    transition: 250ms all ease-in-out;

                    &:hover,
                    &.active {
                        color: $COLOR_MARKETING_GRAY;
                    }
                }
            }
        }
    }
}
