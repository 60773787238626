.playlist-map-content-item-icon-container {
    $iconWidthMobile: 40px;
    $arcWidthMobile: 30.5px;
    $arcHeightMobile: 57px;

    $iconWidthDesktop: 50px;
    $arcWidthDesktop: 38px;
    $arcHeightDesktop: 71px;

    $arcPaddingTopMobile: 12.5px;
    $arcPaddingLeftRightMobile: 10.5px;
    $arcPaddingBottomMobile: 3.5px;

    $arcPaddingTopDesktop: 15.5px;
    $arcPaddingLeftRightDesktop: 13px;
    $arcPaddingBottomDesktop: 5px;

    position: relative;
    display: inline-block;
    text-align: center;

    margin: 5px;
    margin-bottom: 10px;

    // padding to make room for arc around icon
    padding: $arcPaddingTopMobile $arcPaddingLeftRightMobile $arcPaddingBottomMobile $arcPaddingLeftRightMobile;
    @media (min-width: $screen-sm-min) {
        padding: $arcPaddingTopDesktop $arcPaddingLeftRightDesktop $arcPaddingBottomDesktop $arcPaddingLeftRightDesktop;
        margin-bottom: 15px;
    }

    &.locked {
        padding: 0;
        .playlist-arc {
            display: none;
        }
    }

    .playlist-arc {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: $arcWidthMobile;
        height: $arcHeightMobile;

        @media (min-width: $screen-sm-min) {
            width: $arcWidthDesktop;
            height: $arcHeightDesktop;
        }

        path.filled {
            fill: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        &.complete path.filled {
            fill: $COLOR_V3_YELLOW;
        }
    }

    .content-item-icon {
        display: inline-block;
        width: $iconWidthMobile;
        height: $iconWidthMobile;

        @media (min-width: $screen-sm-min) {
            width: $iconWidthDesktop;
            height: $iconWidthDesktop;
        }

        &.complete {
            background-image: url("~images/complete_playlist_map_icon.png");
            background-size: 100%;
            background-repeat: none;
        }
        &.locked {
            background-image: url("~vectors/track_icon_locked.svg");
            background-size: 100%;
            background-repeat: none;
        }
    }
}
