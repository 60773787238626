.lesson-start-screen-footer {
    .estimated-time {
        text-align: center;
        margin-bottom: 8px;
        font-size: 15px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    button.continue {
        @media (max-width: $screen-xs-max) {
            h2 {
                margin: 0px;
            }
        }

        .estimated-time {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_WHITE;
            font-size: 10px;
            margin: 0px;
        }
    }

    &.demoMode {
        text-align: center;

        @media (min-width: $screen-sm-min) {
            .estimated-time {
                background-color: $COLOR_V3_WHITE;
                padding: 5px 10px 4px 10px;
                border: 0px solid transparent;
                border-radius: 4px;
                display: inline-block;
                box-shadow: 0px 0px 7px $COLOR_V3_BEIGE_BEYOND_DARK;
            }
        }
    }
}
