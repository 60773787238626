// .fa-twitter-square
.fa-no-block-tw-square:before {
    content: "\f081";
}
// .fa-twitter
.fa-no-block-tw:before {
    content: "\f099";
}

// .fa-facebook-f, .fa-facebook
.fa-no-block-fb:before {
    content: "\f09a";
}
// .fa-facebook-square
.fa-no-block-fb-square:before {
    content: "\f082";
}
// .fa-facebook-official
.fa-no-block-fb-official:before {
    content: "\f09a";
}

// .fa-linkedin-square
.fa-no-block-li-square:before {
    content: "\f08c";
}
// .fa-linkedin
.fa-no-block-li:before {
    content: "\f0e1";
}

// .fa-youtube-square
.fa-no-block-yt-square:before {
    content: "\f166";
}
// .fa-youtube
.fa-no-block-yt-:before {
    content: "\f167";
}
// .fa-youtube-play
.fa-no-block-yt-play:before {
    content: "\f16a";
}
