.mobile-exit-search {
    display: block;
    position: fixed;
    bottom: calc($APP_MENU_MOBILE_HEIGHT + 8px);
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 20px;
    color: $COLOR_V3_WHITE;
    background: $COLOR_V3_BLACK;
    box-shadow: 0 5px 5px 0 rgba($COLOR_V3_BLACK, 0.3);
    text-transform: uppercase;
    border: 0px solid transparent;
    border-radius: 22px;
    font-size: 16px;
    line-height: 44px;
    white-space: nowrap;
    z-index: 1000000;

    @media (min-width: $screen-sm-min) {
        left: calc(50% + 110px);
        bottom: 10px;
    }

    @media (min-width: 930px) {
        display: none;
    }
}
