//
// Header.
//

// Header themes.
$HEADER_THEMES: (
    0: (
        color: #41bddf
    ),
    1: (
        color: #4a76ff
    ),
    2: (
        color: #54de6f
    ),
    3: (
        color: #141f41
    ),
    4: (
        color: #ff4d63
    ),
    5: (
        color: #801925
    )
);

//
//  Header.
//

.onboarding_header {
    position: relative;
    overflow: hidden;

    width: 100vw;
    height: 235px;

    .container {
        &,
        * {
            display: block;
            @media (max-width: $screen-sm-min) {
                max-width: 100% !important;
            }
        }

        .nav {
            padding: 0 20px;
            margin-top: 40px;
        }
    }

    .onboarding_header__logo {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -9px;
        height: 10px;
        width: 100%;
        background-color: #fff;
    }

    .college-separator {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;

        width: 100%;
        max-width: 100%;
        max-height: 150px;
    }

    @each $KEY, $VALUE in $HEADER_THEMES {
        &__#{$KEY} {
            .onboarding_header_content {
                background-color: map-get($VALUE, color);
            }
        }
    }
}

.onboarding_header__register {
    .onboarding_header_content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .onboarding_header__title {
        position: relative;
        top: 0;

        display: block;
        padding: 0 60px;

        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: 22px;
        text-align: center;
        color: #fff;

        &.ng-enter {
            transition: opacity 0.6s cubic-bezier(0.86, 0, 0.1, 1);
        }

        &.ng-leave {
            transition: opacity 0.3s cubic-bezier(0.86, 0, 0.1, 1);
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }
}

.onboarding_header_content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    transition: background-color 0.5s 0.7s ease;

    .onboarding_header__register & {
        transition: none;
    }
}

//
// Media queries.
//

// Small screens.
@media (min-width: $screen-sm-min) {
    .onboarding_header {
        height: 350px;

        .container {
            .nav {
                margin-top: 30px;
            }
        }
    }

    .onboarding_header__register {
        .onboarding_header__title {
            top: -50px;

            padding: 0 20px;

            font-size: 36px;
        }
    }
}

@media (min-width: 1920px) {
    .onboarding_header {
        height: 20vw;
        max-height: 500px;

        .onboarding_header_content {
            transform: scale(1.1);

            .animated_img__illustration-mountain {
                bottom: 0px;
            }
        }
    }
}
