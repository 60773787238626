//
// Footer.
//

.onboarding_footer {
    position: relative;
    z-index: 0;

    display: block;
    margin: 0;
    padding: 20px 0 40px;

    text-align: center;

    &.ng-enter {
        transition: opacity 0.6s cubic-bezier(0.86, 0, 0.1, 1);
    }

    &.ng-leave {
        transition: opacity 0.6s 0.2s cubic-bezier(0.86, 0, 0.1, 1);
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }

    p {
        font-family: $FONT_FAMILY;
        font-style: normal;
        @include font-size(16);
        color: #b5b5b5;
    }
}

onboarding-register {
    .onboarding_footer {
        p > a {
            color: rgba(#ff4d63, 0.4);

            transition: color 0.3s ease;

            &:hover {
                color: #ff4d63;
            }
        }
    }
}

onboarding-login,
onboarding-forgot-password {
    .onboarding_footer {
        p > a {
            color: rgba(#ff4d63, 0.4);

            transition: color 0.3s ease;

            &:hover {
                color: #ff4d63;
            }
        }
    }
}

onboarding-login {
    .onboarding_footer {
        .icp-china-footer-wrapper {
            text-align: center;
            margin-top: 30px;

            .icp-china-footer-link a {
                font-size: 12px;
                color: #ff4d63;
                text-transform: capitalize;
            }
        }
    }
}

//
// Media queries.
//

// Small screens.
@media (min-width: $screen-sm-min) {
    .onboarding_footer {
        padding: 46px 0 90px;
    }
}
