// Variables shared with Student Dashboard, etc.
// Border and margin variables
$dashboardBorderRadius: 3px;
$dashboardBoxMargin: 30px;

// Title font size variables
$titleFontSizeMobile: 22px;
$titleFontSizeTablet: 22px;
$titleFontSizeDesktop: 25px;

$titleLineHeightMobile: 20px;
$titleLineHeightTablet: 24px;
$titleLineHeightDesktop: 28px;

.content-box,
.top-nav-box {
    .title {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        overflow: hidden;

        // Mobile font size and weight
        // In 1-column layout
        font-size: $titleFontSizeMobile;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        line-height: $titleLineHeightMobile;

        // Tablet size
        // 2-column layout default to two lines (as well as progress icons)
        @media (min-width: $screen-sm-min) {
            height: $titleLineHeightTablet * 2 + $dashboardBoxMargin;
            font-size: $titleFontSizeTablet;
            line-height: $titleLineHeightTablet;
        }

        // Desktop size
        @media (min-width: $screen-md-min) {
            height: $titleLineHeightDesktop * 2 + $dashboardBoxMargin;
            font-size: $titleFontSizeDesktop;
            line-height: $titleLineHeightDesktop;
        }
    }
}

.course-box:not(.locked),
.top-nav-box {
    // Hover state
    .no-touchevents &:hover,
    .no-touchevents a:hover & {
        .header-box:after {
            margin: 0px;
            padding: 0px;
            border: 0px solid transparent;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            background-image: url("~images/header_box_overlay.png");
            background-size: 130px 100%;
            background-repeat: no-repeat;
            background-position: right -50px top;
            animation: 0.75s header-box-slide-right ease;
        }
    }

    // rtl-language overrides
    html[dir="rtl"] & {
        .header-box:after {
            transform: scaleX(-1);
        }
    }
}

.course-box,
.top-nav-box,
.top-nav-button {
    // Active state
    &:active,
    a:active & {
        background-color: $COLOR_V3_BEIGE_LIGHTEST;
    }
}

.course-box,
.top-nav-box,
.chapter-box {
    // Header box. Can be attached to top or bottom.
    .header-box {
        $headerMargin: 10px;
        display: block;
        position: relative;
        border: 0px solid transparent;
        color: $COLOR_V3_WHITE;
        text-align: center;

        // Flush with sides
        margin-right: -$headerMargin;
        margin-left: -$headerMargin;

        // Paddings to match grid-box
        padding-left: 10px;
        padding-right: 10px;

        // mobile font size and weight
        font-size: 20px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        line-height: 35px;

        // Larger padding (iPad Portrait and larger)
        @media (min-width: $screen-sm-min) {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 25px;
            line-height: 45px;
        }

        &.top,
        &.top:after {
            border-top-right-radius: $dashboardBorderRadius;
            border-top-left-radius: $dashboardBorderRadius;
            margin-top: -$headerMargin;
            margin-bottom: $headerMargin;
        }

        &.bottom,
        &.bottom:after {
            border-bottom-right-radius: $dashboardBorderRadius;
            border-bottom-left-radius: $dashboardBorderRadius;
            margin-bottom: -$headerMargin;
            margin-top: $headerMargin;
        }

        // Small sizes
        @media (min-width: $screen-sm-min) {
            $smallHeaderMargin: 15px;
            margin-right: -$smallHeaderMargin;
            margin-left: -$smallHeaderMargin;

            &.top {
                margin-top: -$smallHeaderMargin;
            }

            &.bottom {
                margin-bottom: -$smallHeaderMargin;
            }
        }
    }

    // Stat Groups
    .stat-group {
        .caption {
            font-size: 12px;
            line-height: 14px;
            font-weight: $FONT_WEIGHT_NORMAL;
            color: $COLOR_V3_BEIGE_DARKER;
            margin: 0px;
        }

        .big-number {
            font-size: 44px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 44px;
        }

        .fraction {
            font-size: 18px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 18px;
        }

        .total {
            font-size: 12px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 18px;
        }
    }
}

// Box for content inside bootstrap container grid
.grid-box {
    border: 0px solid transparent;
    border-radius: $dashboardBorderRadius;
    background-color: $COLOR_V3_WHITE;
    position: relative;
    margin-bottom: 13px;
    overflow: hidden;
    // Default padding (mobile)
    padding: 10px;

    // Padding at larger sizes (iPad Portrait and larger)
    @media (min-width: $screen-sm-min) {
        padding: 15px;
        margin-bottom: $dashboardBoxMargin;
    }
}

.right-arrow {
    $width: 30px;
    position: absolute;
    height: 100%;
    width: $width;
    right: 0;
    top: 0;

    border: 0px solid transparent;
    border-top-right-radius: $dashboardBorderRadius;
    border-bottom-right-radius: $dashboardBorderRadius;

    // rtl-language overrides
    html[dir="rtl"] & {
        left: 0;
        right: auto;
        transform: scaleX(-1);
    }

    div {
        display: table !important;
        table-layout: fixed;
        height: 100%;

        i {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            height: 20px;
            width: 30px;
            font-size: 20px;
            line-height: 20px;
            color: $COLOR_V3_WHITE;

            &.progress-icon {
                &:before {
                    content: "";
                    display: inline-block;
                    background-image: url("~images/progress_badge_incomplete.png");
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    image-rendering: -moz-crisp-edges; /* Firefox */
                    image-rendering: -o-crisp-edges; /* Opera */
                    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                    image-rendering: crisp-edges;
                    -ms-interpolation-mode: nearest-neighbor;
                    width: 25px;
                    height: 25px;

                    html[dir="rtl"] & {
                        transform: scaleX(-1);
                    }
                }

                &.inProgress:before {
                    background-image: url("~images/progress_badge_in_progress.png");

                    html[dir="rtl"] & {
                        transform: scaleX(-1);
                    }
                }

                // offset for space taken up by button below
                &.completed:before {
                    background-image: url("~images/progress_badge_complete.png");

                    html[dir="rtl"] & {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }

    .available-offline-icon {
        position: absolute;
        bottom: 7px;
        width: 18px;

        // set the right side to the middle
        // of the space and then move the icon
        // over half of its width to get it centered
        right: $width*0.5;
        transform: translateX(50%);
    }
}
