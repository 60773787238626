// Hide the student clusters until they are moved into the map's DOM by the overlay
// See student_cluster_dir.js for more details.
student-cluster {
    display: none;
}

network-amap {
    .amap-markers {
        &.hasfocus {
            .student-cluster {
                background-color: lighten($COLOR_V3_CORAL, 22%);
            }
        }

        .student-cluster {
            // TRICKY: we want to be able to change the border size without worrying about the width of the box
            box-sizing: content-box;

            border-radius: 50%;
            border-color: $COLOR_V3_WHITE;
            border-style: solid;
            background-color: rgba($COLOR_V3_CORAL, 0.9);
            color: $COLOR_V3_WHITE;

            opacity: 0;
            animation: fade-in 0.5s;
            animation-fill-mode: both;

            transition: background-color 0.33s;

            &.focused {
                z-index: 1000 !important;
                background-color: rgba($COLOR_V3_CORAL, 1);
            }

            &:hover {
                .no-touchevents & {
                    background-color: rgba($COLOR_V3_CORAL, 1);
                }
            }

            .count {
                $fontSize: 14px;
                display: block;
                position: absolute;
                left: 0px;
                top: calc(50% - #{$fontSize * 0.5});
                width: 100%;

                text-align: center;
                font-size: $fontSize;
                line-height: $fontSize;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }
    }
}

ng-map {
    student-cluster {
        position: absolute;
        display: block;
        width: 1px;
        height: 1px;
        cursor: pointer;
        overflow: none;
        direction: ltr;

        &.focused {
            z-index: 1000 !important;

            .student-cluster {
                background-color: rgba($COLOR_V3_CORAL, 1);

                &.valar {
                    background-color: rgba($COLOR_V3_BLUE, 1);
                }
            }
        }

        &.disabled {
            .student-cluster {
                background-color: lighten($COLOR_V3_CORAL, 22%);

                &.valar {
                    background-color: lighten($COLOR_V3_BLUE, 22%);
                }
            }
        }

        .student-cluster {
            // TRICKY: we want to be able to change the border size without worrying about the width of the box
            box-sizing: content-box;

            border-radius: 50%;
            border-color: $COLOR_V3_WHITE;
            border-style: solid;
            background-color: rgba($COLOR_V3_CORAL, 0.9);
            color: $COLOR_V3_WHITE;

            transform: translateX(-50%) translateY(-50%);

            opacity: 0;
            animation: fade-in 0.5s;
            animation-fill-mode: both;

            transition: background-color 0.33s;

            &:hover {
                .no-touchevents & {
                    background-color: rgba($COLOR_V3_CORAL, 1);
                }
            }

            &.valar {
                background-color: rgba($COLOR_V3_BLUE, 0.9);

                &:hover {
                    .no-touchevents & {
                        background-color: rgba($COLOR_V3_BLUE, 1);
                    }
                }
            }

            .count {
                $fontSize: 14px;
                display: block;
                position: absolute;
                left: 0px;
                top: calc(50% - #{$fontSize * 0.5});
                width: 100%;

                text-align: center;
                font-size: $fontSize;
                line-height: $fontSize;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }
    }
}
