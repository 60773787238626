edit-career-profile {
    // removes any pseudo-elements so they don't occupy space
    @mixin remove-pseudo-elements {
        &:before,
        &:after {
            display: none;
        }
    }

    @mixin set-min-width-and-side-padding($min-width, $padding) {
        min-width: $min-width;
        padding-left: $padding;
        padding-right: $padding;
    }

    .form-actions {
        @include remove-pseudo-elements;
        display: flex;
        justify-content: space-between;

        .btn + .btn {
            margin-left: 10px;
        }

        &.profile-save {
            .button-group:only-child {
                margin: 0 auto;
            }
        }
    }
}
