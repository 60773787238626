preview-profile-status-message {
    .preview-profile-status-container {
        position: relative;
        display: block;
        background-color: $COLOR_V3_MESSAGE_BOX_COLOR;
        color: $COLOR_V3_WHITE;

        border-radius: 4px;
        border: 0px solid transparent;

        margin: 10px;
        padding: 13px 30px 20px 40px;

        &.no_cn_access,
        &.no_cn_access_cno,
        &.incomplete,
        &.inactive {
            background-color: $COLOR_V3_GREY_DARKER;
        }

        @media (min-width: $screen-md-min) {
            margin: 0px;
            padding: 25px 25px 25px 50px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .title {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 14px;
        display: block;
        margin-bottom: 5px;

        @media (min-width: $screen-md-min) {
            font-size: 16px;
        }
    }

    .description {
        display: inline-block;
        color: rgba($COLOR_V3_WHITE, 0.8);
        font-size: 12px;

        a,
        a:hover,
        a:active {
            color: $COLOR_V3_WHITE;
            text-decoration: underline;
        }

        @media (min-width: $screen-md-min) {
            font-size: 14px;
        }
    }

    .icon {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;

        &.applied:after,
        &.reviewing_degree:after,
        &.reviewing_non_degree:after,
        &.reviewing_student_network:after,
        &.pending_student_network:after,
        &.deferred:after {
            @include font-awesome-icon("\f252"); // fa-hourglass-half
            color: $COLOR_V3_YELLOW;
        }

        &.no_cn_access:after,
        &.no_cn_access_cno:after,
        &.no_student_network_access:after {
            @include font-awesome-icon("\f017", $FONT_AWESOME_REGULAR); // fa-clock
            color: $COLOR_V3_GREY;
        }

        &.active:after {
            @include font-awesome-icon("\f058"); // fa-check-circle
            color: $COLOR_V3_GREEN;
        }

        &.inactive:after {
            @include font-awesome-icon("\f057"); // fa-times-circle
            color: $COLOR_V3_ORANGE;
        }

        &.incomplete:after,
        &.accepted_degree_incomplete_profile:after,
        &.accepted_degree_incomplete_profile_alt:after {
            @include font-awesome-icon("\f056"); // fa-minus-circle
            color: $COLOR_V3_GREY;
        }

        &:after {
            position: absolute;
            display: inline-block;
            left: 14px;
            top: 10px;
            width: 15px;
            height: 20px;
            font-size: 18px;

            @media (min-width: $screen-md-min) {
                font-size: 22px;
                left: 22px;
                top: 20px;
                width: 24px;
                height: 26px;
            }
        }
    }
}
