// Note: this is used in both the career network and the student alumni network
.pagination {
    height: 30px;
    padding: 5px 20px;
    margin: 0 auto;
    display: block;

    @media (min-width: $screen-md-min) {
        margin-bottom: 10px;
        padding: 0;
        height: 20px;

        &.bottom {
            margin-top: 10px;
        }
    }

    .current {
        float: left;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;

        // rtl-language overrides
        html[dir="rtl"] & {
            float: right;
        }
    }

    .buttons {
        float: right;

        // rtl-language overrides
        html[dir="rtl"] & {
            float: left;
        }

        button {
            padding: 0;
            border: none;
            background: none;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BLUE;
            margin-left: 10px;
            outline: none;
            cursor: pointer;
            transition: 0.25s ease;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 10px;

                i {
                    transform: scaleX(-1);
                }
            }

            @media (min-width: $screen-sm-min) {
                margin-left: 15px;
            }

            &:not([disabled]):hover {
                color: $COLOR_V3_BLUE_DARK;
            }

            &[disabled] {
                cursor: inherit;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
            }
        }
    }
}
