.cf-continue-button {
    // Desktop styles
    // We normally do not use media queries inside componentized styles, but this is an
    // exception because it is rendered inside the front-royal-footer, so it doesn't have the special
    // classes added in the show_frames_player_dir, like gte-sm. (It doesn't need those classes,
    // since the footer stretches the full width of the screen regardless of the columns in the lesson player.
    @media (min-width: $screen-sm-min) {
        .with-message {
            @include flat-outline(2px, $COLOR_V3_WHITE);
            .no-touchevents &:hover,
            .no-touchevents a:hover & {
                border-color: $COLOR_V3_WHITE !important;
                box-shadow: inset 0px 0px 0px 1px $COLOR_V3_WHITE !important;
            }
        }
    }

    .practice-mode-continue {
        button {
            position: absolute;
            width: 49%;
        }

        button:nth-of-type(1) {
            left: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        button:nth-of-type(2) {
            right: 0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
}
