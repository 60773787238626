disconnected-mobile-init {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $COLOR_V3_CORAL;

    .message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 8px;
        background-color: $COLOR_V3_WHITE;
        text-align: center;
        min-width: 300px;
        min-height: 210px;
        padding: 20px;

        span {
            font-size: 16px;
            color: $COLOR_V3_BLACK;
            display: block;
        }

        front-royal-spinner {
            margin-top: 42px !important;
        }
    }
}
