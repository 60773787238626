write-text-about {
    .char-num {
        text-align: right;
    }

    textarea,
    input {
        width: 100%;
        resize: none;
    }
}
