student-network-settings-form {
    .front-royal-form-container & pills-select .form-group.row:first-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .card-list-item {
        padding-right: 0;
        border: none;

        + .sub-text {
            margin: 30px 0 0;
        }
    }
}
