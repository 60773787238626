// Fill 100% of the height with these wrapper divs
show-stream,
show-stream > .show-stream,
show-stream > .show-stream > show-lesson,
show-stream > .show-stream > show-lesson > .show-lesson {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

show-stream {
    .show-stream {
        // Pop-up if the lesson is unlaunchable
        .unlaunchable {
            max-width: 300px;
            margin: 75px auto 0px;
            display: block;

            button {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
