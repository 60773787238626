student-dashboard-lesson-link-box {
    $launchButtonWidth: 176px;
    $iconSize: 50px;

    // Horizontal Rule
    hr.hr-course {
        background-color: $COLOR_V3_BEIGE_MIDDARK;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    // Lesson and course launch box shown at bottom of course and active playlist views
    .launch-box {
        position: relative;
        padding-right: $launchButtonWidth + 10px;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-right: 0px;
            padding-left: $launchButtonWidth + 10px;
        }

        // Unused, but might bring it back...
        // @media (min-width: $screen-sm-min) {
        //     &.course-mode {
        //         padding-left: $iconSize + 10px;
        //     }
        // }

        // .stream-icon {
        //     display: block;
        //     position: absolute;
        //     left: 0px;
        //     top: calc( 50% - #{$iconSize / 2.0} );
        //     width: $iconSize;
        //     height: $iconSize;
        // }

        .lesson-index-box {
            width: 100%;

            // Lesson Index and Time Estimate
            .lesson-index,
            .lesson-divider {
                display: inline-block;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 15px;
                color: $COLOR_V3_BEIGE_DARKER;
                margin-right: 5px;
            }

            .lesson-time-estimate {
                font-weight: $FONT_WEIGHT_NORMAL;
                color: $COLOR_V3_BEIGE_DARKER;
                text-transform: uppercase;
            }
        }

        .stream-title {
            font-size: 15px;
            color: $COLOR_V3_BEIGE_FOR_TEXT;
            font-weight: normal;
        }

        .lesson-title {
            font-size: 20px;
            line-height: 1.05em;
            color: $COLOR_V3_BEIGE_FOR_TEXT;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .launch-button {
            width: $launchButtonWidth;
            height: 100%;
            position: absolute;
            border-radius: 50px;
            top: 0px;
            right: 0px;
            margin: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 0px;
                right: auto;
            }
        }
    }
}
