.main-box tabs:not(.tab-style-slash) {
    .tabs {
        $borderWidth: 1px;

        display: table;
        table-layout: fixed;
        width: 100%;

        .tab {
            display: table-cell;
            vertical-align: top;

            button {
                border-right: $borderWidth solid $COLOR_V3_BEIGE_MIDDARK;
            }

            &:last-of-type button {
                border-right: none;
            }

            @media (min-width: $screen-sm-min) {
                &:first-of-type {
                    border: 0px solid transparent;
                    border-top-left-radius: 4px;
                    overflow: hidden;
                }
                &:last-of-type {
                    border: 0px solid transparent;
                    border-top-right-radius: 4px;
                    overflow: hidden;
                }
            }
        }

        button {
            display: block;
            width: 100%;
            height: 40px;
            background-color: $COLOR_V3_BEIGE_LIGHT;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            text-align: center;
            transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
            line-height: 1em;

            @media (min-width: $screen-sm-min) {
                height: 50px;
            }

            &.active {
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_BLACK;
            }

            &:hover:not(:disabled) {
                .no-touchevents & {
                    opacity: 1 !important; // FIXME: https://trello.com/c/bwLScJs2

                    &:not(.active) {
                        background-color: $COLOR_V3_BEIGE_MEDIUM;
                    }
                }
            }

            &:active:not(:disabled) {
                .touchevents &,
                .no-touchevents & {
                    opacity: 1 !important; // FIXME: https://trello.com/c/bwLScJs2

                    &:not(.active) {
                        background-color: $COLOR_V3_BEIGE_MIDDARK;
                    }
                }
            }
        }
    }
}

.main-box tabs.tab-style-slash {
    display: block;
    position: relative;
    overflow: hidden;
    height: 46px;
    margin-bottom: 5px;
    border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;

    @media (min-width: $screen-sm-min) {
        height: 43px;
        margin-bottom: 12px;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: -1px;
        z-index: 101;
        pointer-events: none;
    }

    &::before {
        left: 0;
        width: 20px;
        background: linear-gradient(to left, rgba($COLOR_V3_BEIGE, 0), $COLOR_V3_BEIGE);
    }

    &::after {
        right: 0;
        width: 40px;
        background: linear-gradient(to right, rgba($COLOR_V3_BEIGE, 0) 0, $COLOR_V3_BEIGE 90%);
        transition: width 0.25s ease;
    }

    @media (max-width: $screen-xs-max) {
        display: block;
    }

    @media (min-width: $screen-md-min) {
        &::before,
        &::after {
            content: normal;
        }
    }

    @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
        &.widen {
            margin-left: -#{$CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_EXTRA_WIDTH};
            margin-right: -#{$CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_EXTRA_WIDTH};
        }
    }

    .tabs {
        margin: 5px 0;
        position: relative;
        z-index: 100;
        text-align: center;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        height: 56px;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: $screen-sm-min) {
            margin: 10px 0 12px;
            padding: 0 0 10px;
        }
    }

    &.no-border {
        border: none;

        .tabs {
            margin: 5px auto;
            width: $CAREERS_CARD_ONE_PAGE_LAYOUT_WIDTH;

            button::after {
                content: normal;
            }
        }
    }

    .tab {
        display: inline-block;
        padding: 0 10px 10px;

        &:first-child {
            padding-left: 20px;

            @media (min-width: $screen-sm-min) {
                padding-left: 10px;
            }
        }

        &:last-child {
            padding-right: 30px;

            @media (min-width: $screen-sm-min) {
                padding-right: 10px;
            }
        }

        @media (min-width: $screen-sm-min) {
            border: none;
        }

        @media (min-width: $screen-md-min) {
            padding-left: 0;
            padding-right: 0;

            &:not(:last-child)::after {
                content: "/";
                padding: 0 10px;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-size: 14px;

                @media (min-width: $screen-md-min) {
                    padding: 0 20px;
                }
            }
        }
    }

    button {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 14px;
        text-transform: uppercase;
        transition: 0.25s ease;
        position: relative;

        @media (max-width: $screen-xs-max) {
            margin-top: 10px;
        }

        &:not(:disabled) {
            cursor: pointer;
        }

        &:disabled {
            opacity: 0.5;
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            border-bottom: 2px solid transparent;
            position: absolute;
            bottom: -11px;
            transition: 0.25s ease;

            @media (min-width: $screen-md-min) {
                bottom: -12px;
            }
        }

        &.unstyled-button:hover:not(:disabled) {
            opacity: 1;
        }

        .no-touchevents &:not(.active):not(:disabled):hover::after {
            border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        &.active {
            color: $COLOR_V3_BLUE;

            &:after {
                border-color: $COLOR_V3_BLUE;
            }
        }
    }
}
