.student-dashboard-efficacy-study {
    .sidebar-box {
        @include sidebar-box();
        .description {
            p {
                font-size: 15px;
            }
        }
    }
}
