//---------------------------
// Page Styling
//---------------------------

// Not sure why we have to do this here, but Firefox was ignoring the reset in _scaffolding.scss
* {
    -moz-box-sizing: border-box;
}
*:before,
*:after {
    -moz-box-sizing: border-box;
}

html {
    user-select: none !important;

    // font-size has to be set on html so that libraries that rey on rem
    // work correctly (i.e. material-ui and ReactDatepicker)
    font-size: $FONT_SIZE_DEFAULT !important;
}

html,
body {
    height: 100%;
    margin: 0px;
    @include box-sizing(border-box);

    // Pixelmatters design wanted this; disabling for now so we're more consistent
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // @media
    // only screen and (-webkit-min-device-pixel-ratio: 1.25),
    // only screen and ( min-device-pixel-ratio: 1.25),
    // only screen and ( min-resolution: 200dpi),
    // only screen and ( min-resolution: 1.25dppx) {
    //     -webkit-font-smoothing: subpixel-antialiased;
    // }
}

body {
    @include primary-font;
    width: 100%;
    background: $COLOR_V3_BEIGE_LIGHTEST;
    position: relative;
    padding: 0;

    -webkit-overflow-scrolling: touch; // ensure that we have nice inertial scrolling

    &.modal-open {
        -webkit-overflow-scrolling: auto; // disable inertial scrolling when modal is open
    }
}

a {
    color: $COLOR_V3_EGGPLANT;
    outline: 0 !important;
    &:hover,
    &:active {
        color: $COLOR_V3_EGGPLANT;
    }

    // <a> tags cannot actually by disabled, but we can mark
    // the as such and at least get the styling
    &:not([disabled="disabled"]) {
        cursor: pointer;
    }
}

hr {
    height: 1px;
    margin: 0;
    border: 0;
    margin-bottom: 20px;
    background-color: $COLOR_V3_BEIGE_LIGHT;
}

strong {
    font-weight: $FONT_WEIGHT_BOLD;
}

em,
i {
    font-style: $FONT_STYLE_ITALIC;
}

ul {
    padding-left: 1.75em;
}

code {
    white-space: pre;
    white-space: pre-wrap;
    word-break: normal;

    // this ensures that wrapping lines are padded properly; see https://css-tricks.com/multi-line-padded-text/
    box-decoration-break: clone;
    // we explicitly allow learners to select text out of <code> elements in lessons:
    user-select: text;
}

pre {
    color: $code-color;
    background-color: $code-bg;
    border: 0px solid transparent;
}

//---------------------------
// Alert Banners
//---------------------------

.alert {
    background-image: none;
    border: none;
    color: $COLOR_V3_WHITE;
    font-weight: $FONT_WEIGHT_NORMAL;
    font-size: 18px;

    &.alert-success {
        background-color: $COLOR_V3_GREEN;
    }
    &.alert-info {
        background-color: $COLOR_V3_BLUE;
    }
    &.alert-warning {
        background-color: $COLOR_V3_YELLOW;
    }
    &.alert-danger {
        background-color: $COLOR_V3_RED;
    }
}

//---------------------------
// Baseline Responsiveness
//---------------------------

// media should scale
img,
canvas,
iframe,
video,
svg {
    max-width: 100%;
}

// <meta viewport> possibly being standardized as CSS selector, with MS betting early
// on this. Results in a mixture of concerns. See also HTML templates for this.
@-ms-viewport {
    width: device-width;
}

//---------------------------
// Main content
//---------------------------

#app-root {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;

    @media (min-width: $screen-sm-min) {
        // prevent unnecessary scrolling due to weird extra line that
        // shows up below the #wrap div
        margin-bottom: -$line-height-computed;
    }
}

//---------------------------
// Forms
//---------------------------

@include fancy_checkbox($COLOR_V3_BEIGE_DARK, $COLOR_V3_BEIGE_DARK, 20px, $COLOR_V3_DARK_GREY);

form {
    .form-error {
        @include font-size(14);
        padding: 0 35px;
        margin-top: 10px;
        display: none;
        text-align: left;
        color: #ff4d63;
        a {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-style: normal;
            color: #ff4d63;
        }
        .form-error-validation {
            display: none;
        }
        &.active {
            display: block;
        }
        &.invalid {
            .form-error-exists {
                display: none;
            }
            .form-error-validation {
                display: block;
            }
        }
    }
}

.checkbox,
.radio,
.checkbox-inline,
.radio-inline {
    &.small {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 12px;

        input[type="radio"],
        .input[type="checkbox"] {
            margin-top: 1px;
        }
    }
}

//---------------------------
// Misc Styles
//---------------------------

button.wide {
    width: 100%;
}

.relative {
    position: relative;
}

.center {
    display: block;
    text-align: center;

    // for mathjax centering to work properly...
    .mjx-block {
        text-align: center;
    }
}

.flush-right {
    html:not([dir="rtl"]) & {
        padding-right: 0;
    }

    // rtl-language overrides
    html[dir="rtl"] & {
        padding-left: 0;
    }
}

.flush-left {
    html:not([dir="rtl"]) & {
        padding-left: 0;
    }

    // rtl-language overrides
    html[dir="rtl"] & {
        padding-right: 0;
    }
}

.text-right {
    // rtl-language overrides
    html[dir="rtl"] & {
        text-align: left;
    }
}

.text-left {
    // rtl-language overrides
    html[dir="rtl"] & {
        text-align: right;
    }
}

//---------------------------
// mjx-chtml Overrides
//---------------------------

// Mathjax blocks
.MJXc-display,
.mjx-chtml .math {
    // Because we set clip: auto; in challenge_blank_dir.js, we need
    // to suppress some of the empty sections of absolutely positioned
    // spans from overlapping things like modals
    overflow: hidden;
    pointer-events: none;
    // Ensure that the challenges inside mathjax are still clickable
    .within-mathjax {
        pointer-events: all;
    }
}

.mjx-chtml {
    // Mathjax 2.6 has a weird focus ring that started showing up. Kill it!
    outline: none;
    // Mathjax 2.6 will sometimes override its size to be bigger than 100%. We don't want to allow this.
    font-size: 100% !important;
}

.mjx-chtml .math {
    // Inline mathjax blocks are positioned incorrectly with inline-block and overflow: hidden.
    display: inline !important;
}

.MJXc-display {
    // prevent clipping of numbers right at the edge of the block
    // Do this by removing the 1em default margin and moving it into the internal padding
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    margin: 0 !important;
}

// Is this safe? Override some padding to add a bit of distance between markers above ops, e.g.: a bar over a variable
.mjx-munderover .mjx-stack {
    .mjx-over + .mjx-op {
        padding-top: 0.05em;
    }
}

// Fix an issue with bootstrap overriding box-styles which are needed by CHTML layout
// see also: https://github.com/mathjax/MathJax/issues/1393#issuecomment-185804821
.mjx-chtml {
    &,
    span {
        box-sizing: initial;
    }
}

// Add a little margin around the inline math so fractions don't butt up against one another on subsequent lines
.MathJax_CHTML {
    // Inline math is display inline-block
    display: inline-block !important;
    margin: 0px 0px 2px 0px !important;
}

.MJXc-display .MathJax_CHTML {
    display: inline !important;
    margin: 0 !important;
}

//--------------------------------
// Modal Defaults
//--------------------------------

.modal-backdrop {
    // we don't need the backdrop for our modals
    // the .modal takes up the entire screen in all cases
    //
    // (This is not in modals.scss because that is included in common and
    // we don't want this on marketing pages)
    display: none;
}

//--------------------------------
// Safari Zoom Hacks
//--------------------------------

// Prevent wrapping of grid columns when zoomed out
// https://github.com/twbs/bootstrap/issues/22131
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    min-height: 2px;
}

// undo the bootstrap styles here (initially did this for compatibility with material-ui)
legend {
    display: block;
    width: auto;
    padding: initial;
    margin-bottom: initial;
    font-size: initial;
    line-height: initial;
    color: initial;
    border: initial;
    border-bottom: initial;
}

/* ---------------------------- Tailwind competition ------------------------------- */
/*
    bootstrap has some classnames that are also implemented by tailwind. The following definitions
    ensure that when someone was trying to use the bootstrap classes, the bootstrap styles win.
*/

// Tailwind uses the `collapse` class, but does not use `panel-collapse`, so in places where `collapse` exists
// on the same element with `panel-collapse`, we can be sure that we're expecting bootstrap to win.
.panel-collapse.collapse {
    display: none !important;

    &.in {
        display: block !important;
        visibility: visible !important;
    }
}
