@import "oreo/global/colors_v3";

.telephone-wrapper {
    .inner-input-wrapper {
        display: flex;

        > div {
            padding: 0;
        }

        .country-wrapper {
            flex: 0 1 40%;
            .country-code-select {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            input:disabled {
                opacity: 0;
                background-color: inherit;
            }
        }

        .phone-wrapper {
            flex: 1 1 50%;

            input {
                padding: 3px 8px;
                &:disabled {
                    background-color: inherit;
                }
            }
            .MuiInputBase-root {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            &.include-extension {
                .MuiInputBase-root {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }

        .extension-wrapper {
            input {
                padding: 3px 8px;
            }

            .MuiInputBase-root {
                width: 80px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }

    .error-label {
        font-size: 14px;
        line-height: 18px;
        color: $COLOR_V3_CORAL;
        padding: 0 15px;
        margin-top: 10px;
    }
}
