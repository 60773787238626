program-switcher {
    cursor: auto;

    .program-section {
        display: flex;
        align-items: center;
        padding: 14px 15px;

        p.program-title {
            color: $COLOR_V3_GREY_DARKER;
            line-height: 19px;
            padding: 0;
            margin: 0;
            flex: 1 1 auto;
            white-space: normal;
        }

        img {
            max-height: 25px;
            flex: 0 0 auto;
            width: 25px;
            margin-right: 13px;

            html[dir="rtl"] & {
                margin-right: 0;
                margin-left: 13px;
            }
        }

        &:not(.selected),
        &.disabled {
            img {
                filter: grayscale(1);
                opacity: 0.5;
            }
        }

        &:not(.selected):hover {
            cursor: pointer;
        }

        &.selected:not(.disabled) {
            pointer-events: none;

            &.quantic,
            &.smartly {
                background: rgba(255, 77, 99, 0.05);
            }

            &.valar {
                background: rgba(77, 122, 255, 0.05);
            }
        }

        &.disabled {
            background: rgba(0, 0, 0, 0.05);
        }
    }
}

dialog-modal-alert .modal.program-switcher .modal-dialog .modal-content {
    .modal-header,
    .program-section {
        border-bottom: 1px solid $COLOR_V3_BEIGE_MEDIUM;
    }

    .modal-body {
        padding: 0 0 40px 0;
    }
}
