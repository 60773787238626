edit-documents {
    .faq-link,
    .sub-text.desktop-message {
        color: $COLOR_V3_CORAL;
    }

    .disabled {
        opacity: 0.5;

        [disabled] {
            // any disabled element inside of the ancestor element that has the disabled CLASS applied
            // should have a normal opacity level of 1 to prevent it from being too blurred out.
            opacity: 1 !important;
        }
    }

    .verified {
        text-align: center;
        margin-bottom: 15px;

        .box {
            display: inline-block;
            min-width: 250px;
            border: 1px solid $COLOR_V3_TURQUOISE;
            border-radius: 4px;
            padding: 20px;
            margin: 0 auto;
        }

        .sub-text {
            color: $COLOR_V3_TURQUOISE !important;
        }

        img {
            width: 40px;
            display: inline-block;
            margin-right: 5px;
        }
    }

    .uploaded-documents-container {
        padding-top: 10px;
    }

    .transcript-list {
        margin-bottom: 10px;

        a,
        p {
            margin-right: 5px;
            display: inline-block;
        }

        p {
            margin-bottom: 0px;
        }
    }

    .file-name {
        cursor: pointer;
        i {
            margin-left: 3px;
        }
    }

    .mailing-address-form {
        height: auto;
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 1s ease-in-out;

        &.collapsed {
            max-height: 0px;
        }

        .show-address-line-2 {
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;

                a {
                    text-decoration: underline;
                }
            }

            a {
                color: $COLOR_V3_CORAL;
                margin-bottom: 0;
                padding: 0;
                i {
                    padding-right: 10px;
                }
            }
        }

        .show-address-line-2,
        .address-line-2 {
            height: 48px;
            transition: height 0ms, opacity 600ms 200ms;

            &.not-visible {
                opacity: 0;
                height: 0;
                overflow: hidden;
                transition: height 0ms, opacity 400ms;
            }
        }
    }
}

.front-royal-form-container edit-documents .file-info-container.transcript-container {
    float: left;
    padding: 0 0 10px 20px;
    margin-bottom: 10px;
    position: relative;
    margin: 10px 0;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: $COLOR_V3_GREY_LIGHTER;
        border-radius: 4px;
    }

    .transcript {
        margin-top: 10px;
    }
}
