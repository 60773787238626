// see also: _backgrounds.scss for background-specific overrides
.progress-container {
    margin: 10px auto;
    width: 300px;
    position: relative;

    &.ng-leave {
        display: none;
    }

    .linear-progress-bar-container {
        position: absolute;
        width: 100%;
        top: 10px;
        display: flex;
        justify-content: center;
        padding-inline-start: 3.7em;

        @media (max-width: $screen-xs-max) {
            width: 90%;
            padding-inline-start: 4.2em;
        }

        .linear-progress-bar-outer {
            border-radius: 15px;
            background: $COLOR_V3_WHITE;
            height: 18px;
            margin-inline-end: 5px;
            width: calc(100% - 3em);
            overflow: hidden;

            .linear-progress-bar-inner {
                height: 100%;
                background: $COLOR_V3_CORAL;
                transition: all 0.3s ease-in-out;
                opacity: 0.6;

                .valar & {
                    background: $COLOR_V3_BLUE;
                }

                .bg-turquoise & {
                    background: $COLOR_V3_TURQUOISE !important;
                }

                .bg-blue & {
                    background: $COLOR_V3_BLUE !important;
                }

                .bg-purple & {
                    background: $COLOR_V3_PURPLE !important;
                }
            }
        }

        .linear-progress-bar-frame-display {
            width: 4em;
            margin-top: -2px;

            @media (max-width: $screen-xs-max) {
                font-size: 10px;
                width: 3.2em;
                margin-top: 2px;
            }
        }
    }

    .select-navigation-container {
        position: absolute;
        top: 6px;
        display: flex;
        justify-content: center;
        width: 100%;

        .select-frame-text {
            margin-top: 1px;
            margin-inline-end: 5px;
        }

        .select-frame-select {
            width: 8em;

            .selectize-input {
                height: 1.5em;
                min-height: 1.5em;
                padding: 2px;
            }
        }
    }

    .indicator-row {
        position: absolute;
        z-index: 2; // should still be below the back and exit buttons
        width: 100%;

        &.three-rows {
            // When 3 rows in mobile, apply a margin to center the rows vertically
            @media (max-width: $screen-xs-max) {
                margin-top: 5px;
            }
        }

        &:nth-child(2) {
            z-index: 1; // put the second row below the first

            @media (min-width: $screen-sm-min) {
                top: 14px;
            }
            @media (max-width: $screen-xs-max) {
                top: 12px;
            }
            @media (max-width: 350px) {
                &.long-row {
                    top: 10px;
                }
            }
        }

        &:nth-child(3) {
            z-index: 0; // put the third row below the second

            @media (min-width: $screen-sm-min) {
                top: 28px;
            }
            @media (max-width: $screen-xs-max) {
                top: 24px;
            }
            @media (max-width: 350px) {
                &.long-row {
                    top: 20px;
                }
            }
        }

        &.single-row {
            top: 8px;

            &.long-row {
                top: 8px;
            }
        }

        &.row-offset {
            @media (min-width: $screen-sm-min) {
                left: 8px;
            }
            @media (max-width: $screen-xs-max) {
                left: 6.75px; // this looks good on iOS, but 6.5 doesn't for some reason, even though the math works
            }
        }

        &.row-reverse-offset {
            @media (min-width: $screen-sm-min) {
                right: 8px;
            }
            @media (max-width: $screen-xs-max) {
                right: 6.75px; // this looks good on iOS, but 6.5 doesn't for some reason, even though the math works
            }
        }

        button.indicator.flat {
            display: inline-block;

            background-color: transparent;
            background-size: 100%;
            background-repeat: no-repeat;
            margin: 0 0 0 1px;
            background-image: url("~vectors/progress_indicator_dark_coral.svg");
            backface-visibility: hidden;
            padding: 0px;

            @media (min-width: $screen-sm-min) {
                width: 15px;
                height: 17px;
            }
            @media (max-width: $screen-xs-max) {
                width: 13px;
                height: 15px;
            }
            @media (max-width: 350px) {
                &.long-row-indicator {
                    width: 11px;
                    height: 13px;
                }
            }

            &:focus,
            &[disabled="disabled"] {
                background-color: transparent;
            }

            .no-touchevents & {
                &:enabled {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.3;
                    }
                }
            }

            &.filled,
            &.filled[disabled] {
                opacity: 1; // override disabled style
                animation: lesson-progress-flip 1s ease;
                background-image: url("~vectors/progress_indicator_white.svg") !important;

                &.active-highlight,
                &.active-highlight[disabled] {
                    background-image: url("~vectors/progress_indicator_white_current.svg") !important;
                }
            }

            &.partially-filled,
            &.partially-filled[disabled] {
                animation: lesson-progress-flip 1s ease;
                opacity: 0.6;
                background-image: url("~vectors/progress_indicator_white.svg") !important;
            }
        }
    }
}
