.deferral-link {
    width: 100%;
    text-align: center;

    .title {
        font-weight: bold;
        font-size: 24px;

        @media (min-width: $screen-md-min) {
            font-size: 28px;
        }
    }

    .from-messaging-wrapper {
        margin: 0 auto;
        margin-left: 15px;
        margin-right: 15px;

        @media (min-width: $screen-sm-min) {
            margin-left: 25px;
            margin-right: 25px;
            width: 700px;
        }

        @media (min-width: $screen-md-min) {
            width: 750px;
        }

        .title,
        .messaging,
        .select-wrapper,
        .billing-confirmation-wrapper,
        .defer-button-wrapper {
            margin-top: 30px;

            @media (min-width: $screen-md-min) {
                margin-top: 50px;
            }
        }

        .defer-button-wrapper {
            button {
                margin-bottom: 5px;
            }
        }

        .select-wrapper {
            .to-cohort-select {
                margin-top: 5px;
                width: 290px !important;
                font-size: 16px;

                @media (min-width: $screen-sm-min) {
                    width: 350px;
                    font-size: 18px;
                }

                @media (min-width: $screen-md-min) {
                    width: 450px !important;
                    font-size: 24px;
                }
            }
        }

        .billing-confirmation-wrapper {
            font-size: 18px;

            @media (min-width: $screen-md-min) {
                font-size: 24px;
            }
        }

        .contact-us {
            font-size: 14px;
            margin-top: 15px;
            margin-bottom: 60px; // 30 + the bottom app menu bar

            @media (min-width: $screen-md-min) {
                font-size: 18px;
                margin-top: 25px;
                margin-bottom: 30px;
            }
        }
    }

    .to-messaging-wrapper {
        margin: 0 auto;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 75px;

        @media (min-width: $screen-sm-min) {
            margin-top: 150px;
            width: 600px;
        }

        .to-messaging {
            margin-bottom: 15px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 25px;
            }
        }
    }

    .from-messaging-wrapper,
    .to-messaging-wrapper {
        display: inline-block;
        font-size: 18px;

        @media (min-width: $screen-md-min) {
            font-size: 24px;
        }
    }
}
