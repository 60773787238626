div.edit-user-details-form {
    display: flex;
    flex-direction: column;

    > div {
        margin: 5px 0;

        & .MuiSelect-multiple {
            white-space: inherit;
        }

        > label {
            padding: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
