// add fillable-rect-on-image so we can be specific enough to override stuff there
.cf-challenge-overlay-blank.fillable-rect-on-image {
    display: block;
    white-space: normal; // do not inherit the nowrap from interactive_cards .carousel down into the blanks

    // remove <a> styling (it's an <a> because we want active classes)
    text-decoration: none;

    &.stretch-to-fit {
        width: 100%;
        height: 100%;

        // This is for proper positioning of the completed text
        // (but we don't want it to apply to the hint button.  That needs to
        //  be text-align center)
        .stretch {
            display: block !important;
            width: 100% !important;
            height: 100% !important;
            text-align: left !important;
            line-height: 1em !important;
            vertical-align: middle;

            // rtl-content overrides
            div[dir="rtl"] & {
                text-align: right !important;
            }
        }
    }

    &:not(.stretch-to-fit) {
        .fill-content-container {
            display: block; // get rid of display: table-cell so that things get sized correctly during resetDimensions
        }
    }

    // // useful for debugging
    // .fill-content-container {
    //     background-color: red;
    // }

    /* see fillable-rect-on-image for all the fancy stuff */

    &.multiple_choice {
        // Hide the question mark icon
        &:before {
            display: none;
        }

        border: 0px solid transparent;
        margin: 3px; // backwards compatibility; old content was built when these had a 3px solid border
        box-shadow: $FILL_IN_THE_BLANK_UNSELECTED_BOX_SHADOW;
        border-radius: 3px;

        &.selected {
            box-shadow: $BLANKS_ON_IMAGE_SELECTED_BOX_SHADOW;
            background: rgba($BLANK_UNDERLINE_SELECTED_COLOR, 0.1);
        }

        // Hide shadow when completed
        &.completed {
            box-shadow: none;
            background: none;
        }
    }

    // Compose Blanks on Image styling
    &.user_input.fillable-rect-on-image {
        background: transparent;
        font-size: 14px;
        line-height: 1em;
        vertical-align: middle;
        padding: 0;
        border-width: 0px; // remove border from fillable-rect-on-image

        // used to have question marks on unanswered cboi questions
        // this turns off question marks for the cboi frametype
        // https://trello.com/c/6YZ132yk
        &:before {
            display: none;
        }

        &.selected,
        &.completed {
            animation: none;

            // this is the pending question mark
            // since we're showing the hint icon, we hide the question mark
            &:before {
                display: none;
            }

            // this is the completed checkmark
            &:after {
                $afterElementPosition: 16px;

                // Position the checkmark outside of the box on the right side, vertically centered
                top: 50%;
                left: auto;
                right: -$afterElementPosition;

                .in-right-half-of-overlay & {
                    right: auto;
                    left: -$afterElementPosition;
                }
            }
        }

        &.has_background {
            background-color: $COLOR_V3_WHITE;
        }

        .cf-challenge-blank {
            .user_input.blank {
                // move all the border and padding out of the
                // input box and onto the wrapper, so that things
                // will not move when switching from pending
                // to completed
                //border: 2px inset transparent;
                border: 1px solid transparent;
                padding: 1px;

                &.pending {
                    border-color: lighten($COLOR_V3_CORAL, 10%);
                }

                &.selected:not(.completed) {
                    // Thicken border, remove padding, so things don't move
                    border-color: $COLOR_V3_CORAL;
                    border-width: 2px;
                    padding: 0;
                }

                input,
                textarea {
                    // same border-width and padding as complete_container, so
                    // text does not move when completed
                    display: inline-block;
                    border: 0px;
                    padding: 0px;

                    line-height: 1em;
                    background-color: rgba(255, 255, 255, 0);
                    text-align: left;
                }
                span.complete_container {
                    // since we're forcing table display with !important priority,
                    // we'll need to ensure that ng-hide does the same
                    &.ng-hide {
                        display: none !important;
                    }

                    // same border-width and padding as input, so
                    // text does not move when completed
                    display: table !important;

                    // removing this because now that we have a textarea, we want the
                    // completed text to be lined up at the top just like the input text.
                    // span {
                    //     display: table-cell !important;
                    //     vertical-align: middle;
                    // }
                }
            }
        }
    }
}
