$APP_MENU_WIDTH: 300px;
$APP_MENU_MOBILE_WIDTH: 270px;

#sp-hide-menu {
    display: none;
}

#sp-page {
    position: absolute;
    width: 100%;
    height: 100%;
    float: left;
    transition: filter 0.25s ease;

    // transform needed to animate closing of app-menu sidebar
    transition: transform $SP_PAGE_TRANSITION_DURATION ease;

    // background color can be overridden in AppHeaderViewModel
    background-color: $COLOR_V3_WHITE;

    overflow: hidden;
}

// needed for privacy policy and terms
.scrollable-body {
    overflow-x: auto;
    overflow-y: auto;
    overflow: auto;
}

// Used by the app-menu and app-menu-mobile
.connection-number {
    position: absolute;
    right: 20px;
    top: 3px;
    background: $COLOR_V3_BLUE;
    color: $COLOR_V3_WHITE;
    font-weight: $FONT_WEIGHT_BOLD;
    font-size: 10px;
    min-width: 17px;
    line-height: 14px;
    border: 1px solid $COLOR_V3_WHITE;
    border-radius: 4px;
    text-align: center;
    padding: 1px 3px 0;

    @media (min-width: $screen-sm-min) {
        right: 0px;
        top: 0px;
    }
}

.student-network {
    .connection-number {
        background-color: $COLOR_V3_BLUE;
    }
}
