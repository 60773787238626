.cf-tile-prompt {
    $PROMPT_SIZE: 150px;
    $MAX_IMAGE_WIDTH: 90%;
    $MAX_IMAGE_HEIGHT: 90%;

    width: $TILE_PROMPT_WIDTH;
    height: $TILE_PROMPT_HEIGHT;
    opacity: 1;
    background-color: $COLOR_V3_BEIGE_LIGHTER;
    color: $COLOR_V3_EGGPLANT;
    border-radius: 3px;

    &.editable {
        border: 1px solid $COLOR_V3_PURPLE;
    }

    .frame-container.lte-xs & {
        width: $TILE_PROMPT_WIDTH_SMALL_MIN;
        height: $TILE_PROMPT_HEIGHT_SMALL_MIN;
    }

    .text-image-wrapper {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: table;
    }

    .prompt {
        &.image {
            width: 100%;
            height: 100%;

            img {
                max-height: $MAX_IMAGE_HEIGHT;
                max-width: $MAX_IMAGE_WIDTH;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }

        &.text {
            font-size: 20px;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            .frame-container.lte-xs & {
                font-size: 14px;
            }

            ul,
            ol,
            pre,
            blockquote {
                text-align: left;

                // rtl-content overrides
                div[dir="rtl"] & {
                    text-align: right;
                }
            }

            blockquote {
                background-color: rgba($COLOR_V3_EGGPLANT, 0.05);
                border-left: 4px solid rgba($COLOR_V3_EGGPLANT, 0.15);

                p {
                    color: rgba($COLOR_V3_EGGPLANT, 0.7);
                }

                // rtl-content overrides
                div[dir="rtl"] & {
                    border-left: none;
                    border-right: 4px solid rgba($COLOR_V3_EGGPLANT, 0.15);
                }
            }
        }
    }
}
