//
// Register page.
//

.onboarding_register_complete_container {
    display: block;
    position: relative;
}

.onboarding_register_complete {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 20px;
    width: 100%;

    .register_complete_actions {
        transition: transform 0.6s 0.8s ease, opacity 0.6s 0.8s ease;
    }

    // Transitions.
    &.ng-enter,
    &.ng-leave {
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        transition: opacity 0.6s 0.8s ease;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;

        .register_complete_actions {
            opacity: 0;
            transform: scale(0.5);
        }
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;

        .register_complete_actions {
            opacity: 1;
            transform: scale(1);
        }
    }

    h2,
    p {
        display: block;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 5px;
        color: #000;
    }

    p {
        margin-bottom: 20px;
        font-size: 18px;
        color: #b5b5b5;
    }

    .register_link_later {
        display: block;

        a {
            font-size: 18px;
            color: #b5b5b5;

            &:hover {
                color: #666;
            }
        }
    }

    .btn {
        margin-bottom: 20px;
        padding: 10px 30px;

        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 18px;
    }
}

//
// Media queries.
//

// Small screens.
@media (min-width: $screen-sm-min) {
    .onboarding_register_complete {
        .btn {
            padding: 17px 40px;
        }

        h2 {
            margin-bottom: 10px;
            font-size: 28px;
        }

        p {
            margin-bottom: 40px;
            font-size: 21px;
        }
    }
}
