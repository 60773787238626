onboarding-hybrid-questionary {
    background-color: rgb(255, 77, 99);
    overflow: hidden;
    padding: 0px 10px;
    @media (min-width: 375px) {
        padding: 0px 15px;
    }
    .onboarding_hybrid_outter_wrapper {
        background-color: rgb(255, 77, 99);
    }

    .questionary__cards__wrapper {
        width: 100%;
        height: 400px;
        position: relative;
        ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
            li.questionary__card {
                width: 95%;
                overflow: hidden;
                border-radius: 10px;
                position: absolute;
                background-color: white;
                box-shadow: 0px 2px 2px 0px rgba(black, 0.1);
                opacity: 0;
                $transition: 150ms ease-in-out;
                transition: opacity $transition, top $transition, left $transition, width $transition;
                text-align: center;
                padding-top: 20px;
                &:nth-last-of-type(2) {
                    opacity: 0.6;
                    width: 98%;
                    left: 1%;
                    top: 5px;
                }
                &:last-of-type {
                    width: 100%;
                    opacity: 1;
                    transform: scale(1);

                    top: 0px;
                    left: 0px;
                }
                .questionary__card__image {
                    width: 100%;
                    height: 199px;
                    background-size: 177px 199px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    margin: auto;
                    pointer-events: none;
                }
                p {
                    width: 100%;
                    padding: 20px 10px 0px 10px;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    font-weight: $FONT_WEIGHT_NORMAL;

                    // special handling for short devices
                    @media (max-height: $onboarding_short_device_max_height) {
                        padding-top: 15px;
                        margin: 0px;
                    }
                }
                .questionary__buttons_wrapper {
                    width: 100%;
                    max-width: 300px;
                    margin: auto;
                    padding: 35px 35px;
                    display: flex;
                    justify-content: space-between;

                    // special handling for short devices
                    @media (max-height: $onboarding_short_device_max_height) {
                        padding: 15px 35px;
                    }

                    button {
                        width: 94px;
                        height: 44px;
                        border-radius: 100px;
                        color: #b5b5b5;
                        background-color: #f9f9f9;
                        font-size: 18px;
                        font-weight: $FONT_WEIGHT_MEDIUM;

                        line-height: 44px;
                        outline: 0px;
                        border: 0px;
                        &:first-of-type {
                            margin-right: 40px;
                        }
                        &:active,
                        &.active {
                            color: white;
                            &:first-of-type {
                                background-color: #ff4d63;
                            }
                            &:last-of-type {
                                background-color: #54de6f;
                            }
                        }
                    }
                }
            }
        }
    }
}
