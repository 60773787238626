.onboarding__form {
    width: 100%;
    background-color: white;

    h1,
    h2 {
        display: block;
        text-align: center;
    }

    @media screen and(min-width:375px) {
        padding: 0px 10px;
    }
    *:not(input) {
        user-select: none !important;
        apple-user-select: none !important;
        apple-user-select: none !important;
    }
    form,
    h3,
    p,
    .onboarding__form__social__buttons {
        width: 100% !important;
        padding: 0px 20px;

        .form-group {
            width: 100%;
        }
    }
    h3 {
        font-size: 16px;
        line-height: 16px;
        color: rgb(97, 90, 82);
        &:first-of-type {
            margin: 20px 0px 18px 0px;
        }
        &:nth-of-type(2) {
            margin: 12px 0px 8px 0px;
        }
    }
    script {
        display: none;
    }
    .onboarding__form__social__buttons {
        button {
            width: 125px;
            @media screen and(min-width:360px) {
                width: 47%;
            }
            @media screen and(min-width:414px) {
            }
            height: 46px;
            border-radius: 4px;
            padding: 1px 0px 0px 23px;
            margin: 0px;
            border: 0px;
            outline: 0px;

            font-weight: $FONT_WEIGHT_MEDIUM;
            float: left;
            font-size: 14px;
            line-height: 46px;
            color: white;

            background-repeat: no-repeat;
            &:first-of-type {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Facebook-Logo"));
                background-size: 16px 16px;
                background-position: 19px 15px;
                @media screen and(min-width:375px) {
                    background-position: 29px 15px;
                }
                @media screen and(min-width:414px) {
                    background-position: 38px 15px;
                }
                background-color: rgba(59, 89, 152, 1);
                float: left;
                &:active {
                    background-color: rgba(59, 89, 152, 0.7);
                }
            }
            &:last-of-type {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "GooglePlus-Logo"));
                background-size: 20px 13px;
                background-position: 25px 16px;
                background-color: rgba(220, 78, 65, 1);
                float: right;
                @media screen and(min-width:375px) {
                    background-position: 33px 16px;
                }
                @media screen and(min-width:414px) {
                    background-position: 42px 16px;
                }

                &:active {
                    background-color: rgba(220, 78, 65, 0.7);
                }
            }
        }
    }
    input {
        width: 100%;
        padding: 13px 20px;
        margin: 5px auto;
        border-radius: 4px;
        font-size: 16px;
        box-sizing: border-box;
        display: inline-block;
        -webkit-appearance: none;
    }
    input:not([type="submit"]) {
        border: 1px solid rgb(229, 229, 229);
        color: #615a52;
        &.has_success {
            border-color: rgb(29, 208, 180) !important;
            color: rgb(29, 208, 180);
        }
        &.has_error {
            border-color: #ff4d63 !important;
            color: #ff4d63;
        }
        &:focus {
            border-color: #b2b2b2;
            outline: 0px;
        }
    }
    input::-webkit-input-placeholder {
        color: rgb(181, 181, 181);
    }
    input[type="submit"] {
        border: 0px;
        outline: 0px;
        text-align: center;
        color: white;
        opacity: 1;
        margin: 5px auto;
        &:disabled {
            opacity: 0.4;
            &:active {
                opacity: 0.4;
            }
        }
        &.onboarding__form__register__submit {
            background-color: rgb(29, 208, 180);
        }
        &.onboarding__form__login__submit {
            background-color: rgb(255, 77, 99);
        }
        &:active {
            opacity: 0.8;
        }
    }

    p {
        font-size: 13px;
        line-height: 16px;

        span {
            font-weight: $FONT_WEIGHT_MEDIUM;
            color: rgba(black, 0.5);
            padding: 10px 0px 10px 0px;
        }

        a {
            font-weight: $FONT_WEIGHT_BOLD;
            color: rgba(black, 0.5);
            letter-spacing: -0.1px;
            padding: 10px 0px 10px 0px;
        }

        &.onboarding__form__register__password_minimum_warning {
            font-size: 11px;
            margin: 0px;
            text-align: left;
            font-weight: $FONT_WEIGHT_MEDIUM;
            color: rgb(181, 181, 181);
            padding-left: 0px !important;
        }
    }

    .onboarding__form__forgot_password {
        width: 100%;
        text-align: left;
        font-size: 14px;
        margin: 11px 0px 13px 0px;
        color: $color_smartly_red;
    }

    .footer-text {
        width: calc(100% - 40px) !important;
        margin: 0 20px 10px 20px;
        font-size: 13px;
        line-height: 16px;
        font-weight: $FONT_WEIGHT_MEDIUM;
        color: rgba(black, 0.5);

        span.bolded-text {
            font-weight: $FONT_WEIGHT_BOLD;
            color: rgba(black, 0.5);
            letter-spacing: -0.1px;
            padding: 10px 0px 10px 0px;
        }
    }
}
