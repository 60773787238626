// NOTE: This is needed in order to support dropdown functionality in the player header,
// allowing overlaps with app-main-container
app-header {
    position: absolute;
    width: 100%;
    z-index: $APP_HEADER_Z_INDEX;
    transform: translateZ(0);
    // prevents random re-draws of the header (for instance, in this or that)
}

app-menu {
    position: absolute;
    left: 194px;
    top: calc(50% - 20px);

    // rtl-language overrides
    html[dir="rtl"] & {
        right: 194px;
        left: auto;
    }
}

app-menu-user {
    position: absolute;
    display: block;
    right: 30px;
    top: calc(50% - 23px);

    // rtl-language overrides
    html[dir="rtl"] & {
        left: 30px;
        right: auto;
    }
}

// see also: _backgrounds.scss for background-specific overrides
.app-header {
    $leftRightPaddingDesktop: 30px;
    $leftRightPaddingMobile: 15px;
    $boxShadowDimensions: 0px 1px 4px 0px;
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
    $INTERSTITIAL_TEXT_COLOR: $COLOR_V3_WHITE;

    @media (min-width: $screen-sm-min) {
        height: $APP_HEADER_HEIGHT;
    }
    @media (max-width: $screen-xs-max) {
        height: $APP_HEADER_HEIGHT_MOBILE;
    }

    .popover {
        font-weight: $FONT_WEIGHT_NORMAL;
        color: $COLOR_V3_EGGPLANT;
        white-space: nowrap;
        cursor: pointer;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: 0px !important;
        }

        hr {
            margin: 5px 0px;
            background-color: $COLOR_V3_BEIGE;
        }

        .arrow:after {
            // rtl-language overrides
            html[dir="rtl"] & {
                left: 0px !important;
            }
        }

        p {
            margin: 0;
            font-size: 15px;
            line-height: 30px;
            padding-right: 20px;
            transition: color 0.25s;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 0px;
                padding-left: 20px;
            }

            &:hover {
                color: $COLOR_V3_CORAL;
            }

            &.active {
                color: $COLOR_V3_CORAL_DARK;
            }

            &.disabled {
                color: rgba($COLOR_V3_EGGPLANT, 0.5);
                cursor: default;
            }
        }

        // catch hover events
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0px;
            top: -10px;
            width: 100%;
            height: 20px;
            background-color: transparent;
        }
    }

    .ghost-mode-label {
        position: absolute;
        top: 0px;
        left: 50%;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 4;
        font-size: 11px;
        line-height: 16px;
        background: $COLOR_V3_YELLOW;
        color: $COLOR_V3_BLACK;
        transform: translateX(-50%);
        text-align: center;
    }

    // If no header, just make ourselves invisible
    .header-invisible & {
        display: none;
    }

    // Anchor styles
    a,
    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }

    // top left brand container (sometimes has a back button, sometimes a smartly logo)
    .brand {
        $brandSlideDistance: 50px;

        &.demo {
            cursor: pointer;
        }

        &.ng-enter,
        &.ng-hide-remove {
            transition: all 0.5s ease 0s;
        }

        &.ng-leave,
        &.ng-hide-add {
            transition: all 0.5s ease 0s;
        }

        // start position when putting on screen
        &.ng-enter,
        &.ng-hide-remove {
            opacity: 0;
            transform: translateX(-#{$brandSlideDistance});

            &.alternate {
                transform: translateX($brandSlideDistance);
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                transform: translateX($brandSlideDistance);
                &.alternate {
                    transform: translateX(-#{$brandSlideDistance});
                }
            }
        }

        // end position when removing from screen
        &.ng-leave.ng-leave-active,
        &.ng-hide-add.ng-hide-add-active {
            opacity: 0;
            transform: translateX(-#{$brandSlideDistance});

            &.alternate {
                transform: translateX($brandSlideDistance);
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                transform: translateX($brandSlideDistance);
                &.alternate {
                    transform: translateX(-#{$brandSlideDistance});
                }
            }
        }

        // stable position when home is on screen
        &.ng-leave,
        &.ng-enter.ng-enter-active,
        &.ng-hide-add,
        &.ng-hide-remove.ng-hide-remove-active {
            transform: translateX(0%);
            opacity: 1;
        }

        position: absolute;
        top: 0px;
        left: 0px;
        padding: 17px $leftRightPaddingDesktop;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: 0px;
            left: auto;
        }

        img.wordmark {
            margin-left: 12px;
        }

        // Mobile styles
        @media (max-width: $screen-xs-max) {
            padding: 17px $leftRightPaddingMobile;

            img.wordmark {
                display: none;
            }
        }

        // Alternate is used on screens other than the home (student dashboard) screen
        &.alternate {
            height: 75px;
            padding-top: 23px;
            padding-bottom: 23px;

            .icon.home {
                display: inline-block;
                width: 29px;
                height: 29px;
                background-image: url("~vectors/home_back_large.svg");
                background-size: 100%;
                background-repeat: no-repeat;

                // rtl-language overrides
                html[dir="rtl"] & {
                    transform: scaleX(-1);
                }
            }

            .alternate-home-caption {
                color: $COLOR_V3_WHITE;
                font-weight: $FONT_WEIGHT_NORMAL;
                font-size: 25px;
                line-height: 18px;
                position: relative;
                display: inline-block;
                margin-left: 8px;
                top: -6px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-left: 0px;
                    margin-right: 8px;
                }
            }

            &:active {
                background-color: $COLOR_V3_CORAL_DARK;

                .icon.home {
                    background-image: url("~vectors/player_back_large.svg");
                }
            }
        }
    }

    &.valar .main-header {
        background: $COLOR_V3_BLUE;

        @media (max-width: $screen-sm-min) {
            border-bottom: 1px solid rgba($COLOR_V3_BLUE_DARK, 0.5);
        }

        .brand.alternate {
            &:active {
                background-color: $COLOR_V3_BLUE_DARK;
            }

            .icon.home {
                background-image: url("~vectors/home_back_large_alternate_colors.svg");
                opacity: 0.35;
            }
        }

        .back:active {
            background-color: $COLOR_V3_BLUE_DARK !important;
        }
    }

    .main-header {
        .brand img.wordmark {
            object-fit: contain;
            height: 40px;
            width: 104px;
        }
    }

    &.valar {
        .main-header {
            .brand img.wordmark {
                // Valar has a wordmark without a logo, so we bump the wordmark
                // over a bit to account for the missing logo.
                margin-left: 37px;

                // Aligns the text "VALAR" with the text in the nav pills
                padding-top: 10px;
            }

            .learning-mode {
                .mba-button,
                .close-button {
                    background-color: rgba($COLOR_V3_BLUE_DARK, 0.6);

                    &:active {
                        background-color: $COLOR_V3_BLUE_DARK;
                    }
                }
            }

            .header-button {
                .button-container {
                    background-color: rgba($COLOR_V3_BLUE_DARK, 0.6);

                    &:active {
                        background-color: $COLOR_V3_BLUE_DARK;
                    }
                }
            }
        }
    }

    &.miyamiya {
        .main-header .brand img.wordmark {
            width: 145px;
        }
    }

    .main-header,
    .careers-header,
    .lesson-editor-header {
        background: $COLOR_V3_CORAL;
        @media (max-width: $screen-sm-min) {
            border-bottom: 1px solid rgba($COLOR_V3_CORAL_DARK, 0.5);
        }
    }

    &.has-top-message,
    &.has-top-message.valar {
        .main-header,
        .careers-header,
        .lesson-editor-header {
            @media (max-width: $screen-sm-min) {
                border-bottom: none;
            }
        }
    }

    .main-header,
    .careers-header,
    .lesson-editor-header {
        width: 100%;
        height: 100%;
        position: relative;

        .back {
            position: absolute;
            height: $APP_HEADER_HEIGHT_MOBILE;
            width: 35px;
            cursor: pointer;

            html[dir="rtl"] & {
                left: -10px;
                right: auto;

                img {
                    margin-right: 8px;
                }
            }

            img {
                width: 9px;
                height: 15px;
                margin-top: 12px;
                margin-left: 10px;
            }

            &:active {
                background-color: $COLOR_V3_CORAL_DARK;
            }
        }

        .header-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_WHITE;
            font-size: 12px;
            line-height: 12px;
            cursor: pointer;

            .button-container {
                height: 25px;
                margin: 0px;
                line-height: 25px;
                padding: 0px 8px;
                background-color: rgba($COLOR_V3_CORAL_DARK, 0.6);
                border-radius: 4px;

                &:active {
                    background-color: $COLOR_V3_CORAL_DARK;
                }

                // catch click events outside of the button
                &:after {
                    content: "";
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: calc(100% + 20px);
                    height: calc(100% + 20px);
                }

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: default;
                }
            }
        }

        .caret {
            pointer-events: none;
            position: absolute;
            bottom: 3px;
            right: 30px;
            animation: 0.5s linear fadein;

            @keyframes fadein {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $COLOR_V3_MAP_BLUE_DARKER;
            }

            &.default {
                &:before {
                    border-bottom-color: $COLOR_V3_GREY_DARKER;
                }
            }

            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        .settings-menu {
            right: 0px;

            .button-container {
                margin-right: 8px;

                i {
                    margin-inline-start: 5px;
                }
            }
        }

        .learning-mode {
            left: 0px;

            .button-container {
                margin-left: 8px;
            }
        }

        .actions {
            position: absolute;
            left: 40px;

            [lang="ar"] & {
                left: 25px;
            }

            .bookmark {
                width: 12px;
                height: $APP_HEADER_HEIGHT_MOBILE;
                padding-top: 12px;
                padding-bottom: 11px;
                margin-inline-end: 14px;
            }

            .share {
                width: 15px;
                height: $APP_HEADER_HEIGHT_MOBILE;
                padding-top: 12px;
                padding-bottom: 11px;
                margin-inline-end: 11px;
            }

            img[disabled] {
                opacity: 0.3;
            }
        }

        .title {
            color: $COLOR_V3_WHITE;
            width: 45%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            // special styles used in conversations section
            .top-title,
            .bottom-title {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: calc(100% - 50px);
                display: inline-block;
            }
            .top-title {
                text-transform: uppercase;
                margin-top: 4px;
            }
            .bottom-title {
                opacity: 0.6;
            }

            @media (max-width: $screen-xs-max) {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 12px;
                line-height: 12px;
                width: 38%;

                &.small {
                    font-size: 10px;
                }
            }

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 20px;
            }
        }

        .pinned-title-span {
            color: $COLOR_V3_CORAL_DARK;
            display: inline-block;
            font-size: 0.8em;
            background-color: $COLOR_V3_WHITE;
            border: 0px solid transparent;
            border-radius: 3px;
            margin-top: 5px;
            padding: 3px 6px;
        }

        .last-saved-span {
            color: $COLOR_V3_CORAL_DARK;
            display: inline-block;
            font-size: 0.7em;
            margin-top: 2px;
        }

        .menu-icon-container {
            position: absolute;
            display: block;
            right: 0px;
            top: 0px;
            height: 25px + 2 * 25px;
            width: 27px + 2 * $leftRightPaddingDesktop;
            padding: 25px $leftRightPaddingDesktop;
            cursor: pointer;
            transition: 0.3s background-color;

            &:active {
                background-color: $COLOR_V3_CORAL_DARK;
                transition-delay: 0;
                transition-duration: 0;

                .menu-icon,
                .menu-icon:before,
                .menu-icon:after {
                    transition-delay: 0;
                    transition-duration: 0;
                    background-color: $COLOR_V3_WHITE;
                }
            }

            .menu-icon,
            .menu-icon:before,
            .menu-icon:after {
                display: block;
                position: absolute;
                top: 25px + 12px;
                left: $leftRightPaddingDesktop;
                font-size: 0px;
                width: 27px;
                height: 3px;
                border: 0px solid transparent;
                border-radius: 2px;
                background-color: $COLOR_V3_CORAL_DARK;
            }

            // Mobile styles
            @media (max-width: $screen-xs-max) {
                width: 27px + 2 * $leftRightPaddingMobile;
                padding: 25px $leftRightPaddingMobile;

                .menu-icon,
                .menu-icon:before,
                .menu-icon:after {
                    left: $leftRightPaddingMobile;
                }
            }

            .menu-icon {
                transition: 0.3s background-color 0.3s;

                .showingMenu & {
                    background-color: transparent;
                }
            }

            .menu-icon:before,
            .menu-icon:after {
                content: "";
                top: 0px;
                left: 0px;
                transition: 0.3s transform 0.3s, 0.3s background-color 0.3s;
            }

            .menu-icon:before {
                transform: translateY(-10px);

                .showingMenu & {
                    transform: translateY(0) rotate(45deg);
                }
            }

            .menu-icon:after {
                transform: translateY(10px);

                .showingMenu & {
                    transform: translateY(0) rotate(-45deg);
                }
            }
        }
    }

    .player-header {
        $EXIT_PLAYER_ICON_PADDING_DESKTOP: 15px;
        $EXIT_PLAYER_TOP_PADDING_DESKTOP: 6px;
        $EXIT_PLAYER_LEFT_PADDING_DESKTOP: 2 * $EXIT_PLAYER_ICON_PADDING_DESKTOP + $EXIT_PLAYER_TOP_PADDING_DESKTOP +
            29px;

        position: absolute;
        height: $APP_HEADER_HEIGHT;
        width: 100%;
        transition: 0.3s background-color;

        .home-link {
            .exit-text {
                display: none;
                position: absolute;
                top: $APP_HEADER_HEIGHT;
                left: 0;
                width: 100%;
                height: 30px;
                text-align: center;
                padding-top: 4px;
                background-color: $COLOR_V3_CORAL_DARK;
                color: $COLOR_V3_WHITE;
            }

            &:hover {
                &:not(:active) {
                    background: rgba($COLOR_V3_CORAL_DARK, 0.35);
                }

                .exit-text {
                    display: block;
                    // rtl-language overrides
                    html[dir="rtl"] & {
                        padding-bottom: 35px;
                    }
                }
            }
        }

        .lesson-details {
            position: absolute;
            right: 0px;
            top: 0px;
            min-width: 200px;
            max-width: 33%;
            height: $APP_HEADER_HEIGHT;
            padding-right: $EXIT_PLAYER_ICON_PADDING_DESKTOP;
            padding-top: $EXIT_PLAYER_TOP_PADDING_DESKTOP;
            padding-left: $EXIT_PLAYER_LEFT_PADDING_DESKTOP;
            text-align: right;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 0px;
                right: auto;
                padding-top: 0px; // increased line height of the h2, h3 takes care of this
                padding-left: $EXIT_PLAYER_ICON_PADDING_DESKTOP;
                padding-right: $EXIT_PLAYER_LEFT_PADDING_DESKTOP - 20px; // Arabic text is a bit longer here, so give it extra space
                text-align: left;
            }

            h2 {
                margin: ($EXIT_PLAYER_TOP_PADDING_DESKTOP - 3) 0;
                padding: 3px 0;
            }

            h2,
            h3 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                // rtl-language overrides
                html[dir="rtl"] & {
                    line-height: 1.6;
                    margin: 0px;
                }
            }
            &.translation-link {
                line-height: $APP_HEADER_HEIGHT;
                font-size: 0.4 * $APP_HEADER_HEIGHT;
                text-align: center;
            }
        }

        h3.frame-instructions {
            margin: 0 auto;
            margin-top: 48px;
            width: 60%;
            z-index: 3;
            opacity: 0;
            animation: fade-in 0.5s linear 0.25s;
            animation-fill-mode: forwards;
            backface-visibility: hidden;

            @media (max-width: $screen-xs-max) {
                font-size: $FONT_SIZE_H2_DEFAULT * $MOBILE_FONT_MULTIPLIER;
                width: 80%;
            }

            &.shift-up {
                margin-top: 40px;
            }
        }

        .back-player-container,
        .exit-player-container,
        .edit-demo-desktop {
            display: block;
            position: absolute;
            cursor: pointer;
            z-index: 3; // in front of lesson-progress-bar
        }

        lesson-progress-bar {
            z-index: 1; // behind back and exit buttons
        }

        .back-player-container {
            @media (max-width: $screen-xs-max) {
                left: 0px;
                padding: 27px 26px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                    transform: scaleX(-1);
                }
            }

            // Mobile-only active state
            &:active {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        .exit-player-container {
            @media (max-width: $screen-xs-max) {
                right: 0;
                padding: 27px 26px 27px 20px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 0px;
                    right: auto;
                }

                img {
                    vertical-align: top;
                }

                // Mobile-only active state
                &:active {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        .exit-demo-desktop {
            position: absolute;
            right: 0px;
            top: 0px;
            min-width: 200px;
            max-width: 33%;
            height: $APP_HEADER_HEIGHT;
            padding-right: $EXIT_PLAYER_ICON_PADDING_DESKTOP;
            padding-top: 20px;
            padding-left: $EXIT_PLAYER_LEFT_PADDING_DESKTOP;
            text-align: right;
            cursor: pointer;

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: 0px;
                padding-left: $EXIT_PLAYER_ICON_PADDING_DESKTOP;
                padding-right: $EXIT_PLAYER_LEFT_PADDING_DESKTOP;
                text-align: left;
            }

            h2 {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-left: 10px;
                    margin-right: 0px;
                }
            }
        }
    }

    &.showing-uneditable-lesson {
        .lesson-editor-header {
            background-color: $COLOR_V3_TURQUOISE;
            .last-saved-span,
            .pinned-title-span {
                color: $COLOR_V3_TURQUOISE_DARK;
            }
        }

        .menu-icon-container {
            &:active {
                background-color: $COLOR_V3_TURQUOISE_DARK;
            }

            .menu-icon,
            .menu-icon:before,
            .menu-icon:after {
                background-color: $COLOR_V3_TURQUOISE_DARK;
            }
        }
    }

    .tap-scroll-container {
        z-index: 5;
        height: 15px;
        position: absolute;
        cursor: none;

        // adjust hit area to not interfere with main navigation attempts
        left: 160px;
        right: 70px;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: 70px;
            right: 160px;
        }

        @media (max-width: $screen-xs-max) {
            left: 50px;
            right: 50px;
        }
    }
}
