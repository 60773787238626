// Some devices have a unibrow notch at the top of the device as well as a home swipe drag-area indicator at the bottom of the screen.
// We need to offset the entire viewport to accomodate and refine things like fixed element positioning offsets as well.
// All the pixel offsets referenced here are pulled directly from the originating style definitions. We typically just need
// to add the appropriate `safe-area-inset-*` styles via `calc()` calls to get `fixed` elements positioned as expected.

// see also: `.notch-test` feature-detction element in `hybrid/www/index.html`

html.notch {
    //---------------------------
    // Viewport
    //---------------------------

    // iOS 11.2+
    padding-top: env(safe-area-inset-top) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;

    background-color: $COLOR_V3_CORAL;

    //---------------------------
    // HTML element background styles
    //---------------------------

    &.bg-coral {
        background-color: $COLOR_V3_CORAL !important;
    }
    &.bg-purple {
        background-color: $COLOR_V3_PURPLE !important;
    }
    &.bg-turquoise {
        background-color: $COLOR_V3_TURQUOISE !important;
    }
    &.bg-blue {
        background-color: $COLOR_V3_BLUE !important;
    }
    &.bg-completion-blue {
        background-color: $COLOR_V3_BLUE_COMPLETION !important;
    }

    //---------------------------
    // Google Autocomplete
    //---------------------------

    .pac-container {
        margin-top: calc(0px - env(safe-area-inset-top)) !important;
    }

    //---------------------------
    // Bottom Buttons
    //---------------------------

    .app-menu-mobile {
        border-top: 1px solid $COLOR_V3_CORAL_DARK !important;
        border-bottom: none !important; // on balance, I think it looks better without the bottom border to match the top of the screen buttons that don't have top borders when actively pressed

        margin-bottom: env(safe-area-inset-bottom) !important;

        &.valar {
            border-top: 1px solid $COLOR_V3_BLUE_DARK !important;
        }
    }

    //---------------------------
    // Modals
    //---------------------------

    dialog-modal-alert .modal {
        padding-top: env(safe-area-inset-top) !important;

        &.no-header {
            @media (max-width: $screen-sm-max) {
                padding-top: 65px !important;
            }
        }
    }

    dialog-modal-alert .modal.disconnected-modal-not-initialized {
        padding: 0px !important;
    }

    dialog-modal-alert .modal.student-network-event-details {
        background-color: rgba(0, 0, 0, 0);
        height: calc(100% - env(safe-area-inset-bottom) - #{$APP_MENU_MOBILE_HEIGHT});
        padding-top: 0px !important;
    }

    dialog-modal-alert .modal.student-network-event-type-filters {
        // This dialog modal should have access to the app menu so that users can
        // navigate to another part of the app even if the dialog modal is open.
        // This is largely handled elsewhere (see this modal's specific overrides
        // in modals.scss), but on mobile devices with a notch, we want to make
        // sure that the coral areas below the app menu and around the notch don't
        // have a dark background applied to them because it looks strange  with
        // the app menu enabled.
        margin-bottom: env(safe-area-inset-bottom);
        margin-top: env(safe-area-inset-top);
    }

    dialog-modal-alert .modal.accepted-application-modal {
        @media (max-width: $screen-sm-max) {
            padding-top: 0px !important;
        }
    }

    dialog-modal-alert .modal.exec-ed-cert-congrats-modal {
        padding-top: 0px !important;
        .modal-dialog {
            .modal-content {
                .modal-body {
                    button.close {
                        top: env(safe-area-inset-top);
                    }
                    .cert-accepted-modal {
                        padding-top: env(safe-area-inset-top) !important;
                        padding-bottom: 50px;
                    }
                }
            }
        }
    }

    //---------------------------
    // Popovers
    //---------------------------

    .popover.student-network-event-ambiguous-popover {
        @media (max-width: $screen-xs-max) {
            top: calc(
                50vh - #{$APP_MENU_MOBILE_HEIGHT * 0.5} + env(safe-area-inset-top) - env(safe-area-inset-bottom)
            ) !important;
            max-height: calc(
                100vh - #{$APP_MENU_MOBILE_HEIGHT} - #{env(safe-area-inset-top)} - #{env(safe-area-inset-bottom)} - 20px
            ) !important;

            .event-list-container {
                max-height: calc(
                    100vh - #{$APP_MENU_MOBILE_HEIGHT} - #{env(safe-area-inset-top)} - #{env(safe-area-inset-bottom)} - 77px
                ) !important;
            }
        }
    }

    //---------------------------
    // Fixed Elements
    //---------------------------

    #app-main-container.hide-mobile-menu {
        // Settings
        .page-with-left-nav-and-main-box {
            .main-box {
                bottom: env(safe-area-inset-bottom) !important;
            }
        }

        .responsive-nav {
            max-height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
        }
    }

    // Settings
    .page-with-left-nav-and-main-box {
        .main-box {
            top: calc(
                env(safe-area-inset-top) + #{$APP_HEADER_HEIGHT_MOBILE} + var(--mobile-nav-toggle-btn-height)
            ) !important;
            bottom: calc(env(safe-area-inset-bottom) + #{$APP_MENU_MOBILE_HEIGHT}) !important;
        }

        .mobile-nav-toggle-btn {
            top: calc(env(safe-area-inset-top) + #{$APP_HEADER_HEIGHT_MOBILE}) !important;
        }

        .responsive-nav {
            top: env(safe-area-inset-top) !important;
            max-height: calc(
                100% - env(safe-area-inset-top) - env(safe-area-inset-bottom) - #{$APP_MENU_MOBILE_HEIGHT}
            ) !important;
        }
    }

    // Toasts
    .ng-toast.ng-toast--top {
        top: env(safe-area-inset-top) !important;
        .ng-toast__list {
            top: env(safe-area-inset-top) !important;
        }
    }

    // Careers Menu
    .careers-menu-mobile {
        top: env(safe-area-inset-top) !important;
    }

    // Feedback Sidebar (sm+)
    feedback-sidebar {
        top: calc(env(safe-area-inset-top) + 450px) !important;
    }

    // Accepted Application
    .accepted-application {
        .congrats-container {
            position: relative;
            top: calc(env(safe-area-inset-top) + 15px) !important;
        }
        .bottom-container {
            bottom: calc(env(safe-area-inset-bottom) + 15px) !important;

            @media (min-width: $screen-sm-min) and (min-height: 600px) {
                bottom: calc(env(safe-area-inset-bottom) + 30px) !important;
            }
        }
    }

    // Share Stream (lg+ / landscape)
    share-stream-sidebar {
        @media (min-width: $screen-lg-min) {
            top: calc(env(safe-area-inset-top) + 110px) !important;
        }
    }

    // Browse Courses Filters
    .browse-courses .select-filters button.save-and-close-select-filters {
        bottom: calc(
            env(safe-area-inset-bottom) + #{$CONTINUE_BUTTON_DESKTOP_PADDING} + #{$APP_MENU_MOBILE_HEIGHT}
        ) !important;
    }

    // Sessions Navigation (Login / Join)
    .gradient-backdrop {
        top: env(safe-area-inset-top) !important;
    }

    // Onboarding Modal
    .onboarding_modal {
        top: env(safe-area-inset-top) !important;
    }

    // Onboarding Footer Nav
    onboarding-hybrid-start #nav {
        bottom: calc(env(safe-area-inset-bottom) + 31px) !important;
    }

    // Common styles for collapsible sidebar boxes
    // Of the following current collapsible boxes, only 4
    // can possibly show at once
    .sidebar-collapsible-content > * {
        @for $i from 0 through 3 {
            &:nth-last-child(#{$i + 1}) .box-container {
                bottom: calc(env(safe-area-inset-bottom) + #{$APP_MENU_MOBILE_HEIGHT} + #{$i * 45px});

                @media (min-width: $screen-sm-min) {
                    bottom: calc(env(safe-area-inset-bottom) + #{$i * 65px});
                }
            }
        }
    }

    .sidebar-collapsible-content {
        student-dashboard-schedule,
        student-dashboard-sidebar-box {
            .box-container {
                &.open {
                    bottom: calc(env(safe-area-inset-bottom) + #{$APP_MENU_MOBILE_HEIGHT});
                    height: calc(
                        100% - env(safe-area-inset-bottom) - env(safe-area-inset-top) - #{$APP_MENU_MOBILE_HEIGHT} - #{$APP_HEADER_HEIGHT_MOBILE}
                    );
                    @media (min-width: $screen-sm-min) {
                        height: calc(100% - env(safe-area-inset-bottom) - #{$APP_HEADER_HEIGHT});
                        bottom: env(safe-area-inset-bottom);
                    }
                }
            }
        }
    }

    // Student Network Filtering
    student-network {
        .mobile-nav-toggle-btn {
            top: calc(env(safe-area-inset-top) + #{$APP_HEADER_HEIGHT_MOBILE}) !important;
        }
        .filter-box-container {
            student-profile-list {
                bottom: calc(env(safe-area-inset-bottom) + #{$APP_MENU_MOBILE_HEIGHT}) !important;
            }
            .responsive-nav {
                top: env(safe-area-inset-top) !important;
                max-height: calc(
                    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom) - #{$APP_MENU_MOBILE_HEIGHT}
                ) !important;
            }
        }
        .mobile-exit-search {
            bottom: calc(env(safe-area-inset-bottom) + $APP_MENU_MOBILE_HEIGHT + 8px) !important;
        }
    }

    // HelpScout Beacon
    .BeaconFabButtonFrame {
        @media (max-width: $screen-xs-max) {
            // Do not let the button overlap our app menu
            bottom: calc(env(safe-area-inset-bottom) + #{$APP_MENU_MOBILE_HEIGHT} + 8px) !important;
        }
    }

    .BeaconContainer {
        @media (max-width: $screen-xs-max) {
            // Ensure there's room for the close button at the top
            // 32px is from Helpscout's CSS
            top: calc(env(safe-area-inset-top) + 32px) !important;
            height: calc(100% - env(safe-area-inset-top) - 32px) !important;
        }
    }

    #HSBeaconContainerFrameClose {
        padding-top: calc(env(safe-area-inset-top) + 6px) !important; // 6px is from Helpscout's CSS
        top: calc(-32px - env(safe-area-inset-top)) !important; // 32px is from Helpscout's CSS
    }
}
