.form-responsive {
    .form-item-container {
        clear: both;

        label {
            width: 25%;
            float: left;
            padding-right: 10px;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                float: none;
            }
        }

        .input-container {
            width: 75%;
            float: right;

            input,
            textarea {
                width: 100%;
            }

            @media (max-width: $screen-xs-max) {
                width: 100%;
                float: none;
            }
        }
    }
}
