playlist-map {
    .map-container {
        padding: 0px 30px 60px 30px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 30px;
        }

        &.compact {
            padding-bottom: 30px;

            // remove the playlist collection line separator (the `hr` element) on mobile
            // for the first playlist `.playlist-collection` since it's not necessary
            @media (max-width: $screen-xs-max) {
                .playlist-collection:first-of-type {
                    padding-top: 26px;

                    hr {
                        display: none;
                    }
                }
            }
        }
    }

    // we want to center everything in here
    .row {
        text-align: center;

        // make extra space for the playlist boxes in the rows and cols
        @media (max-width: $screen-xs-max) {
            margin-left: -5px;
            margin-right: -5px;
        }
    }

    .playlist-col {
        text-align: center;
        display: inline-block;
        float: none !important;
        vertical-align: top;

        // make extra space for the playlist boxes in the rows and cols
        @media (max-width: $screen-xs-max) {
            padding-left: 5px;
            padding-right: 5px;
        }

        &.special-col {
            display: block;
        }
    }

    .locked-message {
        padding: 30px;
        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: 15px;
        color: $COLOR_V3_BEIGE_DARKER;

        div {
            min-height: 44px;
            max-height: 132px;

            hr {
                margin-top: 21px;
            }
        }
    }

    .playlist-collection,
    .map-extras {
        hr {
            margin-top: 26px;
            margin-bottom: 31px;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 41px;
            }
        }

        .playlist-collection-header,
        .map-extras-header {
            margin-bottom: 10px;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 15px;
            }

            .playlist-collection-title,
            .map-extras-title {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                line-height: 1.1em;
                font-size: 22px;

                @media (min-width: $screen-sm-min) {
                    font-size: 25px;
                }
            }
            .map-extras-subtitle {
                display: block;
                font-size: 12px;
                line-height: 1.1em;
                font-weight: $FONT_WEIGHT_NORMAL;
                color: $COLOR_V3_BEIGE_DARKER;

                @media (min-width: $screen-sm-min) {
                    font-size: 15px;
                }
            }
        }

        .playlist-collection-toggle-container {
            color: $COLOR_V3_BEIGE_DARKER;
            font-size: 18px;

            i {
                cursor: pointer;
            }
        }

        &.expanded {
            .playlist-collection-toggle-container {
                padding-top: 30px;
            }
        }
    }
}
