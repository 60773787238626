.card-list-item-container {
    position: relative;
}

.card-list-item {
    @extend .clearfix;
    background: $COLOR_V3_WHITE;
    border-top: 1px solid $COLOR_V3_BEIGE_MIDDARK;
    font-size: 12px;
    color: $COLOR_V3_GREY_DARK;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
    box-shadow: 0px 5px 5px $COLOR_V3_BEIGE_DARKER;

    @media (min-width: $screen-sm-min) {
        position: relative;

        .card-content {
            padding: 0px 130px 0px 0;
            position: relative;
        }

        &.no-actions .card-content {
            padding-right: 0;
        }
    }

    // make the candidate list extra wide at larger desktop sizes to break out of the smaller main box size
    @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
        font-size: 16px;
    }

    &.admin {
        padding-right: 0;
        max-width: 640px;
        margin: 0 auto;
    }

    // collapsing of cards
    .body {
        max-height: 400px;
        transition: 0.25s ease;
    }

    .full-profile,
    .text-links {
        transition: opacity 0.25s ease, border-color 0.25s ease !important;
    }

    &.compact {
        .body {
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            overflow: hidden;
        }

        .full-profile,
        .text-links {
            opacity: 0;
            transform: scale(0);
            transition-delay: 0.25s;
        }

        .badge {
            float: left;
        }

        candidate-list-actions .actions .open {
            margin-top: 0;

            @media (min-width: $screen-sm-min) {
                margin-top: 5px;
            }
        }
    }

    &.blurred {
        filter: blur(3px);
        pointer-events: none;
    }

    button.expand {
        background: $COLOR_V3_WHITE;
        border: 1px solid $COLOR_V3_BEIGE_DARKER;
        border-radius: 4px;
        padding: 1px 5px 0;
        color: $COLOR_V3_BEIGE_DARKER;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 12px;
        text-transform: uppercase;
        outline: none;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
        transition: 0.25s ease;
        animation: 0.75s ease move-down;

        &:hover {
            color: $COLOR_V3_BEIGE_DARKEST;
        }
    }

    // tooltips
    .tooltip {
        position: absolute;
        line-height: 14px;
        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: 12px;
        text-transform: none;
        border-radius: 4px;
        opacity: 0;
        white-space: nowrap;
        margin-top: -11px;
        padding: 5px 10px !important;
        transform: scale(0);
        transition: opacity 0.25s ease, margin 0.25s ease;
        background: $COLOR_V3_GREY_LIGHTER;
        border: 1px solid white;
        color: $COLOR_V3_BLACK;

        @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
            candidate-list & {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            transform: translateX(-50%);
            border: 6px solid transparent;
            border-bottom-color: $COLOR_V3_GREY_LIGHTER;
            border-top: none;
        }

        &::before {
            border-width: 7px;
            border-bottom-color: $COLOR_V3_WHITE;
            top: -7px;
        }

        ul {
            padding: 0px;
            margin: 0px;
        }

        li {
            list-style: none;
            margin: 4px 0;

            &.muted {
                color: $COLOR_V3_GREY_NOT_SO_LIGHT;
            }
        }
    }

    .underline {
        color: $COLOR_V3_GREY_DARKER;
        text-decoration: underline;
        white-space: nowrap;
    }

    .has-tooltip {
        position: relative;

        .tooltip {
            @include tooltip(auto, calc(100% + 10px), calc(100% - 40px), 15px);
        }

        .tooltip.wrapping-top {
            @include tooltip-top(400px, calc(100% + 10px), calc(100% - 185px), 170px);
        }

        .tooltip.wrapping-bottom {
            @include tooltip-bottom(400px, calc(100% + 10px), calc(100% - 185px), 155px);
        }

        .tooltip {
            @include tooltip-dark($COLOR_V3_BEIGE_BEYOND_BEYOND_DARK);
        }
    }

    .action-has-tooltip {
        position: relative;

        .tooltip {
            @include tooltip(auto, calc(100% + 10px), -300%, 80%);
            @include tooltip-dark($COLOR_V3_GREY_DARK_BLUE);

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                @include tooltip(auto, calc(100% + 10px), 0px, calc(100% - 15px));
                left: auto;
                right: 0px;
            }

            @media (max-width: $screen-xs-max) {
                display: none;
            }
        }
    }

    :hover > .tooltip,
    .tooltip-trigger:hover .tooltip {
        opacity: 1;
        margin-top: 0;
        transform: scale(1);
    }

    .tooltip-trigger:hover .tooltip {
        transform: translateX(-50%) scale(1);
    }

    // animations
    &.ng-animate {
        z-index: -1;
    }
    &.ng-enter,
    &.ng-leave {
        transition: 0.5s;
        overflow: hidden;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active {
        max-height: 500px;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        max-height: 0;
    }
}
