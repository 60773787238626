lesson-start-screen {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .lesson-start-screen {
        width: 448px;
        max-width: 100%;
        margin: 40px auto 0px;

        @media (max-width: $screen-xs-max) {
            margin-top: 30px;
        }

        h1 {
            color: $COLOR_V3_CORAL;
            text-align: center;
            margin-bottom: 23px;
            font-size: 28px;
        }

        h2 {
            text-align: center;
            font-size: 13px;
            color: $COLOR_V3_BEIGE_DARK;
            margin-bottom: 27px;

            @media (min-width: $screen-iphone6-min) {
                font-size: 15px;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 17px;
            }
        }

        ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
        }

        ul li {
            $bullet_width: 38px;
            font-size: 14px;
            line-height: 14px;
            padding: 2px 0px 2px $bullet_width + 2px;
            color: $COLOR_V3_CORAL_DARK;
            position: relative;
            min-height: $bullet_width;
            display: table;

            // rtl-content overrides
            div[dir="rtl"] & {
                direction: rtl;
                padding: 2px $bullet_width + 2px 2px 0px;
                line-height: 1.4;
            }

            // Firefox doesn't support min-height on display: table; it treats height as min-height in that case
            @-moz-document url-prefix() {
                height: $bullet_width;
            }

            > span {
                display: table-cell;
                height: 100%;
                vertical-align: middle;

                p {
                    margin: 0;
                    padding-right: 20px;
                }
            }

            @media (min-width: $screen-iphone6-min) {
                font-size: 16px;
                line-height: 16px;
                padding: 2px 0px 2px $bullet_width + 7px;

                // rtl-content overrides
                div[dir="rtl"] & {
                    padding: 2px $bullet_width + 7px 2px 0px;
                }
            }

            @media (min-width: $screen-sm-min) {
                font-size: 18px;
                line-height: 18px;
                padding: 2px 0px 2px $bullet_width + 12px;

                // rtl-content overrides
                div[dir="rtl"] & {
                    padding: 2px $bullet_width + 12px 2px 0px;
                }
            }

            &:after {
                $height: 40px;
                background-image: url("~images/lesson_start_bullet_list_icon.png");
                image-rendering: -moz-crisp-edges; /* Firefox */
                image-rendering: -o-crisp-edges; /* Opera */
                image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                image-rendering: crisp-edges;
                -ms-interpolation-mode: nearest-neighbor;

                background-size: $bullet_width $height;
                content: "";
                position: absolute;
                left: 0px;
                top: calc(50% - 1px);
                transform: translateY(-50%);
                width: $bullet_width;
                height: $height;

                // rtl-content overrides
                div[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                }

                @media (min-width: $screen-iphone6-min) {
                    left: 5px;

                    // rtl-content overrides
                    div[dir="rtl"] & {
                        left: auto;
                        right: 5px;
                    }
                }

                @media (min-width: $screen-sm-min) {
                    left: 7px;

                    // rtl-content overrides
                    div[dir="rtl"] & {
                        left: auto;
                        right: 7px;
                    }
                }
            }
        }

        &.turquoise {
            h1 {
                color: $COLOR_V3_TURQUOISE;
            }

            ul li {
                color: $COLOR_V3_TURQUOISE_DARK;
            }
        }

        &.demoMode {
            padding: 20px 20px 40px 20px;
            background-color: $COLOR_V3_CORAL;
            h1,
            h2,
            ul li {
                color: $COLOR_V3_WHITE;
            }
            h2 {
                margin-bottom: 20px;
            }
            ul li {
                padding: 5px 0px 5px 55px;
                line-height: 1.3em;

                // rtl-content overrides
                div[dir="rtl"] & {
                    padding: 5px 55px 5px 0px;
                }

                &:after {
                    background-image: url("~images/progress_badge_complete.png");
                }
            }
        }
    }
}
