.app-menu {
    width: 100%;
    height: 100%;
    color: $COLOR_V3_WHITE;

    ul {
        padding-left: 0;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-right: 0;
        }

        li {
            margin-right: 1px;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
            }

            &.with-button {
                padding-left: 20px;
                margin-top: -1px;

                html[dir="rtl"] & {
                    padding-left: 0;
                    padding-right: 20px;
                }
            }
        }
    }

    &.admin-menu ul li:first-child {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    .admin-popover {
        font-family: $FONT_FAMILY;

        .arrow {
            right: 30px !important;
            border-bottom-color: white !important;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 30px !important;
                right: auto !important;
            }
        }
    }
}

.spotlight {
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100000;
        transform: translate(-50%, -50%);
        width: 100%;
        padding-top: 100%;
        border: 2px solid white;
        border-radius: 50%;
        box-shadow: 0 0 0 300vw rgba(black, 0.85);
    }
}
