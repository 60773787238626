.edit_chapter {
    margin-bottom: 5px;
    border: 2px solid $COLOR_V3_GREY;
    border-radius: 4px;
    width: 100%;

    &.pending {
        background-color: $COLOR_V3_RED_LIGHT;
    }

    &.available {
        background-color: $COLOR_V3_GREEN_LIGHT;
    }

    .chapter-sessions-header-container {
        width: 100%;
        text-align: left;
        padding: 5px;

        label {
            width: 15px;
            text-align: center;
            font-size: 20px;
        }
        input[name="chapter_name"] {
            width: calc(100% - 110px);
            margin-left: 10px;
            font-size: 16px;
        }
        .chapter-reorder-container {
            margin-left: 6px;
            margin-top: 2px;
            float: right;
        }
    }

    .lessons-container {
        margin: 5px;

        .lesson.pending {
            color: rgba($COLOR_V3_BLACK, 0.25);
        }
    }

    .selectize-control {
        margin-top: 15px;
    }

    .pending-delete-container {
        height: 50px;
        padding: 8px;

        button[name="toggle_pending"] {
            float: left;
        }

        button[name="remove_chapter"] {
            float: right;
        }
    }
}
