.issue-refund-form {
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 12px;

        &.issue-refund {
            display: flex;
            flex-direction: column;
            > div {
                margin-bottom: 10px;
            }
            > p {
                margin-bottom: 5px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
