/*
    the 'primary' class always must be included along
    with the editor_button class.  including them both here
    makes sure that these overrides take precedence over stuff
    in primary.

    we tried to do this by including the primary mixin here, but
    that led to trouble with chrome dev tools, and expands the
    size of our css needlessly.
*/
button.primary.editor_button {
    text-align: center;
    width: 140px;
    min-height: 32px;
    font-size: 16px;
    padding: 0px 0px;

    margin: 2px !important;

    html[dir="ltr"] & {
        text-align: center;
    }

    &.preview {
        color: $COLOR_V3_WHITE;
        background-color: $COLOR_V3_PURPLE;
        border-color: $COLOR_V3_PURPLE;

        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_V3_PURPLE;
        }
    }

    &.save_and_pin {
        color: $COLOR_V3_WHITE;
        background-color: $COLOR_V3_GREEN_DARK;
        border-color: $COLOR_V3_GREEN_DARK;

        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_V3_GREEN_DARK;
        }
    }

    &.save {
        color: $COLOR_V3_WHITE;
        background-color: $COLOR_V3_GREEN;
        border-color: $COLOR_V3_GREEN;

        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_V3_GREEN;
        }
    }

    &.warning {
        background-color: $COLOR_V3_BLUE_LIGHT;
        border-color: $COLOR_V3_BLUE_LIGHT;
        color: $COLOR_V3_WHITE;

        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_V3_BLUE_LIGHT;
            color: $COLOR_V3_WHITE;
        }
    }

    &.correct {
        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_ANSWER_CORRECT;
        }
    }

    &.incorrect {
        &:hover,
        &:hover:enabled,
        &:active,
        &:active:enabled,
        &:focus {
            background-color: $COLOR_ANSWER_INCORRECT;
        }
    }

    &.preview,
    &.warning,
    &.correct,
    &.incorrect,
    &.save_and_pin,
    &.save {
        &:disabled {
            opacity: 0.5;
            background-color: $COLOR_V3_BEIGE_LIGHT;
        }
    }
}
