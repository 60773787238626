.BeaconFabButtonFrame {
    @media (max-width: $screen-xs-max) {
        // Do not let the button overlap our app menu
        // (see also notch.scss)
        bottom: $APP_MENU_MOBILE_HEIGHT + 8px !important;

        transform: translateX(-50%) !important; // important to override style attribute
        left: 50% !important;
        right: auto !important;
    }
}
