.app-main-container {
    position: absolute;

    // In Chrome, we don't want the scrollbar to take up space.
    // This is the default behavior on iOS / Safari.
    // However, the simplest way to do this right now is via
    // the `overlay` value for overflow. Unfortunately, this value
    // is currently deprecated. Fortunately, Chrome has indicated
    // they don't intend to actually remove it until a suitable
    // replacement is available. This will eventually come in the form
    // of the new `scrollbar-gutter` CSS property. Until then, we'll
    // use this deprecated API to force the behavior we want.
    //
    // Reference discussion: https://github.com/w3c/csswg-drafts/issues/92
    overflow-y: auto; // fallback behavior for Firefox, etc.
    overflow-y: overlay; // desired behavior; deprecated but works fine
    overflow-x: hidden;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: $APP_MAIN_CONTAINER_Z_INDEX;

    // Push down to make room for header
    @media (min-width: $screen-sm-min) {
        top: $APP_HEADER_HEIGHT;
    }
    @media (max-width: $screen-xs-max) {
        top: 40px;
    }

    // If no header, move to top
    .header-invisible &,
    .header-invisible &.player {
        top: 0;
    }
    .header-invisible &.player {
        // shared with .app-header so that changes happen in sync
        transition-duration: $SP_PAGE_TRANSITION_DURATION;
    }

    // These wrapper divs stretch the full height of the screen
    > ng-include > [ng-view],
    > ng-include > [ng-view] > div {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    // added programmatically in AppHeaderViewModel based on player status
    &.player {
        bottom: 0px;
        top: $APP_HEADER_HEIGHT;

        // this used to be the default in flat buttons, but no longer. It is
        // possible that this is unnecessary.  The button we were trying to
        // fix turned out to be inside of front-royal-footer
        button.flat {
            white-space: normal;
        }
    }
}
