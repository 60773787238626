lesson-finish-screen-demo-content {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
    width: auto;

    button.flat {
        padding: 5px;

        h2 {
            font-size: 0.8em;
            text-transform: none;
        }
    }
    button.free-trial {
        width: 100%;
        display: block;
        padding: 8px;
        margin-top: 10px;

        h2 {
            font-size: 1em;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    .share-container {
        text-align: center;
    }
    a.share {
        $buttonSize: 36px;
        $lineHeight: 25px;
        font-size: $lineHeight;
        line-height: $lineHeight;
        text-align: center;
        height: $buttonSize;
        width: $buttonSize;
        display: inline-block;
        color: $COLOR_V3_WHITE;
        padding: ($buttonSize - $lineHeight) * 0.5;
        border: 0px solid transparent;

        .no-touchevents &:hover {
            color: darken($COLOR_V3_WHITE, 5%);
        }
    }

    p.learn-more {
        display: block;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: $COLOR_V3_WHITE;
        margin-top: 10px;
        margin-bottom: -10px;
        cursor: pointer;

        &:after {
            display: block;
            @include font-awesome-icon("\f107"); // fa-angle-down
            margin-top: -4px;
        }
    }
}
