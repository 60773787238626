form[name="tuition_and_scholarships"] {
    input[type="checkbox"] {
        margin-top: 6px;
    }

    .checkbox-group-label-wrapper {
        margin-bottom: 10px;

        .control-title {
            margin: 0;
        }
    }

    .short-answer-container {
        margin-top: 20px;
    }

    .radio-label {
        margin-bottom: 5px;

        input[type="radio"] {
            margin-top: 8px;
        }

        .checkbox-group-label-wrapper {
            .option-text {
                font-size: 18px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }

            .subtext {
                font-weight: $FONT_WEIGHT_NORMAL;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-size: 14px;
            }
        }
    }

    .disclaimer {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
    }

    .scholarships-container {
        margin-top: -20px;

        .label-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .scholarship-category-label {
                color: $COLOR_V3_WHITE;
                background: $COLOR_V3_BLUE_NOT_SO_LIGHT;
                padding: 5px;
                margin-inline-start: 15px;
                border-radius: 8px;
                height: fit-content;
                min-width: 80px;
                max-width: 80px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    .scholarship-eligibility-notice {
        margin-top: 20px;
        margin-inline-start: 1.5em;
        font-size: 14px;

        .scholarship-eligibility-sub-heading {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            margin-bottom: 1em;
        }

        .scholarship-eligibility-description {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-size: 14px;
            margin-bottom: 1em;
        }

        @media (min-width: $screen-sm-min) {
            margin-inline-start: 2em;
        }
    }
}
