.hint-button {
    text-transform: none !important;
    height: $PREVIOUS_BUTTON_HEIGHT;
    margin-bottom: $CONTINUE_BUTTON_DESKTOP_PADDING !important;

    h2 {
        line-height: $FRONT_ROYAL_FOOTER_LINE_HEIGHT;
        margin: 0px;
    }

    // Desktop styles
    @media (min-width: $screen-sm-min) {
        &.with-message {
            @include flat-outline(2px, $COLOR_V3_WHITE);
            z-index: 10000;
            .no-touchevents &:hover,
            .no-touchevents a:hover & {
                border-color: $COLOR_V3_WHITE !important;
                box-shadow: inset 0px 0px 0px 1px $COLOR_V3_WHITE !important;
            }
        }
    }

    @media (min-width: $screen-xs-max) {
        width: $CONTINUE_BUTTON_WIDTH;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: $CONTINUE_BUTTON_DESKTOP_PADDING;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: auto;
            left: 0px;
            margin-left: $CONTINUE_BUTTON_DESKTOP_PADDING;
            margin-right: 0px;
        }
    }

    @media (max-width: $screen-sm-min) {
        width: auto;
        height: auto;
        margin: 0 auto;

        // rtl-language overrides
        html[dir="rtl"] & {
            margin: 0 auto;
        }

        h2 {
            line-height: $FRONT_ROYAL_FOOTER_LINE_HEIGHT * 0.75;
        }
    }
}
