share-stream-sidebar {
    $buttonSize: 50px;
    $lineHeight: 25px;

    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    position: relative;

    .sidebar-button:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        // rtl-language overrides
        html[dir="rtl"] & {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
    .sidebar-button:last-of-type {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        // rtl-language overrides
        html[dir="rtl"] & {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    @media (min-width: $screen-md-min) {
        width: 100%;
        margin-bottom: 25px;
    }

    @media (min-width: $screen-lg-min) {
        position: fixed;
        top: 110px; // see also: iphonex.scss
        left: 0px;
        margin: 0;
        opacity: 0;
        transform: translate(-100px, 0px);
        animation: slide-in-left-sidebar 0.5s 0.5s ease;
        animation-fill-mode: forwards;
        width: auto;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
            transform: translate(100px, 0px);
            animation: slide-in-right-sidebar 0.5s 0.5s ease;
            animation-fill-mode: forwards;

            @media screen and (-webkit-min-device-pixel-ratio: 0) {
                right: 7px;
            }
        }

        &,
        html[dir="rtl"] & {
            .sidebar-button:first-of-type {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            .sidebar-button:last-of-type {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }

        // Hide while sliding back to the right
        .route-leave & {
            display: none !important;
        }
    }

    .sidebar-button {
        $paddingNormal: ($buttonSize - $lineHeight) * 0.5;

        transition: all 0.15s ease;
        font-size: $lineHeight;
        line-height: $lineHeight;
        text-align: center;
        height: $buttonSize;
        display: inline-block;
        color: $COLOR_V3_WHITE;
        background-color: #9b9b9b;
        padding: ($buttonSize - $lineHeight) * 0.5;
        border: 0px solid transparent;
        width: 25%;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            // Match the height of the Bookmark button on the same row
            padding: (54px - $lineHeight) * 0.5;
            height: 54px;
        }

        @include user-select(none);

        &.facebook {
            background-color: $COLOR_V3_BLUE_FACEBOOK;
            &:hover {
                background-color: $COLOR_V3_BLUE_FACEBOOK_HOVER;
            }
        }
        &.twitter {
            background-color: $COLOR_V3_BLUE_TWITTER;
            &:hover {
                background-color: $COLOR_V3_BLUE_TWITTER_HOVER;
            }
        }
        &.linkedin {
            background-color: $COLOR_V3_BLUE_LINKEDIN;
            &:hover {
                background-color: $COLOR_V3_BLUE_LINKEDIN_HOVER;
            }
        }
        &.email {
            background-color: #9b9b9b;
            &:hover {
                background-color: lighten(#9b9b9b, 5%);
            }
        }

        i {
            position: relative;
        }

        &:active {
            i {
                top: 1px;
            }
        }

        @media (min-width: $screen-lg-min) {
            display: block;
            width: $buttonSize;

            &.email {
                font-size: $lineHeight - 2px;
                line-height: $lineHeight;
                i {
                    position: relative;
                    top: -1px;
                }
            }

            &:hover {
                padding-left: $paddingNormal + 5px;
                padding-right: $paddingNormal + 5px;
                width: $buttonSize + 10px;
                height: $buttonSize;
            }
        }
    }
}
