.information-container {
    .row-val {
        text-transform: capitalize;
        text-align: right;

        &.no-transform {
            text-transform: none;
        }

        > div.payment-status-info {
            display: flex;
            justify-content: flex-start;
            text-align: right;
            > p {
                width: 120px;

                &:last-of-type {
                    width: 64px;
                    margin-left: 8px;
                    text-align: left;
                }
            }
            &:last-of-type {
                > p {
                    margin-bottom: 0;
                }
            }
        }

        &.id-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            text-transform: unset;

            span {
                word-break: break-all;
            }

            button {
                margin-left: 10px;
            }
        }

        &.file-download-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            > div.transcript-select-container {
                align-items: center;
                display: flex;
                gap: 10px;

                .MuiInputBase-root {
                    min-width: 175px;
                }

                .MuiSelect-select {
                    text-align: left;
                }
            }

            > div.error-msg {
                font-size: small;
                text-transform: none;
                margin-left: 10px;
            }
        }
    }

    &.user-information {
        ul {
            margin: 0;

            li {
                list-style: none;
            }
        }
    }
}

.information-panel-row.group {
    flex-wrap: wrap;

    ul {
        margin-top: 8px;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            &:not(.payment-info) {
                border-left: 0.5px solid grey;
                padding-left: 16px;
                margin-left: 8px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}
