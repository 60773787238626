$matchingBoardFontSizeSM: 18px;
$matchingBoardFontSizeXS: 12px;
$matchingBoardButtonSelector: '.matching-board .cf_answer_list[renderer="buttons"] button.primary';

/*
	It's a little silly to use border-image for the answer buttons, since we could
	produce these styles with regular scss borders (they are not pointy, just
	rectangular).  But, since we want to line them up with the pointy buttons, and those
	require border-image, it's easier to do these as border-images as
	well to make sure they line up and look the same.

    NOTE: ALL OF THESE IMAGES ARE PRELOADED IN /image_preloader.scss. MAKE SURE TO
    UPDATE THAT FILE IF ANY OF THESE URLS CHANGE
*/
@include pointy_button(
    $matchingBoardButtonSelector,
    "cfMatchingBoardAnswerButtons",
    $matchingBoardFontSizeSM,
    // font-size desktop
    $matchingBoardFontSizeXS,
    // font-size mobile
    url("~vectors/non_pointy_button_light_turquoise_border.svg"),
    //base
    $COLOR_V3_WHITE,
    $COLOR_V3_TURQUOISE_LIGHT,
    url("~vectors/non_pointy_button_turquoise_border.svg"),
    // hover
    $COLOR_V3_WHITE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/non_pointy_button_turquoise_background.svg"),
    // active
    $COLOR_V3_TURQUOISE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/non_pointy_button_turquoise_background.svg"),
    // selected
    $COLOR_V3_TURQUOISE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/non_pointy_button_dark_turquoise_background.svg"),
    // completed
    $COLOR_V3_TURQUOISE_GRASSY_DARK,
    $COLOR_V3_TURQUOISE_GRASSY_DARK,
    url("~vectors/non_pointy_button_green_background.svg"),
    //correct
    url("~vectors/non_pointy_button_yellow_background.svg"),
    //incorrect
    "right"
);

// adding a bunch of selectors to ensure we are more specific than pointy-buttons
html.borderimage,
html:not(.borderimage) {
    .matching-board {
        cf-matching-challenge-button button {
            position: absolute;
            right: 4px;
            top: 0px;
        }

        // rtl-content overrides
        &[dir="rtl"] cf-matching-challenge-button button {
            left: 4px;
            right: auto;
        }
    }

    .matching-board {
        cf-ui-component .cf_answer_list[renderer="buttons"] button.primary {
            position: absolute;
            left: 4px;
            top: 0px;
        }

        // rtl-content overrides
        &[dir="rtl"] cf-ui-component .cf_answer_list[renderer="buttons"] button.primary {
            right: 4px;
            left: auto;
        }
    }

    $rand: 0.06387277250178158, 0.9255966928321868, 0.19386852835305035, 0.7151252704206854, 0.41273484588600695,
        0.8269555126316845, 0.9737566208932549, 0.6535373507067561, 0.29710868722759187, 0.16146287531591952,
        0.31803123163990676, 0.29175563203170896, 0.20073852082714438, 0.009134277468547225, 0.7926375831011683,
        0.78637790447101, 0.6870588504243642, 0.8208720996044576, 0.7995328775141388, 0.041092560393735766;
    @for $i from 1 through 10 {
        $wiggle: 0.3; // the actual value will be this much above or below the average
        //$rand1 and $rand2 are numbers between -0.5 and 0.5
        $rand1: nth($rand, (2 * $i) - 1) - 0.5;
        $rand2: nth($rand, (2 * $i)) - 0.5;
        .matching-board cf-matching-challenge-button:nth-of-type(#{$i}) button {
            $duration: 0.7 + (2 * $wiggle * $rand1) + s;
            transition: transform $duration $EASE_OUT_BOUNCE;
        }

        .matching-board cf-ui-component .cf_answer_list[renderer="buttons"] button:nth-of-type(#{$i}) {
            $duration: 0.4 + (2 * $wiggle * $rand2) + s;
            transition: transform $duration $EASE_OUT_BOUNCE;
        }
    }
}

cf-matching-board {
    display: block; // needed because we set the height in the js in order to ensure scrolling works

    .matching-board {
        // the buttons in both columns need to stretch.
        cf-matching-challenge-button button,
        .cf_answer_list .wide_buttons button.primary, // make sure we have enough specificity
        .cf_answer_list .single_column button.primary, // make sure we have enough specificity
        .cf_answer_list button.primary {
            .frame-container.lte-xs & {
                width: 90%; //90%? that's what it used to be
            }

            .frame-container.gte-sm & {
                width: 80%;
            }
        }

        // pointy button styling has padding 9px 21px 9px 9px; we don't need the extra right padding
        // for answer buttons, so counteract it with a style for the contained elements
        cf-answer-list-buttons {
            button,
            button.primary {
                html.borderimage & {
                    .button_image,
                    .button_text {
                        width: calc(100% + 12px);
                        transform: translateZ(0); // hack that fixes a rendering bug in Safari 9.0+
                    }
                }
            }
        }
    }
}
