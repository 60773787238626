[ng-view] {
    @mixin slide($leftOrRight) {
        $dir: -1;
        @if $leftOrRight == "left" {
            $dir: 1;
        }

        &.ng-enter,
        &.route-leave {
            $transitionOpts: 0.5s ease 0s;
            transition: transform $transitionOpts, opacity $transitionOpts;
        }

        // start position when putting element on screen
        &.ng-enter {
            opacity: 0;
            transform: translateX($dir * 300px);
        }

        // end position when removing element from screen
        &.route-leave {
            opacity: 0;
            transform: translateX(-$dir * 300px);
        }

        // stable position when element is on the screen
        &.ng-enter.ng-enter-active {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    &.slide-left {
        @include slide("left");

        // rtl-language overrides
        html[dir="rtl"] & {
            @include slide("right");
        }
    }

    &.slide-right {
        @include slide("right");

        // rtl-language overrides
        html[dir="rtl"] & {
            @include slide("left");
        }
    }
}
