// We add the '.nav-pills > li > a.app-header-pill' to say something
// like, "Even if you are inside of a bootstrappy nav-pill thing, these
// styles are still more important"
.app-header-pill,
.nav-pills > li > a.app-header-pill {
    color: $COLOR_V3_WHITE;
    font-weight: $FONT_WEIGHT_NORMAL;
    font-size: 14px;
    line-height: 20px;
    border-radius: 100px;
    transition: background-color 0.25s;
    cursor: pointer;

    @media (min-width: $screen-md-min) {
        font-size: 20px;
    }

    &:not(.disabled) {
        &:focus {
            background-color: transparent;
        }
        &:hover:not(.disabled),
        &.popover-open {
            background-color: rgba($COLOR_V3_CORAL_DARK, 0.25);
        }
        &:active {
            background-color: rgba($COLOR_V3_CORAL_DARK, 0.65);
        }
    }

    .nav-pills-blue &:not(.disabled) {
        &:hover,
        &.popover-open {
            background-color: rgba($COLOR_V3_BLUE_DARK, 0.25);
        }
        &:active {
            background-color: rgba($COLOR_V3_BLUE_DARK, 0.65);
        }

        &.active,
        &.active:hover,
        &.active:focus {
            background-color: rgba($COLOR_V3_BLUE_DARK, 0.65);
        }
    }

    // In some cases, links are hard-coded in the html to be disabled
    // Links can also be diabled in offline mode
    &.disabled {
        opacity: 0.5;
        cursor: default;

        // I'm not sure where this background-color is coming from.
        // Maybe bootstrap
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }
    }

    &.active,
    &.active:hover,
    &.active:focus {
        background-color: rgba($COLOR_V3_CORAL_DARK, 0.65);
    }
}

// Prevent overlapping menu items with user menu.
// Don't select user menu here, which is div.app-header-pill
.nav-pills > li > a.app-header-pill {
    @media (min-width: $screen-md-min) and (max-width: 1100px) {
        padding: 6px 8px;
        margin-top: 5px;

        + .connection-number {
            right: -6px;
            top: -1px;
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding: 6px 8px;
        margin-top: 5px;

        + .connection-number {
            right: -6px;
            top: 0px;
        }
    }
}
