$tilePromptBoardFontSizeSM: 18px;
$tilePromptBoardFontSizeXS: 14px;

/*
    NOTE: ALL OF THESE IMAGES ARE PRELOADED IN /image_preloader.scss. MAKE SURE TO
    UPDATE THAT FILE IF ANY OF THESE URLS CHANGE
*/
@include pointy_button(
    '.tile-prompt-board .cf_answer_list[renderer="buttons"] button.primary',
    "tilePromptBoardRightButton",
    $tilePromptBoardFontSizeSM,
    // font-size desktop
    $tilePromptBoardFontSizeXS,
    // font-size mobile
    url("~vectors/pointy_button_purple_background.svg"),
    //base
    $COLOR_V3_PURPLE,
    $COLOR_V3_PURPLE,
    url("~vectors/pointy_button_purple_background_dark_purple_border.svg"),
    // hover
    $COLOR_V3_PURPLE,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_dark_purple_background.svg"),
    // active
    $COLOR_V3_PURPLE_DARK,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_dark_purple_background.svg"),
    // selected
    $COLOR_V3_PURPLE_DARK,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_green_background.svg"),
    // completed = correct
    $COLOR_ANSWER_CORRECT,
    $COLOR_ANSWER_CORRECT,
    url("~vectors/pointy_button_green_background.svg"),
    //correct
    url("~vectors/pointy_button_yellow_background.svg"),
    //incorrect
    "right"
);

/*
    NOTE: ALL OF THESE IMAGES ARE PRELOADED IN /image_preloader.scss. MAKE SURE TO
    UPDATE THAT FILE IF ANY OF THESE URLS CHANGE
*/
@include pointy_button(
    '.tile-prompt-board .cf_answer_list[renderer="buttons"] button.primary:first-child',
    "tilePromptBoardLeftButton",
    $tilePromptBoardFontSizeSM,
    // font-size desktop
    $tilePromptBoardFontSizeXS,
    // font-size mobile
    url("~vectors/pointy_button_purple_background_left.svg"),
    //base
    $COLOR_V3_PURPLE,
    $COLOR_V3_PURPLE,
    url("~vectors/pointy_button_purple_background_dark_purple_border_left.svg"),
    // hover
    $COLOR_V3_PURPLE,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_dark_purple_background_left.svg"),
    // active
    $COLOR_V3_PURPLE_DARK,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_dark_purple_background_left.svg"),
    // selected
    $COLOR_V3_PURPLE_DARK,
    $COLOR_V3_PURPLE_DARK,
    url("~vectors/pointy_button_green_background_left.svg"),
    // completed = correct
    $COLOR_ANSWER_CORRECT,
    $COLOR_ANSWER_CORRECT,
    url("~vectors/pointy_button_green_background_left.svg"),
    //correct
    url("~vectors/pointy_button_yellow_background_left.svg"),
    //incorrect
    "left"
);

.tile-prompt-board {
    .button {
        color: $COLOR_V3_WHITE;

        &.primary {
            text-align: center;
            margin-left: 0px;
            margin-right: 0px;
            width: 50%;

            // Since IE doesn't have border-image, add back the margin
            .no-borderimage & {
                width: calc(50% - 5px);
                &:first-of-type {
                    margin-right: 5px;
                }
                &:last-of-type {
                    margin-left: 5px;
                }
            }
        }
    }

    // in RTL languages, we still want the arrows to display left to right
    .cf_answer_list {
        direction: ltr;
    }

    // only show disabled styling when all challenges are completed. otherwise, show with full opacity.
    .cf_answer_list button.primary:disabled {
        opacity: 1;
    }
    &.completed .cf_answer_list button.primary:disabled {
        opacity: 0.5;
    }

    /* make room for the cards to rotate without overlapping text above */
    margin-top: 30px;

    .tile-container {
        width: $TILE_PROMPT_WIDTH;
        min-height: $MAIN_IMAGE_HEIGHT;
        margin: 0 auto;
        position: relative;
        margin-bottom: 30px;

        .frame-container.lte-xs & {
            width: $TILE_PROMPT_WIDTH_SMALL_MIN;
            min-height: $MAIN_IMAGE_SHORT_HEIGHT;
        }
    }

    .tile,
    .fake-tile {
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        transition: all 0.2s linear;
        user-select: none;
        z-index: 0;
        min-height: $MAIN_IMAGE_HEIGHT;
        height: auto;

        .frame-container.lte-xs & {
            min-height: $MAIN_IMAGE_SHORT_HEIGHT;
        }

        .cf-tile-prompt {
            .frame-container.lte-xs & {
                min-height: $MAIN_IMAGE_SHORT_HEIGHT;
            }
            min-height: $MAIN_IMAGE_HEIGHT;
            height: auto;
        }

        .cf-tile-prompt > .answer-text-left,
        .cf-tile-prompt > .answer-text-right {
            opacity: 0;
        }

        &.selected {
            z-index: 2;

            .cf-tile-prompt {
                background-color: $COLOR_V3_WHITE;
            }
        }

        &.next {
            transform: rotate(-5deg) translate3d(-50%, -10%, 0);
            z-index: 1;
            .cf-tile-prompt {
                background-color: hsl(0, 0%, 92%);
            }
        }

        &.last {
            transform: rotate(6deg) translate3d(-50%, 10%, 0);
            .cf-tile-prompt {
                background-color: hsl(0, 0%, 84%);
            }
        }

        //----------------------------
        // Tile animation styling
        //----------------------------

        &.animate-right-partial {
            transform: translate3d(-30%, 0, 0) rotate(7deg);
        }
        &.animate-left-partial {
            transform: translate3d(-70%, 0, 0) rotate(-7deg);
        }

        &.animate-full {
            transition: transform 0.5s linear, opacity 0.5s ease-out 0.25s;
        }

        &.animate-right-full {
            transform: translate3d(100%, 15%, 0) rotate(60deg);
            opacity: 0;
        }
        &.animate-left-full {
            transform: translate3d(-200%, 15%, 0) rotate(-60deg);
            opacity: 0;
        }

        &.animate-left-partial,
        &.animate-left-full {
            .cf-tile-prompt > .answer-text-left {
                opacity: 0.75;
            }
        }

        &.animate-right-partial,
        &.animate-right-full {
            .cf-tile-prompt > .answer-text-right {
                opacity: 0.75;
            }
        }
    }
}
