.cf_answer_list {
    &[renderer="image_hotspot"] {
        /* see fillable-rect-on-image for all the fancy stuff */
        button.hotspot.fillable-rect-on-image {
            background: transparent;
            font-size: 14px;
            padding: 0;

            &:before {
                // Position the question mark outside of the box on the right side, vertically centered
                @include icon-at-point(16px, 16px, 50%, 100%);
                left: auto;
                right: -16px;
            }
            &.selected,
            &.completed {
                &:after {
                    // Position the checkmark outside of the box on the right side, vertically centered
                    top: 50%;
                    left: auto;
                    right: -16px;
                }
            }

            &.selected:not(.incorrect):not(.correct) {
                box-shadow: 0px 0px 10px 0px rgba($COLOR_ANSWER_NEUTRAL, 0.85),
                    5px 5px 10px 0px rgba($COLOR_ANSWER_NEUTRAL, 0.3),
                    inset 0px 0px 0px 3px rgba($COLOR_ANSWER_NEUTRAL, 0.85);
                border-color: $COLOR_ANSWER_NEUTRAL;

                // selected icon centered to the right of the blank
                &:after {
                    @include font-awesome-icon("\f058"); // fa-check-circle
                    @include icon-at-point(
                        $FILLABLE_RECT_SMALL_ICON_DIAMETER,
                        $FILLABLE_RECT_SMALL_ICON_DIAMETER,
                        50%,
                        100%
                    );
                    left: auto;
                    right: -#{$FILLABLE_RECT_SMALL_ICON_DIAMETER};
                    color: $COLOR_V3_WHITE;
                    text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_ANSWER_NEUTRAL,
                        0 0 5px $COLOR_ANSWER_NEUTRAL;
                    text-decoration: none;
                }
            }

            &.has_background {
                background-color: $COLOR_V3_WHITE;
            }

            &.no_image {
                border-radius: 5px;
                box-shadow: 0px 0px 10px 0px rgba($COLOR_V3_BLACK, 0.7), 5px 5px 10px 0px rgba($COLOR_V3_CORAL, 0.3);

                &:not(.selected) {
                    border-color: rgba($COLOR_V3_WHITE, 0.7);
                }

                &.incorrect {
                    box-shadow: 0px 0px 10px 0px rgba(darken($COLOR_ANSWER_INCORRECT, 30%), 0.7),
                        5px 5px 10px 0px rgba($COLOR_ANSWER_INCORRECT, 0.3);
                }

                &.correct {
                    box-shadow: 0px 0px 10px 0px rgba(darken($COLOR_ANSWER_CORRECT, 30%), 0.7),
                        5px 5px 10px 0px rgba($COLOR_ANSWER_CORRECT, 0.3);
                }

                &.selected:not(.incorrect):not(.correct) {
                    box-shadow: 0px 0px 10px 0px rgba(darken($COLOR_ANSWER_NEUTRAL, 30%), 0.7),
                        5px 5px 10px 0px rgba($COLOR_ANSWER_NEUTRAL, 0.3);
                }

                // image_hotspot text-alignment defaults to center if no alignment is set, unlike other
                // text components which default to left
                cf-ui-component > cf-text {
                    display: block;

                    &:not(.left):not(.center):not(.right) {
                        text-align: center;
                    }
                }
            }
        }

        button.hotspot.fillable-rect-on-image.editable {
            &:before {
                content: attr(data-answer-index);
                white-space: nowrap;
            }

            &.correct:after {
                content: attr(data-answer-index);
            }

            .message-preview {
                position: absolute;
                top: -4px;
                left: -#{$FILLABLE_RECT_SMALL_ICON_DIAMETER * 1.5};
                z-index: 100;
                .lesson_popover {
                    color: $COLOR_V3_WHITE;
                    text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_V3_CORAL, 0 0 5px $COLOR_V3_CORAL;
                    font-size: 13px;
                    line-height: 1em;
                    padding: 1px 4px;
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }
}
