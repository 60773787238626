edit-account {
    .copyable {
        user-select: text;

        i.fa-copy {
            cursor: pointer;
            margin-left: 6px;

            html[dir="rtl"] & {
                margin-left: 0px;
                margin-right: 6px;
            }

            &:hover {
                color: $COLOR_V3_BLUE_HOVER;
            }
        }
    }

    .delete-account {
        &,
        h2 {
            color: $COLOR_V3_GREY;
        }

        h2 {
            margin: 20px 0px;
        }

        a {
            color: $COLOR_V3_BLACK;
        }
    }

    .form-group.row.student-id,
    .form-group.row.proctoring-section {
        align-items: baseline;
        color: $COLOR_V3_BLACK;
        display: flex;
        flex-wrap: wrap;

        p,
        a,
        front-royal-spinner {
            @media (min-width: $screen-sm-min) {
                margin: 0 0 0 auto;

                html[dir="rtl"] & {
                    margin: 0 auto 0 0;
                }
            }
        }

        front-royal-spinner {
            margin-top: 0;
        }
    }

    .form-group.row.proctoring-section i {
        margin-left: 6px;

        html[dir="rtl"] & {
            margin-left: 0px;
            margin-right: 6px;
        }
    }

    form[name="studentEmailForm"] .form-group.row {
        color: $COLOR_V3_BLACK;

        .section-desc,
        &.student-email p {
            margin-bottom: 0;
        }
    }
}
