cf-component-overlay {
    display: block; // required for dynamic scaling, which uses transform, to work

    // keep the top where it is and scale up and in
    // from the sides (this makes sense because we're
    // changing the actual height of the element, as well as scaling it).
    // see the following code around line 180 of component_overlay_dir:
    // `elem.height(scale * scope.originalHeight);`
    transform-origin: 50% 0%;
    max-width: 100%;

    // for the positionalbles to work in RTL
    direction: ltr;
}

.cf-component-overlay {
    /* the following 3 styles combine with the
       content_wrapper below
       to accomplish 2 goals:

       1. have a wrapper around both the main component
          and the overlaid components that is the same
          width and height as the main component.  This
          allows us to position and size the overlaid
          components relative to that wrapper, but have it
          look like they are positioned and sized relative to
          the main component.
        2. center all of it in the middle of the screen
    */
    left: 50%; /* push the parent element over so the left edge is centered */
    display: inline-block; /* make the parent element stretch to fit whatever is inside of it */
    position: relative;

    > .content_wrapper {
        display: inline-block; /* stretch to fit the main component size */
        left: -50%; /* shift left 50% the size of the main component, so that it ends up centered */
        position: relative;

        /* we need the content wrapper to have some size so that
          when overlays are added, the percentage resize can work */
        min-width: 10px;
        min-height: 10px;

        // Prevent the background image from exceeding the height of the interactiveImage area
        .cf-image {
            // DO NOT CHANGE THIS MAX HEIGHT.  DO NOT ADD A MEDIA QUERY FOR MOBILE.
            // If this is anything other than what it was when the content author
            // was editing this component in the editor, then
            // it will mess up the scaling.
            // If we decide we want it smaller on mobile, we can scale it more
            // in the js maybe
            max-height: $MAIN_IMAGE_HEIGHT;
        }
    }

    /*
        Let the main component stretch to the maximum allowed by the content
        area, regardless of the size of the screen.  So, even if we're on a little
        mobile device and the content area is not that wide, we still allow the image to
        go to 700.  But then we will scale it down in javascript using css transforms, making
        sure that text scales down along with it.
    */
    .main_image {
        img {
            max-width: $MAIN_CONTENT_MIN_WIDTH;
        }
    }

    /*
        Make sure that the wrappers for each overlaid
        component all stretch to fill the size of the
        main component, so resizing can be done relative
        to that.
    */
    .overlay_component_wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    /*
        Make sure that the contents of each positionable
        element stretches to fill
    */
    .overlay_component_wrapper > [positionable].fixed-size {
        > cf-ui-component,
        > cf-ui-component > *,
        > cf-ui-component > * > * {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    /*
    move the wrapper to the top left corner, the
    [positionable] element will move around inside of that
    */
    .overlay_component_wrapper {
        top: 0px;
        left: 0px;

        > [positionable] > cf-ui-component {
            transform: translateZ(0);
        }
    }
}
