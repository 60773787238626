.change-cohort-form {
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
