form[name="basic_info"] {
    input[name="last_name"] {
        border-left: none !important;
    }

    .scholarship-radio-label {
        margin-bottom: 0px;
        span {
            vertical-align: top;
            display: inline-block;
        }
    }

    .birthdate-preview {
        display: inline-block;
        padding-top: 5px;
        margin-left: -5px;
        margin-bottom: 0px;
    }
}
