// since the mathjax elements that can be contained within a lesson_popover have
//   white-space: nowrap!important;
// set by mathjax css, and the lesson_popover container element has padding,
// on mobile you can get weird wrapping behavior where it looks like there's
// leading whitespace wrapping a line, when really its just the lesson_popover
// padding wrapping while its contents do not wrap
// making the container nowrap as well is a hack but fixes this.
// SO, modal_popover_dir checks for this on link and optionally adds this class
// to make the parent container nowrap in this case as well.
.nowrap {
    white-space: nowrap;
}

a.modal-popup {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
