$ONBOARDING_IMAGES: (
    "Back-Icon": (
        url1x: url("~images/onboarding/Back-Icon.png"),
        url2x: url("~images/onboarding/Back-Icon@2x.png")
    ),
    "GooglePlus-Logo": (
        url1x: url("~images/onboarding/GooglePlus-Logo.png"),
        url2x: url("~images/onboarding/GooglePlus-Logo@2x.png")
    ),
    "Facebook-Logo": (
        url1x: url("~images/onboarding/Facebook-Logo.png"),
        url2x: url("~images/onboarding/Facebook-Logo@2x.png")
    ),
    "logo-facebook": (
        width: 20px,
        height: 21px,
        url1x: url("~images/onboarding/logo-facebook.png"),
        url2x: url("~images/onboarding/logo-facebook@2x.png")
    ),
    "logo-googleplus": (
        width: 25px,
        height: 18px,
        url1x: url("~images/onboarding/logo-googleplus.png"),
        url2x: url("~images/onboarding/logo-googleplus@2x.png")
    ),
    "Message-Sender": (
        url1x: url("~images/onboarding/Message-Sender.png"),
        url2x: url("~images/onboarding/Message-Sender@2x.png")
    ),
    "Message-Sender-Quantic": (
        url1x: url("~images/onboarding/Message-Sender_quantic.png"),
        url2x: url("~images/onboarding/Message-Sender@2x_quantic.png")
    ),
    "Message-Sender-MiyaMiya": (
        url1x: url("~images/onboarding/Message-Sender_miyamiya.png"),
        url2x: url("~images/onboarding/Message-Sender@2x_miyamiya.png")
    ),
    "Message-Sender-Valar": (
        url1x: url("~images/onboarding/Message-Sender_valar.png"),
        url2x: url("~images/onboarding/Message-Sender@2x_valar.png")
    ),
    "smartly-logo": (
        width: 31px,
        height: 34px,
        url1x: url("~images/onboarding/smartly-logo.png"),
        url2x: url("~images/onboarding/smartly-logo@2x.png")
    ),
    "quantic-logo": (
        width: 31px,
        height: 34px,
        url1x: url("~vectors/logos/quantic-logo-white.svg"),
        url2x: url("~vectors/logos/quantic-logo-white.svg")
    ),
    "miyamiya-logo": (
        width: 145px,
        height: 34px,
        // This image is big enough to be both the 1x and 2x size
        url1x: url("~images/miyamiya/miyamiya-logo-white.png"),
        url2x: url("~images/miyamiya/miyamiya-logo-white.png")
    ),
    "Trail-0": (
        url1x: url("~images/onboarding/Trail-0.png"),
        url2x: url("~images/onboarding/Trail-0@2x.png")
    ),
    "Trail-1": (
        url1x: url("~images/onboarding/Trail-1.png"),
        url2x: url("~images/onboarding/Trail-1@2x.png")
    ),
    "Trail-2": (
        url1x: url("~images/onboarding/Trail-2.png"),
        url2x: url("~images/onboarding/Trail-2@2x.png")
    ),
    "Trail-4": (
        url1x: url("~images/onboarding/Trail-4.png"),
        url2x: url("~images/onboarding/Trail-4@2x.png")
    ),
    "Trail-5": (
        url1x: url("~images/onboarding/Trail-5.png"),
        url2x: url("~images/onboarding/Trail-5@2x.png")
    )
);

$ONBOARDING_IMAGES_ANIMATED: (
    "brown-circle": (
        width: 172px,
        height: 171px,
        url1x: url("~images/onboarding/brown-circle.png"),
        url2x: url("~images/onboarding/brown-circle@2x.png")
    ),
    "clouds-left": (
        width: 336px,
        height: 55px,
        url1x: url("~images/onboarding/clouds-left.png"),
        url2x: url("~images/onboarding/clouds-left@2x.png")
    ),
    "clouds-right": (
        width: 368px,
        height: 222px,
        url1x: url("~images/onboarding/clouds-right.png"),
        url2x: url("~images/onboarding/clouds-right@2x.png")
    ),
    "dollar-pattern-1": (
        width: 292px,
        height: 302px,
        url1x: url("~images/onboarding/dollar-pattern-1.png"),
        url2x: url("~images/onboarding/dollar-pattern-1@2x.png")
    ),
    "dollar-pattern-2": (
        width: 712px,
        height: 220px,
        url1x: url("~images/onboarding/dollar-pattern-2.png"),
        url2x: url("~images/onboarding/dollar-pattern-2@2x.png")
    ),
    "dollar-pattern-3": (
        width: 1131px,
        height: 220px,
        url1x: url("~images/onboarding/dollar-pattern-3.png"),
        url2x: url("~images/onboarding/dollar-pattern-3@2x.png")
    ),
    "illustration-application": (
        width: 190px,
        height: 176px,
        url1x: url("~images/onboarding/illustration-application.png"),
        url2x: url("~images/onboarding/illustration-application@2x.png")
    ),
    "illustration-megaphone": (
        width: 270px,
        height: 184px,
        url1x: url("~images/onboarding/illustration-megaphone.png"),
        url2x: url("~images/onboarding/illustration-megaphone@2x.png")
    ),
    "illustration-mountain": (
        width: 326px,
        height: 250px,
        url1x: url("~images/onboarding/illustration-mountain.png"),
        url2x: url("~images/onboarding/illustration-mountain@2x.png")
    ),
    "illustration-rocket": (
        width: 210px,
        height: 216px,
        url1x: url("~images/onboarding/illustration-rocket.png"),
        url2x: url("~images/onboarding/illustration-rocket@2x.png")
    ),
    "illustration-table": (
        width: 735px,
        height: 272px,
        url1x: url("~images/onboarding/illustration-table.png"),
        url2x: url("~images/onboarding/illustration-table@2x.png")
    ),
    "progress-circle-active": (
        width: 12px,
        height: 12px,
        url1x: url("~images/onboarding/progress-circle-active.png"),
        url2x: url("~images/onboarding/progress-circle-active@2x.png")
    ),
    "progress-circle": (
        width: 12px,
        height: 12px,
        url1x: url("~images/onboarding/progress-circle.png"),
        url2x: url("~images/onboarding/progress-circle@2x.png")
    ),
    "progress-connection-active": (
        width: 20px,
        height: 6px,
        url1x: url("~images/onboarding/progress-connection-active.png"),
        url2x: url("~images/onboarding/progress-connection-active@2x.png")
    ),
    "progress-connection": (
        width: 20px,
        height: 6px,
        url1x: url("~images/onboarding/progress-connection.png"),
        url2x: url("~images/onboarding/progress-connection@2x.png")
    ),
    "puzzle-1": (
        width: 355px,
        height: 496px,
        url1x: url("~images/onboarding/puzzle-1.png"),
        url2x: url("~images/onboarding/puzzle-1@2x.png")
    ),
    "puzzle-2": (
        width: 773px,
        height: 480px,
        url1x: url("~images/onboarding/puzzle-2.png"),
        url2x: url("~images/onboarding/puzzle-2@2x.png")
    ),
    "puzzle-3": (
        width: 1204px,
        height: 492px,
        url1x: url("~images/onboarding/puzzle-3.png"),
        url2x: url("~images/onboarding/puzzle-3@2x.png")
    ),
    "puzzle-4": (
        width: 1512px,
        height: 325px,
        url1x: url("~images/onboarding/puzzle-4.png"),
        url2x: url("~images/onboarding/puzzle-4@2x.png")
    ),
    "rocket-pattern-1": (
        width: 312px,
        height: 232px,
        url1x: url("~images/onboarding/rocket-pattern-1.png"),
        url2x: url("~images/onboarding/rocket-pattern-1@2x.png")
    ),
    "rocket-pattern-2": (
        width: 778px,
        height: 232px,
        url1x: url("~images/onboarding/rocket-pattern-2.png"),
        url2x: url("~images/onboarding/rocket-pattern-2@2x.png")
    ),
    "rocket-pattern-3": (
        width: 1244px,
        height: 232px,
        url1x: url("~images/onboarding/rocket-pattern-3.png"),
        url2x: url("~images/onboarding/rocket-pattern-3@2x.png")
    ),
    "snow": (
        width: 306px,
        height: 49px,
        url1x: url("~images/onboarding/snow.png"),
        url2x: url("~images/onboarding/snow@2x.png")
    )
);

@mixin background-image-onboarding($imageMap) {
    background-image: map-get($imageMap, url1x);

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        & {
            background-image: map-get($imageMap, url2x);
        }
    }
}
