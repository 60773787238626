app-menu-user {
    width: auto;

    .app-menu-user {
        $extraPadding: 5px;
        $iconSize: 37px;
        $rightPadding: $extraPadding + $iconSize + 8px;
        $leftPadding: $extraPadding + 10px;
        $mobileMaxWidth: 100px;
        $maxWidth: 200px;
        $height: $iconSize + 2 * $extraPadding;

        position: relative;
        width: $iconSize + 2 * $extraPadding;
        height: $height;
        text-align: right;

        &:hover {
            cursor: pointer;

            app-menu-user-avatar .caret-container:before {
                color: $COLOR_V3_CORAL;
            }

            app-menu-user-avatar.valar .caret-container:before {
                color: $COLOR_V3_BLUE;
            }
        }

        &:active,
        &.popover-open {
            app-menu-user-avatar .caret-container:before {
                color: $COLOR_V3_CORAL_DARK;
            }

            app-menu-user-avatar.valar .caret-container:before {
                color: $COLOR_V3_BLUE_DARK;
            }
        }

        &:active:not(.offline-mode) {
            // show the avatar overlay on mousedown
            app-menu-user-avatar {
                &:before {
                    opacity: 0.25;
                }
            }
        }

        &.offline-mode {
            cursor: default;

            app-menu-user-avatar .caret-container:before {
                color: black !important; // override hover/active effects
            }
        }

        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: left;
        }

        app-menu-user-avatar {
            position: absolute;
            right: $extraPadding;
            top: $extraPadding;
            height: $iconSize;
            width: $iconSize;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px rgba($COLOR_V3_CORAL_DARK, 0.4);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            // avatar tint overlay
            &:before {
                content: "";
                position: absolute;
                border-radius: 50%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $COLOR_V3_CORAL_DARK;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                left: $extraPadding;
                right: auto;
            }

            &.valar {
                box-shadow: inset 0 0 0 1px rgba($COLOR_V3_WHITE, 0.4);

                &:before {
                    background-color: $COLOR_V3_BLUE_DARK;
                }
            }

            .caret-container {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 13px;
                height: 13px;
                background-color: white;
                right: 0;
                top: 30px;
                border-radius: 50%;

                &:before {
                    @include font-awesome-icon("\f0d7"); // fa-caret-down
                    transition: color 0.2s ease-in-out;
                    font-size: 11px;
                    color: black;
                }
            }
        }

        span {
            position: relative;
            display: inline-block;
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 15px;
            line-height: 1.1em;
            max-width: $mobileMaxWidth;
            width: auto;
            color: $COLOR_V3_WHITE;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: $screen-lg-min) {
                max-width: $maxWidth;
            }
        }
    }

    .popover {
        font-family: $FONT_FAMILY;

        // expand the click catcher a little bit
        &:before {
            top: -20px;
            height: 20px;
            background-color: transparent;
        }

        .arrow {
            right: 12px !important;
            border-bottom-color: white !important;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 12px !important;
                right: auto !important;
            }
        }

        // override the "pointer" cursor being applied to the popover on hover
        &:before,
        .arrow {
            cursor: default;
        }
    }

    .menu-user-popover.popover {
        width: 249px;
        padding: 0;

        p,
        h3,
        a {
            color: $COLOR_V3_GREY_DARKER;
        }

        .popover-content {
            padding: 0;
        }

        nav {
            font-weight: 500;
            display: grid;
            grid-template-columns: 1fr 1fr;

            max-height: calc(100vh - 80px);
            overflow-y: auto;

            p:hover {
                color: initial;
            }

            // These elements span both columns
            h3,
            a,
            .apply-to-program {
                grid-column: 1 / span 2;
                margin: 0;
                padding: 12px 15px;
                color: $COLOR_V3_GREY_DARKER;
            }

            a.settings-link {
                background-image: url("~vectors/arrow_right_black_thin.svg");
                background-size: 6px 12px;
                background-position: right 20px top 50%;
                background-repeat: no-repeat;

                // rtl-language overrides
                html[dir="rtl"] & {
                    background-image: url("~vectors/arrow_left_black_thin.svg");
                    background-position: left 20px top 50%;
                }
            }

            program-switcher {
                grid-column: 1 / span 2;
                margin: 0;
            }

            .apply-to-program {
                display: flex;
                align-items: center;
                position: relative;

                p {
                    line-height: 16px;
                    position: absolute;
                    left: 52px;

                    html[dir="rtl"] & {
                        left: 0;
                        right: 52px;
                    }
                }

                &:before {
                    font-size: 21px;
                    line-height: 21px;
                    @include font-awesome-icon("\f56f", $FONT_AWESOME_LIGHT); // fa-file-import
                }
            }

            h3,
            a.settings-link:last-of-type,
            program-switcher .program-section {
                border-bottom: 1px solid $COLOR_V3_BEIGE_MEDIUM;
            }

            h3 {
                cursor: default;
                font-size: 16px;
                padding-bottom: 10px;
                padding-top: 25px;
                white-space: normal;

                &:first-of-type {
                    padding-top: 15px;
                }
            }

            $border: 1px solid $COLOR_V3_BEIGE_MEDIUM;

            // These elements span one column
            .feedback,
            .sign-out {
                grid-column: span 1;
                padding: 13px;
                text-align: center;
                font-size: 13px;
                border-top: $border;
            }

            .feedback {
                border-bottom-left-radius: 6px;
            }

            .sign-out {
                border-bottom-right-radius: 6px;
            }

            .feedback {
                border-right: $border;

                html[dir="rtl"] & {
                    border-right: none;
                    border-left: $border;
                }
            }

            a {
                font-size: 15px;
                line-height: 12px;
                margin: 0;
                border: 0; // override popover link styling
            }

            a,
            .feedback,
            .sign-out,
            .apply-to-program,
            program-switcher .program-section:not(.selected) {
                &:hover {
                    background-color: #fcfbfb;
                    transition: 0.1s ease-in-out background-color;
                }
            }
        }
    }
}
