a-map {
    display: block;

    #amap-container {
        height: 100%;
    }

    // add the same style with /styles/oreo/student_network/network_map.scss line 51 to avoid the amap display issue
    .amap-layers canvas {
        max-width: unset;
    }
}
