student-network-event-details {
    $details-content-font-size: 16px;
    $description-text-minimized-height: 22px;

    color: $COLOR_V3_GREY_DARKER;
    font-size: $details-content-font-size;
    position: relative;
    display: block;

    .action {
        cursor: pointer;
    }

    header {
        position: relative;
        max-height: 277px;
        overflow: hidden;

        img {
            width: 100%;
        }

        #event-details-image-placeholder {
            background-color: $COLOR_V3_MAP_BLUE_MID;
            padding-top: 75%;
            width: 100%;
        }

        .header-group-container {
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 55%;
            width: 100%;
            background: linear-gradient(to bottom, transparent, black);

            hgroup {
                position: absolute;
                bottom: 0px;
                left: 20px;
                right: 20px;

                h3 {
                    color: #cccccc;
                    font-size: $details-content-font-size;
                    font-weight: $FONT_WEIGHT_MEDIUM;
                    margin-bottom: 0px;
                }

                h1 {
                    color: $COLOR_V3_WHITE;
                    font-size: 24px;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    margin: 5px 0px 20px;
                }

                h1,
                h3 {
                    text-shadow: black 0px 2px 4px;
                }
            }
        }
    }

    section.details {
        // Setting `overflow: auto` here in preparation for making the event details section scrollable
        // when the content overflows past the RSVP button at the bottom. However, setting `overflow: auto`
        // isn't enough on its own to make the content scrollable; you also need to set a `height` or `max-height`
        // on the element, otherwise the content has no height limit and will occupy as much space as needed,
        // which can lead to its content overflowing into other elements. The tricky part here is that the
        // desired height of the event details section is dynamic because it can change as the window is
        // resized because the height of the event image above the event details section shrinks/expands
        // as the window is resized. As such, we've delegated the responsibility of calculating the desired
        // `max-height` value for the event details section to the directive application logic.
        overflow: auto;
        padding: 20px 20px 0px;
        margin-bottom: 61px;

        &.anonymized {
            margin-bottom: 0;
        }

        section {
            margin-bottom: 20px;

            &:first-of-type {
                margin-top: 0px;
            }

            &:last-of-type {
                margin-bottom: 30px;
            }

            h4 {
                font-size: $details-content-font-size;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                margin: 0px 0px 2px;
            }

            .line-item {
                margin-bottom: 0px;

                &.action {
                    color: $COLOR_V3_CORAL;
                }
            }

            &.description {
                .description-text > p:last-of-type {
                    margin-bottom: 0px;
                }
            }

            &.location {
                address {
                    margin-bottom: 15px;

                    .adr_address {
                        span:last-of-type {
                            // We want the `.country-name` `<span>` element to be on a separate line than
                            // the rest of the address, but ONLY if it's the last `<span>` element in the
                            // address - there were cases where the `.country-name` `<span>` element was
                            // placed before the `.postal-code` `<span>` element, but it was only seen for
                            // locations inside of city-states (e.g. Singapore), so the `.country-name` was
                            // effectively being used as the "city" in the address.
                            &.country-name {
                                display: block;
                            }
                        }
                    }
                }

                ng-map,
                a-map,
                .event-detail-map-placeholder {
                    height: 120px;

                    & > div {
                        border-radius: 10px;
                    }
                }
            }

            &.event-type {
                line-height: 24px;

                .type {
                    margin-left: 8px;
                    vertical-align: middle;
                }
            }
        }
    }

    footer {
        position: absolute;
        display: block;
        width: 100%;
        top: 100%;
        left: 0px;
        padding: 0px 20px 20px;
        background-color: $COLOR_V3_WHITE;

        .rsvp-button-container {
            position: relative;

            button {
                &:before {
                    position: absolute;
                    top: -20px;
                    left: 0px;
                    height: 20px;
                    width: 100%;
                    content: "";
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $COLOR_V3_WHITE);
                    pointer-events: none;
                }

                &.rsvp-not-required {
                    background-color: $COLOR_V3_MAP_BLUE_MID;
                    opacity: 1;
                    cursor: pointer;
                }

                i {
                    margin-left: 7px;
                }
            }
        }
    }
}
