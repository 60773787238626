// special mixins based on Bootstrap to redefine for RTL
@use "sass:math";

@mixin make-xs-column-offset-rtl($columns) {
    margin-left: inherit;
    margin-right: percentage(math.div($columns, $grid-columns));
}
@mixin make-xs-column-push-rtl($columns) {
    left: auto;
    right: percentage(math.div($columns, $grid-columns));
}
@mixin make-xs-column-pull-rtl($columns) {
    right: auto;
    left: percentage(math.div($columns, $grid-columns));
}

@mixin make-sm-column-offset-rtl($columns) {
    @media (min-width: $screen-sm-min) {
        margin-left: inherit;
        margin-right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-sm-column-push-rtl($columns) {
    @media (min-width: $screen-sm-min) {
        left: auto;
        right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-sm-column-pull-rtl($columns) {
    @media (min-width: $screen-sm-min) {
        right: auto;
        left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-md-column-offset-rtl($columns) {
    @media (min-width: $screen-md-min) {
        margin-left: inherit;
        margin-right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-md-column-push-rtl($columns) {
    @media (min-width: $screen-md-min) {
        left: auto;
        right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-md-column-pull-rtl($columns) {
    @media (min-width: $screen-md-min) {
        right: auto;
        left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-lg-column-offset-rtl($columns) {
    @media (min-width: $screen-lg-min) {
        margin-left: inherit;
        margin-right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-lg-column-push-rtl($columns) {
    @media (min-width: $screen-lg-min) {
        left: auto;
        right: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-lg-column-pull-rtl($columns) {
    @media (min-width: $screen-lg-min) {
        right: auto;
        left: percentage(math.div($columns, $grid-columns));
    }
}

// begin overrides
[dir="rtl"] {
    // column alignment, offsets, push, pull
    @for $i from 0 through $grid-columns {
        // right alignment
        .col-xs-#{$i},
        .col-sm-#{$i},
        .col-md-#{$i},
        .col-lg-#{$i} {
            float: right;
        }

        // offsets
        .col-xs-offset-#{$i} {
            @include make-xs-column-offset-rtl($i);
        }
        .col-sm-offset-#{$i} {
            @include make-sm-column-offset-rtl($i);
        }
        .col-md-offset-#{$i} {
            @include make-md-column-offset-rtl($i);
        }
        .col-lg-offset-#{$i} {
            @include make-lg-column-offset-rtl($i);
        }

        // push
        .col-xs-push-#{$i} {
            @include make-xs-column-push-rtl($i);
        }
        .col-sm-push-#{$i} {
            @include make-sm-column-push-rtl($i);
        }
        .col-md-push-#{$i} {
            @include make-md-column-push-rtl($i);
        }
        .col-lg-push-#{$i} {
            @include make-lg-column-push-rtl($i);
        }

        // pull
        .col-xs-pull-#{$i} {
            @include make-xs-column-pull-rtl($i);
        }
        .col-sm-pull-#{$i} {
            @include make-sm-column-pull-rtl($i);
        }
        .col-md-pull-#{$i} {
            @include make-md-column-pull-rtl($i);
        }
        .col-lg-pull-#{$i} {
            @include make-lg-column-pull-rtl($i);
        }
    }
}
