$maxWidthForOneColumn: $screen-xs-max;
$COLUMN_SLIDE_UP_DURATION: 0.7s;

// we have to support both the ng-if classes and the ng-show classes here because
// we use both in different places
@mixin frame-transition($duration: 0.5s) {
    display: block;
    &.ng-enter,
    &.ng-hide-remove {
        transition: all $duration ease 0s;
    }

    &.ng-leave,
    &.ng-hide-add {
        transition: all $duration ease 0s;
    }

    // start position when putting frame on screen
    &.ng-enter,
    &.ng-hide-remove {
        opacity: 0;
        transform: translateX(300px);

        // rtl-language overrides
        html[dir="rtl"] & {
            transform: translateX(-300px);
        }
    }

    // end position when removing frame from screen
    &.ng-leave.ng-leave-active,
    &.ng-hide-add.ng-hide-add-active {
        opacity: 0;
        transform: translateX(-300px);

        // rtl-language overrides
        html[dir="rtl"] & {
            transform: translateX(300px);
        }
    }

    // start position when putting frame on screen, if going back
    &.backwards-add.ng-enter,
    &.backwards.ng-enter,
    &.backwards-add.ng-hide-remove,
    &.backwards.ng-hide-remove {
        opacity: 0;
        transform: translateX(-300px);

        // rtl-language overrides
        html[dir="rtl"] & {
            transform: translateX(300px);
        }
    }

    // end position when removing frame from screen, if going back
    &.backwards-add.ng-leave.ng-leave-active,
    &.backwards.ng-leave.ng-leave-active,
    &.backwards-add.ng-hide-remove.ng-hide-remove-active,
    &.backwards.ng-hide-remove.ng-hide-remove-active {
        opacity: 0;
        transform: translateX(300px);

        // rtl-language overrides
        html[dir="rtl"] & {
            transform: translateX(-300px);
        }
    }

    // stable position when frame is on the screen
    &.ng-leave,
    &.ng-enter.ng-enter-active,
    &.ng-hide-add,
    &.ng-hide-remove.ng-hide-remove-active {
        transform: translateX(0%);
        opacity: 1;
    }
}

.continue-button-animation-wrapper {
    @include frame-transition;
}

.slide-message-desktop {
    @include frame-transition;
}

// This should be mixed in to `.frame-container`
@mixin frame-player-width($width, $maxWidth) {
    &,
    .frame,
    .frame > cf-ui-component,
    .frame > cf-ui-component > cf-text-image-interactive,
    .frame > cf-ui-component > cf-text-image-interactive > .text_image_interactive {
        display: block;
        width: $width;
        max-width: $maxWidth;
    }
}

// This is also used in edit_frame_list
@mixin frames-player($main_content_width, $left_sidebar_width, $right_sidebar_width) {
    $overlayIndex: 101; // has to be more than the max z-index set in ProcessesMathjaxEditor

    .edit-lesson-preview-mode {
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 50px;
        padding: 0px;
        right: 0px;
    }

    .lesson-content-container {
        padding: 0px;
        font-size: $FONT_SIZE_CONTENT;

        margin-top: 0px;
        margin-bottom: 0px;
        min-width: $main_content_width;

        position: relative;

        @media (max-width: $screen-xs-max) {
            margin-left: 0px;
            margin-right: 0px;
            width: 100%;
            font-size: $FONT_SIZE_CONTENT_SCREEN_SMALL_MIN;
            min-width: inherit;
        }

        .column {
            flex: 1 1 auto;
            transition: all 0.3s ease-in-out;
            position: relative;

            @media (max-width: $maxWidthForOneColumn) {
                transition: all $COLUMN_SLIDE_UP_DURATION ease-in-out;
            }

            /* By default, we only show the main column with the frame content
            See .showing-end-column below for when we show both columns together
            */
            &.main-column {
                flex: 1 1 100%;
                max-width: 100%;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: $overlayIndex;
                    pointer-events: none;
                    background: rgba(0, 0, 0, 0);
                    transition: 0.3s ease;
                }

                .scroll-wrapper {
                    padding-bottom: $CONTINUE_BUTTON_HEIGHT + 20px; // 20px is the padding of the continue button
                }

                .return-to-screen-wrapper {
                    // when the screen is smaller than maxWidthForOneColumn, the main
                    // column is hidden, and we also want to hide the return-to-screen
                    // button that is in the main column.
                    @media (max-width: $maxWidthForOneColumn) {
                        display: none;
                    }
                }

                @media (max-width: $maxWidthForOneColumn) {
                    > * {
                        animation: returnToScreen 0.5s 0.5s ease-in-out backwards;
                    }
                }
            }

            &.end-column {
                flex: 1 1 0%;
                background-image: linear-gradient(to top, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0.95) 100%);

                @media (max-width: $maxWidthForOneColumn) {
                    border-top: 1px solid #979797;
                    border-left: none;
                }

                // Mobile -- The Return to Screen wrapper and button render in the .end-column only in mobile.
                // In desktop, the button is rendered in the .main-column
                .return-to-screen-wrapper {
                    top: 40px; // There is logic in WelcomeMessage.tsx that relies on this 40px. See comment in the clsx call in the top element of that component
                    bottom: unset;
                    opacity: 1;

                    // when the screen is larger than maxWidthForOneColumn, we hide
                    // the return-to-screen-wrapper from the end-column since the one
                    // in the main column will be visible. At smaller sizes, the main
                    // colunm is hidden, so we need this one
                    @media (min-width: ($maxWidthForOneColumn+1)) {
                        display: none;
                    }

                    @media (max-width: $maxWidthForOneColumn) {
                        // 'transitions' to overflow-y: auto when the column slides up
                        overflow: visible;
                    }

                    .return-to-screen {
                        opacity: 0;
                        // delay this animation until the column has slid into place
                        animation: bounceInDown 1s ($COLUMN_SLIDE_UP_DURATION - 0.3) ease forwards;
                    }
                }
            }

            .scroll-wrapper {
                height: 100%;
                // This is not used to show a scrollbar on the bot column because the bot is h-full
                overflow-y: auto;
                overflow-x: hidden;
            }

            .return-to-screen-wrapper {
                position: absolute;

                // This 48px has to match the value for bottom margin in the chat-input-wrapper element in
                // Chat.tsx[data-id="chat-input-wrapper"] that is used when we are not showing the app menu
                // (since the app menu is hidden in the lesson player)
                bottom: 48px;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: $overlayIndex + 1;

                .return-to-screen {
                    @include footer-buttons-animation();
                    font-size: 18px; // There is logic in WelcomeMessage.tsx that relies on this 18px font-size. See comment in the clsx call in the top element of that component
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    background: #fff;
                    border-radius: 1.5em;
                    color: $COLOR_V3_CORAL;
                    border: 1px solid $COLOR_V3_BEIGE_DARK;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 20px; // There is logic in WelcomeMessage.tsx that relies on this 40px. See comment in the clsx call in the top element of that component
                    max-height: 56px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

                    i {
                        font-size: 20px;
                        margin-inline-end: 0.5em;
                    }

                    &:hover {
                        color: $COLOR_V3_WHITE;
                        background-color: $COLOR_V3_CORAL;
                    }

                    body.bg-turquoise &,
                    body.bg-purple &,
                    body.bg-blue & {
                        background: $COLOR_V3_WHITE;
                    }

                    body.bg-turquoise & {
                        color: $COLOR_V3_TURQUOISE_DARK;
                        &:hover {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_TURQUOISE_DARK;
                        }
                    }

                    body.bg-purple & {
                        color: $COLOR_V3_PURPLE_DARK;
                        &:hover {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_PURPLE_DARK;
                        }
                    }

                    body.bg-blue & {
                        color: $COLOR_V3_BLUE_DARK;
                        &:hover {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_BLUE_DARK;
                        }
                    }

                    body.valar:not(.bg-turquoise):not(.bg-purple):not(.bg-blue) & {
                        background: $COLOR_V3_WHITE;
                        color: $COLOR_V3_BLUE;
                        &:hover {
                            background: $COLOR_V3_BLUE;
                            color: $COLOR_V3_WHITE;
                        }
                    }
                }
            }
        }

        .frame-container {
            margin: 0 auto;
            padding: 10px $FRAME_CONTAINER_LARGE_SCREEN_HORZ_PADDING 0px $FRAME_CONTAINER_LARGE_SCREEN_HORZ_PADDING;
            &.lte-xs {
                margin: 0;
                width: 100%;
                padding-left: $FRAME_CONTAINER_SMALL_SCREEN_HORZ_PADDING;
                padding-right: $FRAME_CONTAINER_SMALL_SCREEN_HORZ_PADDING;
            }
        }
    }

    // Fill 100% of the width and height with these wrapper divs
    &,
    > div,
    > .show-frames-player,
    > .lesson-content-container,
    > div > .lesson-content-container, // This is how it's structured in edit_frame_list
    > .show-frames-player > .lesson-content-container {
        display: flex;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    // Set appropriate width so transitions won't mess with layout
    > div > .lesson-content-container,  // This is how it's structured in edit_frame_list
    > .lesson-content-container,
    > .show-frames-player > .lesson-content-container {
        .frame-container {
            @include frame-player-width($main_content_width, $MAIN_CONTENT_MIN_WIDTH);
        }
        .frame-container.lte-xs {
            @include frame-player-width(100%, 100%);
        }

        // Vertically center the frame
        .frame-container {
            display: table !important;
            table-layout: fixed; // allows us to set a specific width
            height: 100%;

            // prevent odd ng-animate offset during animation
            &.showing-bookend-screen {
                display: block !important;
            }

            > .frame {
                display: table-cell !important;
                vertical-align: middle;
            }

            // we only show footer if we meet min-height guidelines.
            // min-height might not be met in android landscape mode while keyboard is active.
            // set a padding so that scroll begins when height exceeds overall height minus footer height
            @media (min-width: $screen-sm-min) and (min-height: $MIN_REQUIRED_HEIGHT) {
                padding-bottom: $FRONT_ROYAL_FOOTER_HEIGHT_LARGE;
            }
            @media (max-width: $screen-xs-max) and (min-height: $MIN_REQUIRED_HEIGHT) {
                padding-bottom: $FRONT_ROYAL_FOOTER_HEIGHT_SMALL;
            }
        }

        // On mobile, the two "columns" are stacked on top of each other so the bot UI slides up
        // from the bottom instead of in from the right
        @media (max-width: $maxWidthForOneColumn) {
            flex-direction: column;
        }

        &.showing-end-column {
            > .column {
                padding: 0;

                &.main-column {
                    flex: 1 1 50%;

                    @media (max-width: $maxWidthForOneColumn) {
                        flex: 1 1 0%;
                        opacity: 0;
                        overflow: hidden;

                        > * {
                            display: none;
                            opacity: 0;
                            animation: returnToScreen 0.3s ($COLUMN_SLIDE_UP_DURATION - 0.3s) ease forwards;
                        }
                    }

                    .scroll-wrapper {
                        padding: 0 0 100px 0;
                        overflow: hidden; // The frame content is disabled while showing split screen
                    }

                    .overlay {
                        display: block;
                        pointer-events: auto;
                        background: rgba(0, 0, 0, 0.1);
                    }
                }
                &.end-column {
                    flex: 1 1 50%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-left: 1px solid #979797;

                    @media (max-width: $maxWidthForOneColumn) {
                        flex: 1 1 100%;
                        border-top: 1px solid #979797;
                        border-left-width: 0;
                        overflow-y: auto;
                    }
                }
            }
        }
        &:not(.showing-end-column) {
            > .column {
                &.end-column {
                    padding: 0;
                }
            }
        }
    }

    .slow-loading {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        transform: translateY(-50%);
        padding: 0px 0px 50px; // push it up a little bit
        text-align: center;

        .message-wrapper {
            position: relative;
            height: 2em;

            // when the message changes, we want the one to
            // fade out and the next to fade in in the same place,
            // so we make them absolute
            > div {
                position: absolute;
                width: 100%;
            }
        }

        .appearable {
            transition: opacity 1s ease 0s;
            opacity: 0;

            &.visible {
                opacity: 1;
            }
        }
    }
}

.public show-frames-player {
    @include frames-player($MAIN_CONTENT_MIN_WIDTH, 0, 0);

    // Only use the frame transition in the player; instantly change in the editor
    > .show-frames-player > .lesson-content-container .frame-container {
        > .frame {
            @include frame-transition;
        }
    }
}

// turn off animations in bot to speed things up
.public bot show-frames-player {
    @include frames-player($MAIN_CONTENT_MIN_WIDTH, 0, 0);

    // Only use the frame transition in the player; instantly change when running a bot
    > .show-frames-player > .lesson-content-container .frame-container {
        > .frame {
            @include frame-transition(0s);
        }
    }
}

// Modal link
.frame {
    a.modal-popup {
        color: $COLOR_V3_EGGPLANT;

        .lesson_popover {
            cursor: pointer;
            position: relative;
            background-color: rgba($COLOR_V3_BEIGE_DARK, 0.35);
            border-bottom: 2px dotted darken($COLOR_V3_BEIGE_DARK, 15%);
            transition: background-color 0.15s, color 0.15s;

            padding-left: 3px;
            padding-right: 3px;

            // unnecessary element that can affect wrapping
            .MathJax_Preview {
                display: none;
            }

            // different color for special screens
            .bg-purple &,
            .bg-turquoise &,
            .bg-blue & {
                color: $COLOR_V3_WHITE;
                border-bottom: 2px dotted rgba($COLOR_V3_WHITE, 0.75);
            }

            // necessary for proper coloring on Valar branded site
            body.bg-white.valar & {
                color: $COLOR_V3_EGGPLANT;
                border-bottom: 2px dotted darken($COLOR_V3_BEIGE_DARK, 15%);

                &[aria-describedby] {
                    background-color: $COLOR_V3_BLUE !important;
                    color: $COLOR_V3_WHITE !important;
                    border-bottom: 2px solid $COLOR_V3_BLUE;
                }
            }

            .no-touchevents &:hover {
                background-color: rgba($COLOR_V3_BEIGE_DARK, 0.75);
            }
            .no-touchevents &:active,
            .touchevents &:active {
                background-color: $COLOR_V3_CORAL;
                border-bottom: 2px solid $COLOR_V3_CORAL;
                color: $COLOR_V3_WHITE;
            }
            .no-touchevents body.bg-white.valar &:active,
            .touchevents body.bg-white.valar &:active {
                background-color: $COLOR_V3_BLUE;
                border-bottom: 2px solid $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;
            }

            &[aria-describedby] {
                background-color: $COLOR_V3_CORAL !important;
                color: $COLOR_V3_WHITE !important;
                border-bottom: 2px solid $COLOR_V3_CORAL;
            }
        }
    }
}

// Keep the standard style inside of tile prompts
.frame .cf-tile-prompt a.modal-popup .lesson_popover {
    color: $COLOR_V3_EGGPLANT;
    border-bottom: 2px dotted darken($COLOR_V3_BEIGE_DARK, 15%);
}

.frame .popover,
.frame-popover {
    // Override bootstrap popover styling
    @include primary-font;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: $COLOR_V3_CORAL;
    color: $COLOR_V3_WHITE;
    padding: 10px;
    cursor: pointer;
    // overlay the continue button that has z-index 10000
    z-index: 10001;
    max-width: 400px;

    // blue styling for Valar
    body.bg-white.valar & {
        background-color: $COLOR_V3_BLUE;

        &.bottom .arrow {
            &:after {
                border-bottom-color: $COLOR_V3_BLUE;
            }
        }
        &.top .arrow {
            &:after {
                border-top-color: $COLOR_V3_BLUE;
            }
        }
        &.left .arrow {
            &:after {
                border-left-color: $COLOR_V3_BLUE;
            }
        }
        &.right .arrow {
            &:after {
                border-right-color: $COLOR_V3_BLUE;
            }
        }
    }

    .popover-content {
        & > p,
        & > span {
            font-size: 18px;
            line-height: 1.4em;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &.bottom .arrow {
        border: none !important;
        left: calc(50% + 10px) !important;
        &:after {
            border-bottom-color: $COLOR_V3_CORAL;
        }
    }
    &.top {
        margin-top: 20px;
        .arrow {
            border: none !important;
            left: calc(50% + 10px) !important;
            bottom: -10px;
            &:after {
                border-top-color: $COLOR_V3_CORAL;
            }
        }
    }
    &.left {
        margin-left: -20px;
        .arrow {
            top: calc(50% + 10px) !important;
            border: none !important;
            &:after {
                border-left-color: $COLOR_V3_CORAL;
            }
        }
    }
    &.right .arrow {
        border: none !important;
        &:after {
            border-right-color: $COLOR_V3_CORAL;
        }
    }

    // Swing in from bottom
    &.bottom {
        &.fade {
            transform: perspective(1000px) rotate3d(1, 0, 0, -45deg);
            transform-origin: 50% -10px;
            transition: opacity 0.2s, transform 0.2s;

            &.in {
                transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
            }
        }
    }

    // Swing in from top
    &.top {
        &.fade {
            transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
            transform-origin: 50% calc(100% + 10px);
            transition: opacity 0.2s, transform 0.2s;

            &.in {
                transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
            }
        }
    }

    // On Mobile, fill screen
    // It may be wrong to use a media query here, since this is inside the frame and the frame won't
    // take up the whole screen when the bot column is visible. We have solved this elswhere by using
    // the .frame-container.lte-xs class. But, it makes no difference here because you can't look at
    // modal popups when the bot is active, so not messing with it here.
    @media (max-width: $screen-xs-max) {
        position: absolute;
        display: block;
        max-width: none;
        border: 1px solid transparent !important; // something gives the modal a top border if we don't do this
        left: 10px !important;
        right: 10px !important;
        top: calc(50% - 25px) !important;
        margin: 0px;
        padding: 25px;

        &.top,
        &.bottom {
            &.fade {
                transform: translateY(-80%);
                transform-origin: 50% 50%;
                transition: opacity 0.2s, transform 0.2s;

                &.in {
                    transform: translateY(-50%);
                }
            }
        }

        .popover-content {
            & > p,
            & > span {
                font-size: 15px;
            }
        }

        .arrow {
            display: none;
        }

        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 5px;
            right: 5px;
            background-image: url("~vectors/close_icon_white.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            opacity: 0.5;
            width: 40px;
            height: 40px;
            opacity: 0.5;
        }
        &:active:after {
            opacity: 1;
        }
    }
}

@keyframes returnToScreen {
    0% {
        transform: translateY(-20%);
        opacity: 0;
        display: none;
    }

    90% {
        opacity: 1;
        display: block;
    }

    100% {
        transform: translateY(0%);
    }
}
