forgot-password-form {
    z-index: 10;
    display: block;
    position: relative;

    .container {
        width: auto;
    }

    .forgot-password-sent-img {
        margin: 0 auto;
        display: inline-block;
    }
}

forgot-password {
    .sessions {
        .form-container-inner {
            padding: 10px;
        }
    }

    forgot-password-form {
        @media (min-width: $screen-sm-min) {
            margin-top: 110px;
        }
    }

    .sessions .login-register-footer {
        .sign-in-link {
            color: $COLOR_V3_EGGPLANT;
            a {
                color: $COLOR_V3_CORAL;
            }
        }

        @media (min-width: $screen-sm-min) {
            padding: 120px 0px 0px;
        }
    }
}

onboarding-forgot-password {
    .onboarding_modal__forgot-password {
        .onboarding_modal__dialog {
            padding: 0;
        }
    }

    .sessions {
        .container {
            width: 100%;
            .form-container-inner {
                width: 100%;
                margin: 0;
                padding: 10px 10px 16.3px;
            }
        }
    }
}
