@mixin footer_wrapper($primary_color_dark) {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 14px;

    &,
    a,
    span {
        color: $primary_color_dark;
    }

    a,
    span {
        margin: 8px 4px;
    }

    div {
        margin-top: 8px;
    }

    // if this is inside one of those hexago forms, then fix it
    // to the bottom of the hexagon
    .absolute-positioning-wrapper & {
        position: absolute;
        bottom: 68px;
    }
}

.footer-wrapper,
.footer-wrapper-orange,
.footer-wrapper-turquoise {
    @include footer_wrapper($COLOR_V3_CORAL_DARK);
}

.footer-wrapper-orange {
    &,
    a,
    span {
        color: $COLOR_V3_ORANGE_DARK;
    }
}

.footer-wrapper-turquoise {
    &,
    a,
    span {
        color: $COLOR_V3_TURQUOISE_DARK;
    }
    padding-top: 5px;
}
