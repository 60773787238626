@mixin tooltip($width, $top, $left, $arrowLeft) {
    width: $width;
    top: $top;
    left: $left;
    pointer-events: none;

    &::before,
    &::after {
        left: $arrowLeft;
    }

    @media (max-width: $screen-xs-max) {
        width: calc(100vw - 100px);
        left: 50%;
        margin-left: calc(-50vw + 50px);
        white-space: normal;

        &::before,
        &::after {
            left: 50%;
        }
    }
}

@mixin tooltip-centered($width, $top) {
    width: $width;
    top: $top;
    left: calc(100% - 30px);
    transform: translateX(-50%);
    pointer-events: none;

    &::before,
    &::after {
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: $screen-xs-max) {
        width: calc(100vw - 100px);
        left: 50%;
        margin-left: calc(-50vw + 50px);
        white-space: normal;

        &::before,
        &::after {
            left: 50%;
        }
    }
}

@mixin tooltip-bottom($width, $bottom, $left, $arrowLeft) {
    width: $width;
    top: auto;
    bottom: $bottom;
    left: $left;
    text-align: center;
    white-space: normal;
    pointer-events: none;

    &::before,
    &::after {
        left: $arrowLeft;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: auto;
        bottom: -6px;
        transform: translateX(-50%);
        border: 6px solid transparent;
        border-top-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        border-bottom: none;
    }

    &::before {
        border-width: 7px;
        border-top-color: $COLOR_V3_WHITE;
        top: auto;
        bottom: -7px;
    }

    .item-in-tooltip {
        white-space: nowrap;
        display: inline-block;
    }

    @media (max-width: $screen-xs-max) {
        width: calc(100vw - 100px);
        left: 50%;
        margin-left: calc(-50vw + 50px);

        &::before,
        &::after {
            left: 50%;
        }
    }
}

@mixin tooltip-top($width, $top, $left, $arrowLeft) {
    width: $width;
    top: $top;
    left: $left;
    text-align: center;
    white-space: normal;
    pointer-events: none;

    &::before,
    &::after {
        left: $arrowLeft;
        transform: translateX(-50%);
    }

    .item-in-tooltip {
        white-space: nowrap;
        display: inline-block;
    }

    @media (max-width: $screen-xs-max) {
        width: calc(100vw - 100px);
        left: 50%;
        margin-left: calc(-50vw + 50px);

        &::before,
        &::after {
            left: 50%;
        }
    }
}

@mixin tooltip-dark($fillColor) {
    background: $fillColor;
    color: $COLOR_V3_WHITE;

    &:after {
        border-bottom-color: $fillColor;
    }
}

@keyframes move-down {
    0% {
        transform: translate(-50%, 0);
        opacity: 0;
    }
    66% {
        transform: translate(-50%, 0);
        opacity: 0;
    }
}

candidate-list {
    display: block;

    .search-filters + .clearfix & {
        margin-top: 5px;

        @media (min-width: $screen-md-min) {
            margin-top: 10px;
        }
    }

    .list-wrapper {
        clear: both;
        margin: 5px auto;
        position: relative;

        &.no-pagination {
            margin-bottom: 50px;
        }
    }

    .choose-a-plan-wrapper {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: calc(100% - 20px);
        background: $COLOR_V3_WHITE;
        max-width: 360px;
        box-shadow: 0 5px 8px rgba($COLOR_V3_BLACK, 0.5);
        margin-bottom: 0;
    }

    .deep-link-divider {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        display: block;
        margin-bottom: 40px;
        margin-top: 40px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;

        & > span {
            position: relative;

            &:before,
            &:after {
                background: #c1b8ae;
                content: "";
                position: absolute;
                top: 50%;
                width: 9999px;
                height: 1px;
            }

            &:before {
                right: 100%;
                margin-right: 15px;
            }

            &:after {
                left: 100%;
                margin-left: 15px;
            }
        }
    }

    .unsupported-deep-link-container {
        .candidate-list-card-placeholder {
            border: 3px solid #c1b9af;
            border-radius: 2px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            padding-bottom: 60px;
            padding-top: 60px;
            text-align: center;
        }
    }
}

.choose-a-plan-wrapper {
    background: $COLOR_V3_BEIGE_MIDDARK;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    margin-bottom: 100px;

    span {
        display: block;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 20px;
        margin-bottom: 12px;
        color: $COLOR_V3_GREY_DARKER;
    }
}
