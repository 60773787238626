// Hide the event markers until they are moved into the map's DOM by the overlay
// See event_marker_dir.js for more details.
event-marker {
    display: none;
}

ng-map {
    event-marker {
        position: absolute;
        display: block;
        width: 1px;
        height: 1px;
        cursor: pointer;
        overflow: visible;
        direction: ltr;

        animation: 0.25s fade-in-down-subtle 0.35s;
        animation-fill-mode: both;

        .limited-access-tooltip.tooltip {
            width: 200px;

            .tooltip-arrow {
                border-top-color: $COLOR_V3_MAP_BLUE_DARKER;
            }

            .tooltip-inner {
                background-color: $COLOR_V3_MAP_BLUE_DARKER;
            }
        }

        .event-marker {
            transform: translateX(-50%) translateY(-50%);
            animation: fade-in 0.5s;
            animation-fill-mode: both;
        }
    }
}
