.upload-avatar {
    margin: 0;
    padding: 0;

    careers-avatar {
        width: 60px;
        height: 60px;
        display: inline-block;

        @media (min-width: $screen-sm-min) {
            width: 130px;
            height: 130px;
        }
    }

    .upload-instructions {
        width: calc(100% - 90px);
        float: right;

        @media (min-width: $screen-sm-min) {
            width: calc(100% - 160px);
        }

        .saved-auto {
            margin-bottom: 0px;
            font-size: 12px;
        }

        .max-size {
            font-style: $FONT_STYLE_ITALIC;
            font-weight: normal;
            font-size: 11px;

            &.error {
                color: $COLOR_V3_RED;
            }
        }

        .linkedin {
            font-weight: normal;
            font-size: 11px;

            &.error {
                color: $COLOR_V3_RED;
            }
        }
    }

    button {
        margin-bottom: 5px;
    }

    button[name="getAndUploadPhoto"] {
        font-size: 14px;
        margin-right: 8px;
    }
}
