.browse-courses {
    .dashboard-container > div {
        z-index: 0;

        // select-filters must be in front of
        // everything else so it works when it takes
        // up the full screen in mobile
        &.select-filters {
            z-index: 1;
        }
    }

    h1,
    h2 {
        color: $COLOR_V3_BEIGE_DARKER;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 20px;
        clear: both;
        position: relative; // because the close button is positioned within it
    }

    h1 {
        text-align: center;
        margin-bottom: 8px;
    }

    .can-be-hidden-xs-animation {
        // animating visibility seems silly, but it works
        // to get it out of the way when the opacity is 0
        //transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    .hidden-xs-animation {
        opacity: 0;
        visibility: hidden;

        &#search-results {
            // so that there is no scroll bar when select filters is showing. Hurts
            // the animation a tiny bit, but better overall
            display: none;
        }

        /* On larger screens, we always show the results */
        @media (min-width: $screen-sm-min) {
            opacity: 1;
            visibility: visible;

            &#search-results {
                display: block;
            }
        }
    }

    .search-form {
        $height: 42px;
        $buttonWidth: $height;
        $borderRadius: 3px;
        position: relative;
        margin-bottom: 10px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 20px;
        }

        input[placeholder] {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        ::-moz-placeholder {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        } /* firefox 19+ */
        input:-moz-placeholder {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        input[type="text"] {
            height: $height;
            background-color: $COLOR_V3_BEIGE_MIDDARK;
            color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
            width: calc(100% - #{$buttonWidth - 1});
            border: 0px solid transparent;
            outline: 0px;
            padding-left: 15px;
            font-size: 15px;
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            transition: background-color 0.3s ease;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 15px;
                padding-left: 0px;
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }

            @include placeholder($COLOR_V3_BEIGE_BEYOND_DARK);
        }

        button {
            height: $height;
            width: $buttonWidth;
            position: absolute;
            right: 0px;
            top: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: 0px;
            }
        }
        button.search {
            border-radius: 0px;
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            // rtl-language overrides
            html[dir="rtl"] & {
                border-radius: 0px;
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: $height;
                background-color: transparent !important;
                background-image: url("~vectors/search_magnifying_glass.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 21px 21px;
            }
        }
        button.clear {
            right: $buttonWidth;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            background: transparent !important;

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: $buttonWidth;
            }
        }

        &.hasInput input,
        input:focus {
            background-color: $COLOR_V3_WHITE;
            color: $COLOR_V3_EGGPLANT;
        }
        &.hasInput button.search {
            background-color: $COLOR_V3_YELLOW !important;
        }
    }

    .select-filters {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        min-height: 100%;
        background-color: white;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 70px + $APP_MENU_MOBILE_HEIGHT; // make room for close button and allow for scrolling

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
        }

        @media (min-width: $screen-sm-min) {
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            padding-right: 0px;
            padding-left: 0px;
            background-color: transparent;
            padding-bottom: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: auto;
            }
        }

        h2:nth-of-type(2) {
            margin-top: 28px;
        }

        .progress-buttons,
        .status-buttons {
            margin-bottom: 28px;
        }

        .topic,
        .progress-button,
        .status-button {
            font-size: 15px;
            float: left;
            margin: 0px 4px 4px 0px;
            padding: 6px 8px 4px; // less padding on bottom because of descenders
            text-transform: uppercase;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
            }
        }

        .center {
            text-align: center;
        }

        button.save-and-close-select-filters {
            position: fixed;
            bottom: $CONTINUE_BUTTON_DESKTOP_PADDING + $APP_MENU_MOBILE_HEIGHT;
            left: $CONTINUE_BUTTON_DESKTOP_PADDING;
            width: calc(100% - 2 * #{$CONTINUE_BUTTON_DESKTOP_PADDING});
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 24px;
            height: 50px;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                pointer-events: none;
                bottom: 100%;
                right: 0;
                width: 100%;
                height: 15px;
                background-color: transparent !important;
                @include gradient-vertical(rgba($COLOR_V3_WHITE, 0), rgba($COLOR_V3_WHITE, 1));
            }
        }

        button.close-select-filters {
            $padding: 16px;

            position: absolute;
            right: -$padding; // move it look like the x is all the way to the right, even though the element goes further to make a bigger click target
            top: 50%;
            transform: translateY(-50%);
            display: block;
            padding: $padding; // make it a bigger click target;
            background-color: transparent; // this is only necessary on Nate's ipod.  Even the simulator looked find without it.  Go figure

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: -$padding;
            }

            img {
                width: 13px;
                height: 13px;
            }

            &:active {
                opacity: 0.5;
            }
        }

        .fancy_checkbox {
            margin-left: 10px;
            margin-top: 16px;
        }
    }

    .toggle-select-filters {
        margin-bottom: 22px;
        $borderRadius: 3px;
        $height: 42px;

        a {
            background-color: white;
            border-radius: $borderRadius;
            height: $height;
            display: block;
            position: relative;
            overflow: hidden;

            h2 {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                font-size: 18px;
                line-height: $height;
                border-radius: $borderRadius;
                margin: 0px;
                padding: 0px 40px 0px 10px;
                z-index: 1;
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 84%,
                    rgba(255, 255, 255, 0) 100%
                );

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                    padding: 0px 10px 0px 40px;
                    background: linear-gradient(
                        to left,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, 1) 84%,
                        rgba(255, 255, 255, 0) 100%
                    );
                }
            }

            .topics {
                z-index: 0;
                position: absolute;
                right: 6px;
                top: 50%;
                width: 99999px; // getting around what looks like a mobile safari bug, which was pushing the contents out of the right side
                transform: translateY(-50%);
                text-align: right;
                white-space: nowrap;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 6px;
                    right: auto;
                    text-align: left;
                }
            }

            .topic {
                display: inline-block;
                border-radius: 3px;
                padding: 0px 4px;
                font-size: 12px;
                line-height: 25px;

                // copying the styles for the white-and-beige flat button, but this
                // is not a button because we don't want any of the active/hover, etc.
                &.white-and-beige {
                    @include flat-button-color($COLOR_V3_WHITE, $COLOR_V3_WHITE);
                    border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
                    color: $COLOR_V3_BEIGE_DARKER;
                }

                &.yellow {
                    @include flat-button-color($COLOR_V3_YELLOW, $COLOR_V3_YELLOW_DARK, lighten($COLOR_V3_YELLOW, 10%));
                }
            }
        }
    }

    .clear {
        clear: both;
    }

    .no-results-container {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        text-align: center;
        margin: 30px auto;

        h1 {
            color: $COLOR_V3_CORAL;
            font-size: 40px;
            line-height: 52px;
            margin: 40px 0px;

            @media (max-width: $screen-xs-max) {
                font-size: 38px;
                line-height: 40px;
                margin-bottom: 40px;
            }
        }
        button {
            margin-bottom: 40px;

            h3 {
                color: $COLOR_V3_BEIGE_DARKER;
            }
        }
    }
}
