.edit_stream {
    > front-royal-spinner {
        margin-top: 200px;
        display: block;
    }

    .sub-text {
        font-size: 13px;
    }

    .course-flags {
        label {
            margin-left: 20px;
        }
    }

    .coming-soon-label {
        color: $COLOR_V3_CORAL;
    }

    .lessons-container {
        ul {
            padding: 0;
            list-style-type: none;
            li {
                cursor: pointer;
                &.sortable-ghost {
                    opacity: 0.25;
                }
            }
        }

        .lesson {
            width: 100%;
            font-size: 14px;
            position: relative;
            padding-left: 8px;

            .lesson-controls-container {
                position: absolute;
                right: 8px;

                button {
                    font-size: 14px;
                    width: 20px;
                    height: 20px;
                    margin-left: 2px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    margin-right: 0px;
                }
            }
        }
    }

    .help-block {
        a {
            color: $COLOR_V3_CORAL;
        }
    }
    .well {
        padding: 5px;
    }

    .resource-row {
        margin-bottom: 10px;
    }

    .download-url-row {
        padding-right: 60px;
        position: relative;

        button,
        span,
        a {
            display: inline-block;
            vertical-align: top;
        }

        .progress {
            display: inline-block;
            width: 100%;
            margin-top: 3px;
        }

        .help-inline {
            vertical-align: middle;
            vertical-align: -webkit-baseline-middle;
        }

        .download-url {
            width: calc(100% - 30px);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px;
            border: 0px solid transparent;
            border-radius: 3px;
            background: #ccc;
        }

        .test-link {
            position: absolute;
            right: 0px;
            top: 5px;
        }
    }

    .link-row-data {
        .form-group {
            margin-bottom: 0;
        }
    }

    .back-link {
        display: block;
        margin: 20px 0px;
        color: $COLOR_V3_CORAL;
    }

    // overrides to styles defined in component_editor.scss
    button {
        &.remove-button {
            margin-top: 0;
        }
    }

    input[name="key_topics"] {
        margin: 2px;
    }

    .groups {
        margin-top: 20px;
    }

    .images {
        position: relative;

        .no-icon-placeholder {
            height: 150px;
            width: 150px;
            border: 0px solid transparent;
            border-radius: 50%;
            background-color: $COLOR_V3_BEIGE_LIGHTEST;
            margin-bottom: 5px;
            text-align: center;
            line-height: 150px;
            color: $COLOR_V3_BEIGE_DARKEST;
        }

        img {
            height: 150px;
            &.coming-soon {
                opacity: 0.5;
            }
        }
        img.coming-soon-icon {
            height: 80px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 100;
        }
    }

    .actions {
        margin-top: 15px;
    }

    button {
        &.save_metadata {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_GREEN;
            border-color: $COLOR_V3_GREEN;

            &:hover,
            &:hover:enabled,
            &:active,
            &:active:enabled,
            &:focus {
                background-color: $COLOR_V3_GREEN;
            }
        }
    }

    textarea:disabled {
        background-color: lightgray;
        color: gray;
    }

    input:disabled {
        background-color: lightgray;
        color: gray;
    }

    .exam-time-pickers-container {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: $COLOR_V3_BEIGE_LIGHTEST;

        label {
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .datetimepicker {
            margin: 0 auto;
            margin-bottom: 15px;
            border: 1px solid $COLOR_V3_BEIGE_DARKEST;
            background-color: $COLOR_V3_WHITE;
        }
    }
}
