@import "oreo/global/colors_v3";

div.program_preferences {
    &.center {
        .logo {
            text-align: center;
        }
    }

    .blurb {
        color: $COLOR_V3_BLACK;

        &:not(:last-of-type) {
            margin-bottom: 50px;
        }

        &.error {
            font-weight: 700;
        }

        h3 {
            font-size: 1.5em;
            margin-bottom: 15px;
            color: $COLOR_V3_BLACK;
        }

        a {
            color: $COLOR_V3_BLUE;
        }

        .logo {
            margin-bottom: 20px;
        }
    }

    .valar-opt-in {
        margin: -20px 0 20px 0;
        padding: 0;
    }
}
