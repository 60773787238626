upload-unofficial-transcripts-modal {
    .message {
        .tooltip-anchor {
            color: $COLOR_V3_BLACK;
            cursor: pointer;

            &::after {
                @include font-awesome-icon("\f05a"); // fa-info-circle
                padding-left: 2px;
            }
        }

        .tooltip {
            opacity: 1;

            .tooltip-inner {
                max-width: 293px;
                padding: 8px;

                a {
                    color: $COLOR_V3_WHITE;
                    text-decoration: underline;
                }
            }
        }
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;

        button {
            // the padding override ensures there's some space
            // between the buttons at small mobile screen sizes
            padding: 10px;
            text-transform: none;
        }
    }
}
