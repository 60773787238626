@import "oreo/global/colors_v3";
@import "oreo/global/fonts";

div.change-program-section-warning {
    margin: 10px 15px;
    color: $COLOR_V3_RED;
    font-weight: $FONT_WEIGHT_SEMIBOLD;
}

.payment-information {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
}

.add-scholarship {
    align-self: end;
}

.scholarship-wrapper {
    width: 100%;
    display: flex;

    > div {
        display: flex;
        flex-direction: column;
        flex: 1 1 75%;
        margin: 0 0.5rem 0 0;
    }

    .remove-scholarship {
        align-self: center;
        width: 40px;
        height: 40px;
    }
}

.tuition-plan-wrapper {
    margin-bottom: 10px;
}
