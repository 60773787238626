@use "sass:math";

@import "oreo/global/colors_v3";

@mixin custom-sessions-page(
    $colorNeutral,
    $colorBright,
    $colorBrightLight,
    $colorCooler,
    $colorCoolerLight,
    $logo,
    $logoWhite,
    $logoWidth,
    $logoHeight,
    $recolorSocialButtons: true
) {
    background-color: $colorNeutral;

    .gradient-backdrop {
        background-image: -webkit-linear-gradient(-45deg, $colorCooler 0, $colorCoolerLight 100%);
        background-image: -moz-linear-gradient(-45deg, $colorCooler 0, $colorCoolerLight 100%);
        background-image: -o-linear-gradient(-45deg, $colorCooler 0, $colorCoolerLight 100%);
        background-image: linear-gradient(135deg, $colorCooler 0, $colorCoolerLight 100%);
        z-index: 0;

        .separator g {
            fill: $colorNeutral;
        }
    }

    sign-in-form,
    forgot-password,
    forgot-password-form,
    institution-register,
    .login-register-footer {
        z-index: 10;
        display: block;
        position: relative;
    }

    @media (min-width: $screen-sm-min) {
        padding-bottom: 400px;
    }

    .sessions-navigation-container .nav-container {
        .nav-logo.custom-logo {
            width: $logoWidth;
            height: $logoHeight;

            .inner-logo {
                display: inline-block;
                width: 100%;
                height: 100%;
                background: $logoWhite center center no-repeat;
                background-size: contain;
                margin: 0px;
            }
        }

        &.open {
            .nav-logo.custom-logo .inner-logo {
                background-image: $logo;
            }
        }
    }

    .nav-logo img {
        margin-left: 20px;
        height: 20px;
        @media (min-width: $screen-sm-min) {
            height: 25px;
        }
    }

    .btn.btn-red {
        border-color: $colorBright;
        background: $colorBright;

        &:hover,
        &:active,
        &:focus {
            background: $colorBrightLight;
        }
    }

    .form-container {
        .form-container-inner {
            border: 0px solid transparent;
            border-radius: 8px;

            .forgot-password-link-container a {
                color: $colorBrightLight;
            }

            @if $recolorSocialButtons {
                .soc-btns .soc-btn {
                    background-color: $colorBright;
                }
            }
        }

        button.form-submit {
            &,
            &[disabled] {
                background: $colorBright;
            }
            &:hover,
            &:active,
            &:focus {
                background: $colorBrightLight;
                &[disabled] {
                    background: $colorBright;
                }
            }
        }
    }

    .login-register-footer {
        .join-link,
        .sign-in-link {
            color: $colorCooler;
            a {
                color: $colorBrightLight;
            }
        }
    }

    .tab-pane .sign-up-sidebars .upsell-sidebar-box:before {
        border-top-color: $colorBright;
    }
}

// Georgetown McDonough School
.sessions.georgetownmsb,
.sessions.georgetownmim {
    @include custom-sessions-page(
        #e7dac6,
        // $colorNeutral: beige
        #97022e,
        // $colorBright: red
        #af3c5e,
        // $colorBrightLight: light red
        #1f294b,
        // $colorCooler: dark blue
        #002e62,
        // $colorCoolerLight: light blue
        url("~images/georgetown/georgetown_logo.png"),
        url("~images/georgetown/georgetown_logo_white.png"),
        137px * 0.5,
        107px * 0.5
    );

    .sessions-header-container h3 {
        max-width: none;
    }
}

// Reboot
.sessions.reboot {
    @include custom-sessions-page(
        #eeeeee,
        // $colorNeutral: white
        darken($COLOR_V3_GREEN, 5%),
        // $colorBright: darker green
        $COLOR_V3_GREEN,
        // $colorBrightLight: green
        #333333,
        // $colorCooler: gray
        #444444,
        // $colorCoolerLight: lighter gray
        url("~images/reboot/reboot_logo.png"),
        url("~images/reboot/reboot_logo_white.png"),
        340px * 0.25,
        156px * 0.25
    );
}

// Blended
.sessions.blended {
    @include custom-sessions-page(
        #eeeeee,
        // $colorNeutral:
        #40b1e3,
        // $colorBright:
        #5bc4e5,
        // $colorBrightLight:
        #0056c5,
        // $colorCooler:
        #3397ee,
        // $colorCoolerLight:
        url("~images/blended/blended_logo.png"),
        url("~images/blended/blended_logo_white.png"),
        336px * 0.35,
        123px * 0.35
    );

    .form-container .form-container-inner .soc-btns .soc-btn {
        background-color: darken($COLOR_V3_YELLOW, 1%);
    }
    .login-register-footer {
        .join-link,
        .sign-in-link {
            color: #000000;
            a {
                color: #1175ff;
            }
        }
    }
}

// Valar
.sessions.valar {
    $nav-height: 100px;
    $nav-height-mobile: 61px;
    $montserrat: "Montserrat", "AmiriWeb", sans-serif;
    $dm-sans: "DMSans", "AmiriWeb", sans-serif;

    font-family: $dm-sans;
    font-weight: bold;
    min-height: 100vh;

    @media (min-width: $screen-sm-min) {
        padding-bottom: 91px;
    }

    background: linear-gradient(303deg, $COLOR_V3_BLUE 0, $COLOR_V3_BLUE 30%, whitesmoke 30%, whitesmoke 100%);
    background-repeat: no-repeat;
    background-position-y: $nav-height-mobile;

    @media (min-width: $screen-sm-min) {
        background: linear-gradient(
            303deg,
            $COLOR_V3_BLUE 0,
            $COLOR_V3_BLUE 15%,
            whitesmoke 15%,
            whitesmoke 88%,
            $COLOR_V3_BLUE 88%,
            $COLOR_V3_BLUE 100%
        );
        background-repeat: no-repeat;
        background-position-y: $nav-height;
    }

    .icp-china-footer-wrapper {
        display: block;
        background-color: white;
        text-align: center;
        margin-top: 16px;
        padding: 16px;

        @media (min-width: $screen-sm-min) {
            background-color: transparent;
        }

        .icp-china-footer-link a {
            font-weight: normal;
            letter-spacing: 0.4px;
            color: #4d7aff;
            font-size: 12px;
            text-transform: capitalize;
        }
    }

    &.institution-register {
        padding-bottom: 25px;

        .form-container {
            padding: 16px;

            .form-container-inner {
                border-radius: 2px 2px 0 0;

                .form-submit[disabled] {
                    background-color: $COLOR_V3_BLUE;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            padding-bottom: 61px;

            .form-container {
                width: 490px;
                padding: 0;
                margin: 0;

                .form-container-inner {
                    border-radius: 0px 2px 2px 0;
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            background: none;
        }
    }

    .login-register-footer {
        display: none;
    }

    .sessions-navigation-container {
        background-color: white;
        height: $nav-height;
        margin-bottom: 64px;

        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-top: 0;
            padding: 0;
            height: $nav-height;

            &::before,
            &::after {
                display: none;
            }

            .nav-logo {
                cursor: pointer;
            }

            .cta-sign-in {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            p {
                color: $COLOR_V3_PRIMARY_GRAY;
                line-height: 1.5;
                letter-spacing: 0.25px;
                margin: 0 20px 0 0;
                padding: 0;
            }

            button {
                font-family: $montserrat;
                background-color: $COLOR_V3_BLUE;
                color: white;
                text-transform: uppercase;
                border-radius: 2px;
                padding: 11px 20px;
                border: 0;
                letter-spacing: 0.5px;
                line-height: 1.13;
                transition: all 0.23s ease-in-out 0s;

                @media (min-width: $screen-sm-min) {
                    &:hover {
                        background-color: $COLOR_V3_SECONDARY_DEEP_BLUE;
                    }
                }
            }
        }

        .nav-logo {
            background-image: url(~vectors/logos/valar-logo-secondary-color.svg);
            background-repeat: no-repeat;
            background-position: bottom center;
            margin-top: 5px;
            width: 211px;
            height: 22px;
            background-size: contain;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }
        }
    }

    .sessions-header-container {
        margin-bottom: 24px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 40px;
        }

        h1 {
            font-family: $montserrat;
            font-size: 56px;
            font-weight: bold;
            line-height: 1.25;
            color: black;
            margin: 0;
            letter-spacing: 0.4px;

            @media (min-width: $screen-md-min) {
                letter-spacing: normal;
            }
        }
    }

    .sign-up-form-container {
        .form-container .form-container-inner {
            padding: 24px 16px 32px 16px;

            @media (min-width: $screen-sm-min) {
                padding: 40px 30px 48px 30px;
            }
        }

        .context-sidebar {
            background: $COLOR_V3_BLUE;
            color: white;

            h2 {
                margin-bottom: 24px;

                @media (min-width: $screen-md-min) {
                    margin-bottom: 28px;
                }
            }

            ul {
                list-style-type: none;
                padding-left: 0;

                li {
                    font-weight: normal;
                    font-size: 16px;
                    display: flex;
                    align-items: flex-start;
                    letter-spacing: 0.25px;
                    line-height: 24px;

                    &:not(:last-of-type) {
                        margin-bottom: 24px;
                    }
                }

                & li:before {
                    @include font-awesome-icon("\f00c"); // fa-check
                    margin-right: 14px;
                }
            }
        }
    }

    forgot-password-form {
        h1 {
            color: black;
        }
    }

    .form-container {
        padding: 0;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            &.contained {
                width: 558px;
            }
        }

        .required:not(.selectize-input):after {
            margin-left: 2px;
        }

        .form-container-inner {
            border-radius: 2px;
            box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
            padding: 40px 68px;

            p.intro {
                font-weight: normal;
                letter-spacing: 0.5px;
            }

            h2 {
                font-family: $montserrat;
                font-size: 13px;
                font-weight: bold;
                line-height: 1.23;
                letter-spacing: 0.4px;
                color: $COLOR_V3_PRIMARY_GRAY;
                text-transform: uppercase;
                position: relative;
                width: 100%;

                span {
                    background: white;
                    padding: 0 8px;

                    @media (min-width: $screen-md-min) {
                        padding: 0 7px;
                    }
                }

                &::before {
                    content: "";
                    display: block;
                    background: $COLOR_V3_NEUTRAL_100;
                    height: 1px;
                    margin-bottom: -9px;
                }
            }

            label {
                font-family: $dm-sans;
                font-size: 12px;
                font-weight: bold;
                line-height: 1.5;
                letter-spacing: 0.4px;
                text-transform: uppercase;
                margin-bottom: 2px;
            }

            .soc-btns + h2 {
                margin-top: 25px;
                margin-bottom: 7px;
            }

            .form {
                margin-top: 10px;

                @media (min-width: $screen-sm-min) {
                    margin-top: 14px;
                }

                .form-error {
                    &.active {
                        display: block;
                    }
                    padding: 0;
                    font-size: 12px;
                    margin-top: 6px;
                }

                .form-group {
                    margin-bottom: 2px;

                    input {
                        height: 48px;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.25px;
                        font-weight: normal;
                        border-radius: 0px;
                        padding: 12px;
                        border-color: $COLOR_V3_NEUTRAL_100;
                        color: $COLOR_V3_NEUTRAL_700;

                        &.ng-pristine:focus {
                            border-color: $COLOR_V3_NEUTRAL_400;
                        }
                    }

                    .form-show-password {
                        top: 11px;
                        background-color: $COLOR_V3_NEUTRAL_200;
                        background-image: none;
                        mask-image: url("~vectors/show-password.svg");
                        padding: 0;
                        margin-top: 6px;
                    }
                }
            }

            @media (min-width: $screen-sm-min) {
                .soc-btns .row .soc-btn-col {
                    margin-bottom: 8px;

                    &:nth-of-type(odd) {
                        padding-right: 4px;
                    }

                    &:nth-of-type(even) {
                        padding-left: 4px;
                    }
                }
            }

            .soc-btn {
                font-family: $dm-sans;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.4px;
                font-weight: normal;
                text-transform: uppercase;
                padding: 10px 0;
                border-radius: 2px;

                span {
                    text-align: left;
                    padding-left: 40px;
                }

                &::before {
                    width: 30px;
                    height: 30px;
                    left: 5px;
                }

                &.soc-no-block-a-btn:before {
                    // This value can be anything, really. Setting this resets the position of the
                    // icon back to center height.
                    background-position-y: 0%;
                }
            }

            .forgot-password-link-container {
                margin: 12px 0 16px 0;
                line-height: 15px;

                a {
                    font-weight: normal;
                    letter-spacing: 0.4px;
                    color: $COLOR_V3_BLUE;
                    font-size: 13px;
                    float: none;
                    text-transform: capitalize;
                }
            }

            div[name="captcha_container"] {
                padding-top: 10px;
                margin-bottom: -10px;

                .g-recaptcha,
                .h-captcha {
                    transform: scale(0.87);
                    transform-origin: 0 0;
                }

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 0px;

                    .g-recaptcha,
                    .h-captcha {
                        transform: scale(1);
                    }
                }

                @media (min-width: $screen-md-min) {
                    padding-top: 6px;
                }
            }

            .privacy-notice {
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                margin: 12px 0 12px 0;
                text-align: left;
                letter-spacing: 0.4px;
                color: $COLOR_V3_NEUTRAL_500;

                a {
                    color: $COLOR_V3_BLUE;
                }
            }

            .form-submit {
                font-family: $montserrat;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                line-height: 1.13;
                font-weight: bold;
                font-size: 16px;
                background: $COLOR_V3_BLUE;
                border-radius: 2px;
                box-shadow: none;
                margin-bottom: 0px;
                margin-top: 12px;
                width: 100%;
                padding: 15px;
                transition: all 0.23s ease-in-out 0s;

                @media (min-width: $screen-md-min) {
                    margin: 12px 0 0 0;
                    width: 100%;
                }

                &:disabled {
                    opacity: 0.6;
                    border-radius: 2px;
                    box-shadow: none;
                }

                &:hover:not(:disabled) {
                    background-color: $COLOR_V3_SECONDARY_DEEP_BLUE;
                }
            }
        }
    }

    .gradient-backdrop {
        display: none;
    }

    @media (max-width: $screen-xs-max) {
        .form-container {
            .form-container-inner {
                padding: 24px 16px 32px;

                .soc-btns {
                    .soc-btn span {
                        float: left;
                    }

                    .row .soc-btn-col:not(:last-of-type) {
                        margin-bottom: 8px;
                    }
                }

                .form-submit {
                    margin-bottom: 0;
                }
            }

            &.contained {
                padding: 16px 16px 40px 16px;
            }
        }

        &.institution-register {
            overflow-x: hidden;

            .sessions-navigation-container {
                margin-bottom: 40px;
            }

            .sessions-header-container {
                margin-bottom: 1.8px;
            }
        }

        .sessions-navigation-container {
            height: $nav-height-mobile;
            margin-bottom: 24px;

            .nav {
                height: $nav-height-mobile;
                padding: 0;

                p {
                    display: none;
                    text-align: right;
                }

                button {
                    background-color: transparent;
                    color: black;
                    padding-right: 0;
                }
            }

            .nav-logo {
                width: 144px;
                height: 15px;
            }
        }

        .sessions-header-container {
            margin-bottom: 8px;

            h1 {
                font-size: 31px;
                line-height: 37px;
            }
        }
    }
}

// AS-GSV 2016
.sessions.asugsv {
    @include custom-sessions-page(
        #ececec,
        // $colorNeutral:
        #771885,
        // $colorBright:
        lighten(#771885, 5%),
        // $colorBrightLight:
        #1175ff,
        // $colorCooler:
        lighten(#1175ff, 5%),
        // $colorCoolerLight:
        url("~images/asugsv/asu_gsv_logo.png"),
        url("~images/asugsv/asu_gsv_logo_white.png"),
        429px * 0.2,
        379px * 0.2
    );

    .form-container .form-container-inner .soc-btns .soc-btn {
        background-color: #0ac524;
    }
    .login-register-footer {
        .join-link,
        .sign-in-link {
            color: #000000;
            a {
                color: #1175ff;
            }
        }
    }
}

.sessions.shrm {
    @include custom-sessions-page(
        #faf6f0,
        // $colorNeutral: beige
        #f90,
        // $colorBright: orange
        lighten(#f90, 5%),
        // $colorBrightLight: light orange
        #005396,
        // $colorCooler: dark blue
        #6d8fbf,
        // $colorCoolerLight: light blue
        url("~images/shrm/shrm_logo.png"),
        url("~images/shrm/shrm_logo.png"),
        714px * 0.125,
        470px * 0.125
    );
}

.sessions.jmu {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #ad9c65,
        // $colorBright: gold
        #cbb677,
        // $colorBrightLight: darker gold
        #450084,
        // $colorCooler: dark purple
        lighten(#450084, 15%),
        // $colorCoolerLight: light purple
        url("~images/jmu/jmu-logo.png"),
        url("~images/jmu/jmu-logo-white.png"),
        631px * 0.125,
        280px * 0.125
    );
}

.sessions.emiratesdb {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #e51937,
        // $colorBright: crimson dark
        lighten(#e51937, 15%),
        // $colorBrightLight: light crimson
        #83725d,
        // $colorCooler: dark beige
        lighten(#83725d, 15%),
        // $colorCoolerLight: light beige
        url("~images/emiratesdb/emiratesdb-logo.png"),
        url("~images/emiratesdb/emiratesdb-logo.png"),
        math.div(436px, 9),
        math.div(436px, 9)
    );
}

.sessions.math101a,
.sessions.math101b {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #e49a30,
        // $colorBright: orange
        lighten(#e49a30, 5%),
        // $colorBrightLight: light orange
        #001335,
        // $colorCooler: dark blue
        #053578,
        // $colorCoolerLight: light blue
        url("~vectors/emu/emu-logo-white-color.svg"),
        url("~vectors/emu/emu-logo-white-color.svg"),
        856px * 0.125,
        345px * 0.125
    );
}

.sessions.gwstatistics {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #0096d6,
        // $colorBright: orange
        lighten(#0096d6, 5%),
        // $colorBrightLight: light orange
        #004065,
        // $colorCooler: dark blue
        lighten(#004065, 10%),
        // $colorCoolerLight: light blue
        url("~images/gwu/gwu_logo.png"),
        url("~images/gwu/gwu_logo_white.png"),
        600px * 0.125,
        457px * 0.125
    );
}

.sessions.nyu {
    @include custom-sessions-page(
        lighten(#818790, 20%),
        // $colorNeutral: gray
        #8900e1,
        // $colorBright: purple
        #330662,
        // $colorBrightLight: purple hover
        #220337,
        // $colorCooler: dark purple
        #57068c,
        // $colorCoolerLight: nyu_purple
        url("~images/nyu/nyu_stern_logo_color.png"),
        url("~images/nyu/nyu_stern_logo_white.png"),
        638px * 0.25,
        112px * 0.25
    );

    .tab-content {
        border-radius: 4px;
        border: 0px solid transparent;
        overflow: hidden;
    }

    .nav-logo.custom-logo .inner-logo {
        margin-top: 5px !important;
    }
}

.sessions.iebusiness {
    @include custom-sessions-page(
        #b3b2bc,
        // $colorNeutral: gray
        #334d68,
        // $colorBright: orange
        darken(#334d68, 10%),
        // $colorBrightLight: darker orange
        #11111f,
        // $colorCooler: blue gray
        #1a1927,
        // $colorCoolerLight: dark blue gray
        url("~images/iebusiness/ie-logo.png"),
        url("~images/iebusiness/ie-logo-white.png"),
        math.div(571px, 7),
        math.div(239px, 7)
    );
}

.sessions.dubaipolice {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #ee1c25,
        // $colorBright: crimson dark
        lighten(#ee1c25, 15%),
        // $colorBrightLight: light crimson
        #505050,
        lighten(#505050, 15%),
        url("~images/dubaipolice/dubai_police_logo.png"),
        url("~images/dubaipolice/dubai_police_logo_white.png"),
        518px * 0.25,
        190px * 0.25
    );
}

.sessions.research-study17 {
    @include custom-sessions-page(
        #e2e2e2,
        // $colorNeutral: gray
        #e49a30,
        // $colorBright: orange
        lighten(#e49a30, 5%),
        // $colorBrightLight: light orange
        #001335,
        // $colorCooler: dark blue
        #053578,
        // $colorCoolerLight: light blue
        url("~vectors/emu/emu-logo-white-color.svg"),
        url("~vectors/emu/emu-logo-white-color.svg"),
        856px * 0.125,
        345px * 0.125
    );

    // special requirements for efficacy to hide all the nav elements, including logos
    nav {
        display: none;
    }
}

.sessions.intuit {
    @include custom-sessions-page(
        #f5f5f5,
        // $colorNeutral: gray
        #004f83,
        // $colorBright: dark blue
        darken(#004f83, 10%),
        // $colorBrightLight: darker blue
        #0077c5,
        // $colorCooler: blue
        #0077c5,
        // $colorCoolerLight: blue
        url("~images/intuit/intuit-logo.png"),
        url("~images/intuit/intuit-logo-white.png"),
        1016px * 0.125,
        499px * 0.125
    );
}

.sessions.inframark {
    @include custom-sessions-page(
        #f5f5f5,
        // $colorNeutral: gray
        #008fd5,
        // $colorBright: dark blue
        darken(#008fd5, 10%),
        // $colorBrightLight: darker blue
        #0d004c,
        // $colorCooler: blue
        #0d004c,
        // $colorCoolerLight: blue
        url("~images/inframark/inframark-logo.png"),
        url("~images/inframark/inframark-logo-white.png"),
        391px * 0.5,
        89px * 0.5
    );
}

.sessions.miyamiya {
    @include custom-sessions-page(
        #194695,
        // $colorNeutral: gray
        #e04f4f,
        // $colorBright: dark blue
        darken(#e04f4f, 10%),
        // $colorBrightLight: darker blue
        #00a572,
        // $colorCooler: blue
        #00a572,
        // $colorCoolerLight: blue
        url("~images/miyamiya/miyamiya-logo.png"),
        url("~images/miyamiya/miyamiya-logo-white.png"),
        716px * 0.25,
        226px * 0.25
    );

    .nav-logo:not(.custom-logo) {
        display: none;
    }

    .login-register-footer {
        .join-link,
        .sign-in-link {
            color: #fff;
            a {
                color: #00a572;
            }
        }
    }
}

.sessions.tjhs {
    @include custom-sessions-page(
        #faf6f0,
        // $colorNeutral: beige
        #cc0000,
        // $colorBright: red
        lighten(#cc0000, 5%),
        // $colorBrightLight: light red
        #000d42,
        // $colorCooler: dark blue
        #005396,
        // $colorCoolerLight: light blue
        url("~images/tjhs/tjhs-logo.png"),
        url("~images/tjhs/tjhs-logo.png"),
        math.div(524px, 7),
        math.div(470px, 7)
    );
}
