@import "oreo/global/colors_v3";

.aoi-record-card {
    display: flex;

    h4 {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    .actions-container {
        flex: 1 1 auto;
        padding: 15px;
    }

    .information-container {
        background-color: rgba($COLOR_V3_BEIGE, 0.3);
        border-left: 1px solid $COLOR_V3_BEIGE;

        .information-table {
            border-radius: 4px;
            flex: 0 0 auto;
            width: 350px;

            .information-panel-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;

                &:not(:last-child) {
                    border-bottom: 1px solid $COLOR_V3_BEIGE;
                }

                &:nth-child(odd):not(:only-child) {
                    background-color: rgba(255, 255, 255, 0.8);
                }

                .row-key {
                    text-transform: capitalize;
                    font-weight: bold;
                }

                .row-val {
                    margin-left: 20px;
                    pre {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .clear {
        clear: both;
    }

    .action-select {
        min-width: 250px;
    }

    .action-form {
        margin: 8px 0;
        border: 2px solid $COLOR_V3_BEIGE;
        border-radius: 4px;
        background-color: rgba($COLOR_V3_BEIGE, 0.35);
        padding: 13px;
        color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        min-width: 350px;

        .title {
            color: $COLOR_V3_BLACK;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .description {
            margin-bottom: 10px;

            p {
                margin-bottom: 0px;
            }
        }

        .form-fields {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}
