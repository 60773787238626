cf-text {
    &.error {
        color: $COLOR_V3_RED;
    }

    &.left {
        text-align: left;

        .mjx-block {
            text-align: left;
        }
    }

    &.right {
        text-align: right;

        .mjx-block {
            text-align: right;
        }
    }

    &.center {
        text-align: center;

        // for mathjax centering to work properly...
        .mjx-block {
            text-align: center;
        }
    }

    // Defined here since we override it in a few places below
    $externalLinkColor: $COLOR_V3_CORAL;
    $externalLinkColorHover: $COLOR_V3_CORAL_DARK;
    @mixin external-link-base-color($linkColor) {
        color: $linkColor;
        border-bottom: 2px dotted $linkColor;
        text-shadow: none;
        &:after {
            color: $linkColor;
        }
    }

    @mixin external-link-active-hover-color($linkColor) {
        .no-touchevents &:hover,
        .no-touchevents &:active,
        .touchevents &:active {
            color: $linkColor;
            border-bottom: 2px dotted $linkColor;
            &:after {
                color: $linkColor;
            }
        }
    }

    a.external-link {
        position: relative;
        text-decoration: none;
        word-wrap: break-word;

        padding-left: 0.1rem;
        margin-left: 1rem;

        &:hover {
            text-decoration: none;
        }

        // Default colors
        @include external-link-base-color($externalLinkColor);
        @include external-link-active-hover-color($externalLinkColorHover);

        &:after {
            @include font-awesome-icon("\f14c"); // fa-external-link-square // ('\f08e'); // fa-external-link
            display: inline-block;
            position: absolute;
            width: 0px;
            overflow: visible;
            left: -1rem;
            top: 2px;
            line-height: 1;
            font-size: 0.9em;
        }

        // rtl-content overrides
        [dir="rtl"] & {
            padding-left: 0px;
            margin-left: 0px;

            padding-right: 0.1rem;
            margin-right: 1rem;

            &:after {
                left: auto;
                right: -1rem;
            }
        }
    }

    // make external link white on dark backgrounds
    .popover-content &,
    .cf_answer_list[renderer="poll"] button.challenge-complete &,
    .slide-message-desktop &,
    .slide-message &,
    body.bg-turquoise &,
    body.bg-blue &,
    body.bg-purple &,
    .lesson_popover[aria-describedby] {
        a.external-link {
            @include external-link-base-color($COLOR_V3_WHITE);
            @include external-link-active-hover-color($COLOR_V3_CORAL_DARK);
        }
    }

    // VALAR

    // make normal external links blue
    body.bg-white.valar & {
        a.external-link {
            @include external-link-base-color($COLOR_V3_BLUE);
            @include external-link-active-hover-color($COLOR_V3_BLUE_DARK !important);
        }
    }

    // make modal and answer message external links white due to various colored backgrounds
    body.bg-white.valar .popover-content &,
    body.bg-white.valar .slide-message &,
    body.bg-white.valar .slide-message-desktop & {
        a.external-link {
            @include external-link-base-color($COLOR_V3_WHITE);
            @include external-link-active-hover-color($COLOR_V3_BLUE_DARK !important);
        }
    }

    // in the editor popover message preview, make the links visible on white bg
    body.bg-white.valar .editor-popover-message & {
        a.external-link {
            @include external-link-base-color($COLOR_V3_BLUE);
            @include external-link-active-hover-color($COLOR_V3_BLUE_DARK !important);
        }
    }

    // in the editor popover message preview, make the links visible on white bg
    .editor-popover-message & {
        a.external-link {
            @include external-link-base-color($COLOR_V3_CORAL);
        }
    }

    .popover-content & a.external-link,
    .slide-message-desktop & a.external-link,
    .slide-message & a.external-link {
        @include external-link-active-hover-color($COLOR_V3_CORAL_DARK !important);
    }

    // user a lighter hover color because the dark coral has
    // a low contrast with certain backgrounds
    .bg-turquoise & a.external-link {
        @include external-link-active-hover-color($COLOR_V3_TURQUOISE_LIGHT);
    }

    .bg-blue & a.external-link {
        @include external-link-active-hover-color($COLOR_V3_BLUE_LIGHT);
    }

    .bg-purple & a.external-link {
        @include external-link-active-hover-color(lighten($COLOR_V3_PURPLE, 25%));
    }

    .cf-tile-prompt & a.external-link {
        @include external-link-base-color($externalLinkColor);
        @include external-link-active-hover-color($externalLinkColorHover);
    }

    // See also text_image_interactive.scss, where we duplicate
    // this style in a way that will only apply to older content

    /*
        There are different kinds of images embedded in text components.

        1. inline images, created with `![image-label]`.  These
            have the `inline` class and show up small inside
            a line of text
        2. display images, created with `!![image-label]. These
            have the `display` class and show up large.
            - The height can be controlled with `!![image-label]{height:42}
            - display images can also have other classes, created
                with `!![image-label]{left|right}`
    */

    // inline-images should fill the line-height
    img.inline,
    .popover-content & img.inline {
        height: $line-height-base * 1em;
        max-width: $MAIN_CONTENT_MIN_WIDTH;
    }

    // img:not([class]) refers to inline images that were added before
    // https://trello.com/c/jb0NQLmf.  Those should behave like display
    // images.  See #5 above
    img.display {
        display: block;
        max-height: $MAIN_IMAGE_HEIGHT;
        margin-right: auto;
        margin-left: auto;

        .frame .popover .popover-content & {
            // See https://trello.com/c/F0GtLFDz.
            // We need to keep images small so that modals fit on
            // the screen.
            max-height: 33vh;
            margin-top: 10px;
            margin-bottom: 10px;

            &.short {
                max-height: 20vh;
            }
        }

        &.left {
            margin-left: 0px;
            margin-right: auto;
        }

        &.right {
            margin-right: 0px;
            margin-left: auto;
        }

        &.short {
            .frame-container.gte-sm & {
                max-height: $MAIN_IMAGE_SHORT_HEIGHT;
            }
            .frame-container.lte-xs & {
                max-height: $MAIN_IMAGE_SHORT_HEIGHT_SMALL_MIN;
            }
        }
    }
}
