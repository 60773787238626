submit-application-form {
    .preview-row {
        @media (min-width: $screen-sm-min) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .sub-text {
            display: block;
            text-align: center;
            font-size: 16px;
            line-height: 22px;

            @media (min-width: $screen-sm-min) {
                margin: 0 auto;
                max-width: 475px;
            }
        }
    }

    .main-message {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 16px;
        line-height: 22px;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;

        &.text-disabled {
            opacity: 0.5;
        }
    }

    .not-complete-message {
        text-align: center;
        margin-bottom: 30px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        line-height: 20px;

        span {
            color: $COLOR_V3_CORAL;
            font-size: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    .submit-application {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .form-error.active {
        font-size: 14px;
        line-height: 2.5;
        color: $COLOR_V3_CORAL;
    }

    .early-decision-select {
        display: inline-block;
        float: right;
        width: 100px !important;

        @media (max-width: $screen-xs-max) {
            display: block;
            margin: $SMARTLY_FORM_MARGIN_DEFAULT auto 0;
        }
    }

    .incomplete-steps {
        text-align: center;

        ul {
            display: inline-block;
            text-align: left;

            html[dir="rtl"] & {
                text-align: right;
            }
        }

        li {
            color: $COLOR_V3_BLUE;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .valar-logo {
        background-image: url("~vectors/logos/valar-logo-wordmark-light-bg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 20px;
        margin-bottom: 20px;
    }

    .early-admission-opt-in-row {
        .early-admission-heading {
            font-size: 1.5em;
            font-weight: $FONT_WEIGHT_SEMIBOLD;

            &.required::after {
                margin-left: 0px;
                [dir="rtl"] & {
                    margin-right: 0px;
                }
            }
        }

        .checkbox-group-label-wrapper {
            margin-bottom: 10px;

            .control-title {
                margin: 0;
            }
        }

        .radio-label {
            margin-bottom: 5px;

            input[type="radio"] {
                margin-top: 7px;
            }

            .option-text {
                font-size: 18px;
                margin-bottom: 5px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }

            .description {
                font-weight: $FONT_WEIGHT_NORMAL;
                font-size: 16px;
            }
        }
    }
}
