.contact-form {
    margin: -22px -30px 0;

    header {
        text-align: center;
        padding: 0 20px;

        careers-avatar {
            width: 75px;
            height: 75px;
            margin: -82px auto 15px;
            border-radius: 50%;
            border: 3px solid $COLOR_V3_WHITE;
            box-shadow: 0 8px 11px rgba(black, 0.5);
        }

        h2 {
            color: $COLOR_V3_BLACK;
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    .front-royal-form-container {
        label {
            font-size: 14px;

            @media (min-width: 400px) {
                font-size: 16px;
            }
        }

        .form-group.row {
            margin: 5px 0;
            padding: 0 20px;

            &:last-child {
                margin-top: 20px;
            }
        }

        .selectize-control:not(.wide) {
            width: 100%;
        }

        .selectize-input .item {
            font-size: 16px;
            color: $COLOR_V3_BLACK;
        }

        input[readonly] {
            background: rgba($COLOR_V3_YELLOW, 0.15);
            color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        }

        input.ng-invalid {
            border-color: $COLOR_V3_RED;
        }
    }

    .message {
        min-height: 174px;
    }

    .sent-message {
        text-align: center;

        img {
            margin-top: 35px;
        }

        div {
            margin-top: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 20px;
            color: $COLOR_V3_BLUE;
        }
    }

    .salary-select {
        clear: both;
    }
}

.col-no-pad {
    padding-left: 0;
    padding-right: 0;
}

.contact-form {
    margin: -22px -50px -15px;

    @media (min-width: 400px) {
        margin: -22px -30px 0;
    }

    .message {
        min-height: 114px;

        @media (min-width: 400px) {
            min-height: 194px;
        }
    }
}
