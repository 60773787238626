//-----------------------------------
// Default classes for text content
//-----------------------------------

// Smartly Palette

.coral {
    color: $COLOR_V3_CORAL;
}
.purple {
    color: $COLOR_V3_PURPLE;
}
.plum {
    color: $COLOR_V3_PLUM;
}
.yellow {
    color: $COLOR_V3_YELLOW;
}
.blue {
    color: $COLOR_V3_BLUE;
}
.turquoise {
    color: $COLOR_V3_TURQUOISE;
}
.green {
    color: $COLOR_V3_GREEN;
}
.pink {
    color: $COLOR_V3_CORAL;
} // Backwards compatibility for existing content; same as coral
.grey {
    color: $COLOR_V3_BEIGE_DARKEST;
}
.orange {
    color: $COLOR_V3_ORANGE;
}
.red {
    color: $COLOR_V3_RED;
}
.eggplant {
    color: $COLOR_V3_EGGPLANT;
}

.darkturquoise {
    color: $COLOR_V3_TURQUOISE_DARK;
}
.darkpurple {
    color: $COLOR_V3_PURPLE_DARK;
}
.darkyellow {
    color: $COLOR_V3_YELLOW_DARK;
}
.darkblue {
    color: $COLOR_V3_BLUE_DARK;
}
.darkgreen {
    color: $COLOR_V3_GREEN_DARK;
}
.darkcoral {
    color: $COLOR_V3_CORAL_DARK;
}
.darkorange {
    color: $COLOR_V3_ORANGE_DARK;
}
.darkred {
    color: $COLOR_V3_RED_DARK;
}

// Standard Palette

.white {
    color: $COLOR_V3_WHITE;
}

// Background Palette

.background-beige {
    background-color: $COLOR_V3_BEIGE_LIGHTER;
}
