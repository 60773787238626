/*

In order to create a page-with-left-nav-and-main-box, you need

1. An outer element with the class 'page-with-left-nav-and-main-box row' and
    a dynamic 'expanded' class. (Yes, the row is necessary)
1. An element with the .responsive-nav class to go inside of .page-with-left-nav-and-main-box
1. An element with 'mobile-nav-toggle-btn' to go inside of .responsive-nav. This should
    have the text navigation.section_navigation.header
1. Some number of items with 'nav-item nav-1-item' classes and dynamic expanded class
1. Some number of items with 'nav-item nav-2-item' classes and dynamic selected class
1. A .main-box div for your main content
1. If you want a dashboard-footer on the page, put it INSIDE of page-with-left-nav-and-main-box

page-with-left-nav-and-main-box is just styling.  It does not help you define the
logic behind setting the expanded and selected classes, but following the example
below it should be pretty straightforward

<div class="page-with-left-nav-and-main-box"
    ng-class="{'expanded': proxy.mobileNavExpanded}">

    <div class="mobile-nav-toggle-btn"
        ng-click="proxy.mobileNavExpanded = !proxy.mobileNavExpanded">
        <span class="section-navigation-span" translate-once="navigation.section_navigation.header"></span>
    </div>

    <div class="responsive-nav">

        <div class="nav-item nav-1-item" name="primaryitem1" ng-class="{expanded: amIExpanded('primaryitem1')"></div>
        <div class="nav-item nav-2-item" name="secondaryItem1" ng-class="{selected: amISelected('secondaryItem1')}"></div>
        <div class="nav-item nav-2-item" name="secondaryItem2" ng-class="{selected: amISelected('secondaryItem2')}"></div>
        <div class="nav-item nav-1-item" name="primaryitem2" ng-class="{expanded: amIExpanded('primaryitem2')"></div>
    </div>

    <div class="main-box">
        ...Content
    </div>
</div>

*/

// the threshold for moving the navbar to the top is
// screen-md-min, which is inconsistent with most
// places in smartly.  The more common thing is to
// changes layouts between xs and sm.  We think this
// looks better here though.
// NOTE: scroll_helper.js hardcodes this position threshold,
// so if something changes here, check out the container() method
// over there.

// screenshots

:root {
    --mobile-nav-toggle-btn-height: 40px;
}

$navPositionThreshold: $screen-md-min;
$desktopNavWidth: $MAIN_BOX_WITH_LEFT_NAV_DESKTOP_NAV_WIDTH; // see _sizes.scss

.page-with-left-nav-and-main-box.row {
    position: relative;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;

    // when showing the sidebar, we need to set min-height
    // to 100% so that the navbar stretches all the way down the screen
    @media (min-width: $navPositionThreshold) {
        min-height: 100%;
    }

    @media (max-width: $screen-sm-max) {
        margin: 0;
    }

    // this is the box with the grey border and the white
    // background that wraps the single form on each page
    .main-box,
    .main-box-footer {
        position: relative;

        // iOS has issues with fixed elements inside of scrolling containers which was causing the mobile-nav-toggle-btn to jitter.
        // To fix the issue I made the main-box also be fixed, like the responsive-nav. This feels hacky but it prevents us from
        // having to refactor the section-navigation logic.
        @media (max-width: calc($navPositionThreshold - 1px)) {
            position: fixed;
            overflow-x: hidden;
            overflow-y: auto;
            left: 0;
            right: 0;

            &.inactive {
                overflow-y: hidden;
            }
        }
        @media (max-width: calc($navPositionThreshold - 1px)) and (max-width: $screen-xs-max) {
            bottom: $APP_MENU_MOBILE_HEIGHT;
            top: calc($APP_HEADER_HEIGHT_MOBILE + var(--mobile-nav-toggle-btn-height));

            // when app header is disabled...
            .header-invisible & {
                top: var(--mobile-nav-toggle-btn-height);
            }

            .hide-mobile-menu & {
                // Mobile app menu is not rendered when mobile keyboard is showing
                bottom: 0;
            }
        }
        @media (max-width: calc($navPositionThreshold - 1px)) and (min-width: $screen-sm-min) {
            bottom: 0;
            top: calc($APP_HEADER_HEIGHT + var(--mobile-nav-toggle-btn-height));
        }

        @media (min-width: calc($navPositionThreshold - 1px)) {
            // when the left nav is on the screen, the strategy of
            // making this fixed does not work.  So we'll try a
            // different approach
            front-royal-spinner.fixed {
                position: absolute;
                top: 200px;
            }
        }

        background-color: $COLOR_V3_WHITE;
        z-index: 1;
        border-radius: 4px;
        padding-bottom: $MAIN_BOX_PADDING_BOTTOM_MOBILE;

        .showing-helpscout-beacon & {
            // when showing the helpscout beacon button, we need to be able
            // to scroll down farther so we can see the save button
            padding-bottom: $MAIN_BOX_PADDING_BOTTOM_MOBILE + 60px;
        }

        @media (min-width: $screen-sm-min) {
            // we gave it a bottom margin in order to
            // make room for the app-menu at the bottom of
            // the page.  That is only there on -xs screens,
            // so we can remove it here.
            margin-bottom: 0px;
            padding-bottom: $MAIN_BOX_PADDING_BOTTOM_DESKTOP;

            .showing-helpscout-beacon & {
                // when showing the helpscout beacon button, we need to be able
                // to scroll down farther so we can see the save button
                padding-bottom: $MAIN_BOX_PADDING_BOTTOM_DESKTOP + 100px;
            }
        }

        $mainBoxSmallDesktopMargin: 40px;
        $mainBoxMaxWidth: $MAIN_BOX_WITH_LEFT_NAV_DESKTOP_BOX_WIDTH; // see _sizes.scss
        $centerMainBoxAtWidth: $desktopNavWidth + 2 * $mainBoxSmallDesktopMargin + $mainBoxMaxWidth;

        // On smaller desktops, the main-box takes up all of
        // the space to the right of the navbar.
        @media (min-width: $navPositionThreshold) {
            margin-top: $mainBoxSmallDesktopMargin;
            margin-left: $desktopNavWidth + $mainBoxSmallDesktopMargin;
            margin-right: $mainBoxSmallDesktopMargin;
            margin-bottom: $MAIN_BOX_MARGIN_BOTTOM_DESKTOP;

            // admin_users_dir.js no longer has a nav, so just center the main-box
            &.admin-users {
                margin-left: calc($mainBoxSmallDesktopMargin + ($desktopNavWidth / 2));
                margin-right: calc($mainBoxSmallDesktopMargin + ($desktopNavWidth / 2));
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: $desktopNavWidth + $mainBoxSmallDesktopMargin;
                margin-left: $mainBoxSmallDesktopMargin;
            }

            .showing-helpscout-beacon & {
                // when showing the helpscout beacon button, we need to be able
                // to scroll down farther so we can see the save button. (In this case
                // we reset the padding to it's normal setting and add more margin)/
                margin-bottom: $MAIN_BOX_MARGIN_BOTTOM_DESKTOP + 100px;
                padding-bottom: $MAIN_BOX_PADDING_BOTTOM_DESKTOP;
            }
        }

        // Once the screen is large enough that the main box can be 800px,
        // We want to float it in the middle of the space that is to the
        // right of the navbar on the left.
        @media (min-width: $centerMainBoxAtWidth) {
            &:not(.wide) {
                margin-top: $mainBoxSmallDesktopMargin;
                // margin left is the width of the navbar plus half of the space
                // that is available beyond the 800px width of the main-box
                margin-left: calc(#{$desktopNavWidth} + (100% - #{$desktopNavWidth} - #{$mainBoxMaxWidth}) / 2);
                margin-right: auto;
                width: $mainBoxMaxWidth;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-right: calc(#{$desktopNavWidth} + (100% - #{$desktopNavWidth} - #{$mainBoxMaxWidth}) / 2);
                    margin-left: auto;
                }
            }
        }
    }

    .main-box {
        .main-box-header {
            text-align: center;
            padding: 20px 15px;
            margin-bottom: 15px;
            font-weight: $FONT_WEIGHT_NORMAL;
            color: $COLOR_V3_BLACK;
            font-size: 20px;
            border-bottom: $SMARTLY_FORM_BORDER_STYLE;

            &.no-margin {
                margin-bottom: 0px;
            }

            // if there are two spans inside, then the second is a subtitle
            span:nth-child(2) {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-size: 14px;
                display: block;
                margin-top: 10px;

                &.student_network_settings {
                    max-width: 580px;
                    margin: 10px auto 0;
                }

                &.network {
                    max-width: 450px;
                    margin: 10px auto 0;
                }

                @media (min-width: $screen-sm-min) {
                    font-size: 16px;
                }
            }

            @media (min-width: $screen-sm-min) {
                padding: 26px;
                font-size: 26px;
            }
        }
    }

    .main-box.no-bg,
    .main-box-footer {
        background-color: transparent;
        margin-bottom: 0px; // prevent unnecessary scroll
    }

    .main-box-footer {
        // we currently only intend on displaying this content on >sm screen sizes
        // explicitly hiding it on others, due to how our navigational layout works
        top: -16px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;

        @media (max-width: calc($navPositionThreshold - 1px)) {
            display: none;
        }
        @media (max-width: calc($navPositionThreshold - 1px)) and (max-width: $screen-xs-max) {
            display: none;
        }
        @media (max-width: calc($navPositionThreshold - 1px)) and (min-width: $screen-sm-min) {
            display: none;
        }
    }
}

.page-with-left-nav-and-main-box.row,
.filter-box-container {
    .responsive-nav {
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 9998;
        transform: translateZ(0); // WebKit hack - http://stackoverflow.com/a/18764086/1747491

        // rtl-language overrides
        html[dir="rtl"] & {
            right: 0px;
            left: auto;
        }

        // see .expanded section below for the height calculations!

        background-color: $COLOR_V3_WHITE;

        .nav-item {
            display: none;
        }

        @media (min-width: $navPositionThreshold) {
            height: 100%;
            width: $desktopNavWidth;
            border-right: $SMARTLY_FORM_BORDER_STYLE;

            .nav-item {
                display: block;
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                border-left: $SMARTLY_FORM_BORDER_STYLE;
                border-right: none;
            }
        }

        // primary and secondary are the same size on mobile but
        // different on desktop
        $navItem1MobileFontSize: 14px;
        $navItem2MobileFontSize: 14px;
        $navItem1DesktopFontSize: 18px;
        $navItem2DesktopFontSize: 12px;

        // primary and secondary are the same size on mobile but
        // different on desktop
        $navItem1MobileHeight: 50px;
        $navItem2MobileHeight: 50px;
        $navItem1DesktopHeight: 60px;
        $navItem2DesktopHeight: 30px;

        .nav-item {
            font-weight: $FONT_WEIGHT_NORMAL;
            background-color: $COLOR_V3_WHITE;
            text-align: left;
            border-top: $SMARTLY_FORM_BORDER_STYLE;
            border-bottom: $SMARTLY_FORM_BORDER_STYLE;
            cursor: pointer;
            position: relative;
            margin-top: -1px; // prevent doubly-thick borders by overlapping subsequent elements

            // rtl-language overrides
            html[dir="rtl"] & {
                text-align: right;
            }

            &:hover:not(.selected):not(.active):not(.no-hover):not(.disabled) {
                background-color: $COLOR_V3_BEIGE;
            }

            &.disabled {
                cursor: inherit;
                opacity: 0.3;
            }

            &.active {
                background-color: $COLOR_V3_SIDE_NAV_ACTIVE;
                color: $COLOR_V3_WHITE;
                font-weight: $FONT_WEIGHT_MEDIUM;
            }
        }

        .nav-1-item {
            font-size: $navItem1MobileFontSize;
            padding-left: 22px;
            color: $COLOR_V3_GREY_DARKER;
            height: $navItem1MobileHeight;
            line-height: $navItem1MobileHeight;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 0px;
                padding-right: 22px;
            }

            @media (min-width: $navPositionThreshold) {
                font-size: $navItem1DesktopFontSize;
                height: $navItem1DesktopHeight;
                line-height: $navItem1DesktopHeight;
            }

            &,
            &:hover,
            &:active {
                background-image: url("~vectors/arrow_right_black_thin.svg");
                background-size: 7px 13px;
                background-position: right 20px top 50%;
                background-repeat: no-repeat;

                // rtl-language overrides
                html[dir="rtl"] & {
                    background-image: url("~vectors/arrow_left_black_thin.svg");
                    background-position: left 20px top 50%;
                }
            }

            &.active {
                background-image: url("~vectors/arrow_right_white_thin.svg");

                // rtl-language overrides
                html[dir="rtl"] & {
                    background-image: url("~vectors/arrow_left_white_thin.svg");
                }
            }

            &.expanded,
            &.expanded:hover,
            &.expanded:active {
                background-image: url("~vectors/arrow_down_white_thin.svg");
                background-size: 13px 7px;
                background-position: right 15px top 50%;

                // rtl-language overrides
                html[dir="rtl"] & {
                    background-image: url("~vectors/arrow_down_white_thin.svg");
                    background-position: left 15px top 50%;
                }
            }
        }

        // On mobile, nav-2 items have a fixed height.
        // On desktop, they do not have fixed height, but instead
        // have padding.  This becomes important on dealing with
        // a nav-item that has two lines of text inside.
        .nav-2-item {
            $paddingLeft: 32px;
            padding-left: $paddingLeft;
            font-size: $navItem2MobileFontSize;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            background-color: rgba($COLOR_V3_BEIGE_LIGHTER, 0.41);
            height: $navItem2MobileHeight;
            line-height: $navItem2MobileHeight;
            position: relative;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: $paddingLeft;
                padding-left: 0px;
            }

            @media (min-width: $navPositionThreshold) {
                font-size: $navItem2DesktopFontSize;
                height: auto;
                line-height: $navItem2DesktopFontSize + 2px;
                padding-top: 8px;
                padding-bottom: 8px;
                border: 0px;
            }

            .valign {
                @media (max-width: calc($navPositionThreshold - 1px)) {
                    line-height: $navItem2MobileFontSize + 2px;
                    position: absolute;
                    left: $paddingLeft;
                    right: $paddingLeft; // So that the element will stretch as wide as you expect
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            // The selected nav item gets a bit darker.  If you
            // have something else that you want to get the same styling
            // as selected items do, use <strong>.  See the progress
            // bar in edit-career-profile for an example
            &.selected,
            strong {
                color: $COLOR_V3_GREY_DARKER;
                font-weight: $FONT_WEIGHT_SEMIBOLD;

                @media (min-width: $navPositionThreshold) {
                    padding-top: 9px;
                    padding-bottom: 7px;
                }
            }

            &.incomplete:after {
                position: absolute;
                display: block;
                left: $paddingLeft - 12px;
                top: calc(50% - 1px);
                transform: translateY(-50%) translateX(-50%);
                color: $COLOR_V3_BLUE;
                content: "•";
                font-size: $navItem2MobileFontSize + 10px;

                @media (min-width: $navPositionThreshold) {
                    top: calc(50% - 2px); // on desktop, need to adjust for the font baseline making it slightly higher
                    font-size: $navItem2DesktopFontSize + 10px;
                }
            }

            &.complete:after {
                @include font-awesome-icon("\f00c"); // fa-check
                position: absolute;
                display: block;
                left: $paddingLeft - 12px;
                top: calc(50% - 1px);
                transform: translateY(-50%) translateX(-50%);
                color: $COLOR_V3_BEIGE_MIDDARK;
                font-size: 10px;
            }
        }

        .notification {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            background: $COLOR_V3_BLUE;
            color: $COLOR_V3_WHITE;
            font-weight: $FONT_WEIGHT_BOLD;
            font-size: 10px;
            min-width: 17px;
            line-height: 14px;
            border: 1px solid $COLOR_V3_WHITE;
            border-radius: 4px;
            text-align: center;
            padding: 1px 3px 0;
        }
    }

    dashboard-footer {
        .footer {
            @media (min-width: $screen-md-min) {
                padding-left: $desktopNavWidth + 60px;
                padding-right: 50px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: $desktopNavWidth + 60px;
                    padding-left: 50px;
                }
            }
            .dashboard-container {
                @media (min-width: $screen-md-min) {
                    width: 100%;
                }
            }
        }
    }
}

.mobile-nav-toggle-btn {
    $horPadding: 15px;

    position: fixed;
    width: 100%;
    left: 0px;
    top: 40px;
    z-index: 9999;
    height: var(--mobile-nav-toggle-btn-height);
    background-color: $COLOR_V3_SIDE_NAV_ACTIVE;
    border-bottom: 1px solid $COLOR_V3_GREY_DARK;
    cursor: pointer;

    color: $COLOR_V3_WHITE;
    text-align: left;
    font-weight: $FONT_WEIGHT_MEDIUM;
    font-size: 14px;
    line-height: var(--mobile-nav-toggle-btn-height);

    &.disabled {
        color: rgba($COLOR_V3_WHITE, 0.5);
    }

    // when app header is disabled...
    .header-invisible & {
        top: 0px;
    }

    @media (min-width: $screen-sm-min) {
        top: $APP_HEADER_HEIGHT;
    }

    // hide section nav past nav position threshold
    @media (min-width: $navPositionThreshold) {
        display: none;
    }

    // Note: this changes below when expanded
    .navigation-header-span {
        display: inline-block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px $horPadding;
        max-width: calc(100% - #{2 * $horPadding});
    }

    &:after {
        position: absolute;
        right: $horPadding;
        top: 0px;
        content: url("~vectors/arrow_down_white_thin.svg");
        margin-left: $SMARTLY_FORM_MARGIN_DEFAULT;
        transition: transform 0.2s linear;

        // rtl-language overrides
        html[dir="rtl"] & {
            margin-right: $SMARTLY_FORM_MARGIN_DEFAULT;
            margin-left: 0px;
        }
    }
}

// height and padding-top calculations for responsive-nav
.responsive-nav {
    padding-top: 80px; // mobile app header
    height: 80px; // mobile app header + section nav

    // when app header is disabled...
    .header-invisible & {
        padding-top: 40px;
        height: 40px;
    }

    @media (min-width: $screen-sm-min) {
        padding-top: $APP_HEADER_HEIGHT + 40px;
        height: $APP_HEADER_HEIGHT + 40px; // app header + section nav
    }

    @media (min-width: $navPositionThreshold) {
        padding-top: $APP_HEADER_HEIGHT; // no visible section nav
        height: 100%;
    }

    .beta-flag {
        background: repeating-linear-gradient(
            135deg,
            $COLOR_V3_BLUE 0,
            $COLOR_V3_BLUE 10px,
            $COLOR_V3_BLUE_CAREERS_LIGHTER 11px,
            $COLOR_V3_BLUE_CAREERS_LIGHTER 20px
        );
        color: $COLOR_V3_WHITE;
        text-align: center;
        font-size: 12px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        text-transform: uppercase;
        line-height: 30px;
        letter-spacing: 3px;
        transform: translateY(-1px);
    }

    .footer {
        width: 100%;
        @media (min-width: $screen-sm-min) {
            display: none;
        }
    }

    &:not(.is-scrolling) .footer {
        position: absolute;
        bottom: 20px;
    }

    &.is-scrolling .footer {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

// this is used in the student network UI
.page-with-left-nav-and-main-box.row,
.filter-box-container {
    &.expanded .responsive-nav {
        height: calc(100% - #{$APP_MENU_MOBILE_HEIGHT}); // leave room for mobile menu at bottom of screen
        .hide-mobile-menu & {
            height: 100%;
        }

        @media (min-width: $screen-sm-min) {
            height: 100%;
        }

        @media (min-width: $navPositionThreshold) {
            height: 100%;
        }
    }

    &.expanded {
        // expanded is only relevant in mobile, so we use a max-width
        // selector here in order to define these things
        @media (max-width: calc($navPositionThreshold - 1px)) {
            .mobile-nav-toggle-btn {
                &:after {
                    transform: rotate(180deg);
                }
            }

            .main-box {
                display: none;
            }

            .responsive-nav .nav-item {
                display: block;
            }

            // If there is a footer, it looks odd to have it there under the mobile nav,
            // especially if the mobile nav is short. It might be better to have a white
            // background underneath the nav, but in order to implement that we need
            // to add height: 100% all the way up the hierarchy, or switch to fixed position,
            // which is tricky because this nav can exist in ipad-size or mobile size, which
            // means it needs to work with different header and footer styles.
            dashboard-footer {
                display: none;
            }
        }
    }
}
