@use "sass:math";

$CAREERS_CARD_ONE_PAGE_LAYOUT_WIDTH: 300px;
$CAREERS_CARD_THREE_PAGE_LAYOUT_WIDTH: 747px;

$CAREERS_CARD_AVATAR_SIZE: 50px;
$CAREERS_CARD_AVATAR_DESKTOP_SIZE: 105px;
$CAREERS_CARD_AVATAR_HOR_OFFSET: 10px;

$CAREERS_CARD_USER_SECTION_HEIGHT: 74px;

$cardHeight: 420px;
$threePageCardHeight: 330px;
$CANDIDATE_CARD_AVATAR_OVERFLOW_ADJUSTMENT: ($CAREERS_CARD_AVATAR_DESKTOP_SIZE - $CAREERS_CARD_USER_SECTION_HEIGHT) *
    0.5;
$CAREERS_CARD_ONE_PAGE_LAYOUT_HEIGHT: $cardHeight;
$CAREERS_CARD_THREE_PAGE_LAYOUT_HEIGHT: $threePageCardHeight;

$CAREERS_USER_SUBTITLE_HEIGHT: 46px;

$CAREERS_TOP_SECTION_HEIGHT: 147px;
$CAREERS_TOP_SECTION_HEIGHT_DESKTOP: 127px;

// FIXME: remove these when we fix the subtitle (fun fact, etc.)
$CAREERS_PAGE1_AVATAR_HOR_OFFSET: 10px;
$CAREERS_PAGE1_AVATAR_SIZE: 71px;
$CAREERS_PAGE2_AVATAR_HOR_OFFSET: 10px;
$CAREERS_PAGE2_AVATAR_SIZE: 50px;
// END FIXME

$CAREERS_TRANSITION_STYLE: 0.25s ease-out;

$CAREERS_CARD_BORDER_RADIUS: 4px;
$CAREERS_PAGE_1_LEFT_COL_DIV_PADDING: 8px;

$CAREERS_COL_HEADER_HEIGHT: 24px;

$CAREERS_CARD_TWO_COLUMN_LEFT_COL_WIDTH: 180px;
$CAREERS_CARD_TWO_COLUMN_RIGHT_COL_WIDTH: $CAREERS_CARD_ONE_PAGE_LAYOUT_WIDTH - $CAREERS_CARD_TWO_COLUMN_LEFT_COL_WIDTH;

$CAREERS_CARD_THREE_PAGE_LAYOUT_LEFT_PAGE_WIDTH: math.div($CAREERS_CARD_THREE_PAGE_LAYOUT_WIDTH, 3);
$CAREERS_CARD_THREE_PAGE_LAYOUT_CENTER_PAGE_WIDTH: math.div($CAREERS_CARD_THREE_PAGE_LAYOUT_WIDTH, 3);
$CAREERS_CARD_THREE_PAGE_LAYOUT_RIGHT_PAGE_WIDTH: math.div($CAREERS_CARD_THREE_PAGE_LAYOUT_WIDTH, 3);

$CAREERS_CANDIDATE_CARD_ONE_PAGE_LAYOUT_FOOTER_HEIGHT: 31px;

// the mocks show the top of the main content
// 110px from the top of the page, but the
// blue user subtitle is also part of the page, in
// order to support animations, so we subtract
// its height.
$CAREERS_CARD_PAGE_TOP: 108px - $CAREERS_USER_SUBTITLE_HEIGHT;
$CAREERS_CARD_PAGE_HEIGHT: $CAREERS_CARD_ONE_PAGE_LAYOUT_HEIGHT - $CAREERS_CARD_PAGE_TOP -
    $CAREERS_CANDIDATE_CARD_ONE_PAGE_LAYOUT_FOOTER_HEIGHT;
$CAREERS_CARD_PAGE_TWO_COL_HEIGHT: $CAREERS_CARD_PAGE_HEIGHT - $CAREERS_USER_SUBTITLE_HEIGHT;

$CAREERS_CARD_VIEW_ALL_OVERLAY_Z_INDEX: 9;
$CAREERS_CARD_VIEW_ALL_OVERLAY_Z_INDEX_DESKTOP: 4; // on desktop, it must sit beneath the avatar

$CAREERS_CONNECTED_COLOR: $COLOR_V3_GREY_NOT_SO_LIGHT;

$CAREERS_LIKED_COLOR: $COLOR_V3_TURQUOISE;
$CAREERS_LIKED_COLOR_HOVER: $COLOR_V3_TURQUOISE_HOVER;

$CAREERS_PASSED_COLOR: $COLOR_V3_BEIGE_DARKER;
$CAREERS_INVITED_COLOR: $COLOR_V3_BLUE;

$CAREERS_SAVED_COLOR: $COLOR_V3_PURPLE;
$CAREERS_SAVED_COLOR_HOVER: $COLOR_V3_PURPLE_HOVER;

// Candidate list in browse
$CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_WIDTH: 900px;
$CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_EXTRA_WIDTH: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_WIDTH -
    $MAIN_BOX_WITH_LEFT_NAV_DESKTOP_BOX_WIDTH;
$CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD: $MAIN_BOX_WITH_LEFT_NAV_DESKTOP_NAV_WIDTH +
    $MAIN_BOX_WITH_LEFT_NAV_DESKTOP_BOX_WIDTH + 2 * $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_EXTRA_WIDTH + 50px;
