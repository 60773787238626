student-network-recommended-event-list {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: unset;
    height: 100%;
    width: $STUDENT_NETWORK_EVENTS_SIDEBAR_WIDTH_DESKTOP;
    background: $COLOR_V3_WHITE;
    font-family: $FONT_FAMILY;
    font-weight: $FONT_WEIGHT_SEMIBOLD;
    font-size: 14px;
    color: $COLOR_V3_MAP_BLUE_DARK;
    box-shadow: -3px 0 8px 0 rgba(92, 104, 124, 0.5);

    [dir="rtl"] & {
        right: unset;
        left: 0;
    }

    @media (max-width: $screen-xs-max) {
        left: 0;
        width: 100%;
        border: none;
        box-shadow: none;
    }

    #recommended-event-list-filters-container {
        margin-bottom: 23px;
        margin-top: 13px;
        padding: 0px 20px;

        @media (max-width: $screen-sm-min) {
            padding: 0px 10px;
        }
    }

    .event-list-container {
        // NOTE: The max-height value is dynamically calculated in the directive's logic.
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: $screen-xs-max) {
            // Remove scrollbar on mobile
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
