candidate-list-card,
.card-list-item student-profile-list-card,
candidate-full-card {
    header {
        @extend .clearfix;
        padding: 10px 10px 5px 10px;
        background: $COLOR_V3_BEIGE_LIGHT;

        @media (min-width: $screen-sm-min) {
            padding: 20px 20px 10px 20px;
        }

        .status {
            width: 70px;
            height: 70px;
            padding: 5px;
            border-radius: 50%;
            position: relative;
            transition: background 0.25s ease;
            float: left;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
            }

            @media (min-width: $screen-sm-min) {
                width: 90px;
                height: 90px;
                margin: -5px 0 0 -5px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin: -5px -5px 0 0;
                }
            }

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                width: 100px;
                height: 100px;
            }

            .tooltip {
                @include tooltip(auto, 70px, -10px, 15px);
                @include tooltip-dark($COLOR_V3_BEIGE_BEYOND_BEYOND_DARK);

                @media (min-width: $screen-sm-min) {
                    @include tooltip(auto, 95px, -10px, 19px);
                }

                @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                    @include tooltip(auto, 103px, -10px, 19px);
                }

                > .dots {
                    margin-right: 5px;
                }

                .dots span {
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    vertical-align: 1px;
                    background: $COLOR_V3_GREY_LIGHT;

                    @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                        width: 6px;
                        height: 6px;
                        vertical-align: 2px;
                    }
                }

                .job-types {
                    opacity: 0.6;
                }
            }

            &:hover {
                background: $COLOR_V3_BEIGE_MIDDARK;
            }

            i {
                display: none;

                @media (min-width: $screen-sm-min) {
                    display: block;
                    position: absolute;
                    left: 5px;
                    bottom: 5px;
                    width: 11px;
                    height: 11px;
                    font-size: 7px;
                    text-align: center;
                    padding-left: 1px;
                    line-height: 9px;
                    color: $COLOR_V3_GREY_LIGHT;
                    border: 1px solid $COLOR_V3_GREY_LIGHT;
                    border-radius: 50%;
                }
            }

            .indicator {
                position: absolute;
                top: 0;
                left: 0;

                @media (min-width: $screen-sm-min) {
                    top: 2px;
                    left: 2px;
                }

                span {
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: $COLOR_V3_GREY_LIGHT;
                    border: 1px solid $COLOR_V3_WHITE;
                    border-radius: 50%;
                    position: absolute;

                    &:nth-child(1) {
                        top: 13px;
                        left: 0;
                    }

                    &:nth-child(2) {
                        top: 6px;
                        left: 4px;
                    }

                    &:nth-child(3) {
                        top: 1px;
                        left: 11px;
                    }
                }
            }

            &.very_interested {
                .indicator span,
                .tooltip span span {
                    background: $COLOR_V3_GREEN;
                }
            }

            &.interested {
                .indicator span,
                .tooltip span span {
                    &:nth-child(1),
                    &:nth-child(2) {
                        background: $COLOR_V3_GREEN;
                    }
                }
            }

            &.neutral {
                .indicator span,
                .tooltip span span {
                    &:nth-child(1) {
                        background: $COLOR_V3_GREEN;
                    }
                }
            }

            careers-avatar {
                display: block;
                width: 60px;
                height: 60px;
                border: 1px solid $COLOR_V3_GREY_LIGHT;
                border-radius: 50%;

                @media (min-width: $screen-sm-min) {
                    width: 80px;
                    height: 80px;
                }

                @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                    width: 90px;
                    height: 90px;
                }
            }
        }

        .name-links,
        .work,
        .location,
        .fun-fact {
            margin: 6px 0 6px 80px;
            line-height: 14px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin: 6px 80px 6px 0;
            }

            @media (min-width: $screen-sm-min) {
                margin-left: 100px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-left: 0;
                    margin-right: 100px;
                }
            }

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                line-height: 16px;
            }
        }

        .name-links {
            font-size: 16px;
            color: $COLOR_V3_GREY_DARKER;
            line-height: 16px;

            @media (min-width: $screen-sm-min) {
                font-size: 18px;
            }

            button:not(.unstyled-button) {
                font-size: 10px;
                font-weight: $FONT_WEIGHT_NORMAL;
                color: $COLOR_V3_BLUE;
                text-transform: uppercase;
                background: none;
                outline: none;
                border: none;
                padding: 0;
                transition: 0.25s ease;

                @media (min-width: $screen-sm-min) {
                    margin-left: 15px;
                }

                @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                    font-size: 12px;
                }

                &:hover {
                    color: $COLOR_V3_BLUE_DARK;
                }

                i {
                    margin-left: 3px;
                }
            }
        }

        .work,
        .location {
            color: $COLOR_V3_GREY_DARKER;
        }

        .fun-fact {
            color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        }

        .current-experience {
            position: relative;
            color: $COLOR_V3_BLACK;
            font-weight: $FONT_WEIGHT_SEMIBOLD;

            @media (max-width: $screen-xs-max) {
                display: block;
            }

            span {
                white-space: nowrap;
            }

            span span {
                text-decoration: underline;
            }

            .tooltip {
                @include tooltip(auto, calc(100% + 10px), calc(100% - 40px), 15px);
                @include tooltip-dark($COLOR_V3_BEIGE_BEYOND_BEYOND_DARK);

                @media (min-width: $screen-sm-min) {
                    @include tooltip-centered(auto, calc(100% + 10px));
                }
            }

            &:hover .tooltip {
                transform: translateX(-50%) scale(1);
            }
        }

        .fun-fact b {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-transform: uppercase;

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                candidate-list & {
                    font-size: 14px;
                }
            }
        }
    }

    .body {
        @extend .clearfix;
        padding: 0 10px;
        margin: 0 0 6px;

        @media (min-width: $screen-sm-min) {
            padding: 5px 20px;
        }

        @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
            padding: 10px 20px 5px;
        }

        dt {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-transform: uppercase;
            display: block;
            width: 80px;
            float: left;
            clear: left;
            padding: 8px 0 6px;
            font-size: 10px;
            line-height: 14px;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
                clear: right;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 12px;
                width: 100px;
                line-height: 16px;
            }

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                candidate-last & {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        dd {
            padding: 7px 0;
            margin-left: 80px;
            line-height: 14px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 80px;
            }

            @media (max-width: $screen-xs-max) {
                .has-tooltip {
                    display: block;
                }
            }

            @media (min-width: $screen-sm-min) {
                line-height: 16px;
                margin-left: 100px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-left: 0;
                    margin-right: 100px;
                }

                &.education,
                &.roles {
                    .tooltip {
                        @include tooltip-centered(auto, calc(100% + 10px));
                    }
                }
            }

            @media (min-width: $CAREERS_CANDIDATE_LIST_WIDER_DESKTOP_THRESHOLD) {
                line-height: 18px;
            }

            .duration {
                display: none;
            }

            &.show-durations .duration {
                display: inline;
            }
        }
    }

    .edit-button {
        margin-left: 5px;
        font-size: 12px;
    }
}
