previous-button-desktop button.flat.hollow.previous-button-desktop {
    display: block;
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    outline: none;
    border: 1px solid $COLOR_V3_BEIGE_DARK;
    box-shadow: none;
    width: $PREVIOUS_BUTTON_WIDTH;
    height: $PREVIOUS_BUTTON_HEIGHT;

    // rtl-language overrides
    html[dir="rtl"] & {
        margin-right: $CONTINUE_BUTTON_DESKTOP_PADDING;
        margin-left: 0px;
    }

    .previous-chevron {
        display: block;

        // rtl-language overrides
        html[dir="rtl"] & {
            transform: scaleX(-1);
        }
    }
}
