student-dashboard-sidebar-box,
.other-boxes-container student-dashboard-sidebar-box {
    .desktop-sidebar-box {
        @include sidebar-box(true);

        font-size: 15px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        line-height: 21px;

        hr.hr-hexagon {
            margin-bottom: 25px;
        }

        p.hr-caption {
            margin: 0 0 10px 0;

            > span {
                display: flex;
                flex-direction: column;
            }
        }

        .hr-caption,
        h4 {
            text-transform: uppercase;
        }

        h4 {
            font-size: 14px;
            text-align: center;
            margin: 25px 0 0 0;
            padding-bottom: 15px;
            font-weight: $FONT_WEIGHT_BOLD;
        }

        li {
            font-size: 15px;
            margin-bottom: 8px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .centered-column {
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 50px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            height: 30px;
            font-size: 12px;
            color: $COLOR_V3_BEIGE_FOR_TEXT;
            line-height: 12px;
            text-transform: uppercase;
            padding: 10px 25px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            transition: 0.2s ease-in-out box-shadow;
            border: 1px solid $COLOR_V3_BEIGE;

            margin-top: 5px;
            margin-bottom: 5px;

            &:first-of-type {
                margin-top: 20px;
            }

            &:last-of-type {
                margin-bottom: 10px;
            }

            &:hover {
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
            }
        }

        // Styles only applied to specific sidebar boxes:
        .mailing-address {
            margin-bottom: 15px;
        }

        // The box renders a directive that has its own styling
        &.has-custom-style {
            padding: 0;
        }
    }
}
