// summaries

.stream-summary {
    $subtextSize: 13px;
    $titleTextSize: 15px;

    margin-bottom: 10px !important;

    &.disabled {
        &,
        a {
            opacity: 0.3;
            cursor: default;
        }
    }

    &:not(.disabled) {
        .summary-link {
            .description.coral {
                .no-touchevents &:hover,
                .no-touchevents &:hover strong {
                    color: $COLOR_V3_CORAL;
                    cursor: pointer;
                }
            }
        }
    }

    img.summary-icon {
        display: block;
        margin-right: 10px;

        // rtl-language overrides
        html[dir="rtl"] & {
            margin-left: 10px;
            margin-right: 0px;
        }
    }
    .icon-container,
    img.summary-icon {
        width: 38px;
        height: 38px;

        &.small {
            width: 25px;
            height: 25px;
        }
    }

    .summary-link {
        display: table;

        strong {
            font-size: $subtextSize;
            font-weight: $FONT_WEIGHT_NORMAL;
            color: $COLOR_V3_CORAL_DARK;
            margin-right: 4px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 4px;
                margin-right: 0px;
            }
        }

        .download-instructions {
            font-size: $subtextSize;
            font-weight: $FONT_WEIGHT_NORMAL;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        .icon-container,
        .description {
            display: table-cell;
            vertical-align: middle;
        }

        .description {
            line-height: 1.2em;
            padding-left: 10px;
            font-size: $subtextSize;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 10px;
                padding-left: 0px;
            }

            &.sidebar {
                @media (min-width: $screen-md-min) {
                    max-width: 250px;
                }
            }

            &.grey {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                strong {
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                }
            }

            &.coral {
                color: $COLOR_V3_CORAL_DARK;
                strong {
                    color: $COLOR_V3_CORAL_DARK;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                }
            }

            .title-text {
                font-size: $titleTextSize;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }
    }
}

.summaries {
    margin-top: 10px;
    margin-left: 5px;

    // rtl-language overrides
    html[dir="rtl"] & {
        margin-right: 5px;
        margin-left: 0px;
    }

    .summary {
        &.big-text,
        &.big-text description.grey,
        &.big-text description.coral {
            font-size: 15px !important;
            span,
            strong {
                font-size: 15px !important;
            }
        }
    }
}
