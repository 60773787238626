/*
    NOTE: ALL OF THESE IMAGES ARE PRELOADED IN /image_preloader.scss. MAKE SURE TO
    UPDATE THAT FILE IF ANY OF THESE URLS CHANGE
*/
@include pointy_button(
    ".cf-matching-challenge-button button",
    "cfMatchingChallengeButton",
    $matchingBoardFontSizeSM,
    // font-size desktop
    $matchingBoardFontSizeXS,
    // font-size mobile
    url("~vectors/pointy_button_light_turquoise_border.svg"),
    //base
    $COLOR_V3_WHITE,
    $COLOR_V3_TURQUOISE_LIGHT,
    url("~vectors/pointy_button_turquoise_border.svg"),
    // hover
    $COLOR_V3_WHITE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/pointy_button_turquoise_background.svg"),
    // active
    $COLOR_V3_TURQUOISE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/pointy_button_turquoise_background.svg"),
    // selected
    $COLOR_V3_TURQUOISE,
    $COLOR_V3_TURQUOISE,
    url("~vectors/pointy_button_dark_turquoise_background.svg"),
    // completed
    $COLOR_V3_TURQUOISE_GRASSY_DARK,
    $COLOR_V3_TURQUOISE_GRASSY_DARK,
    url("~vectors/pointy_button_green_background.svg"),
    //correct
    url("~vectors/pointy_button_yellow_background.svg"),
    //incorrect
    "right"
);

.cf-matching-challenge-button {
    /* markdown will add p tags to button text */
    p {
        display: inline;
    }

    button {
        position: relative;
        color: $COLOR_V3_EGGPLANT;

        &.btn-link:hover,
        &.btn-link:active,
        &.btn-link:focus {
            text-decoration: none;
            color: $COLOR_V3_EGGPLANT;
            outline: none;
        }

        // whenever we have a dark background, we use white text
        &.selected,
        &.completed,
        &.btn-link:active:enabled {
            color: $COLOR_V3_WHITE;

            &:disabled {
                color: $COLOR_V3_WHITE;
            }
        }

        .button_text {
            position: relative;
            display: block;
            text-align: left;
            white-space: normal;

            // correct for the really wide border
            // necessitated by the use of border-image
            margin: -10px -16px -10px -3px;
        }

        .button_image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }

        // rtl-content overrides
        div[dir="rtl"] & {
            transform: scaleX(-1);

            // the button itself is scaleX(-1) in the JS, so this undoes that transform for the button content
            .button_text,
            .button_image {
                transform: scaleX(-1);
                text-align: right;
            }
        }

        &:disabled {
            opacity: 1;
            color: $COLOR_V3_EGGPLANT;
        }
    }
}

.cf-matching-answer-buttons {
    button.primary {
        .button_text {
            // rtl content overrides
            // for some reason, the element containing the text for answer buttons
            // overlaps the parent element border to the right, so we just position it relative to the
            // left edge of its nearest positioned ancestor. This only seems to occur for Arabic content
            // (most likely due to the direction reversal of the buttons).
            div[dir="rtl"] & {
                position: relative;
                left: 12px;
                right: auto;
            }
        }

        div.icon {
            right: -25px;
        }
    }

    .cf_answer_list {
        button.primary:disabled {
            opacity: 1;
        }
    }
}
