@use "sass:math";

lesson-start-screen,
lesson-finish-screen {
    display: block;

    h1 {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }
    h2,
    li {
        font-weight: $FONT_WEIGHT_NORMAL;
    }

    &.ng-leave-hacked-up.ng-leave,
    &.ng-leave-hacked-up.ng-leave-active {
        transition: none;
    }

    // Instead of ng-enter, we use a separate class that we manage
    // manually.  This is because there was all sorts of odd
    // behavior using ng-enter in this case that Nate and Brent
    // could not figure out even after hours of digging.
    &.ng-enter-hacked-up,
    &.ng-leave-hacked-up,
    & {
        // even though this transition does nothing, it
        // must be here for ng-animate to take affect. Making it 2.5s
        // so that ng-animate does not cut off the animation of the elements inside.
        transition: opacity 2.5s ease 0s, background-color 0.5s ease 0s, box-shadow 0.5s ease 0s;

        li,
        .drop {
            transition: all 0.5s ease 0s;
        }

        @for $i from 0 through 5 {
            .drop-#{$i} {
                transition-delay: #{$i*0.1}s;

                @for $j from 0 through 20 {
                    li:nth-of-type(#{$j + 1}) {
                        transition-delay: #{$i*0.1 + $j*0.1}s;
                    }
                }
            }
        }
    }

    // faster transition out of the screen
    &.ng-leave-hacked-up {
        @for $i from 0 through 5 {
            .drop-#{$i} {
                transition-delay: #{math.div($i, 25)}s;

                @for $j from 0 through 20 {
                    li:nth-of-type(#{$j + 1}) {
                        transition-delay: #{math.div($i, 25) + math.div($j, 25)}s;
                    }
                }
            }
        }
    }

    // start position when entering screen and end position when leaving
    &.ng-enter-hacked-up,
    &.ng-leave-hacked-up {
        transition: opacity 2.5s ease 0s, background-color 0.5s ease 0s, box-shadow 0.5s ease 0s;
        background-color: transparent;
        box-shadow: none;

        // even though this transition does nothing, it
        // must be here for ng-animate to take affect
        opacity: 0.99;
        li,
        .drop {
            opacity: 0;
            transform: translateY(-50px);
        }
    }
}
