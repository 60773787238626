privacy-terms-footer {
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 16px;

    span.build_number {
        display: block;
        // We give this a width of 100% because it's mocked out in screenshot specs
        // and we don't want the size of the element to increase as the build number
        // increments in CI.
        width: 100%;
    }

    &.register,
    &.login {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }

    &.register {
        margin-top: 12px;
        &,
        a {
            color: rgba(white, 0.6);
        }
    }

    &.login {
        margin-top: 0px;
        margin-bottom: 8px;
        &,
        a {
            color: rgba(black, 0.5);
        }
    }

    &.settings {
        font-weight: $FONT_WEIGHT_MEDIUM;
        &,
        a {
            color: $COLOR_V3_GREY_DARK;
        }
    }
}
