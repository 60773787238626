stream-dashboard-next-up {
    // Content box is only shown on the student dashboard when there are no streams available
    .content-box {
        position: relative; // so that btn-library can be absolutely positioned
    }

    .btn-library {
        height: 50px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        position: absolute;
        bottom: 0px;
    }

    // on the course-dashboard page, this should look like a box in the center below the course description
    .grid-box {
        margin-bottom: 10px;

        .touchevents & {
            @include user-select(none);
        }
        background-color: $COLOR_V3_WHITE;

        .content-box {
            position: relative;
        }

        .title,
        .next-up {
            color: $COLOR_V3_CORAL_DARK;
            font-size: 20px;
            line-height: 1.05em;

            // rtl-language overrides
            html[dir="rtl"] & {
                line-height: 1.6em;
                overflow: visible;
            }
        }

        .title {
            height: auto;
            font-weight: $FONT_WEIGHT_NORMAL;
            text-align: center; // see different desktop styling below
        }

        .next-up {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            display: block;

            @media (min-width: $screen-sm-min) {
                display: inline;
            }
        }
        // Lesson Index Box
        .lesson-index-box {
            text-align: center; // see different desktop styling below

            // Lesson Index and Time Estimate
            .lesson-index,
            .lesson-divider {
                display: inline-block;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 13px;
                color: $COLOR_V3_BEIGE_DARKER;
                margin-right: 5px;
            }

            .lesson-time-estimate {
                font-weight: $FONT_WEIGHT_NORMAL;
                font-size: 13px;
                color: $COLOR_V3_BEIGE_DARKER;
            }
        }

        // Just Hover Styles
        .no-touchevents &:hover {
            .title,
            .next-up {
                color: $COLOR_V3_CORAL;
            }

            .launch-button {
                background-color: lighten($COLOR_V3_GREEN, 3%);

                &.inProgress {
                    background-color: lighten($COLOR_V3_PURPLE, 3%);
                }

                &.completed {
                    background-color: lighten($COLOR_V3_CORAL, 3%);
                }
            }
        }

        // Just Active Styles
        .no-touchevents &:active,
        .touchevents &:active {
            background-color: $COLOR_V3_BEIGE_LIGHTEST;

            .lesson-index,
            .lesson-divider,
            .title,
            .next-up {
                color: $COLOR_V3_CORAL_DARK;
            }

            .lesson-description,
            .lesson-time-estimate {
                color: $COLOR_V3_BEIGE_DARKEST;
            }

            .launch-button {
                background-color: $COLOR_V3_GREEN_DARK;

                &.inProgress {
                    background-color: $COLOR_V3_PURPLE_DARK;
                }

                &.completed {
                    background-color: $COLOR_V3_CORAL_DARK;
                }
            }
        }

        // Launch button
        .launch-button {
            @include flat-button-color($COLOR_V3_GREEN, $COLOR_V3_GREEN_DARK);
            text-transform: none; // override flat style's transform since these will all be uppercased anyhow
            font-size: 18px;
            font-weight: $FONT_WEIGHT_NORMAL;
            display: block;
            width: 100%;
            height: 50px;
            border-radius: 3px;
            margin-top: 10px;

            &.inProgress {
                @include flat-button-color($COLOR_V3_PURPLE, $COLOR_V3_PURPLE_DARK);
            }

            &.completed {
                @include flat-button-color($COLOR_V3_CORAL, $COLOR_V3_CORAL_DARK);
            }
        }

        // move the launch button to the right on larger screens
        @media (min-width: $screen-sm-min) {
            $launchButtonWidth: 176px;

            .lesson-index-box,
            .title {
                width: calc(100% - #{$launchButtonWidth + 10px});
                text-align: left;

                // rtl-language overrides
                html[dir="rtl"] & {
                    text-align: right;
                }
            }

            .title,
            .next-up {
                font-size: 25px;
                line-height: 1.05em;

                // rtl-language overrides
                html[dir="rtl"] & {
                    line-height: 1.8em;
                    overflow: visible;
                }
            }

            .launch-button {
                width: $launchButtonWidth;
                height: 100%;
                position: absolute;
                top: 0px;
                right: 0px;
                margin: 0px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    right: auto;
                    left: 0px;
                }
            }
        }
    }
}
