lesson-finish-screen-footer {
    // when this is inside the lesson-finish-screen box, rather than
    // fixed to the bottom of the screen in the front-royal-footer,
    // things need to change
    lesson-finish-screen & {
        left: 24px;
        right: 24px;
        bottom: 24px;
        width: auto;

        // on mobile, the continue button moves out of
        // the lesson-finish-screen element and into the
        // front-royal-footer
        @media (max-width: $screen-xs-max) {
            left: 10px;
            right: 10px;
            bottom: 0px;

            button.continue.flat {
                display: none;
            }
        }
    }

    .front-royal-footer & {
        // feedback never shows up in front-royal-footer. Always
        // in the lesson-finish-screen element
        .feedback {
            display: none;
        }

        // on desktop, the continue button moves out of
        // the front-royal-footer and up into the lesson-finish-screen
        // element
        @media (min-width: $screen-sm-min) {
            button.continue.flat {
                display: none;
            }
        }
    }

    .feedback {
        $thumbButtonSize: 36px;
        font-size: 15px;
        line-height: $thumbButtonSize;
        position: relative;
        height: 72px;
        font-weight: $FONT_WEIGHT_NORMAL;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        margin-bottom: 0;
        margin-left: -5px;
        margin-right: -5px;

        direction: ltr;

        @media (min-width: $screen-iphone6-min) {
            margin-left: 0px;
            margin-right: 0px;
        }

        strong {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        span {
            display: inline-block;
        }

        // feedback animations
        > * {
            position: absolute;
            top: 0px;
            left: 0px;
        }

        // First, we ask for feedback
        .give-feedback {
            max-width: calc(100% - 80px);
            line-height: 1.2em;
            padding-right: 5px;
            transform-origin: 0px 50%;
            font-size: 16px;
            opacity: 0;
            text-align: center;
            animation: fade-in 0.5s 0.5s;
            animation-fill-mode: forwards;
        }

        // The feedback form starts out only showing the thumbs, then gradually reveals other states
        .feedback-form {
            width: 100%;
            transform-origin: 50% 50%;

            // Vertical align our children to the top
            > *,
            > .feedback-form-details > *,
            > .feedback-form-reason > * {
                vertical-align: top;
            }

            // First, the thumb buttons
            .thumbs {
                button.flat.thumb {
                    position: relative;
                    display: inline-block;
                    background-size: 15px 16px;
                    background-position: center;
                    width: $thumbButtonSize;
                    height: $thumbButtonSize;
                    transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
                    border-radius: $thumbButtonSize * 0.5;
                    transform-origin: 50% 50%;

                    &:nth-of-type(2) {
                        margin-left: 2px;
                    }

                    &:after {
                        transform: translateZ(0); // stupid, but necessary to get it to render right
                        content: url("~vectors/feedback_thumb.svg");
                        width: 17px;
                        height: 18px;
                        position: absolute;
                        left: calc(50% - 8px);
                        top: 8px;
                    }

                    &[name="thumbs-up"] {
                        opacity: 0;
                        animation: bounceThumbsUp 0.5s 0.6s ease;
                        animation-fill-mode: forwards;

                        background-color: $COLOR_V3_BLUE;
                        .no-touchevents &:hover,
                        &:active {
                            background-color: $COLOR_V3_BLUE_DARK;
                        }
                        &.selected {
                            background-color: $COLOR_V3_BLUE !important;
                        }
                    }

                    &[name="thumbs-down"] {
                        opacity: 0;
                        animation: bounceThumbsUp 0.5s 0.75s ease;
                        animation-fill-mode: forwards;

                        &:after {
                            transform: translateZ(0) rotateZ(180deg);
                            top: 11px;
                            left: calc(50% - 9px);
                        }

                        background-color: $COLOR_V3_ORANGE;
                        .no-touchevents &:hover,
                        &:active {
                            background-color: $COLOR_V3_ORANGE_DARK;
                        }
                        &.selected {
                            background-color: $COLOR_V3_ORANGE !important;
                        }
                    }
                }
            }

            // Next, the reasons for giving a rating
            .feedback-form-reason {
                padding-top: 12px;
                padding-left: 10px;
                position: relative;
                width: calc(100% - #{$thumbButtonSize});

                button.flat.reason {
                    display: inline-block;
                    height: auto;
                    width: auto;
                    text-align: center;
                    font-size: 14px;
                    line-height: 14px;
                    border-radius: 3px;
                    margin: 0px;
                    padding: 4px 4px;
                    margin-right: 5px;

                    &.bad {
                        color: $COLOR_V3_ORANGE;
                        background-color: $COLOR_V3_WHITE;
                        border: 2px solid $COLOR_V3_ORANGE;

                        .no-touchevents &:hover,
                        &.selected {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_ORANGE;
                        }
                        .no-touchevents &:active,
                        .touchevents &:active {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_ORANGE_DARK;
                            border: 2px solid $COLOR_V3_ORANGE_DARK;
                        }
                    }
                    &.good {
                        color: $COLOR_V3_BLUE;
                        background-color: $COLOR_V3_WHITE;
                        border: 2px solid $COLOR_V3_BLUE;

                        .no-touchevents &:hover,
                        &.selected {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_BLUE;
                        }
                        .no-touchevents &:active,
                        .touchevents &:active {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_BLUE_DARK;
                            border: 2px solid $COLOR_V3_BLUE_DARK;
                        }
                    }
                    &.next {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        background-color: $COLOR_V3_WHITE;
                        border: 2px solid $COLOR_V3_BEIGE_BEYOND_DARK;

                        .no-touchevents &:hover,
                        &:active {
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        }
                    }
                }

                .caption {
                    display: block;
                    position: absolute;
                    top: -5px;
                    left: 10px;
                    font-size: 12px;
                    line-height: 12px;
                    height: auto;
                    min-width: 275px;
                    text-align: left;
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                }
                .reasons {
                    line-height: 30px;
                    > *,
                    > span > * {
                        vertical-align: top;
                    }
                }
            }

            // Finally, any additional details about this rating
            .feedback-form-details {
                padding-left: 10px;
                width: calc(100% - #{$thumbButtonSize});

                textarea {
                    display: inline-block;
                    font-size: 13px;
                    vertical-align: middle;
                    background-color: $COLOR_V3_BEIGE;
                    color: $COLOR_V3_EGGPLANT;
                    height: 100%;
                    width: calc(100% - #{10px + 69px});
                    border: 0px;
                    outline: none;
                    margin-right: 10px;
                    line-height: 17px;
                }

                button.flat[name="submit"] {
                    display: inline;
                    height: 100%;
                    width: 69px;
                    text-align: center;
                    color: $COLOR_V3_WHITE;
                    font-size: 12px;
                    background-color: $COLOR_V3_BLUE;
                    border-radius: 3px;
                    margin: 0px;
                    padding: 4px;

                    html:lang(es) & {
                        font-size: 9px;
                        padding: 8px 3px;
                    }
                }
            }
        }

        // After submission, we show a thank you note here
        .feedback-submitted {
            font-size: 16px;
            transform-origin: 50% 50%;
            margin-top: 10px;
        }
    }

    button.flat.continue {
        $arrowSize: 20px;
        $padding: 16px + $arrowSize + 4px; // default padding is 16px. make some more space for the arrow
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        padding-right: $padding;
        padding-left: $padding; // do this on the left too, even though there is no arrow, to keep centered
        white-space: normal;

        &.has-next-lesson {
            h2 {
                margin: 0px;
            }
        }

        &.retry {
            background-color: $COLOR_V3_CORAL;

            &,
            &:hover,
            &:active {
                background-image: url("~vectors/retry.svg");
            }
        }

        &,
        &:hover,
        &:active {
            background-image: url("~vectors/arrow_right_white.svg");
            background-size: $arrowSize $arrowSize;
            background-position: right 15px top 50%;

            // rtl-language overrides
            html[dir="rtl"] & {
                background-image: url("~vectors/arrow_left_white.svg");
                background-size: $arrowSize $arrowSize;
                background-position: left 15px top 50%;

                &.retry {
                    background-image: url("~vectors/retry.svg");
                }
            }
        }

        .next-lesson {
            font-size: 10px;
        }
    }
}
