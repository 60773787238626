.careers-placeholder-container {
    text-align: center;
    color: $COLOR_V3_BEIGE_DARKEST;
    padding-top: 70px;
    padding-bottom: 120px;

    h1 {
        font-size: 32px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        display: block;
    }

    img {
        height: 138px;
        margin: 30px 0px 30px 0px;

        @media (min-width: $screen-sm-min) {
            margin: 30px 0px 40px 0px;
        }
    }

    .subtitle {
        font-size: 18px;
        color: $COLOR_V3_BEIGE_DARKER;
        padding: 0 20px;
        display: block;
    }

    @media (min-width: $screen-sm-min) {
        padding-top: 100px;

        h1 {
            font-size: 24px;
        }
        img {
            height: 100px;
        }
    }
}
