$contentBodyPadding: 20px;

@mixin h2_desktop() {
    text-align: center;
    font-size: 28px;

    &::before {
        display: none;
    }

    &::after {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        font-size: 20px;
        color: $COLOR_V3_WHITE;
        margin: 10px auto 0;
    }

    span:nth-of-type(1) {
        font-size: 14px;
        font-weight: $FONT_WEIGHT_NORMAL;
        margin-bottom: 5px;
    }
}

@mixin sub_head {
    &.sub-head,
    .sub-head {
        font-size: 12px;

        &::before {
            margin-top: 2px;
        }

        span {
            &:nth-of-type(1) {
                margin-top: -2px;
            }
        }

        @media (min-width: $screen-md-min) {
            font-size: 18px;
            margin-top: 11px;
            margin-bottom: 23px;
        }
    }
}

@mixin event_listing {
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        a:hover {
            cursor: not-allowed;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.03);
    }

    &:not(:last-of-type) {
        margin-bottom: 5px;
    }

    .thumbnail {
        display: block;
        object-fit: cover;
        width: 73px;
        height: 54px;
        border-radius: 8px;
        margin-right: 14px;
        flex: 0 0 auto;

        [dir="rtl"] & {
            margin-left: 14px;
            margin-right: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        a {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 3px;
        }

        p {
            font-size: 13px;
            margin: 0;
        }
    }
}

// Common styles for collapsible sidebar boxes
// Of the following current collapsible boxes, only 4
// can possibly show at once
.sidebar-collapsible-content > * {
    @for $i from 0 through 4 {
        &:nth-last-child(#{$i + 1}) .box-container {
            bottom: $APP_MENU_MOBILE_HEIGHT + ($i * 45); // see also notch.scss

            @media (min-width: $screen-sm-min) {
                bottom: 0px + ($i * 65);
            }
        }
    }
}

.sidebar-collapsible-content .color-override-container {
    $sidebar-box-container-colors: $COLOR_V3_BLUE_NOT_SO_LIGHT, $COLOR_V3_BLUE_DARK, $COLOR_V3_BLUE_DARKEST,
        $COLOR_V3_BLUE_NOT_SO_LIGHT;

    @for $i from 1 through 4 {
        &:nth-child(#{$i}) .box-container .content-container {
            @media (max-width: $screen-sm-max) {
                background-color: nth($sidebar-box-container-colors, $i);
                background-image: none;
            }
        }
    }
}

.sidebar-collapsible-content {
    student-dashboard-admissions-info-box,
    student-dashboard-sidebar-box,
    student-dashboard-program-box {
        .box-container {
            position: fixed;
            left: 0;
            right: 0;
            height: 45px;
            z-index: 4;
            background: $COLOR_V3_BEIGE;
            transition: 0.5s ease height;
            overflow: hidden;

            &.open {
                height: calc(100% - #{$APP_MENU_MOBILE_HEIGHT} - #{$APP_HEADER_HEIGHT_MOBILE}); // see also notch.scss
                bottom: $APP_MENU_MOBILE_HEIGHT; // see also notch.scss
                overflow: auto;
                z-index: 5;

                .toggle span {
                    padding-top: 1px;

                    &::before {
                        content: "\f107"; // fa-angle-down
                    }
                }
            }

            @media (min-width: $screen-sm-min) {
                height: 65px;

                &.open {
                    height: calc(100% - #{$APP_HEADER_HEIGHT});
                    bottom: 0;
                }
            }

            @media (min-width: $screen-md-min) {
                &,
                &.open {
                    position: static;
                    height: auto;
                }
            }
        }

        .toggle {
            background: none;
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 45px;
            outline: none;
            padding: 0 10px;
            z-index: 100;

            span {
                float: right;
                border: 1px solid $COLOR_V3_WHITE;
                border-radius: 50%;
                color: $COLOR_V3_WHITE;
                width: 25px;
                height: 25px;
                line-height: 23px;
                text-align: center;

                &::before {
                    @include font-awesome-icon("\f106", $FONT_AWESOME_LIGHT); // fa-angle-up
                    font-size: 18px;
                }

                html[dir="rtl"] & {
                    float: left;
                }
            }

            @media (min-width: $screen-sm-min) {
                height: 65px;
                padding: 0 20px;
            }

            @media (min-width: $screen-md-min) {
                display: none;
            }
        }

        .content-container {
            color: $COLOR_V3_WHITE;
            padding: 10px;
            margin-bottom: 13px;

            &.graduated {
                padding: 0px;

                @media (min-width: $screen-sm-min) {
                    height: 100%;
                }
            }

            a {
                color: $COLOR_V3_YELLOW;
            }

            @media (min-width: $screen-sm-min) {
                padding: 20px;
            }

            @media (min-width: $screen-md-min) {
                padding: 30px 15px;
                border-radius: 4px;
                margin-bottom: 30px;
            }

            @media (min-width: $screen-lg-min) {
                padding: 30px 20px;
            }

            h2 {
                margin: 0;
                font-size: 16px;
                text-transform: uppercase;

                &::before {
                    content: "";
                    display: block;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 50%;
                    float: left;
                    margin-right: 10px;
                    font-size: 14px;
                    color: $COLOR_V3_WHITE;
                }

                span {
                    &.capitalized {
                        text-transform: none;
                    }

                    // "status" or "congratulations"
                    &:nth-of-type(1) {
                        display: block;
                        font-size: 10px;
                        @include sub_head;
                    }

                    &:nth-of-type(2) {
                        margin-bottom: 5px;
                    }

                    // "with honors"
                    &:nth-of-type(3) {
                        display: none;
                    }

                    &.head {
                        font-size: 16px;
                    }
                }

                @media (min-width: $screen-md-min) {
                    @include h2_desktop;
                }
            }

            p {
                text-align: center;
                margin: 10px 0 0;

                &.sub-text {
                    font-size: 15px;
                }

                @media (min-width: $screen-sm-min) {
                    margin-top: 20px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: 20px;
                    font-size: 18px;
                }
            }

            hr:not(.hr-hexagon) {
                background: none;
                height: 0;
                border-bottom: 1px solid rgba($COLOR_V3_WHITE, 0.5);
                width: 52px;
                margin: 20px auto;
            }
        }
    }
}

[name="schedule_status"],
student-dashboard-sidebar-box .desktop-sidebar-box[name="late_project_submission"] {
    .past-due-projects {
        .project {
            margin: 30px 0;

            &:last-of-type {
                margin-bottom: 10px;
            }

            .project-title-text {
                margin-bottom: 10px;
                font-weight: 600;
                display: flex;

                &::before,
                &::after {
                    content: "";
                    flex: 1 1 15%;
                    margin-top: 7px;
                }

                span {
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    text-align: center;
                    flex: 1 1 auto;
                    padding: 0 5px;
                }
            }

            .no-project-documents {
                text-align: center;
                margin: 15px auto;
                font-size: 16px;
                opacity: 0.7;
            }

            .small-doc {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                &:hover {
                    .title-text {
                        text-decoration: underline;
                    }
                }

                .doc-icon-box {
                    height: 25px;
                    flex: 0 0 25px;
                }

                .title-text {
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 10px;
                    line-height: 1.1;
                    word-break: break-word;
                }

                [dir="rtl"] & {
                    flex-direction: row-reverse;

                    .title-text {
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

[name="schedule_status"] {
    .box-container {
        &.open {
            .status.graduated {
                background: none;
                padding: 0px;
            }
        }
    }

    .status,
    .incomplete_assignments {
        @include sub_head;

        @media (min-width: $screen-md-min) {
            background-image: linear-gradient(146deg, #2266d0 0%, #0c2c63 96%);
            padding: 20px;

            &.failed {
                background: $COLOR_V3_MESSAGE_BOX_COLOR;
            }
        }

        h2 {
            margin-bottom: 23px;
        }

        student-dashboard-sidebar-box {
            &:first-of-type {
                > .desktop-sidebar-box:first-of-type {
                    margin-top: 13px;
                    @media (min-width: 768px) {
                        margin-top: 30px;
                    }
                }
            }
            span,
            p {
                color: $COLOR_V3_BEIGE_DARKEST;
            }
            hr.hr-hexagon {
                display: block;
                width: auto;
                height: 3px;
                background-color: #c0b8ae;
                border: 0px solid transparent;
                border-radius: 1.5px;
                margin-bottom: 20px;
            }
        }

        span,
        p {
            color: $COLOR_V3_WHITE;
        }

        &.incomplete_assignments h2::before {
            background: $COLOR_V3_ORANGE url("~images/clock.png") no-repeat 50% 50% / 13px 13px;
        }

        &.on_track h2::before {
            background: $COLOR_V3_BLUE url("~vectors/ellipse.svg") no-repeat 50% 50% / 19px 5px;
        }

        &.on_track_finished h2::before {
            @include font-awesome-icon("\f00c"); // fa-check
            background: $COLOR_V3_TURQUOISE;
        }

        &.week_0 h2::before,
        &.has_pre_graduation_status h2::before {
            @include font-awesome-icon("\f133", $FONT_AWESOME_REGULAR); // fa-calendar
            background: $COLOR_V3_BLUE;
        }

        &.not_graduated, // for .program_complete
		&.week_1,
		&.failed {
            h2::before {
                background: url("~images/shield_quantic.png") no-repeat 50% 50% / 25px auto;
                border-radius: 0;
            }

            h2.valar::before {
                background: url("~images/shield_valar.png") no-repeat 50% 50% / 25px auto;
            }

            h2.smartly::before {
                background: url("~images/shield.png") no-repeat 50% 50% / 25px auto;
            }
        }

        &.incomplete_assignments .academic-probation-status {
            p,
            a {
                font-size: 16px;
            }

            a {
                color: white;
                font-weight: bold;
            }
        }

        &.incomplete_assignments .past-due-projects {
            .project {
                .project-title-text {
                    &::before,
                    &::after {
                        border-top: 1px solid $COLOR_V3_WHITE;
                    }
                }

                .small-doc {
                    .title-text {
                        color: $COLOR_V3_WHITE;
                    }

                    .doc-icon-box {
                        img {
                            /*
                                    This simply makes the doc icon turn white. Another option would be to use an `svg` tag rather than an `img` tag
                                    in the DOM (which lets us utilize the `fill` property rather than `filter`), but I wanted to maintain the
                                    `image-fade-in-on-load` directive that's currently being applied. Context on the `filter` code below: https://stackoverflow.com/a/53336754.
                                */
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
                                hue-rotate(238deg) brightness(101%) contrast(104%);
                        }
                    }
                }
            }

            .submit-project-container {
                padding: 0 32px;

                .submit-project-button {
                    padding: 10px 30px;
                    font-size: 15px;
                    border: 1px solid $COLOR_V3_WHITE;
                    box-shadow: none;
                    text-transform: none;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    min-height: 35px;
                    width: 100%;
                }
            }
        }

        .small-course {
            @include small-course; // from dashboard.scss
            color: $COLOR_V3_WHITE;
            font-weight: $FONT_WEIGHT_MEDIUM;

            &:hover .title-text {
                text-decoration: underline;
            }

            &[disabled="disabled"] {
                opacity: 0.6;

                &:hover .title-text {
                    text-decoration: none;
                }
            }
        }

        .more-courses,
        .more-projects {
            display: block;
            padding-left: 32px;
            line-height: 30px;
            font-size: 14px;
            color: $COLOR_V3_WHITE;

            &:hover {
                text-decoration: underline;
            }
        }

        .in,
        .from_the {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            margin-bottom: 10px;

            @media (min-width: $screen-md-min) {
                margin-top: 10px;
            }
        }

        .program {
            font-size: 28px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            line-height: 1.1;
            margin-top: 0;
            text-transform: uppercase;

            &.font-size-20 {
                font-size: 20px;
            }
        }

        .class {
            text-transform: uppercase;
            font-size: 14px;
        }

        .share {
            a {
                margin-left: 10px;
                font-size: 24px;
                color: $COLOR_V3_WHITE;
                display: inline-block;
                vertical-align: middle;
                transition: 0.25s ease;

                &:hover {
                    text-shadow: 0 1px 1px rgba(black, 0.5);
                }
            }
        }
    }

    h4 {
        font-size: 12px;
        line-height: 1;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        text-transform: uppercase;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        margin: 20px 0 0;
        display: table;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 12px;

        &::before,
        &::after {
            content: "";
            border-top: 2px solid $COLOR_V3_BEIGE_MIDDARK;
            display: table-cell;
            position: relative;
            top: 0.33em;
            width: 50%;
        }

        &::before {
            right: 10px;

            [dir="rtl"] & {
                right: auto;
                left: 10px;
            }
        }

        &::after {
            left: 10px;

            [dir="rtl"] & {
                left: auto;
                right: 10px;
            }
        }

        ~ p {
            margin-top: 12px !important;
        }
    }
}

student-dashboard-sidebar-box {
    .desktop-sidebar-box {
        .upcoming-events-box {
            .no-events-found {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 14px;
                text-align: center;
                padding: 20px 0;
            }

            .events-container {
                a {
                    color: $COLOR_V3_BEIGE_FOR_TEXT;
                    &:hover {
                        color: $COLOR_V3_BEIGE_FOR_TEXT;
                    }
                    &::after {
                        height: 0;
                    }
                }
                p {
                    text-align: left !important;
                }
                .event {
                    @include event_listing;
                }
            }
        }

        ul.slack-schedule {
            padding-left: 15px;
            > li {
                font-weight: $FONT_WEIGHT_NORMAL;
                font-size: 15px;
                span {
                    font-size: inherit;
                    b {
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                    }
                }
            }
        }

        &[name="pre_approved_for_cohort"] {
            padding: 0;
            p,
            span {
                color: white;
            }
        }

        &[name="schedule"],
        &[name="recent_courses"] {
            @include sidebar-box;
            > hr {
                margin-bottom: 15px;
            }

            .date {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 14px;
                text-align: center;
            }

            .caption {
                font-size: 15px;

                a.external-link {
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    text-decoration: none;
                    border: none;
                    font-size: inherit;
                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $COLOR_V3_BEIGE_BEYOND_DARK;
                        position: absolute;
                        bottom: 1px;
                        left: 0;
                    }
                }
            }

            a.small-course {
                @include small-course; // from dashboard.scss
                color: $COLOR_V3_WHITE;
                font-weight: $FONT_WEIGHT_MEDIUM;

                &[disabled="disabled"] {
                    opacity: 0.6;
                }

                > span {
                    &::after {
                        content: none;
                    }
                    &.title-text {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &::after {
                    content: none;
                }
            }

            [name="project_documents"] {
                .caption {
                    a {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        text-decoration: none;
                        border: none;
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            h4 {
                font-size: 12px;
                line-height: 1;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                text-transform: uppercase;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                margin: 20px 0 0;
                display: table;
                white-space: nowrap;
                overflow: hidden;
                padding: 0;
                margin-bottom: 12px;

                &::before,
                &::after {
                    content: "";
                    border-top: 2px solid $COLOR_V3_BEIGE_MIDDARK;
                    display: table-cell;
                    position: relative;
                    top: 0.33em;
                    width: 50%;
                }

                &::before {
                    right: 10px;

                    [dir="rtl"] & {
                        right: auto;
                        left: 10px;
                    }
                }

                &::after {
                    left: 10px;

                    [dir="rtl"] & {
                        left: auto;
                        right: 10px;
                    }
                }

                &.full-schedule-heading {
                    margin-bottom: -5px;
                }
            }

            .submit-project-button {
                display: flex;
                margin: 15px auto;
                justify-content: center;
                align-items: center;
            }
        }

        &[name="schedule_status"] {
            padding: 0;
            .schedule-status {
                background-color: $COLOR_V3_BEIGE;
            }
            .toggle {
                display: none;
            }

            .status,
            .incomplete_assignments {
                p {
                    color: $COLOR_V3_WHITE;
                }
                a::after {
                    content: none;
                }

                .small-doc {
                    ::after {
                        content: none;
                    }
                }

                a.small-course {
                    @include small-course; // from dashboard.scss
                    color: $COLOR_V3_WHITE;
                    font-weight: $FONT_WEIGHT_MEDIUM;

                    .title-text {
                        color: $COLOR_V3_WHITE;
                    }

                    > span {
                        &::after {
                            content: none;
                        }
                    }
                    &::after {
                        content: none;
                    }
                }

                button {
                    margin: 20px auto 10px auto;
                }

                .more-courses,
                .more-projects {
                    color: $COLOR_V3_WHITE;
                }

                h2 {
                    margin: 0;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $COLOR_V3_WHITE;

                    span {
                        color: $COLOR_V3_WHITE;

                        // "status" or "congratulations"
                        &:nth-of-type(1) {
                            display: block;
                            font-size: 10px;
                            @include sub_head;

                            strong {
                                font-size: 14px;
                                color: $COLOR_V3_WHITE;
                                font-weight: $FONT_WEIGHT_SEMIBOLD;
                            }
                        }
                        &:nth-of-type(2) {
                            margin-bottom: 5px;

                            font-size: 28px;
                        }
                        // "with honors"
                        &:nth-of-type(3) {
                            display: none;
                        }
                        &.head {
                            font-size: 16px;
                        }
                    }

                    @include h2_desktop;
                }

                p {
                    text-align: center;
                    margin-top: 20px;
                    font-size: 18px;
                }

                .academic-probation-status {
                    p,
                    a {
                        font-size: 16px;
                    }

                    a {
                        color: white;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                hr {
                    background: none;
                    height: 0;
                    border-bottom: 1px solid rgba($COLOR_V3_WHITE, 0.5);
                    width: 52px;
                    margin: 20px auto;
                }

                &.incomplete_assignments h2::after {
                    background: $COLOR_V3_ORANGE url("~images/clock.png") no-repeat 50% 50% / 22px 22px;
                }

                &.on_track h2::after {
                    background: $COLOR_V3_BLUE url("~vectors/ellipse.svg") no-repeat 50% 50% / 28px 7px;
                }

                &.on_track_finished h2::after {
                    @include font-awesome-icon("\f00c"); // fa-check
                    background: $COLOR_V3_TURQUOISE;
                }

                &.week_0 h2::after,
                &.has_pre_graduation_status h2::after {
                    @include font-awesome-icon("\f133", $FONT_AWESOME_REGULAR); // fa-calendar
                    background: $COLOR_V3_BLUE;
                }

                &.not_graduated, // for .program_complete
                    &.week_1,
                    &.failed {
                    a {
                        color: $COLOR_V3_YELLOW;
                    }
                    h2::after {
                        width: 60px;
                        height: 53px;
                        background: url("~images/shield_quantic.png") no-repeat 50% 50% / 60px 53px;
                        border-radius: 0;
                    }

                    h2.valar::after {
                        background: url("~images/shield_valar.png") no-repeat 50% 50% / 60px 53px;
                    }

                    h2.smartly::after {
                        background: url("~images/shield.png") no-repeat 50% 50% / 60px 53px;
                    }
                }

                &.graduated {
                    padding: 0px;
                }
            }
        }

        &[name="late_project_submission"] {
            .submit-projects-message {
                font-size: 16px;
                text-align: center;
            }

            .past-due-projects {
                a {
                    &::after,
                    & > span::after {
                        display: none;
                    }
                }

                .project {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 20px 0;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }

                    .project-title-text {
                        width: 100%;

                        &::before,
                        &::after {
                            border-top: 1px solid $COLOR_V3_BEIGE_DARKER;
                        }
                    }

                    .small-doc {
                        align-self: flex-start;

                        .title-text {
                            color: $COLOR_V3_CORAL;
                            font-weight: 600;
                        }
                    }

                    .no-project-documents {
                        margin: 5px 0;
                    }

                    button {
                        margin-top: 15px;
                    }
                }

                .more-projects {
                    width: 100%;
                    display: block;
                    text-align: center;
                    color: $COLOR_V3_BEIGE_BEYOND_DARK !important;
                    margin: 10px 0;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.sidebar-collapsible-content {
    student-dashboard-admissions-info-box,
    student-dashboard-sidebar-box,
    student-dashboard-program-box {
        .box-container:not(.desktop-view) {
            background: none;

            .background-overlay {
                position: fixed;
                z-index: -1;
                pointer-events: none;

                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                background-color: black;
                opacity: 0;
                transition: 0.5s ease-in-out opacity;
            }

            button.sidebar-box-cta.flat.round.hollow.white.thin {
                color: white;
                white-space: normal;

                &:hover {
                    background-color: inherit;
                    box-shadow: inset 0px 0px 0px 1px #ffffff;
                }

                a {
                    color: white;
                    pointer-events: none;
                }
            }

            &:not(.open) .content-container {
                justify-content: center;
            }

            .content-container {
                height: 100%;
                display: flex;
                flex-direction: column;

                padding: 0;
                margin: 0;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    pointer-events: none;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    background-image: linear-gradient(155deg, #2266d0 0%, #0c2c63 96%);
                    opacity: 0;
                    transition: 0.5s ease-in-out opacity;
                    z-index: auto;
                }

                .content-header,
                .content-body {
                    position: relative;
                    z-index: auto;

                    p:not(.hr-caption) {
                        text-align: left;
                        margin-top: 0;
                        margin-bottom: 10px;

                        &.sub-sub-text {
                            text-align: center;
                            margin-top: 20px;
                        }
                    }
                }

                .content-header {
                    display: flex;
                    flex: 0 1 auto;
                    justify-content: space-between;
                    align-items: center;

                    $border: 1px solid transparent;
                    border-top: $border;
                    border-bottom: $border;
                    padding: 10px;

                    @media (min-width: 768px) {
                        padding: 20px;
                    }

                    h3 {
                        font-size: 16px;
                        line-height: 16px;
                        text-transform: uppercase;
                        margin: 2px 0 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .subtitle {
                            font-size: 10px;
                        }
                    }

                    button.sidebar-box-cta.flat.round.hollow.white.thin {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;

                        font-size: 10px;
                        line-height: 10px;
                        padding: 7px 13px;

                        i {
                            padding-left: 7px;
                        }
                    }

                    h3,
                    button.sidebar-box-cta.flat.round.hollow.white.thin {
                        max-height: 25px;
                    }
                }

                .content-body {
                    flex: 1 1 auto;
                    transition: 0.5s ease max-height;
                    overflow: hidden;

                    display: flex;
                    flex-direction: column;
                    padding: $contentBodyPadding;

                    &:not(.visible) {
                        height: 0;
                        max-height: 0;
                        padding: 0;
                    }

                    &.visible {
                        height: auto;
                        max-height: 600px;
                    }

                    button.sidebar-box-cta.flat.round.hollow.white.thin {
                        width: 100%;
                        text-transform: initial;

                        margin-bottom: 20px;

                        &:only-of-type {
                            margin-top: 20px;
                        }

                        &:last-of-type {
                            margin-bottom: 10px;
                        }
                    }

                    // Sidebar-specific overrides

                    .schedule-status-container {
                        .status {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 20px;

                            button {
                                align-self: center;
                            }
                        }
                    }
                }

                .other-boxes-container {
                    padding: 20px 0;
                    margin-left: (-$contentBodyPadding);
                    margin-right: (-$contentBodyPadding);
                    background-color: $COLOR_V3_BEIGE;

                    .grid-box {
                        padding: 15px;
                    }

                    .other-sidebar-box:last-of-type .grid-box {
                        margin-bottom: 0;
                    }
                }
            }

            &.open {
                .content-container {
                    .content-header {
                        border-color: rgba(255, 255, 255, 0.25);
                    }
                }
            }

            &.open .content-container::before {
                opacity: 1;
            }

            &.open:not(.full-height) {
                height: auto;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .background-overlay {
                    opacity: 0.4;
                    pointer-events: all;
                }

                .content-container {
                    margin: 0;
                    padding: 0;
                }
            }

            &.open.full-height {
                .content-body {
                    max-height: 100%;
                    overflow-y: auto; // fallback for Firefox
                    overflow-y: overlay; // see comment in `app_main_container.scss` for explanation of "overlay"

                    .mobile-content-html {
                        min-height: 100vh;
                        height: auto;
                    }
                }
            }

            &[name="graduated"] {
                .other-boxes-container {
                    margin-left: -10px;
                    margin-right: -10px;
                }

                .cordova & {
                    .other-boxes-container {
                        margin-left: -10px;
                        margin-right: -10px;
                        margin-top: 1vh;
                    }
                }
            }

            &.has-custom-style {
                .content-container {
                    .content-body {
                        padding: 0;
                    }
                }
            }
        }
    }
}

student-dashboard-program-box,
student-dashboard-admissions-info-box,
student-dashboard-sidebar-box {
    .sidebar-box-cta.flat.round.hollow.white.thin {
        padding: 10px 30px;
        font-weight: $FONT_WEIGHT_MEDIUM;
        letter-spacing: 0.25px;

        &:hover {
            background: white;
            color: $COLOR_V3_BLUE_DARK;
        }
    }
}

button.sidebar-btn.border {
    background: none;
    outline: none;
    border: 2px solid $COLOR_V3_BEIGE_DARKER;
    font-size: 12px;
    line-height: 25px;
    font-weight: $FONT_WEIGHT_SEMIBOLD;
    text-transform: uppercase;
    padding: 1px 15px 0;
    border-radius: 4px;
    color: $COLOR_V3_BEIGE_BEYOND_DARK;
    transition: 0.25s ease;
    margin-top: 12px;

    &:hover {
        border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    &.white {
        border-color: rgba($COLOR_V3_WHITE, 0.8);
        color: $COLOR_V3_WHITE;
        margin-top: 0;

        &:hover {
            border-color: $COLOR_V3_WHITE;
        }
    }
}

.app-main-container > ng-include > [ng-view] > div.schedule-open,
.app-main-container > ng-include > [ng-view] > div.enrollment-open,
.app-main-container > ng-include > [ng-view] > div.id-verification-open,
.app-main-container > ng-include > [ng-view] > div.mobile-sidebar-box-open {
    overflow: hidden;

    @media (min-width: $screen-md-min) {
        overflow: auto;
    }
}

.popover.event-group-popover,
.event-group-mobile-alert {
    // overrides for popover, which is shown when in wider viewports
    &.popover.event-group-popover {
        max-width: 320px;
        padding: 0;
        border-radius: 8px;

        .popover-content {
            padding: 9px 0 3px;

            .event-group-header {
                &::before {
                    content: "";
                    display: block;
                    flex: 0 1 25px;
                }
            }
        }

        .scroll-shadow {
            position: absolute;
            width: 100%;
            height: 40px;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.8) 50%,
                rgba(0, 212, 255, 0) 100% /* transparent */
            );
            bottom: 0;
            left: 0;
            pointer-events: none;
        }

        .arrow {
            border-left-color: transparent;
            border-right-color: transparent; // rtl
        }
    }

    // overrides for alert modal, which is shown when at mobile width
    &.event-group-mobile-alert.modal.no-header {
        max-width: inherit;
        padding: 15px;

        .modal-dialog .modal-content .modal-body {
            padding: 9px;

            .scroll-shadow {
                display: none;
            }

            .event-group-date-sections {
                max-height: inherit;
            }

            button.close {
                top: 15px;
                right: 15px;

                [dir="rtl"] & {
                    right: auto;
                    left: 15px;
                }
            }
        }
    }

    .event-group-content-wrapper {
        position: relative;
    }

    .event-group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        button.close {
            display: inline-block;
            opacity: 0.5;
            font-size: 40px;
            font-weight: normal;
            margin-top: -7px;
            display: flex;
            align-items: center;
            flex: 0 1 25px;
        }

        .event-group-title {
            font-size: 20px;
            font-weight: 600;
            color: $COLOR_V3_BLACK;
            text-align: center;
            margin: 10px auto 20px;
            opacity: 0.75;
        }
    }

    .event-group-date-sections {
        max-height: 425px;
        overflow-y: auto;
        position: relative;

        /* Hide scrollbar since scrollability is indicated by white gradient at base of element (see .scroll-shadow) */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome/Webkit */
        }

        .event-group-date-section {
            margin: 0 0 25px;

            .event-group-date {
                display: flex;
                margin: 0 0 15px;

                &::before,
                &::after {
                    content: "";
                    flex: 1 1 15%;
                    border-top: 1px solid $COLOR_V3_BEIGE;
                    margin-top: 0.4em;
                }

                span {
                    flex: 1 1 0%;
                    padding: 0 5px;
                    text-align: center;
                    white-space: nowrap;
                    color: $COLOR_V3_DARK_GREY;
                    font-weight: 600;
                    font-size: 13px;
                }
            }

            .event {
                @include event_listing;
                display: block;
                padding: 10px 15px;

                .event-main-info {
                    display: flex;
                    margin: 0 0 15px;

                    .content {
                        a.event-title {
                            font-size: 17px;
                            border: 0;
                            color: $COLOR_V3_BLACK;
                            font-weight: 500;
                        }

                        .event-date-tbd-description,
                        .event-date,
                        .event-location {
                            color: $COLOR_V3_DARK_GREY;
                        }
                    }
                }

                .event-misc {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .event-type {
                        font-size: 13px;
                    }

                    .view-button {
                        margin-left: auto; // right-align in flexbox
                        width: 86px;
                        height: 30px;
                        background-color: $COLOR_V3_CORAL;
                        color: $COLOR_V3_WHITE;
                        outline: none;
                        border-radius: 4px;
                        border: none;
                        position: relative;

                        &.anonymized {
                            background-color: $COLOR_V3_MAP_BLUE_DARK;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}
