.register-with-corporate-sponsor {
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        input[type="text"].MuiInputBase-input {
            height: 36px;

            &[name="reportsTagSuffix"] {
                padding-left: 14px;
            }
        }
    }
}
