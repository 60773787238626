body {
    .popover {
        border-radius: 8px 0px 0px 0px 8px 8px 8px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        border: none;
        font-family: $FONT_FAMILY;

        // overlay the continue button that has z-index 10000
        z-index: 10001;

        a {
            color: $COLOR_V3_CORAL;
            border-bottom: 1px dashed $COLOR_V3_CORAL;
        }
    }
}

body.popover-open {
    -webkit-overflow-scrolling: auto; // disable inertial scrolling when popover is open
}
