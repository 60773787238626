@keyframes bounceText {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -12px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -6px, 0);
    }

    90% {
        transform: translate3d(0, -3px, 0);
    }
}

.cf_answer_list {
    /* markdown will add p tags to button text */
    p {
        display: inline;
    }

    .check-many-caption {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        margin: 0;

        .caption {
            display: inline-block;
            margin-bottom: 5px;
        }

        // rtl-content overrides
        div[dir="rtl"] & {
            font-size: 15px;
        }

        .frame-container.gte-sm & {
            .caption {
                margin-bottom: 10px;
            }

            font-size: 15px;

            // rtl-content overrides
            div[dir="rtl"] & {
                font-size: 18px;
            }
        }

        &.bounce {
            animation: bounceText, bounceText, bounceText;
            animation-delay: 0s, 4s, 8s;
            animation-duration: 1s;
            transform-origin: center bottom;
        }

        &.image-hotspot {
            position: relative;
            top: -25px;
        }
    }

    &[renderer="buttons"] {
        button.primary {
            &.selected:not(.incorrect):not(.correct) {
                background-color: $COLOR_ANSWER_NEUTRAL;

                &:disabled,
                &:hover:enabled,
                &:active:enabled,
                &:focus:enabled {
                    background-color: $COLOR_ANSWER_NEUTRAL;
                }
            }

            .button_image {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
            }

            &.checkbox-button {
                &.unvalidated {
                    //---------------------------
                    // De-Selected Answer Styling
                    //---------------------------

                    &.unselected,
                    &.unselected:hover,
                    &.unselected:active {
                        div.icon {
                            right: -10px;
                            top: -20px;
                            opacity: 0.7;
                            width: 20px;
                            height: 20px;
                            border-radius: 3px;
                            background: $COLOR_V3_WHITE;
                            border: 2px solid $COLOR_V3_WHITE;
                            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
                        }

                        &:disabled div.icon {
                            opacity: 0;
                        }
                    }
                }
            }

            .answer-count,
            .correct-incorrect {
                position: absolute;
                top: 5px;
                right: 5px;
                color: $COLOR_V3_BEIGE_DARKER;
                font-size: 13px;
                line-height: 1em;
                .tile-prompt-board & {
                    color: rgba($COLOR_V3_WHITE, 0.8);
                }
                cf-matching-board & {
                    margin-right: -10px;
                }
            }
            .answer-count {
                right: 25px;
            }
            .message-preview {
                position: absolute;
                top: 0px;
                left: 3px;
                z-index: 100;
                .lesson_popover {
                    margin-top: 20px !important;
                    color: $COLOR_V3_BEIGE_DARKER;
                    font-size: 13px;
                    line-height: 1em;
                    padding: 1px 4px;
                    background-color: transparent;
                    border: none;
                    .tile-prompt-board & {
                        color: rgba($COLOR_V3_WHITE, 0.8);
                    }
                }
            }
        }
    }
}
