.pagination-container {
    display: inline-block;

    .results-found,
    .limit-link {
        display: inline-block;
        font-size: 13px;
        line-height: 30px;
        padding-right: 10px;
        vertical-align: top;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }
    .limit-link {
        &,
        &:hover {
            text-decoration: underline;
        }
    }

    .pagination {
        margin: 0px;
        margin-right: 10px;

        > li {
            &:not(.disabled):not(.active) > a {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                &:hover {
                    background-color: $COLOR_V3_BEIGE_LIGHT;
                }
            }

            &.disabled a {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
            }

            &.active a {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                color: $COLOR_V3_WHITE;
            }

            a {
                border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-weight: $FONT_WEIGHT_NORMAL;
                font-size: 13px;
                line-height: 27px;
                padding: 1px 10px 0px;
            }
        }
    }
}
