.partial-access-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 390px;

    color: $COLOR_V3_WHITE;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 0 0 2px rgba($COLOR_V3_WHITE, 0.25);
    animation: 0.5s fade-in-up-subtle-margin 0.35s;
    animation-fill-mode: both;

    @media (min-width: $screen-sm-min) {
        top: 95px;
        right: 20px;
        left: auto;
        transform: none;

        .events-visible & {
            right: $STUDENT_NETWORK_EVENTS_SIDEBAR_WIDTH_DESKTOP + 20px;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border: none;
        outline: none;
        background: url("~vectors/close-icon-white.svg") no-repeat 100% 50%;
        cursor: pointer;
    }

    .main-content {
        background: rgba($COLOR_V3_MAP_BLUE_DARK, 0.9);
        border-radius: 5px 5px 0 0;
        padding: 30px 15px;

        @media (min-width: $screen-sm-min) {
            padding: 30px;
        }

        &:last-child {
            border-radius: 5px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .apply-content {
        @extend .clearfix;
        background: $COLOR_V3_MAP_BLUE_DARKER;
        border-radius: 0 0 5px 5px;
        padding: 15px;

        @media (min-width: $screen-sm-min) {
            padding: 15px 30px;
        }

        p {
            width: calc(100% - 105px); // 105px = button width + 5px
            float: left;
            margin: 0;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 15px;

            span {
                text-transform: none;
                font-size: 15px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }

        button {
            color: $COLOR_V3_MAP_BLUE_DARKER;
            padding: 5px 12px;
            float: right;

            &:hover {
                color: $COLOR_V3_MAP_BLUE_DARKER !important;
            }
        }
    }
}
