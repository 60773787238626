@import "../../onboarding/image_data.scss";

.top-message-container {
    position: relative;
    .app-header & {
        display: none;
    }

    width: 100%;
    height: auto;
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: row;

    > .dismiss-button {
        position: absolute;
        top: 12px;
        right: 12px;
        border: none;
        background-color: unset;
        font-size: 20px;
        color: $COLOR_V3_GREY_COMPLETION;
        display: flex;
        padding: 0;

        html[dir="rtl"] & {
            right: unset;
            left: 12px;
        }
    }

    .top-message-mobile-dismiss {
        display: none;
    }

    .top-message-logo {
        flex-basis: 85px;
        margin: 0 5px 0 0;
        height: 85px;
        @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender-Quantic"));
        background-size: 85px 85px;
        background-repeat: no-repeat;
        background-position: center center;
        align-self: flex-end;

        &.smartly {
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender"));
        }

        &.miyamiya {
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender-MiyaMiya"));
        }

        &.valar {
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender-Valar"));
        }

        // rtl-language overrides
        html[dir="rtl"] & {
            margin: 0 0 0 5px;
        }
    }

    .top-message {
        display: flex;
        flex: 1 1 auto;
    }

    .top-message-message {
        flex: 1;
        border-radius: 10px;
        position: relative;
        padding: 12px;

        &.bubble {
            margin-left: 10px;
            background-color: white;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 10px;
            }

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: -9px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 25px 17px;
                border-color: transparent transparent white transparent;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: -9px;
                    border-width: 0 17px 25px 0;
                }
            }
        }

        p {
            margin: 0 0 10px;

            &:last-of-type {
                margin: 0;
            }

            width: 100%;
            font-size: 16px;
            font-weight: $FONT_WEIGHT_NORMAL;
            letter-spacing: -0.1px !important;
        }
    }

    &.centered-text,
    &.centered-text.mobile {
        .top-message-message p {
            text-align: center;
            // There's 15px of padding on the left side of the top-message-container and the dismiss button is 50px wide ande, which leaves a 35px offset.
            // Dividing that by two and applying it as the margin properly centers the text within the container.
            margin-left: 17.5px;
        }
    }

    &.lg-text,
    &.lg-text.mobile {
        .top-message-message p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.4px !important;
        }
    }

    &.mobile {
        .app-header & {
            display: flex;
        }

        margin: 0;
        width: 100%;
        padding: 5px 0 5px 15px;
        background: $COLOR_V3_MAP_BLUE_DARKER;
        border-bottom: 1px solid rgba($COLOR_V3_MAP_BLUE_DARKER, 0.5);
        color: white;

        &.institution-colors {
            color: black;
            background: $COLOR_V3_CORAL;
            border-bottom: 1px solid rgba($COLOR_V3_CORAL_DARK, 0.5);

            .valar & {
                background: $COLOR_V3_BLUE;
                border-bottom-color: rgba($COLOR_V3_BLUE_DARK, 0.5);
            }
        }

        // rtl-language overrides
        html[dir="rtl"] & {
            padding: 5px 15px 5px 0;
        }

        .top-message-logo {
            min-width: 60px;
            max-width: 60px;
            background-size: contain;
        }

        .top-message-message {
            padding: 10px 0;

            &.bubble {
                padding: 10px;
            }

            height: auto;
            align-self: center;

            p {
                font-size: min(3.5vw, 16px);
            }
        }

        .top-message-mobile-dismiss {
            display: block;
            flex-basis: 50px;

            .dismiss-button {
                background: transparent;
                border: none;
                color: $COLOR_V3_WHITE;
                font-size: 24px;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
