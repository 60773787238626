.stream-completed-link-box {
    $certificateWidthMobile: 84px;
    $certificateHeightMobile: 65px;
    $certificateWidthDesktop: 123px;
    $certificateHeightDesktop: 95px;

    $padding: 10px;

    $minHeightMobile: $certificateHeightMobile + $padding;
    $minHeightDesktop: $certificateHeightDesktop + 2 * ($padding) + 14px + $padding;

    display: block;
    position: relative;
    border-radius: 3px;
    padding-top: $padding;
    padding-bottom: $padding;
    padding-left: $certificateWidthMobile + $padding;
    margin-top: 0px;
    min-height: $minHeightMobile;

    // rtl-language overrides
    html[dir="rtl"] & {
        padding-left: 0px;
        padding-right: $certificateWidthMobile + $padding;
    }

    @media (min-width: $screen-sm-min) {
        margin-top: 15px;
        background: #ffffff;
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: $certificateWidthDesktop + 45px;
        min-height: $minHeightDesktop;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-left: 0px;
            padding-right: $certificateWidthDesktop + 45px;
        }
    }

    .heading {
        color: $COLOR_V3_BEIGE_DARKER;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .certificate-container {
        position: absolute;
        display: inline-block;
        left: 0px;
        top: 10px;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
        }

        @media (min-width: $screen-sm-min) {
            left: 15px;
            top: 10px;
            margin-right: 30px;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: 15px;
                margin-right: 0px;
                margin-left: 30px;
            }
        }

        .certificate {
            position: relative;
            background-image: url("~images/certificate_light_quantic.png");
            background-size: 100%;
            background-repeat: no-repeat;

            width: $certificateWidthMobile;
            height: $certificateHeightMobile;

            &.valar {
                background-image: url("~images/certificate_light_valar.png");
            }

            &.smartly {
                background-image: url("~images/certificate_light.png");
            }

            front-royal-spinner .wrapper {
                padding-top: 20px;
            }

            img {
                position: absolute;
                display: block;
                width: 40px;
                height: 40px;
                top: calc(50% - 20px);
                left: calc(50% - 20px);
            }

            @media (min-width: $screen-sm-min) {
                width: $certificateWidthDesktop;
                height: $certificateHeightDesktop;

                img {
                    width: 50px;
                    height: 50px;
                    top: calc(50% - 25px);
                    left: calc(50% - 25px);
                }

                front-royal-spinner .wrapper {
                    padding-top: 35px;
                }
            }
        }

        .view {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 15px;
            color: #ff4d63;
            line-height: 22px;
            width: 123px;

            @media (max-width: $screen-xs-max) {
                width: 84px;
                font-size: 10px;
                line-height: 12px;
                display: block;
                text-align: center;
            }
        }
    }

    .course-container {
        position: relative;
        min-height: $minHeightMobile;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 15px;
            min-height: $minHeightDesktop;
        }

        .row {
            @media (max-width: $screen-xs-max) {
                display: table;
                float: none;
                height: $minHeightMobile - $padding;

                .description-container {
                    display: table-cell;
                    float: none;
                    vertical-align: middle;
                }
            }
        }

        .description-container,
        .summary-container {
            @media (min-width: $screen-sm-min) {
                padding-left: 15px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 15px;
                }
            }
        }

        .description {
            color: $COLOR_V3_BEIGE_DARKER;
            font-size: 15px;
            line-height: 18px;
        }

        .title {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            // Mobile font size and weight
            // In 1-column layout
            font-size: $titleFontSizeMobile;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            line-height: $titleLineHeightMobile;
            margin-bottom: 0px;

            .no-touchevents &:hover {
                color: $COLOR_V3_CORAL;
            }
            .no-touchevents &:active,
            .touchevents &:active {
                color: $COLOR_V3_CORAL;
            }

            @media (min-width: $screen-sm-min) {
                margin-bottom: 10px;
            }

            // Desktop size
            @media (min-width: $screen-md-min) {
                font-size: $titleFontSizeDesktop;
                line-height: $titleLineHeightDesktop;
            }
        }

        .stream-summary {
            position: relative;
            color: $COLOR_V3_CORAL;
            font-size: 15px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            line-height: 18px;
            margin-bottom: 15px;
            margin-right: $padding;
            padding-left: 30px;
            cursor: pointer;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: $padding;
                margin-right: 0px;
                padding-left: 0px;
                padding-right: 30px;
            }

            img {
                position: absolute;
                left: 0px;
                top: calc(50% - 12.5px);
                width: 25px;
                height: 25px;
                display: inline-block;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                }
            }

            span {
                word-break: normal;
            }
        }
    }
}
