.completion-screen {
    margin: 0 auto;
    width: 100%;
    padding: 0;

    text-align: center;

    h1 {
        font-weight: $FONT_WEIGHT_BOLD;
        margin: 3px 0;
    }

    // NOTE: these are used for other calculations below
    $h2FontSize: 20px;
    $h2Height: 20px; // since there is just one line, it can be the same as font-size
    $h2BottomMargin: 8px;
    h2 {
        color: $COLOR_V3_WHITE;
        opacity: 0.5;
        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: $h2FontSize;
        height: $h2Height;

        // since this shows up right at the top of the page, it's easier
        // to just remove it's top spacing and trust the padding of the page
        margin: 0px 0px $h2BottomMargin;
        padding: 0px;
    }

    .share {
        width: 100%;
        max-width: 450px;

        // horizontally center
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        padding-left: 12px;
        padding-right: 12px;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 50%;
            transform: translateX(50%);
        }

        &.share-header {
            margin-top: 10px;
            margin-bottom: 6px;

            .course-icon {
                width: 55px;
                height: 55px;
                margin-right: 8px;
                background-repeat: no-repeat;
                background-size: cover;
                display: inline-block;
                vertical-align: text-bottom;

                @media (max-width: $screen-xs-max) {
                    display: none;
                    margin-right: 0px;
                }
            }

            .share-title-container {
                display: inline-block;

                .share-title {
                    font-size: 35px;
                    line-height: 35px;
                    font-weight: $FONT_WEIGHT_BOLD;
                    // vertically center when course-icon is displayed
                    display: table-cell;

                    @media (min-width: $screen-sm-min) {
                        font-size: 45px;
                        line-height: 45px;
                    }

                    @media (max-width: $screen-iphone5-max) {
                        font-size: 30px;
                        line-height: 30px;
                    }
                }
            }
        }

        .share-description {
            font-size: 15px;
            margin-bottom: 20px;

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
            }
        }
    }

    $paddingTopDefault: 8px;
    $paddingTopDesktop: 28px;
    .top {
        color: $COLOR_V3_WHITE;
        position: absolute;
        top: 0px;
        bottom: 50%;
        width: 100%;
        padding-top: $paddingTopDefault;

        @media (min-width: $screen-sm-min) {
            padding-top: $paddingTopDesktop;
        }
    }

    .bottom {
        background-color: $COLOR_V3_WHITE;
        color: $COLOR_V3_BLUE_COMPLETION;
        padding: 20px 0;

        @media (min-width: $screen-sm-min) {
            padding: 40px 0;
        }

        position: absolute;
        top: 50%;
        width: 100%;

        bottom: 0;

        h1 {
            color: $COLOR_V3_BLUE_COMPLETION;
            font-weight: $FONT_WEIGHT_BOLD;
            line-height: 72px;
        }
    }

    &.stream-completed {
        $h1FontSize: 40px;
        $h1LineHeight: $h1FontSize + 4px;
        // we allow for two lines of text here.  Anything more is unreasonable, and hidden
        $h1MaxHeight: 2 * $h1LineHeight;
        $h1Margin: 3px;
        h1 {
            font-weight: bold;
            margin: $h1Margin 0;
            font-size: $h1FontSize;
            line-height: $h1LineHeight;
            max-height: $h1MaxHeight;
            min-height: 0.75 * $h1MaxHeight; // if the header has only 1 line, this will vertically center the certificate
            overflow: hidden;
        }

        .v-center-bage-wrapper {
            $bottomPadding: 8px;
            // in mobile, there is only the h2, the h1 is hidden
            $headerSize: $h2Height + $h2BottomMargin;
            z-index: 1;
            position: relative;
            height: calc(100% - #{$headerSize} - #{$bottomPadding});

            @media (min-width: $screen-sm-min) {
                // on larger screens, we show the course title as well
                $headerSize: $h2Height + $h2BottomMargin + $h1MaxHeight + 2 * $h1Margin;
                max-height: calc(100% - #{$headerSize} - #{$bottomPadding});
            }

            img.completion-badge {
                display: block;
                margin: 0px auto;
                max-height: 100%;
                max-width: 90%;
                padding: 20px 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);

                &.offline {
                    padding: 0;
                    max-width: 80%;
                    max-height: 70%;
                    border: 5px solid white;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }

    .share-button-text {
        margin-left: 0px;
    }

    .share-on-facebook {
        @include share_on_facebook($COLOR_V3_BLUE_FACEBOOK, $COLOR_V3_WHITE);
    }

    .share-on-linkedin {
        @include share_on_linkedin($COLOR_V3_BLUE_LINKEDIN, $COLOR_V3_WHITE);
    }

    .share-on-twitter {
        @include share_on_twitter($COLOR_V3_BLUE_TWITTER, $COLOR_V3_WHITE);
    }

    .share-on-email {
        @include share_on_email($COLOR_V3_DARK_GREY, $COLOR_V3_WHITE);
    }

    .share-button {
        text-align: left;
        padding-left: 25px;
        width: 140px;
        margin: 4px;

        @media (min-width: $screen-sm-min) {
            padding-left: 40px;

            html:lang(es) & {
                width: 180px;
            }
        }
    }

    &.exam {
        .share-title-container,
        .course-icon {
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }
}
