.card-preview-with-fallback-placeholder-container {
    margin: 0px auto 40px;
    padding: 0px 8px;
    text-align: center;
    transform: translateZ(0); // fixes iOS rendering issue in Preview

    .card-list-item {
        text-align: left;
        padding-right: 0;
        max-width: 640px;
        margin: 0 auto;
    }

    .edit-profile-button {
        margin-top: 30px;
    }

    .complete-all-message {
        margin-bottom: 15px;
    }

    .empty-learner-img.desktop {
        display: none;
    }

    // looks a bit better to push it down some
    @media (min-width: $screen-sm-min) {
        margin-top: 20px;

        .empty-learner-img.mobile {
            display: none;
        }

        .empty-learner-img.desktop {
            display: inline;
        }
    }
}
