@font-face {
    font-family: "ProximaNova";
    src: url("~fonts/ProximaNova-RegIt.woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Bold.woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Medium.woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Regular.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Semibold.woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("~fonts/montserrat/Montserrat.woff") format("woff"),
        url("~fonts/montserrat/Montserrat.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("~fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("~fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "DMSans";
    src: url("~fonts/dm-sans/DMSans.woff") format("woff"), url("~fonts/dm-sans/DMSans.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DMSans";
    src: url("~fonts/dm-sans/DMSans-Bold.woff") format("woff"), url("~fonts/dm-sans/DMSans-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
