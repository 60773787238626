// see also: http://csshexagon.com/
.message-close-hexagon {
    position: relative;
    width: 40px;
    height: 23.09px;
    background-color: $COLOR_V3_BLUE;
    margin: 11.55px 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
    }

    &:before {
        bottom: 100%;
        border-bottom: 11.55px solid $COLOR_V3_BLUE;
    }

    &:after {
        top: 100%;
        width: 0;
        border-top: 11.55px solid $COLOR_V3_BLUE;
    }
}

.correct .message-close-hexagon {
    background-color: $COLOR_V3_GREEN;
    &:before {
        border-bottom-color: $COLOR_V3_GREEN;
    }
    &:after {
        border-top-color: $COLOR_V3_GREEN;
    }
}

.incorrect .message-close-hexagon {
    background-color: $COLOR_V3_YELLOW;
    &:before {
        border-bottom-color: $COLOR_V3_YELLOW;
    }
    &:after {
        border-top-color: $COLOR_V3_YELLOW;
    }
}
