paid-program-welcome-package {
    $verticalMargin: 30px;
    margin-top: $verticalMargin;
    display: block;

    hr {
        background-color: unset;
    }

    .welcome-section {
        margin: $verticalMargin 0px;

        .subheadline {
            color: $COLOR_V3_GREY_DARKER;
            font-size: 14px;
            text-align: center;
            margin-bottom: 6px;
            opacity: 0.8;

            @media (min-width: $screen-sm-min) {
                font-size: 16px;
            }
        }

        .headline {
            color: $COLOR_V3_BLACK;
            font-size: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-align: center;
            margin-bottom: $verticalMargin;

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
            }
        }

        &.classmates {
            .headline {
                margin-bottom: 0.35 * $verticalMargin;
            }
        }

        &.video {
            margin-bottom: 0px;

            .headline {
                margin-bottom: 0.65 * $verticalMargin;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &.program-guide {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .student-profile {
        $avatarWidth: 60px;
        $avatarBgWidth: 72px; // from the SVG
        $avatarMargin: 20px;

        margin-top: 12px;
        margin-bottom: 12px;
        padding-left: 0px;
        padding-right: 0px;

        @media (min-width: $screen-sm-min) {
            margin-top: #{$verticalMargin * 0.5};
            margin-bottom: #{$verticalMargin * 0.5};
            padding-left: 15px;
            padding-right: 15px;
        }

        &.clear {
            clear: both;
        }

        figure {
            position: relative;

            .avatar-bg {
                position: absolute;
                left: calc(50% - #{0.5 * $avatarBgWidth});
                top: 0px;
                z-index: 0;

                @media (min-width: $screen-sm-min) {
                    left: -6px;
                    top: 0px;
                }

                &.color-0 polygon {
                    fill: $COLOR_V3_TURQUOISE;
                }
                &.color-1 polygon {
                    fill: $COLOR_V3_CORAL;
                }
                &.color-2 polygon {
                    fill: $COLOR_V3_GREEN;
                }
                &.color-3 polygon {
                    fill: $COLOR_V3_YELLOW;
                }
                &.color-4 polygon {
                    fill: $COLOR_V3_BLUE;
                }

                &.flip {
                    transform: scaleX(-1);
                }
            }

            careers-avatar {
                position: relative;
                width: $avatarWidth;
                height: $avatarWidth;
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                margin-bottom: 40px;
                z-index: 1;
                float: left;

                @media (min-width: $screen-sm-min) {
                    margin-right: $avatarMargin;
                    margin-bottom: 0px;
                    float: unset;
                }
            }

            figcaption {
                display: block;
                vertical-align: top;
                font-size: 14px;
                color: $COLOR_V3_GREY_DARKER;
                margin-left: 70px;

                @media (min-width: $screen-sm-min) {
                    display: inline-block;
                    max-width: calc(100% - #{$avatarWidth + $avatarMargin + 5px});
                    margin-left: 0;
                }

                .student-name {
                    font-size: 16px;
                    color: rgba($COLOR_V3_BLACK, 0.8);
                }

                .student-work {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    color: $COLOR_V3_BLACK;
                }

                .student-education {
                    color: $COLOR_PIXELMATTERS_GREY_DARK;
                }

                .student-location {
                    color: rgba($COLOR_V3_BLACK, 0.8);
                }
            }
        }
    }

    .program-guide-download {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        height: 88px;

        @media (min-width: $screen-sm-min) {
            height: 100px;
        }
    }

    .program-guide-bg {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        background-image: linear-gradient(-180deg, #ff6680 0%, #ff4d63 100%);
        box-shadow: 0 4px 6px 0 rgba(92, 92, 92, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08);
        border: 0px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        color: $COLOR_V3_WHITE;
        transition: box-shadow 0.33s;

        .no-touchevents &:hover {
            box-shadow: 0px 10px 20px rgba(#4a4a4a, 0.25);
        }
        .no-touchevents &:active {
            box-shadow: 0px 5px 15px rgba(#4a4a4a, 0.25);
        }

        .logo {
            position: absolute;
            top: 15px;
            left: 20px;
            opacity: 0.7;
        }

        .badge {
            position: absolute;
            display: block;
            width: 72px;
            height: 62px;
            right: 14px;
            top: 14px;
            filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));

            @media (min-width: $screen-sm-min) {
                width: 77px;
                height: 67px;
                right: 14px;
                top: 18px;
            }
        }

        .download-footer {
            width: 100%;
            height: 74px;
            position: absolute;
            bottom: 0px;
            left: 0px;
            padding: 15px 20px;
            text-align: left;

            .download {
                span {
                    font-size: 18px;
                    line-height: 22px;
                    opacity: 0.6;
                    color: $COLOR_V3_WHITE;
                }

                img {
                    width: 14px;
                    height: 14px;
                }
            }

            .program-guide {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 22px;
                line-height: 1em;
            }
        }
    }
}
