share-buttons {
    display: block;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        width: 130px;
        padding-bottom: 20px;

        &:nth-child(odd) {
            float: left;
        }

        &:nth-child(even) {
            float: right;
        }
    }

    button {
        display: block;
        width: 130px;
        height: 40px;
        border: none;
        border-radius: 4px;
        color: $COLOR_V3_WHITE;
        text-align: center;
        transition: background 0.25s ease;
        outline: none;

        i {
            width: 20px;
            text-align: left;
        }

        $buttons: (
            ("facebook", $COLOR_V3_BLUE_FACEBOOK, $COLOR_V3_BLUE_FACEBOOK_HOVER)
                ("linkedin", $COLOR_V3_BLUE_LINKEDIN, $COLOR_V3_BLUE_LINKEDIN_HOVER)
                ("twitter", $COLOR_V3_BLUE_TWITTER, $COLOR_V3_BLUE_TWITTER_HOVER)
                ("email", $COLOR_V3_PURPLE_DARK, $COLOR_V3_PURPLE_NOT_QUITE_AS_DARK)
        );

        @each $button in $buttons {
            &.#{nth($button, 1)} {
                background: #{nth($button, 2)};

                &:hover:not(:disabled) {
                    .no-touchevents & {
                        background: #{nth($button, 3)};
                    }
                }

                &:active:not(:disabled) {
                    .touchevents &,
                    .no-touchevents & {
                        background: #{nth($button, 3)};
                    }
                }
            }
        }
    }
}
