$studentNetworkFilterBoxStickyMinWidth: 460px;

#filter-box {
    width: 100%;
    z-index: 10000000 !important; // one more zero than the Google icon
    transition: all 0.3s ease-out;

    @media (min-width: $studentNetworkFilterBoxStickyMinWidth) {
        max-width: 400px;
        padding: 10px;

        [lang="en"] & {
            max-width: 320px;
        }
    }

    @media (min-width: $screen-sm-min) {
        top: auto !important;
        bottom: 24px;
    }

    &.candidate-list-visible {
        max-width: 900px;
        height: calc(100vh - #{$APP_MENU_MOBILE_HEIGHT});
        padding: 0;

        .filter-box-container {
            border-radius: 0;
        }

        @media (min-width: $screen-sm-min) {
            height: 100%;
            bottom: 0;
        }

        .filter-box-container {
            height: 100%;
        }
    }
}

$controlsBoxShadow: 3px 3px 8px $COLOR_V3_MAP_BLUE_DARK;

@keyframes loading-line-animation {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 200%;
    }
}

.filter-box-container {
    background: $COLOR_V3_WHITE;
    padding: 8px;
    box-shadow: $controlsBoxShadow;
    overflow: hidden;

    @media (min-width: $studentNetworkFilterBoxStickyMinWidth) {
        border-radius: 10px;
    }

    .loading-line {
        position: relative;
        top: -4px;
        left: -8px;
        right: -8px;
        width: calc(100% + 16px);
        margin-top: -4px;
        height: 4px;
        box-shadow: 0px 2px 0px #ffffff;

        background-image: repeating-linear-gradient(
            90deg,
            #ff4d63 0%,
            #9557ec 25%,
            #1dd0b4 50%,
            #9557ec 75%,
            #ff4d63 100%
        );
        background-repeat: repeat;
        background-size: 200% 100%;
        background-position: 0%;

        animation: loading-line-animation 1s linear infinite;
        animation-fill-mode: both;
    }

    h4 {
        font-size: 12px;
        line-height: 10px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $COLOR_V3_MAP_BLUE_MID;
        margin: 1px 0 6px;
    }

    .button-container {
        .button-row {
            margin: 0 -4px 8px;
            display: table;
            width: calc(100% + 8px);

            > div {
                display: table-cell;
                padding: 0 4px;
            }

            &.tabs {
                // expand the tabs into the padding of the container
                width: calc(100% + 14px);
                margin: -6px -7px 8px;
                table-layout: fixed;

                // adjust the shading and border radius
                div {
                    padding: 0 1px;
                    width: calc(50% - 2px);

                    .btn {
                        border-radius: 0px;
                        background: #e2e5ea;
                        color: $COLOR_V3_MAP_BLUE_DARK;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        line-height: 41px;

                        .no-touchevents &:not(.selected):not(.emphasis):not(.advanced-search):hover {
                            background: darken(#e2e5ea, 5%);
                        }

                        &[name="students-tab"] {
                            [lang="am"] & {
                                font-size: clamp(9px, 3vw, 11px);
                            }
                            [lang="es"] & {
                                font-size: clamp(9px, 3.8vw, 14px);
                            }
                        }
                    }

                    &:first-of-type > .btn {
                        @media (min-width: $studentNetworkFilterBoxStickyMinWidth) {
                            border-top-left-radius: 8px;

                            [dir="rtl"] & {
                                border-top-left-radius: 0;
                                border-top-right-radius: 8px;
                            }
                        }

                        &.selected {
                            background-image: linear-gradient(134deg, #5c687c 0%, #9ca8b9 100%);
                            color: $COLOR_V3_WHITE;
                        }
                    }
                    &:last-of-type > .btn {
                        @media (min-width: $studentNetworkFilterBoxStickyMinWidth) {
                            border-top-right-radius: 8px;

                            [dir="rtl"] & {
                                border-top-right-radius: 0;
                                border-top-left-radius: 8px;
                            }
                        }

                        &.selected {
                            background-image: linear-gradient(224deg, #5c687c 0%, #9ca8b9 100%);
                            color: $COLOR_V3_WHITE;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.events-row {
                table-layout: fixed;

                .filter-button-column {
                    width: 130px;

                    @media (min-width: $studentNetworkFilterBoxStickyMinWidth) {
                        width: auto;
                    }
                }

                .summary-pills-inline {
                    // Hack: in mobile safari, this element is sized to 35px tall with what appears to be 1px of padding at the top.
                    // I couldn't get rid of it (browser bug with display: table-cell, perhaps?) so I'm hacking it by offseting up by 1px.
                    position: relative;
                    top: -1px;

                    .summary-pills {
                        display: block;
                        line-height: 26px;
                    }

                    // BUGFIX: Showing all events button position on "Student Network" tab is not adjoining the filter map button on android
                    // https://trello.com/c/PPJLuAtl/124-bug-showing-all-events-button-position-on-student-network-tab-is-not-adjoining-the-filter-map-button-on-android-05
                    vertical-align: top;
                }
                // Hack: unod the top: -1px hack above in Chrome
                @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                    .summary-pills-inline {
                        top: 0px;
                    }
                }

                @media (min-width: $screen-md-min) {
                    margin-bottom: 0px;

                    .summary-pills-inline {
                        display: none;
                    }
                }
            }
        }

        [name="advanced_search_active"] .summary-pills {
            margin-bottom: 8px;
        }
    }

    .btn {
        outline: none;
        border: none;
        width: 100%;
        border-radius: 5px;
        display: block;
        background: $COLOR_V3_MAP_BLUE_MID;
        color: $COLOR_V3_WHITE;
        padding: 0 10px;
        line-height: 34px;

        &.selected {
            background: $COLOR_V3_MAP_BLUE_DARK;
        }

        &.emphasis {
            background: $COLOR_V3_MAP_BLUE_DARK;

            &:hover {
                background: $COLOR_V3_MAP_BLUE_DARKER;
            }
        }

        .no-touchevents &:not(.selected):not(.emphasis):not(.advanced-search):hover {
            background: rgba($COLOR_V3_MAP_BLUE_MID, 0.85);
        }

        img {
            display: inline-block;
            margin-right: 4px;
            vertical-align: -4px;
        }
    }

    .btn.advanced-search {
        background: rgba($COLOR_V3_MAP_BLUE_MID, 0.4);
        color: $COLOR_V3_MAP_BLUE_DARK;

        .no-touchevents &:hover {
            background: rgba($COLOR_V3_MAP_BLUE_MID, 0.5);
        }
    }

    .summary-pills {
        @extend .clearfix;
        font-size: 0px; // prevent whitespace between ineline-block spans from creating space
        line-height: 15px;
        position: relative;
        max-height: 120px;
        overflow: hidden;

        @media (min-width: $screen-md-min) {
            margin-bottom: 8px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -8px;
            bottom: -1px;
            width: 24px;
            background: linear-gradient(to right, rgba($COLOR_V3_WHITE, 0) 0, $COLOR_V3_WHITE 66%);

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: -8px;
                background: linear-gradient(to left, rgba($COLOR_V3_WHITE, 0) 0, $COLOR_V3_WHITE 66%);
            }
        }

        span {
            display: inline-block;
            padding: 0 5px;
            border: 1px solid $COLOR_V3_MAP_BLUE_MID;
            color: $COLOR_V3_MAP_BLUE_MID;
            font-size: 12px;
            line-height: 32px;
            border-radius: 3px;
            margin-right: 5px;
            white-space: nowrap;
            max-width: 96%;
            overflow-x: hidden;
            text-overflow: ellipsis;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: 0;
                margin-left: 1px;
            }
        }
    }

    .navigation-header-span {
        span {
            display: inline-block;

            &:not(:last-child):after {
                content: ",";
            }
        }
    }

    .responsive-nav {
        @media (min-width: $screen-sm-min) {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            padding-top: 0;
            background: none;
            width: 220px;
            border-right: $SMARTLY_FORM_BORDER_STYLE;

            // rtl-language overrides
            html[dir="rtl"] & {
                border-right: none;
                border-left: $SMARTLY_FORM_BORDER_STYLE;
            }

            h4 {
                padding: 10px 15px 9px;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                border-bottom: $SMARTLY_FORM_BORDER_STYLE;
            }
        }

        @media (min-width: 930px) {
            bottom: 0;
        }

        .plain-text-button {
            color: $COLOR_V3_MAP_BLUE_DARK;

            .no-touchevents &.unstyled-button:hover:not(:disabled) {
                color: $COLOR_V3_MAP_BLUE_MID;
                opacity: 1;
            }
        }

        @media (max-width: $screen-xs-max) {
            choose-an-item[item-key="student_network_interest"] {
                .selectize-dropdown {
                    transform: translateY(-100%);
                    top: -20px !important;
                }
            }
        }
    }

    .controls-close {
        position: absolute;
        top: 0;
        right: -30px;
        z-index: 2;
        width: 30px;
        height: 30px;
        background: $COLOR_V3_WHITE url("~vectors/map-close.svg") no-repeat 50%;
        border: none;
        border-radius: 0 0 4px 0;
        box-shadow: $controlsBoxShadow;
        outline: none;
    }

    .front-royal-form-container.responsive-nav .selectize-input {
        padding: 8px 33px 8px 12px;
    }

    front-royal-spinner {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: $screen-sm-min) {
            transform: translate(-50%, -50%);
            margin-left: 110px; // half of the width of the advanced filters

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 110px; // half of the width of the advanced filters
                left: 0;
                right: 50%;
                transform: translate(50%, -50%);
            }
        }
    }

    .no-students-found {
        text-align: center;
        padding: 0 15px;
        position: absolute;
        width: 320px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (min-width: $screen-sm-min) {
            margin-left: 110px; // half of the width of the advanced filters

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 110px; // half of the width of the advanced filters
                left: 0;
                right: 50%;
                transform: translate(50%, -50%);
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        h1 {
            font-size: 24px;
            color: $COLOR_V3_MAP_BLUE_DARK;
            margin: 30px 0 15px;
        }

        p {
            color: $COLOR_V3_MAP_BLUE_MID;
            margin: 0;
        }
    }
}
