student-network-event-list {
    height: 100%;

    .list-container {
        height: 100%;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            max-height: unset;
        }

        // In some instances, we need to make some space for elements at the
        // bottom of the list.
        //
        // Annoyingly, Firefox ignores `padding-bottom` inside of scrollable
        // containers, so we can't just apply padding to the list here. Instead,
        // we create a pseudo-element that just sits at the bottom of the list.
        &.has-bottom-button {
            &::after {
                content: "";
                width: 100%;
                height: 30px;
                display: block;
            }
        }

        &.has-gradient {
            &::after {
                content: "";
                width: 100%;
                height: 20px;
                display: block;
            }
        }

        div.date-separator {
            display: block;
            width: 100%;
            height: 22px;
            font-family: $FONT_FAMILY;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_MAP_BLUE_DARK;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 10px;

            &::before,
            &::after {
                content: "";
                background-color: $COLOR_V3_BEIGE_MIDDARK;
                display: inline-block;
                height: 1px;
                position: relative;
                vertical-align: middle;
                width: 50%;
            }

            &::before {
                right: 5px;
                margin-inline-start: -50%;

                [dir="rtl"] & {
                    right: unset;
                    left: 5px;
                }
            }

            &::after {
                left: 5px;
                margin-inline-end: -50%;

                [dir="rtl"] & {
                    left: unset;
                    right: 5px;
                }
            }
        }
    }

    .no-events-found-container {
        position: relative;
        height: 100%;

        .no-events-found-content {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $COLOR_V3_MAP_BLUE_MID;

            @media (min-width: $screen-sm-min) {
                top: 30%;
            }

            i {
                font-size: 72px;
                margin-bottom: 15px;
            }

            .sub-text {
                font-size: 18px;
                font-weight: $FONT_WEIGHT_NORMAL;
            }
        }
    }
}
