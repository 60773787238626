.welcome {
    padding: 15px;
    height: auto;

    @media (min-width: $screen-sm) {
        padding: 50px 15px;
    }

    .welcome-container {
        width: 100%;
        margin: 0 auto;
        padding: 60px 15px;

        display: block;

        text-align: center;

        background: $COLOR_V3_WHITE;
        border: 1px solid #eee;
        border-radius: 4px;

        @media (min-width: $screen-sm) {
            width: 602px;

            padding: 70px 85px;
        }

        h1 {
            margin-top: 40px;
            margin-bottom: 0;

            font-weight: $FONT_WEIGHT_NORMAL;
            font-style: normal;
            @include font-size(30);
            color: #615a52;

            @media (min-width: $screen-sm) {
                margin-top: 30px;

                @include font-size(36);
            }
        }

        h2 {
            margin-top: 8px;
            margin-bottom: 45px;

            @include font-size(14);
            color: #b3b3b3;

            @media (min-width: $screen-sm) {
                margin-top: -6px;
                margin-bottom: 30px;

                @include font-size(18);
                line-height: 46px;
            }
        }

        h3 {
            margin-bottom: 32px;

            @include font-size(19);
            line-height: 46px;
            color: #615a52;
        }

        p {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-style: normal;
            @include font-size(16);
            line-height: 26px;
            color: #b3b3b3;

            strong {
                font-weight: $FONT_WEIGHT_BOLD;
                font-style: normal;
            }
        }

        .btn {
            width: 100%;
            padding: 21px 0 19px;
            margin-bottom: 0;
            margin-top: 35px;

            font-weight: $FONT_WEIGHT_BOLD;
            font-style: normal;
            @include font-size(21);

            @media (min-width: $screen-sm) {
                margin-bottom: 25px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .apple-devices-cta {
            display: none;

            img {
                margin: 30px 0;
            }

            a {
                font-weight: $FONT_WEIGHT_BOLD;
                font-style: normal;
                @include font-size(18);
                color: #ff4d63;
            }
        }
    }

    &.apple-device {
        .all-devices-cta {
            display: none;
        }
        .apple-devices-cta {
            display: block;
        }
    }
}
