.edit_playlist {
    > front-royal-spinner {
        margin-top: 200px;
        display: block;
    }

    .sub-text {
        font-size: 13px;
    }

    .stream-entry {
        height: 250px;
        padding: 15px;
        border-radius: 10px;
        clear: both;

        &:nth-child(odd) {
            background-color: $COLOR_V3_BEIGE_LIGHT;
        }

        &:nth-child(even) {
            background-color: transparent;
        }

        .stream-link-box .course-box {
            border: 1px solid $COLOR_V3_BEIGE_DARK;
        }

        .index-container {
            height: 100%;
            line-height: 100px; // 100% does not work for proportionally scaling  =[
            text-align: center;
            cursor: move;

            .index {
                font-size: 40px;
                font-weight: $FONT_WEIGHT_BOLD;
            }

            .remove-link {
                cursor: pointer;
            }
        }

        .description-container {
            height: 100%;
            textarea {
                width: 100%;
                height: 100%;
            }
        }
    }

    .back-link {
        display: block;
        margin: 20px 0px;
        color: $COLOR_V3_CORAL;
    }

    // overrides to styles defined in component_editor.scss
    button {
        &.remove-button {
            margin-top: 0;
        }
    }

    .images {
        position: relative;

        .no-icon-placeholder {
            height: 150px;
            width: 150px;
            border: 0px solid transparent;
            border-radius: 50%;
            background-color: $COLOR_V3_BEIGE_LIGHTEST;
            margin-bottom: 5px;
            text-align: center;
            line-height: 150px;
            color: $COLOR_V3_BEIGE_DARKEST;
        }

        img {
            height: 150px;
            &.coming-soon {
                opacity: 0.5;
            }
        }
        img.coming-soon-icon {
            height: 80px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 100;
        }
    }

    .actions {
        margin-top: 15px;
    }

    button {
        &.save_metadata {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_GREEN;
            border-color: $COLOR_V3_GREEN;

            &:hover,
            &:hover:enabled,
            &:active,
            &:active:enabled,
            &:focus {
                background-color: $COLOR_V3_GREEN;
            }
        }
    }

    textarea:disabled {
        background-color: lightgray;
        color: gray;
    }

    input:disabled {
        background-color: lightgray;
        color: gray;
    }
}
