// FIXME: we need to cleanup sessions.scss, sessions_with_payments.scss, _onboarding.scss etc.

.sign-in,
.institution-register {
    .form-container-inner {
        padding: 14px 14px 14px;

        @media (min-width: $screen-sm-min) {
            padding: 40px 40px 30px;
        }
    }
}
