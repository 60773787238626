network-map,
network-amap {
    &,
    > .map-wrapper,
    > .map-wrapper > div,
    > .map-wrapper > div > ng-map {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;

        &.showing-recommended-events {
            width: calc(100% - #{$STUDENT_NETWORK_EVENTS_SIDEBAR_WIDTH_DESKTOP});

            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
    }

    .gm-style {
        font: inherit !important;
    }

    #network-map-container {
        position: relative;
        transition: all 0.3s ease-out;
    }

    & > .map-wrapper.placeholder-map {
        background: url("~images/student_network_map_placeholder.png");
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-weight: 700;
            color: white;
            text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            text-align: center;
            line-height: 1.4;
        }
    }

    // the default style (max-width: 100% which
    // set in ~/Development/Pedago/back_royal/vendor/front-royal/styles/oreo/global/bootstrap_overrides.scss, line 136)
    // will impact the amap render in Android (display as the whiteboard), need to unset it.
    .amap-layers canvas {
        max-width: unset;
    }
}
