student-dashboard-learning-box {
    // The top message is displayed in the app header for mobile view, but we continue
    // to mount it here, though invisibly, to offset the content by the correct added header element height
    .top-message-container.mobile {
        visibility: hidden;
    }

    .top-container {
        // shared component styles
        .launch-button {
            @include flat-button-color($COLOR_V3_GREEN, $COLOR_V3_GREEN_DARK);
            text-transform: none; // override flat style's transform since these will all be uppercased anyhow
            font-size: 18px;
            font-weight: $FONT_WEIGHT_NORMAL;
            display: block;
            border-radius: 3px;

            &.inProgress {
                @include flat-button-color($COLOR_V3_PURPLE, $COLOR_V3_PURPLE_DARK);
            }

            &.completed {
                @include flat-button-color($COLOR_V3_CORAL, $COLOR_V3_CORAL_DARK);
            }

            .no-touchevents &:hover {
                background-color: lighten($COLOR_V3_GREEN, 3%);

                &.inProgress {
                    background-color: lighten($COLOR_V3_PURPLE, 3%);
                }

                &.completed {
                    background-color: lighten($COLOR_V3_CORAL, 3%);
                }
            }

            .no-touchevents &:active,
            .touchevents &:active {
                background-color: $COLOR_V3_GREEN_DARK;

                &.inProgress {
                    background-color: $COLOR_V3_PURPLE_DARK;
                }

                &.completed {
                    background-color: $COLOR_V3_CORAL_DARK;
                }
            }
        }

        // shared content box styles
        .grid-box.top-box {
            // on mobile, fill full width
            border-radius: 0px;
            margin-left: -10px;
            margin-right: -10px;
            border-bottom: 1px solid $COLOR_V3_BEIGE_MEDIUM;

            @media (min-width: $screen-sm-min) {
                padding: 30px;
                border-radius: 3px;
                margin: 0;

                border-bottom: none;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .header-box.complete {
                padding: 10px;
                margin: 0px -10px 0px -10px;
                background-color: $COLOR_V3_BLUE;

                @media (min-width: $screen-sm-min) {
                    padding: 15px;
                    margin: -30px -30px 30px -30px;
                }

                .title {
                    .icon.complete-header-icon {
                        width: 25.5px;
                        height: 28px;
                    }
                }

                .title {
                    color: $COLOR_V3_WHITE;
                    font-size: 15px;
                    line-height: 1em;
                    position: relative;
                    display: table;
                    height: auto;
                    min-height: 25px;
                    padding-left: 35px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        padding-right: 35px;
                        padding-left: 0px;
                    }

                    .title-text {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    @media (min-width: $screen-sm-min) {
                        min-height: 25px;
                        padding-left: 35px;
                    }

                    .icon {
                        position: absolute;
                        display: block;
                        left: 0px;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            left: auto;
                            right: 0px;
                        }

                        @media (min-width: $screen-sm-min) {
                            height: 25px;
                        }
                    }
                }
            }

            .content-box {
                .title {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: auto;
                    min-height: 50px;
                    text-align: center;
                    padding: 0;
                    width: 100%;
                    word-wrap: break-word;
                    overflow: visible; // so we can see all of the playlist progress icon
                    transition: color 0.25s;

                    @-moz-document url-prefix() {
                        height: 50px;
                    }

                    @media (min-width: $screen-sm-min) {
                        font-size: 25px;
                        text-align: left;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            padding-right: 70px;
                            padding-left: 0px;
                            text-align: right;
                        }
                    }

                    @media (min-width: $screen-sm-min) {
                        justify-content: flex-start;
                    }

                    .title-text {
                        display: block;

                        .playlist-title {
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                            line-height: 1.1em;
                            &.mba {
                                color: $COLOR_V3_YELLOW;
                            }
                        }
                        .playlist-subtitle {
                            display: block;
                            font-size: 12px;
                            line-height: 1.1em;
                            font-weight: $FONT_WEIGHT_NORMAL;
                            color: $COLOR_V3_BEIGE_DARKER;

                            @media (min-width: $screen-sm-min) {
                                font-size: 15px;
                            }
                        }
                    }

                    .icon {
                        position: relative;
                        display: block;
                        width: 22px;
                        height: 22px;
                        top: -2px;

                        &.mba-icon {
                            top: 7px;
                            margin-inline-end: 5px;
                        }

                        @media (min-width: $screen-sm-min) {
                            position: unset;
                            height: 50px;
                            width: auto;
                            top: 7px;

                            &.mba-icon {
                                min-width: 50px;
                                width: 50px;
                                height: 45.5px;
                                margin-inline-end: 15px;
                            }
                        }
                    }

                    playlist-map-content-item-icon {
                        display: block;
                        position: absolute;
                        left: -10px;
                        top: -15px;
                        margin: 0;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            left: auto;
                            right: -15px;
                        }

                        .playlist-map-content-item-icon-container {
                            margin: 0;
                        }
                    }

                    .button-container {
                        margin-inline-start: auto;
                        margin-inline-end: 0;
                        width: 180px;
                        text-align: right;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            text-align: left;
                        }
                    }

                    .playlists-toggle-button {
                        display: inline-block;
                        vertical-align: middle;
                        background-color: white;
                        border-radius: 50px;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        font-size: 15px;
                        color: $COLOR_V3_BEIGE_FOR_TEXT;
                        line-height: 12px;
                        text-transform: uppercase;
                        padding: 10px 25px;
                        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                        transition: 0.2s ease-in-out box-shadow;
                        border: 1px solid $COLOR_V3_BEIGE;

                        &.active {
                            display: flex;
                            align-items: center;
                        }

                        &:hover {
                            background-color: white;
                            color: $COLOR_V3_BEIGE_FOR_TEXT;
                            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
                        }

                        .icon {
                            position: unset;
                            display: inline-block;
                            min-width: 18px;
                            width: 18px;
                            height: 16px;
                            margin-right: 10px;
                        }

                        > span:not(.icon) {
                            vertical-align: middle;
                        }
                    }
                }

                .description {
                    font-size: 15px;
                    color: $COLOR_V3_BEIGE_DARKER;
                    margin: 15px 0px 0px 0px;
                }
            }
        }

        .course-recommendation-box-mobile {
            margin-top: 20px;

            stream-link-box .right-arrow {
                background-color: $COLOR_V3_YELLOW !important;
            }
        }
        .course-recommendation-box {
            .content-box {
                .title {
                    color: $COLOR_V3_CORAL;
                    cursor: pointer;

                    .no-touchevents &:hover {
                        color: $COLOR_V3_CORAL_DARK;
                    }
                }
            }
        }

        .active-playlist-box {
            @media (max-width: $screen-xs-max) {
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .content-box {
                @media (max-width: $screen-xs-max) {
                    padding-top: 3px;
                    padding-bottom: 3px;
                }
                .stream-title {
                    cursor: pointer;
                    transition: color 0.25s;

                    .no-touchevents &:hover {
                        color: $COLOR_V3_CORAL_DARK;
                    }
                }
            }
        }

        .playlists-top-box {
            margin-bottom: 0px;

            @media (max-width: $screen-xs-max) {
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .content-box {
                @media (max-width: $screen-xs-max) {
                    padding-top: 3px;
                    padding-bottom: 3px;
                }
            }
        }

        .playlists-box {
            background-color: $COLOR_V3_WHITE;

            @media (max-width: $screen-xs-max) {
                margin-left: -10px;
                margin-right: -10px;
                padding-top: 10px;
            }

            @media (min-width: $screen-sm-min) {
                border-radius: 0px 0px 4px 4px;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        display: block;
        margin-bottom: 35px;
    }
}
