@mixin pointy_button(
    $selector,
    $animationPrefix,
    // does not matter what this is, so long as it is unique amongst calls to pointy_button
    $fontSizeSM,
    $fontSizeXS,
    $baseBorderImage,
    $baseBackgroundColor,
    $baseBorderColor,
    $hoverBorderImage,
    $hoverBackgroundColor,
    $hoverBorderColor,
    $activeBorderImage,
    $activeBackgroundColor,
    $activeBorderColor,
    $selectedBorderImage,
    $selectedBackgroundColor,
    $selectedBorderColor,
    $completedBorderImage,
    $completedBackgroundColor,
    $completedBorderColor,
    $correctBorderImage,
    $incorrectBorderImage,
    $direction
) {
    $borderOptions: 18 18 18 42 fill;
    $borderWidth: 9px 9px 9px 21px; // try 9 9 9 21 for flipped

    // Why use 2x sized SVGs? Because in Safari, it renders SVGs as if they were raster images when there
    // are filters in them. This means that if we were to use 1x SVGs, in Safari they would show up blurry when
    // rendered on retina displays. Thus, we set the width and height of the SVGs to 2x their "natural" size.
    @if $direction == "right" {
        $borderOptions: 18 42 18 18 fill;
        $borderWidth: 9px 21px 9px 9px;
    }

    $correctAnimationNameWBorderImage: $animationPrefix + "CorrectAnimationWBorderImage";
    @keyframes $correctAnimationNameWBorderImage {
        0%,
        85% {
            border-image: $correctBorderImage $borderOptions;
            border-width: $borderWidth;
        }

        100% {
            border-image: $completedBorderImage $borderOptions;
            border-width: $borderWidth;
        }
    }

    $correctAnimationNameWOBorderImage: $animationPrefix + "CorrectAnimationWOBorderImage";
    @keyframes $correctAnimationNameWOBorderImage {
        0%,
        85% {
            border-color: $COLOR_ANSWER_CORRECT;
            background-color: $COLOR_ANSWER_CORRECT;
        }

        100% {
            border-color: $completedBorderColor;
            background-color: $completedBackgroundColor;
        }
    }

    html.borderimage {
        #{$selector} {
            border-width: $borderWidth;
            border-image: $baseBorderImage $borderOptions;
            box-shadow: none;
            background: none;
            // correct for the really wide border
            // necessitated by the use of border-image
            margin-bottom: -3px;
            margin-top: 0px;

            @include n_lines_high_button(
                3,
                $fontSizeSM,
                // font-size desktop
                $fontSizeXS,
                // font-size mobile
                18px // 2x the border width from pointy_button
            );

            /* **************
                All active/hover states are defined as classes
                as well for preloading purposes.  See FrontRoyal.AssetPreloader */

            &:active:enabled,
            &:hover:enabled,
            &.active:enabled,
            &.hover:enabled {
                border-color: transparent;
            }

            // csspie requires the extra not(.selected). wouldn't
            // be necessary normally
            &:hover:enabled,
            &.hover:enabled {
                border-image: $hoverBorderImage $borderOptions;
            }

            &:active:enabled,
            &.active:enabled {
                border-image: $activeBorderImage $borderOptions;
            }

            &.selected {
                border-image: $selectedBorderImage $borderOptions;
            }

            // challenges will be marked as completed, answer buttons
            // will be marked as correct
            &.completed {
                border-image: $completedBorderImage $borderOptions;

                // *** animation on completed for challenge buttons
                // Do not remove this 'animation-name: none; '
                // See comment below about IE and FF.  To be honest
                // I don't understand completely why this case needs it,
                // since there is no animation already, but it seems to work.
                animation-name: none; // Do not remove !!!
                animation-name: $correctAnimationNameWBorderImage;
                animation-duration: $ANSWER_VALIDATION_DURATION;
            }

            // challenges will be marked as completed, answer buttons
            // will be marked as correct
            &.correct {
                // we actually use the completed image here, but first
                // we animate through the correct image
                border-image: $completedBorderImage $borderOptions;

                // *** animation on correct for answer buttons
                // Do not remove this 'animation-name: none; '
                // FF will ignore the following line, because they
                // do not support animating border images.  But, in those
                // cases we want to remove the default animation, which
                // sets the background color.  So the first line is for FF.
                // The second for Webkit. (In those cases we won't get the
                // green background, but we will get the green checkbox and will just
                // immediately go to the disabled color)
                animation-name: none; // Do not remove !!!
                animation-name: $correctAnimationNameWBorderImage;
                animation-duration: $ANSWER_VALIDATION_DURATION;
            }

            &.incorrect {
                border-image: $incorrectBorderImage $borderOptions;
            }
        }
    }

    html:not(.borderimage) {
        #{$selector} {
            border-radius: 1px;
            background-color: $baseBackgroundColor;
            border-color: $baseBorderColor;
            $borderWidth: 3px;
            border-width: $borderWidth;
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
            margin-bottom: 6px;
            margin-top: 0px;

            @include n_lines_high_button(3, $fontSizeSM, $fontSizeXS, 2 * $borderWidth);

            // // why does this not work in IE9?
            // &:disabled {
            //     opacity: 1;
            // }

            &:hover:enabled,
            &.hover:enabled {
                background-color: $hoverBackgroundColor;
                border-color: $hoverBorderColor;
            }

            &:active:enabled,
            &.active:enabled {
                background-color: $activeBackgroundColor;
                border-color: $activeBorderColor;
            }

            &.selected {
                background-color: $selectedBackgroundColor;
                border-color: $selectedBorderColor;
            }

            // challenges will be marked as completed, answer buttons
            // will be marked as correct
            &.completed {
                background-color: $completedBackgroundColor;
                border-color: $completedBorderColor;
                animation-name: $correctAnimationNameWOBorderImage;
                animation-duration: $ANSWER_VALIDATION_DURATION;
            }

            // challenges will be marked as completed, answer buttons
            // will be marked as correct
            &.correct {
                // we actually use the completed color here, but first
                // we animate through the correct color (if animations are supported)
                animation-name: $correctAnimationNameWOBorderImage;
                animation-duration: $ANSWER_VALIDATION_DURATION;

                background-color: $completedBackgroundColor;
                border-color: $completedBorderColor;
            }

            &.incorrect {
                background-color: $COLOR_ANSWER_INCORRECT;
                border-color: $COLOR_ANSWER_INCORRECT;
            }
        }
    }
}
