edit-english-documents {
    .file-name.clickable {
        cursor: pointer;
    }

    .english_language_proficiency_documents_type {
        display: block;
        margin-bottom: 25px;
    }

    .english-proficiency-upload-container {
        margin-top: 28px;
    }

    .english-language-proficiency-comments-container {
        padding-top: 15px;

        textarea {
            min-height: 100px;
            width: 100%;
        }
    }

    .english-language-faq-link {
        font-size: 12px;
        margin-top: 8px;
        padding-left: 18px;

        @media (min-width: $screen-sm-min) {
            font-size: 14px;
        }

        i {
            float: left;
            margin: 2px 0 0 -18px;
        }

        a {
            color: $COLOR_V3_BLUE;
        }
    }
}
