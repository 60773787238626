lesson-finish-screen {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;

    .lesson-finish-screen {
        width: 447px;
        margin: 40px auto 0px;
        position: relative;

        @media (max-width: $screen-xs-max) {
            width: calc(100% - 20px);
            margin-top: 10px;

            // on screens where the continue button has a long title
            // that stretches over 3 lines, we need to add a extra
            // space on the bottom, so that, when scrolled all
            // the way down, the whole finish-screen element is visible
            // above the button.
            margin-bottom: 80px;
        }

        .offline-warning {
            align-items: center;
            background-color: $COLOR_V3_GREY_COMPLETION;
            border: 1px solid $COLOR_V3_GREY_LIGHT;
            color: $COLOR_V3_GREY_DARKISH;
            display: flex;
            font-size: 15px;
            justify-content: space-between;
            margin: 0px auto 10px;
            max-width: 448px;
            padding: 10px;

            @media (min-width: $screen-sm-min) {
                margin: 0px auto 40px;
                width: 90%;
            }

            $icon-size: 25px;

            i {
                background-color: $COLOR_V3_GREY_DARK;
                border-radius: 100%;
                color: $COLOR_V3_WHITE;
                height: $icon-size;
                line-height: $icon-size;
                margin-right: 10px;
                text-align: center;
                width: $icon-size;

                html[dir="rtl"] & {
                    margin-left: 10px;
                    margin-right: 0px;
                    order: 2;
                    transform: scaleX(-1);
                }
            }

            .message {
                font-size: 10px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                width: calc(100% - #{$icon-size});

                html[dir="rtl"] & {
                    order: 1;
                    text-align: right;
                }
            }
        }

        .lesson-finish-box {
            background-color: white;
            box-shadow: 0px 2px 20px 0px $COLOR_V3_BEIGE_DARKER;

            // on mobile, the bottom needs some room for the
            // feedback element or the failed message.  Note that
            // the failed message is taller, so if you change
            // this make sure to test that
            padding: 10px 10px 110px 10px;

            @media (min-width: $screen-sm-min) {
                // on desktop, the bottom needs a lot of room for the
                // absolutely positioned continue button,
                // which can be even taller if the next lesson's title is very long
                padding: 10px 24px 210px;
            }

            &.demo-mode {
                padding-bottom: 160px;
                background-color: $COLOR_V3_CORAL;

                .demo-image {
                    width: 95px;
                    height: auto;
                    margin: 15px auto;
                    display: block;
                }

                h1 {
                    color: $COLOR_V3_WHITE;
                }

                .demo-message {
                    text-align: center;
                    color: $COLOR_V3_WHITE;
                    font-size: 18px;
                    margin-bottom: 30px;
                }
            }

            h1 {
                color: $COLOR_V3_CORAL;
                text-align: center;
                margin-bottom: 24px;
                font-size: 25px;

                [dir="rtl"] & {
                    margin-top: 18px;
                }

                @media (max-width: $screen-xs-max) {
                    font-size: 20px;
                }
            }

            h2.subtitle,
            h2.completed-header {
                text-align: center;
                font-size: 18px;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                line-height: 20px;
                margin: 0px;
            }
            h2.completed-header {
                margin-top: 15px;
                margin-bottom: -10px;
            }

            hr {
                width: 100%;
                margin-top: 10px;
            }

            ul {
                list-style-type: none;
                padding: 0px;
                margin: 0px;
                min-height: 100px;
            }

            ul li {
                color: $COLOR_V3_CORAL_DARK;
                font-size: 18px;
                line-height: 20px;
                text-align: center;

                [dir="rtl"] & {
                    line-height: 28px;
                }
            }

            .brain {
                display: block;
                margin: 10px auto 7px auto;
                width: 130px;
                height: 58px;
            }

            // On desktop, the footer shows up inside the lesson-finish-screen
            // element, rather than in the footer (which is where it is in mobile
            // and where the continue button from the start screen is).  Here is
            // special styling for that case
            lesson-finish-screen-footer {
                button.flat.continue {
                    width: 100%;
                }
            }

            // user-select is disabled in bootstrap_overrides.scss with !important.
            // Overriding with !important here to allow key terms to be copied on
            // lesson finish screen (desktop only).
            .key-terms {
                user-select: text !important;
                .cordova & {
                    user-select: none !important;
                }
            }

            .finish-screen-container.test .lesson-title {
                color: $COLOR_V3_TURQUOISE;
            }

            .test-lesson-end {
                text-align: center;
                img {
                    margin-bottom: 25px;
                }
            }
        }
    }
}
