// Gradient background like on marketing pages
.gradient-backdrop {
    background-image: -webkit-linear-gradient(-45deg, #194695 0, #4d7aff 100%);
    background-image: -moz-linear-gradient(-45deg, #194695 0, #4d7aff 100%);
    background-image: -o-linear-gradient(-45deg, #194695 0, #4d7aff 100%);
    background-image: linear-gradient(135deg, #194695 0, #4d7aff 100%);

    // In mobile safari, if we use position: absolute; here, it interferes with jquery.payment
    // rendering of card and CVC fields after scrolling. No idea why. Work around it with position: fixed
    // only at mobile sizes.
    position: fixed;
    top: 0px;
    width: 100%;
    height: 360px;
    overflow: visible;
    z-index: -10;

    @media (min-width: $screen-sm-min) {
        position: absolute;
        height: 600px;
    }

    // sometimes we need extra room for the Michael Horn quote on
    // the side
    &.extra-tall {
        transform: scaleY(1.3);
    }

    .separator {
        width: 100%;
        max-width: 100%;
        max-height: 150px;
        position: absolute;
        bottom: -1px;
        left: 0;

        g {
            fill: #f5f3f1;
        }
    }

    .cordova & {
        display: none;
    }
}

.sessions-navigation-container {
    margin-bottom: 35px;
    @media (min-width: $screen-sm-min) {
        margin-bottom: 60px;
    }

    .cordova & {
        display: none !important;
    }

    // Copy-pasted from marketing CSS
    .nav {
        margin-top: 38px;
        padding-left: 15px;
        padding-right: 15px;
        display: table;
        z-index: 1;
    }
    .nav .nav-elements,
    .nav .nav-logo {
        display: table-cell;
        vertical-align: middle;
    }
    .nav .nav-logo a {
        display: block;
    }
    .nav .nav-logo a svg .lettering {
        fill: #fff;
    }
    @media (min-width: 768px) {
        .nav {
            margin-top: 35px;
        }
        .nav .nav-elements {
            float: right;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: left;
            }
        }
    }
    .nav .nav-elements #nav-toggle {
        cursor: pointer;
    }
    .nav .nav-elements #nav-toggle .nav-toggle-burger,
    .nav .nav-elements #nav-toggle .nav-toggle-close {
        background-repeat: no-repeat;
        background-size: 100%;
        float: right;

        // rtl-language overrides
        html[dir="rtl"] & {
            float: left;
        }
    }
    .nav .nav-elements #nav-toggle .nav-toggle-burger {
        display: block;
        width: 27px;
        height: 23px;
        background-image: url(~vectors/homepage/menu-icon.svg);
    }
    .nav .nav-elements #nav-toggle .nav-toggle-burger:hover {
        background-image: url(~vectors/homepage/menu-icon-hover.svg);
    }
    .nav .nav-elements #nav-toggle .nav-toggle-close {
        display: none;
        width: 23px;
        height: 23px;
        background-image: url(~vectors/homepage/close-icon.svg);
    }
    .nav .nav-elements #nav-toggle .nav-toggle-close:hover {
        background-image: url(~vectors/homepage/close-icon-hover.svg);
    }
    .nav .nav-elements #nav-toggle img {
        height: 23px;
    }
    .nav .nav-elements ul {
        margin: 0;
        width: 100%;
        display: table;
    }
    @media (min-width: 768px) {
        .nav .nav-elements ul {
            display: table-cell;
            vertical-align: middle;
            text-align: none;
        }
    }
    .nav .nav-elements ul li {
        padding: 0;
        margin-bottom: 16px;
        display: block;
        text-align: center;
        opacity: 0;
        -webkit-transition: opacity 1s;
        -moz-transition: opacity 1s;
        -o-transition: opacity 1s;
        transition: opacity 1s;
    }
    .nav .nav-elements ul li a {
        @include font-size(24);
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-style: normal;
        color: #866b80;
    }
    .nav .nav-elements ul li a.btn {
        @include font-size(20);
        color: #fff !important;
    }
    .nav .nav-elements ul li a.active,
    .nav .nav-elements ul li a:active,
    .nav .nav-elements ul li a:focus,
    .nav .nav-elements ul li a:hover {
        color: #5d5066;
        text-decoration: none;
    }
    @media (min-width: 768px) {
        .nav .nav-elements ul li {
            padding-left: 28px;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            opacity: 1;
        }
        .nav .nav-elements ul li a {
            @include font-size(16);
            color: rgba(255, 255, 255, 0.75);
        }
        .nav .nav-elements ul li a.btn {
            @include font-size(16);
        }
        .nav .nav-elements ul li a.active,
        .nav .nav-elements ul li a:active,
        .nav .nav-elements ul li a:focus,
        .nav .nav-elements ul li a:hover {
            color: #fff;
        }
    }
    .nav-container {
        height: 0;
        -webkit-transition: height 0.4s ease-in;
        -moz-transition: height 0.4s ease-in;
        -o-transition: height 0.4s ease-in;
        transition: height 0.4s ease-in;
    }
    .nav-container.open {
        height: 250px;
        position: relative;
        background-color: #fff;
        -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
        z-index: 4;
    }
    .nav-container.open #nav-toggle .nav-toggle-burger {
        display: none;
    }
    .nav-container.open #nav-toggle .nav-toggle-close {
        display: block;
    }
    .nav-container.open .nav-logo svg .img {
        stroke: #ff4d63;
    }
    .nav-container.open .nav-logo svg .img-beta,
    .nav-container.open .nav-logo svg .lettering {
        fill: #ff4d63;
    }
    .nav-container.open .nav-elements .nav-list {
        height: 350px;
        width: 100%;
        position: absolute;
        top: 35px;
        left: 0;
    }
    .nav-container.open .nav-elements .nav-list ul {
        position: absolute;
        top: 75px;
        left: 0;
        display: block !important;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .nav-container.open .nav-elements .nav-list ul li:last-of-type {
        margin-bottom: 0;
    }
    .nav-container.open .nav-elements .nav-list ul li.animate {
        opacity: 1;
    }
    .nav-container.open .nav-elements .nav-list ul li a.btn {
        margin-top: 13px;
        padding: 10px 23px;
    }
    .nav-container.open.small {
        height: 373px;
    }
    .nav-container.open.small .nav-elements .nav-list {
        height: 300px;
    }
    .nav-container.open.small .nav-elements .nav-list ul {
        top: 145px;
    }
}
