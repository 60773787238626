.img {
    display: inline-block;

    &.img_logo-quantic {
        $quantic: map-get($ONBOARDING_IMAGES, "quantic-logo");
        width: map-get($quantic, width);
        height: map-get($quantic, height);

        background-size: 145px 34px;

        @include background-image-onboarding($quantic);
    }

    &.img_logo-smartly {
        $smartly: map-get($ONBOARDING_IMAGES, "smartly-logo");
        width: map-get($smartly, width);
        height: map-get($smartly, height);

        background-size: 145px 34px;

        @include background-image-onboarding($smartly);
    }

    &.img_logo-miyamiya {
        $miyamiya: map-get($ONBOARDING_IMAGES, "miyamiya-logo");
        width: map-get($miyamiya, width);
        height: map-get($miyamiya, height);

        background-size: 145px 34px;

        @include background-image-onboarding($miyamiya);
    }

    &.img_logo-facebook {
        $fbook: map-get($ONBOARDING_IMAGES, "logo-facebook");
        width: map-get($fbook, width);
        height: map-get($fbook, height);

        background-size: 100%;

        @include background-image-onboarding($fbook);
    }

    &.img_logo-googleplus {
        $gplus: map-get($ONBOARDING_IMAGES, "logo-googleplus");
        width: map-get($gplus, width);
        height: map-get($gplus, height);

        background-size: 100%;

        @include background-image-onboarding($gplus);
    }
}

.animated_img {
    position: absolute;
    top: 50%;
    left: 50%;

    display: inline-block;

    transform: translate3d(-50%, -50%, 0);

    @each $KEY, $VALUE in $ONBOARDING_IMAGES_ANIMATED {
        &.animated_img__#{$KEY} {
            width: map-get($VALUE, width) * 0.65;
            height: map-get($VALUE, height) * 0.65;

            @include background-image-onboarding($VALUE);
            background-size: 100%;
        }
    }

    &.animated_img__illustration-mountain {
        top: auto;

        bottom: -40px * 0.65;

        transform: translate3d(-170px * 0.65, 0, 0);
    }

    &.animated_img__clouds-left {
        transform: translate3d(-380px * 0.65, -70px * 0.65, 0);
    }

    &.animated_img__clouds-right {
        transform: translate3d(95px * 0.65, -50% * 0.65, 0);
    }

    &.animated_img__snow {
        top: 20px * 0.65;

        transform: translate3d(0, 0, 0);
    }

    &.animated_img__puzzle-2 {
        transform: translate3d(-328px * 0.65, -162px * 0.65, 0);
    }

    &.animated_img__illustration-table {
        width: 735px;
        height: 272px;
        transform: translate3d(-50%, -125px, 0);
    }

    &.animated_img__illustration-application {
        width: 152px;
        height: 141px;

        transform: translate3d(-50%, 5px, 0);
    }
}

//
// Media queries.
//

// Medium screens.
@media (min-width: $screen-sm-min) {
    .img {
        &.img_logo-quantic,
        &.img_logo-smartly {
            width: 145px;
        }
    }

    .animated_img {
        @each $KEY, $VALUE in $ONBOARDING_IMAGES_ANIMATED {
            &.animated_img__#{$KEY} {
                width: map-get($VALUE, width);
                height: map-get($VALUE, height);

                @include background-image-onboarding($VALUE);
                background-size: 100%;
            }
        }

        &.animated_img__illustration-mountain {
            top: auto;

            bottom: -40px;

            transform: translate3d(-170px, 0, 0);
        }

        &.animated_img__clouds-left {
            transform: translate3d(-380px, -70px, 0);
        }

        &.animated_img__clouds-right {
            transform: translate3d(95px, -50%, 0);
        }

        &.animated_img__snow {
            top: 20px;

            transform: translate3d(0, 0, 0);
        }

        &.animated_img__puzzle-2 {
            transform: translate3d(-328px, -162px, 0);
        }

        &.animated_img__illustration-table {
            transform: translate3d(-50%, -90px, 0);
        }

        &.animated_img__illustration-application {
            width: 190px;
            height: 176px;

            transform: translate3d(-50%, 25px, 0);
        }
    }
}
