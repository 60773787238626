.stream-dashboard {
    .icon.lock {
        position: absolute;
        width: 28px;
        height: 33px;
        max-height: calc(100% - 4px); // don't let it get to big on mobile
        right: 8px;
        bottom: 2px;

        @media (min-width: $screen-sm-min) {
            bottom: 6px;
        }
    }

    .course-info-row {
        .col-xs-6:first-child {
            padding-right: 5px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 15px;
                padding-left: 5px;
            }
        }
        .col-xs-6:last-child {
            padding-left: 5px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 15px;
                padding-right: 5px;
            }
        }

        .course-information-button {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 13px;
            text-align: center;
            height: 54px;

            @media (min-width: 420px) {
                font-size: 18px;
                line-height: 20px;
            }

            @media (min-width: $screen-sm-min) {
                height: 54px;
            }

            @media (min-width: $screen-lg-min) {
                margin-bottom: 25px;
            }
        }
    }

    .beta-complete-coming-soon {
        p {
            margin: 0px;
            margin-bottom: 13px;
            padding: 20px;
            border: 3px solid $COLOR_V3_BEIGE_MIDDARK;
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            @media (min-width: $screen-sm-min) {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
    }

    .timer-box,
    .exam-schedule-box,
    .exam-score-box {
        background-color: $COLOR_V3_BEIGE_LIGHTEST;
        border-radius: 3px;
        padding: 10px 14px 17px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 14px;
        margin-bottom: 30px;

        // more margin and padding in two-column layout
        @media (min-width: $screen-sm-min) {
            margin-bottom: 40px;
            padding: 20px 40px 25px;
        }

        .header {
            font-size: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            margin-bottom: 8px;
        }

        hr.hr-hexagon {
            display: none;
            margin-bottom: 13px;

            // only show hr in two-column layout
            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }

    .timer-box {
        text-align: center;

        // show larger text in tablet,one-column layout or
        // in desktop, two-column layout
        @media (min-width: $screen-sm-min) {
            font-size: 18px;
        }

        .time-left {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 50px; // smaller than mocks.  Works better across different sizes
        }

        &.in-progress {
            .time-left {
                color: $COLOR_V3_CORAL;
            }
        }
    }

    .exam-schedule-box {
        .header {
            text-align: center;
        }

        // show larger text in tablet,one-column layout or
        // in desktop, two-column layout
        @media (min-width: $screen-sm-min) {
            font-size: 15px;
        }

        .header-2 {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    .exam-score-box {
        text-align: center;
        font-size: 72px;
        font-weight: $FONT_WEIGHT_SEMIBOLD;

        .header {
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;
        }

        hr.hr-hexagon {
            display: block;
        }
    }

    button[name="show-exam-schedule"] {
        margin-bottom: 38px;
    }

    .bookmark-button {
        position: relative;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 13px;
        text-align: center;
        height: 54px;
        padding-right: 35px;
        padding-left: 10px;
        white-space: normal;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-left: 35px;
            padding-right: 10px;
        }

        .title {
            vertical-align: top;
            text-align: center;
        }

        .subtitle {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 11px;
            line-height: 13px;
            text-align: center;
        }

        .bookmark-ribbon {
            right: 5px;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 5px;
                right: auto;
                width: auto;
            }
        }

        @media (min-width: $screen-sm-min) {
            font-size: 18px;
            line-height: 20px;
            padding-top: 5px;
            padding-bottom: 0px;
            height: 54px;

            &.white-and-coral {
                padding-top: 0px;
            }

            .bookmark-ribbon {
                right: 10px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 10px;
                    right: auto;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            text-align: left;
            margin-bottom: 25px;
            padding-right: 16px;
            padding-left: 16px;

            // rtl-language overrides
            html[dir="rtl"] & {
                text-align: right;
            }

            .title {
                margin-right: 35px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-right: 0px;
                    margin-left: 35px;
                }
            }

            .subtitle {
                font-size: 13px;
                line-height: 15px;
            }

            .bookmark-ribbon {
                right: 36px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 10px;
                    right: auto;
                }
            }
        }

        @media (min-width: $screen-lg-min) {
            font-size: 24px;
            height: 65px;

            .subtitle {
                line-height: 20px;
            }
        }

        html:lang(es) & {
            .title {
                @media (max-width: 330px) {
                    font-size: 13px;
                }

                @media (min-width: $screen-md-min) {
                    font-size: 16px;
                }

                @media (min-width: $screen-lg-min) {
                    font-size: 20px;
                }
            }
        }
    }

    // Use bold font in flat launch buttons
    .grid-box button.flat {
        font-weight: $FONT_WEIGHT_SEMIBOLD !important;

        padding-top: 5px !important;
        padding-bottom: 5px !important;

        @media (min-width: $screen-sm-min) {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }

    // Course Description
    .course-description-box {
        background: transparent;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        margin-bottom: 0px;
        overflow: visible;
        z-index: 2;

        // Title and Description Styles
        .title {
            overflow: visible;
            display: table;
            position: relative;
            font-size: 22px;
            line-height: 22px;
            color: $COLOR_V3_CORAL;
            margin-bottom: 10px;

            height: auto;
            padding-left: $appDashboardLeftRightPaddingSmall + 60px;
            min-height: 60px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 0px;
                padding-right: $appDashboardLeftRightPaddingSmall + 60px;
            }

            // Firefox doesn't support min-height on display: table elements
            @-moz-document url-prefix() {
                height: 60px;
            }

            @media (min-width: $screen-iphone6-max) {
                font-size: 28px;
                line-height: 28px;
            }

            // Course Icon Styles
            .course-icon-box {
                display: block;
                overflow: visible;
                position: absolute;
                left: 0px;
                top: calc(50% - 30px);
                margin: 0;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                }

                &.beta {
                    padding-bottom: 10px;
                    top: calc(50% - 40px);
                }

                img {
                    position: relative;
                    display: inline-block;
                    top: 0px;
                    left: 0px;
                    height: 60px;
                }

                .beta-course {
                    position: absolute;
                    display: block;
                    left: 50%;
                    bottom: 0px;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: $FONT_WEIGHT_NORMAL;
                    color: $COLOR_V3_WHITE;
                    background-color: $COLOR_V3_BETA_GREY;
                    border: 1px solid $COLOR_V3_BETA_GREY;
                    padding: 3px 8px 3px 8px;
                    border-radius: 16px;
                    transform: translateX(-50%);
                    white-space: nowrap;
                }
            }

            .title-text {
                display: table-cell;
                vertical-align: middle;
            }
        }

        .description-heading {
            margin: 20px 0px 0px;
            text-transform: uppercase;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 16px;
        }

        .description {
            font-size: 18px;
            line-height: 20px;
            color: $COLOR_V3_CORAL_DARK;

            @media (min-width: $screen-iphone6-max) {
                font-size: 15px;
                line-height: 18px;
            }
        }

        // Larger size for iPad Portrait and above
        @media (min-width: $screen-sm-min) {
            margin-bottom: 15px;

            .title {
                font-size: 50px;
                line-height: 50px;
                padding-left: $appDashboardLeftRightPaddingLarge + 110px;
                min-height: 110px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-left: 0px;
                    padding-right: $appDashboardLeftRightPaddingLarge + 110px;
                }

                // Firefox doesn't support min-height on display: table elements
                @-moz-document url-prefix() {
                    height: 110px;
                }

                .course-icon-box {
                    top: calc(50% - 55px);

                    &.beta {
                        top: calc(50% - 65px);
                    }

                    img {
                        height: 110px;
                    }

                    .beta-course {
                        font-size: 18px;
                        line-height: 18px;
                        padding: 5px 8px 3px 8px;
                        border-radius: 16px;

                        .no-touchevents & {
                            padding: 5px 28px 3px 8px;
                            cursor: pointer;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                padding: 5px 8px 3px 28px;
                            }
                        }

                        .info-icon {
                            display: none;
                            position: absolute;
                            right: 5px;
                            top: 4px;
                            font-size: 12px;
                            border: 1px solid $COLOR_V3_WHITE;
                            border-radius: 50%;
                            width: 18px;
                            height: 18px;
                            padding-top: 2px;
                            text-align: center;

                            .no-touchevents & {
                                display: inline-block;
                            }
                        }

                        .beta-message {
                            display: none;
                            position: absolute;
                            left: 0px;
                            top: 40px;
                            padding: 8px;
                            width: 300px;
                            font-size: 15px;
                            color: $COLOR_V3_WHITE;
                            background-color: $COLOR_V3_BETA_GREY;
                            border: 0px solid transparent;
                            border-radius: 4px;
                            white-space: normal;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                left: auto;
                                right: 0px;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                border-style: solid;
                                border-width: 0 10px 10px;
                                border-color: $COLOR_V3_BETA_GREY transparent;
                                display: block;
                                width: 0;
                                z-index: 1;
                                top: -10px;
                                left: 30px;

                                // rtl-language overrides
                                html[dir="rtl"] & {
                                    left: auto;
                                    right: 30px;
                                }
                            }
                        }

                        &:hover {
                            .beta-message {
                                display: block;
                            }
                        }
                    }
                }
            }

            .description {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 0px;
            }
        }
    }

    // Start Course Box Styles
    .start-course-box {
        margin-bottom: 10px;
        padding-right: 235px;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-left: 235px;
            padding-right: 15px;
        }

        .callout {
            font-size: 23px;
            line-height: 26px;
            padding-top: 7px;
            padding-bottom: 7px;
            display: inline-block;
            color: $COLOR_V3_CORAL_DARK;
        }

        button {
            position: absolute;
            display: inline-block;
            width: 218px;
            right: 13px;
            top: 13px;
            height: calc(100% - 26px);
            background-color: $COLOR_V3_GREEN;
            &:hover {
                background-color: lighten($COLOR_V3_GREEN, 3%);
            }

            &.inProgress {
                background-color: $COLOR_V3_PURPLE;
                &:hover {
                    background-color: lighten($COLOR_V3_PURPLE, 3%);
                }
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 13px;
                right: auto;
            }

            // In the start-course-box button, we show a spinner in place of text when the user
            // is loading BioSig.
            // We need to unset some of the front-royal-spinner defaults for it to display properly
            // within the button.
            front-royal-spinner {
                margin: 0 auto;
                text-align: unset;
                height: 18px;

                // We also want the background to be white as the default Coral is jarring on
                // any variation of the button's colors.
                .wrapper div {
                    background-color: $COLOR_V3_WHITE;
                }
            }
        }

        // Mobile sizes
        @media (max-width: $screen-xs-max) {
            margin-bottom: 13px;
            padding-right: 15px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 15px;
            }

            .callout,
            button {
                font-size: 20px;
                display: block;
                text-align: center;
                padding: 0;
            }

            .callout {
                line-height: 1.2em;
            }

            button {
                position: relative;
                width: 100%;
                margin-top: 10px;
                right: 0;
                top: 0;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 0;
                    right: auto;
                }
            }
        }
    }

    // Caption for chapter list horizontal divider
    .hr-chapter-list-caption {
        margin-top: 10px;
        @media (min-width: $screen-md-min) {
            margin-top: 20px;
        }
    }

    // Chapter List Styles
    .chapter-box {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 30px;
        }

        // Prevent selection on devices that are touch-enabled
        .touchevents & {
            @include user-select(none);
        }
        $playButtonWidthLarge: 170px;
        $playButtonWidthSmall: 150px;

        // Top Header Styles: Chapter Index, Divider, Progress
        .header-box {
            &.top {
                position: relative;
                background: $COLOR_V3_CORAL;
                text-align: left;
                color: $COLOR_V3_WHITE;
                margin-bottom: 0px;
                cursor: pointer;

                // Header Box Top Row
                .chapter-header-box-index-row {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    // Chapter Index Styles
                    .chapter-index {
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        font-size: 22px;
                        line-height: 35px;
                    }

                    .num-lessons-completed {
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: $FONT_WEIGHT_MEDIUM;
                    }

                    @media (min-width: $screen-sm-min) {
                        .chapter-index {
                            font-size: 24px;
                            line-height: 45px;
                        }

                        .num-lessons-completed {
                            font-size: 18px;
                        }
                    }
                }
            }

            // Bottom Expanding Bar Styles
            &.bottom {
                background-color: $COLOR_V3_WHITE;
                font-weight: $FONT_WEIGHT_NORMAL;
                position: relative;
                margin-top: 0px;
                cursor: pointer;
                color: $COLOR_V3_BEIGE_DARKEST;
                transition: background-color 0.2s;
                text-align: center;

                font-size: 18px;
                line-height: 35px;

                @media (min-width: $screen-sm-min) {
                    text-align: right;
                    font-size: 18px;
                    line-height: 45px;
                }

                .collapse-control-box-title {
                    position: relative;
                    text-transform: uppercase;
                    @include user-select(none);
                }

                // Hover, active styles
                .no-touchevents &:hover {
                    color: $COLOR_V3_CORAL;
                }

                &:active:not([disabled]) {
                    background-color: $COLOR_V3_BEIGE_LIGHTEST;

                    .collapse-control-box-title {
                        top: 2px;
                    }
                }

                // Icon for collapsing and expanding
                .expand-collapse-icon {
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 12px;
                    top: 12px;
                    left: 15px;
                    transition: transform 0.25s;

                    @media (min-width: $screen-sm-min) {
                        top: 17px;
                        left: 15px;
                    }
                }

                &.expanded .expand-collapse-icon {
                    transform: rotateZ(180deg);
                }
            }
        }

        // Chapter Title Info Box Styles
        .chapter-info {
            position: relative;

            // Title of the lesson
            .title {
                height: auto;
                color: $COLOR_V3_BEIGE_DARKER;

                font-size: 22px;
                line-height: 24px;
                margin-top: 0px;
                margin-bottom: 10px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    line-height: 1.5em;
                    overflow: visible;
                }

                @media (min-width: $screen-sm-min) {
                    font-size: 24px;
                    line-height: 27px;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        line-height: 27px;
                        overflow: visible;
                    }
                }
            }

            // Upper Horizontal Divider
            hr.hr-chapter {
                height: 1px;
                background-color: $COLOR_V3_BEIGE_MIDDARK;
                // Counter-act default padding (mobile)
                margin-left: -10px;
                margin-right: -10px;
                margin-bottom: 0px;

                // Padding at larger sizes (iPad Portrait and larger)
                @media (min-width: $screen-sm-min) {
                    margin-left: -30px;
                    margin-right: -30px;
                }
            }
        }

        // Lesson Content Box Styles
        .lesson-info {
            &.ng-enter,
            &.ng-hide-remove {
                transition: all 0.8s ease 0s;
            }

            &.ng-leave,
            &.ng-hide-add {
                transition: all 0.8s ease 0s;
            }

            // start position when putting frame on screen
            &.ng-enter,
            &.ng-hide-remove {
                max-height: 0px;
            }

            // end position when removing frame from screen
            &.ng-leave.ng-leave-active,
            &.ng-hide-add.ng-hide-add-active {
                max-height: 0px;
            }

            // stable position when frame is on the screen
            &.ng-leave,
            &.ng-enter.ng-enter-active,
            &.ng-hide-add,
            &.ng-hide-remove.ng-hide-remove-active {
                max-height: 1600px;
            }

            .lesson-box-container {
                // Lesson List Boxes
                .lesson-box {
                    border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;

                    // At small sizes, expand outside of our bounds so that background-color works for hover/active
                    @media (max-width: $screen-xs-max) {
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-left: -10px;
                        margin-right: -10px;
                    }
                    // we render things under this as table-rows, but
                    // IE and FF won't layout properly without explicitly
                    // belonging to a table element
                    a {
                        display: table;
                        width: 100%;

                        &.disabled {
                            pointer-events: none;
                        }
                    }

                    &[disabled] {
                        .lesson-details-box,
                        .lesson-details-box.test {
                            .lesson-description-box {
                                .lesson-index-box .lesson-index,
                                .lesson-index-box .lesson-divider,
                                .lesson-title {
                                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                                }
                            }
                        }
                    }

                    // Just Hover Styles
                    .no-touchevents &:hover.clickable {
                        .lesson-progress-box {
                            background-color: lighten($COLOR_V3_BEIGE_LIGHTEST, 1%);
                        }

                        .lesson-details-box {
                            .lesson-description-box {
                                .lesson-index,
                                .lesson-divider,
                                .lesson-title {
                                    color: $COLOR_V3_CORAL;
                                }
                            }
                            &.assessment,
                            &.test {
                                .lesson-description-box {
                                    .lesson-index,
                                    .lesson-divider,
                                    .lesson-title {
                                        color: $COLOR_V3_TURQUOISE;
                                    }
                                }
                            }
                            &.coming_soon {
                                .lesson-progress-box {
                                    background-color: $COLOR_V3_BEIGE_LIGHTEST;
                                }
                                .lesson-description-box {
                                    .lesson-index,
                                    .lesson-divider,
                                    .lesson-title {
                                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                                    }
                                }
                            }
                        }

                        .launch-button {
                            background-color: lighten($COLOR_V3_GREEN, 3%);

                            &.inProgress:not(:disabled) {
                                background-color: lighten($COLOR_V3_PURPLE, 3%);
                            }

                            &.completed:not(:disabled) {
                                background-color: lighten($COLOR_V3_CORAL, 3%);
                            }
                        }

                        .lesson-details-box {
                            &.assessment,
                            &.test {
                                .launch-button:not(:disabled) {
                                    background-color: lighten($COLOR_V3_TURQUOISE, 3%);
                                }
                            }
                        }
                    }

                    // Just Active Styles
                    .no-touchevents &:active:not([disabled]),
                    .touchevents &:active:not([disabled]) {
                        background-color: $COLOR_V3_BEIGE_LIGHTEST;

                        .lesson-details-box {
                            .lesson-description-box {
                                .lesson-index,
                                .lesson-divider,
                                .lesson-title {
                                    color: $COLOR_V3_CORAL_DARK;
                                }
                            }
                            &.assessment,
                            &.test {
                                .lesson-description-box {
                                    .lesson-index,
                                    .lesson-divider,
                                    .lesson-title {
                                        color: $COLOR_V3_TURQUOISE_DARK;
                                    }
                                }
                            }

                            &.coming_soon {
                                .lesson-progress-box {
                                    background-color: $COLOR_V3_BEIGE_LIGHTEST;
                                }
                                .lesson-description-box {
                                    .lesson-index,
                                    .lesson-divider,
                                    .lesson-title {
                                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                                    }
                                }
                            }
                        }

                        .lesson-progress-box {
                            background-color: $COLOR_V3_BEIGE_MIDDARK;
                        }

                        .lesson-footer-box {
                            background-color: $COLOR_V3_BEIGE_DARK;
                        }

                        .lesson-footer-box .arrow-col:after {
                            border-bottom-color: $COLOR_V3_BEIGE_DARK;
                        }

                        .lesson-details-box .lesson-description-box {
                            .lesson-time-estimate,
                            .lesson-description {
                                color: $COLOR_V3_BEIGE_DARKEST;
                            }
                        }

                        .launch-button:not(:disabled) {
                            background-color: $COLOR_V3_GREEN_DARK;

                            &.inProgress {
                                background-color: $COLOR_V3_PURPLE_DARK;
                            }

                            &.completed {
                                background-color: $COLOR_V3_CORAL_DARK;
                            }
                        }
                        .lesson-details-box {
                            &.assessment,
                            &.test {
                                .launch-button:disabled {
                                    background-color: $COLOR_V3_TURQUOISE_DARK;
                                }
                            }
                        }
                    }

                    // Lesson Title, Index, Estimated Time, Description, etc.
                    .lesson-details-box {
                        // Mobile only adjustments for rightmost arrow
                        @media (max-width: $screen-xs-max) {
                            position: relative;
                            padding-right: 30px;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                padding-left: 30px;
                                padding-right: 0px;
                            }

                            &.not_started .lesson-progress-box:not([disabled]) {
                                background-color: $COLOR_V3_GREEN;
                            }

                            &.in_progress .lesson-progress-box:not([disabled]) {
                                background-color: $COLOR_V3_PURPLE;
                            }

                            &.completed .lesson-progress-box:not([disabled]) {
                                background-color: $COLOR_V3_CORAL;
                            }

                            &.assessment,
                            &.test {
                                .lesson-progress-box:not([disabled]) {
                                    background-color: $COLOR_V3_TURQUOISE;
                                }
                            }

                            &.coming_soon .lesson-progress-box {
                                background-color: $COLOR_V3_BEIGE_DARK !important;
                            }

                            .lesson-progress-box[disabled] {
                                background-color: $COLOR_V3_BEIGE_DARK;
                            }
                        }

                        // Lesson description (only shown at non-mobile sizes)
                        .lesson-description-box {
                            padding-top: 15px;
                            padding-bottom: 15px;

                            // Lesson Index and Time Estimate
                            .lesson-index,
                            .lesson-divider {
                                display: inline-block;
                                font-weight: $FONT_WEIGHT_SEMIBOLD;
                                font-size: 15px;
                                color: $COLOR_V3_CORAL_DARK;
                                margin-right: 5px;
                            }

                            .lesson-time-estimate {
                                font-weight: $FONT_WEIGHT_NORMAL;
                                font-size: 14px;
                                color: $COLOR_V3_BEIGE_DARKER;
                            }

                            // Lesson Title
                            .lesson-title {
                                font-weight: $FONT_WEIGHT_SEMIBOLD;
                                font-size: 22px;
                                line-height: 1.05em;
                                color: $COLOR_V3_CORAL_DARK;

                                // Make things tighter on mobile
                                @media (max-width: $screen-xs-max) {
                                    margin-bottom: 0px;
                                    font-size: 18px;
                                }
                            }

                            // Lesson Description
                            .lesson-description {
                                font-weight: $FONT_WEIGHT_NORMAL;
                                font-size: 15px;
                                line-height: 20px;
                                min-height: 2em;
                                color: $COLOR_V3_BEIGE_DARKER;

                                ul {
                                    padding-left: 0px;

                                    // rtl-language overrides
                                    html[dir="rtl"] & {
                                        padding-right: 0px;
                                    }
                                }

                                li {
                                    position: relative;
                                    list-style-type: none;
                                    margin-left: 20px;

                                    // rtl-language overrides
                                    html[dir="rtl"] & {
                                        margin-right: 20px;
                                        margin-left: 0px;
                                    }

                                    p {
                                        margin-bottom: 0px;
                                    }

                                    &:after {
                                        content: "+";
                                        display: block;
                                        width: 15px;
                                        height: 15px;
                                        position: absolute;
                                        left: -15px;
                                        top: 0px;

                                        // rtl-language overrides
                                        html[dir="rtl"] & {
                                            right: -15px;
                                            left: auto;
                                        }
                                    }
                                }
                            }
                        }

                        // Progress Box Styles
                        .lesson-progress-box {
                            @media (max-width: $screen-xs-max) {
                                position: absolute;
                                display: block;
                                padding: 0px;
                                height: 100%;
                                width: 30px;
                                right: 5px;
                                top: 0;

                                // rtl-language overrides
                                html[dir="rtl"] & {
                                    right: auto;
                                    left: 5px;
                                }

                                div {
                                    display: table !important;
                                    table-layout: fixed;
                                    height: 100%;
                                    width: 30px;
                                }
                            }

                            @media (min-width: $screen-sm-min) {
                                position: relative;
                                height: 100%;
                                background-color: $COLOR_V3_BEIGE_LIGHTEST;
                                vertical-align: middle;
                                text-align: center;
                            }
                        }

                        // Assessment lesson arc
                        assessment-score-arc {
                            $sizeDesktop: 2px * 30 + 8 + (2 * 2);
                            $sizeMobile: 2px * 10 + 8 + (2 * 2);
                            display: inline-block;
                            position: relative;
                            width: $sizeDesktop;
                            height: 0px;
                            padding: 0px;
                            padding-bottom: $sizeDesktop;
                            top: -12px;

                            .assessment-score-arc {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }

                            &.small {
                                display: table-cell;
                                vertical-align: middle;
                                text-align: center;
                                width: $sizeMobile;
                                height: $sizeMobile;
                                padding: 0px;
                                top: 3px;
                                line-height: 0px;

                                .assessment-score-arc {
                                    position: relative;
                                    width: $sizeMobile;
                                    height: $sizeMobile;
                                    margin-left: 2px;
                                }
                            }
                        }

                        // Lesson progress icon for normal lessons
                        .lesson-progress-icon {
                            background-image: url("~images/progress_badge_incomplete.png");
                            display: inline-block;
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            image-rendering: -moz-crisp-edges; /* Firefox */
                            image-rendering: -o-crisp-edges; /* Opera */
                            image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                            image-rendering: crisp-edges;
                            -ms-interpolation-mode: nearest-neighbor;

                            width: 60px;
                            height: 61px;
                            margin-top: -40px;

                            @media (max-width: $screen-xs-max) {
                                margin-top: 0px;
                                display: none;
                                vertical-align: middle;
                                text-align: center;
                                width: 30px;
                                height: 30px;
                                background-size: 25px 25px;
                                margin-left: 2px;

                                &.completed {
                                    display: table-cell;
                                }
                            }

                            &.inProgress {
                                background-image: url("~images/progress_badge_in_progress.png");
                            }

                            // offset for space taken up by button below
                            &.completed {
                                background-image: url("~images/progress_badge_complete.png");
                            }

                            &.locked {
                                background-image: url("~vectors/lock_beige.svg");
                                width: 50px;
                                height: 58px;
                                margin-top: -35px;
                            }

                            &.test,
                            &.assessment {
                                &.completed {
                                    background-image: url("~images/progress_badge_complete_turquoise.png");
                                }
                            }

                            &.assessment,
                            &.test {
                                &.inProgress:not(.locked):not(.completed) {
                                    background-image: url("~images/progress_badge_in_progress_assessment.png");
                                }
                            }
                        }

                        // mobile-only right arrow for not started and in progress courses
                        @media (max-width: $screen-xs-max) {
                            .right-arrow {
                                position: static;
                                display: table-cell !important;
                                vertical-align: middle;
                                text-align: center;
                                height: 20px;
                                width: 30px;
                                font-size: 20px;
                                line-height: 20px;
                                color: $COLOR_V3_WHITE;
                            }
                        }

                        // Launch button (shown at non-mobile sizes)
                        .launch-button {
                            text-transform: none;
                            // override flat style's transform since these will all be uppercased anyhow
                            font-size: 18px;
                            font-weight: $FONT_WEIGHT_NORMAL;
                            display: block;
                            width: 100%;
                            height: 45px;
                            border-radius: 0px;
                            position: absolute;
                            bottom: 0;
                            left: 0;

                            &:not(:disabled) {
                                @include flat-button-color($COLOR_V3_GREEN, $COLOR_V3_GREEN_DARK);
                            }

                            &:disabled {
                                @include flat-button-color($COLOR_V3_BEIGE_MIDDARK, $COLOR_V3_BEIGE_BEYOND_DARK);
                            }

                            &.inline {
                                position: relative;
                                margin-bottom: -5px;

                                .lesson-progress-icon {
                                    display: none;
                                }
                            }

                            &.inProgress:not(:disabled) {
                                @include flat-button-color($COLOR_V3_PURPLE, $COLOR_V3_PURPLE_DARK);
                            }

                            &.completed {
                                &:not(:disabled) {
                                    @include flat-button-color($COLOR_V3_CORAL, $COLOR_V3_CORAL_DARK);
                                }

                                // Display inline icon
                                &.inline {
                                    .lesson-progress-icon {
                                        background-size: 20px 21px;
                                        width: 20px;
                                        height: 21px;
                                        margin: 0;
                                        vertical-align: middle;
                                        margin-top: -4px;
                                        display: inline-block;
                                    }
                                }
                            }
                        }

                        &.assessment,
                        &.test {
                            // Special assessment styling
                            .lesson-description-box {
                                .lesson-index-box .lesson-index,
                                .lesson-index-box .lesson-divider,
                                .lesson-title {
                                    color: $COLOR_V3_TURQUOISE_DARK;
                                }
                            }
                            .launch-button:not(:disabled) {
                                @include flat-button-color($COLOR_V3_TURQUOISE, $COLOR_V3_TURQUOISE_DARK);
                            }
                        }

                        &.coming_soon {
                            // Special coming soon styling
                            .lesson-description-box {
                                background-color: $COLOR_V3_WHITE;

                                .lesson-index-box .lesson-index,
                                .lesson-index-box .lesson-divider,
                                .lesson-title {
                                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                                }
                                .lesson-index-box .lesson-coming-soon-label {
                                    color: $COLOR_V3_BEIGE_DARKER;
                                    font-size: 14px;
                                }
                            }
                            .lesson-progress-box {
                                .coming-soon-block {
                                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                                    font-size: 18px;
                                    @media (max-width: $screen-xs-max) {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Trickery to allow vertical centering of lesson progress icon at larger sizes
            @media (min-width: $screen-sm-min) {
                .lesson-box {
                    margin-left: -15px;
                    margin-right: -16px;

                    .lesson-details-box {
                        display: table-row;
                        float: none;

                        .lesson-description-box {
                            display: table-cell;
                            float: none;
                        }

                        .lesson-progress-box {
                            display: table-cell;
                            float: none;
                        }
                    }

                    .lesson-footer-box {
                        display: table-row;
                        float: none;

                        .arrow-col {
                            display: table-cell;
                            float: none;
                        }

                        .launch-button-col {
                            display: table-cell;
                            float: none;
                        }
                    }
                }
            }
        }
    }

    // Container that holds course information (hides it on mobile before expanding)
    .course-information-box,
    .key-terms-row,
    .links-container-row {
        position: relative;
        display: block;
        padding-bottom: 30px;
        margin-bottom: 20px;

        .show-all-btn {
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 100;

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100px;
                background-color: transparent !important;
                background-image: linear-gradient(to bottom, rgba($COLOR_V3_BEIGE, 0), rgba($COLOR_V3_BEIGE, 1));
                bottom: 100%;
                left: 0;
            }
        }

        &.expanded {
            padding-bottom: 0;
        }
    }

    // Sidebar Styles
    .sidebar-box {
        @include sidebar-box();

        // What You'll Learn
        &.what-you-will-learn-box {
            li p {
                margin: 0;
                font-size: 15px;
                line-height: 1.4em;
            }

            hr.hr-hexagon {
                margin-bottom: 15px;
            }
        }

        // Recommended Courses
        &.recommended-courses-box {
        }

        // Related Courses
        &.related-courses-box {
        }

        // Resources
        &.resources-box {
            h3 {
                font-size: 20px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                margin: 12px 0px;
            }

            ul.list {
                padding-left: 10px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 10px;
                }

                &.summaries-list {
                    padding-left: 10px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        padding-right: 10px;
                    }
                }

                li {
                    list-style-type: none;
                    position: relative;
                    line-height: 15px;
                    margin-bottom: 15px;

                    .icon {
                        display: block;
                        position: absolute;
                        left: -27px;
                        top: -1px;
                        width: 20px;
                        height: 20px;
                        font-size: 20px;
                        line-height: 20px;
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            left: auto;
                            right: -27px;
                        }
                    }

                    .type {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        font-weight: $FONT_WEIGHT_NORMAL;
                        text-transform: uppercase;
                    }
                    .url {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        font-weight: $FONT_WEIGHT_NORMAL;
                        font-size: 10px;
                        line-height: 12px;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &.links-list li .icon {
                    top: 3px;
                }
            }
        }

        // Credits
        &.credits-box {
        }
    }

    .certificate-container {
        text-align: center;
        margin-bottom: 25px;

        img {
            display: inline-block;
            height: 275px;

            @media (max-width: $screen-xs-max) {
                height: 150px;
            }
        }

        &.sidebar-certificate img {
            height: auto;
            width: 100%;
        }

        .view-certificate {
            text-transform: uppercase;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 20px;
            color: #ff4d63;
            line-height: 40px;
            cursor: pointer;
        }
    }

    // Begin Key terms styling

    .key-terms-section {
        margin-top: 30px;
        margin-bottom: 50px;

        #key-terms-caption {
            display: block;
            position: relative;
            text-align: center;
            font-size: 20px;

            @media (min-width: $screen-sm-min) {
                font-size: 25px;
            }
        }

        .key-terms-description-row {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-size: 15px;
            text-align: center;
            margin-bottom: 35px;

            @media (min-width: $screen-sm-min) {
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        .key-terms-row {
            &:not(.expanded) {
                @media screen and (max-width: $screen-sm-max) {
                    max-height: 60vh;
                    overflow: hidden;
                }
            }

            // Used when grouping key terms by lesson
            .key-terms-by-lesson-container {
                .key-term-lesson-container {
                    margin-bottom: 30px;
                }

                .lesson-header {
                    color: $COLOR_V3_BEIGE_DARKER;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    font-size: 15px;
                    line-height: 1.1em;
                    margin-bottom: 15px;

                    .lesson-title {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        font-size: 24px;
                        line-height: 1em;

                        @media (max-width: $screen-iphone6-max) {
                            font-size: 20px;
                        }
                    }

                    .lesson-divider {
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }

                .key-term-list {
                    margin-left: -5px;

                    .key-term-list-item {
                        display: block;
                        position: relative;
                        margin-bottom: 17px;

                        .key-term {
                            margin-bottom: 6px;

                            .key-term-anchor {
                                &:hover {
                                    .key-term-box {
                                        background-color: $COLOR_V3_BEIGE_LIGHTEST;
                                        .key-term-text {
                                            color: $COLOR_V3_CORAL;
                                        }
                                    }
                                }

                                .key-term-box {
                                    padding: 8px 6px;
                                    border-radius: 4px;
                                    display: inline-flex;
                                    align-items: center;
                                    background-color: transparent;
                                    transition: all 0.1s ease;

                                    .key-term-text {
                                        font-size: 18px;
                                        line-height: 0.8em;
                                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                                        color: $COLOR_V3_CORAL_DARK;

                                        @media (min-width: $screen-sm-min) {
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }

                        .key-term-definition {
                            .key-term-definition-text {
                                font-size: 18px;
                                line-height: 1.2em;
                                display: block;
                                margin-inline-start: 38px;
                                font-weight: $FONT_WEIGHT_SEMIBOLD;
                                color: $COLOR_V3_BEIGE_BEYOND_DARK;

                                @media (min-width: $screen-sm-min) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Begin External Links styling

    .external-links-container {
        margin: 30px auto;

        .external-links-description-row {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-size: 15px;
            text-align: center;
            margin-bottom: 15px;

            @media (min-width: $screen-sm-min) {
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        .links-container-row {
            &:not(.expanded) {
                max-height: 60vh;
                overflow: hidden;
            }

            .lesson {
                margin: 0 0 35px 0;

                .lesson-header {
                    line-height: 1.5em;
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    font-weight: $FONT_WEIGHT_MEDIUM;
                    margin: 0 0 20px 5px;

                    .lesson-index-box {
                        font-size: 14px;

                        .lesson-divider {
                            padding: 0 3px;
                        }
                    }

                    .lesson-title {
                        font-size: 22px;
                    }
                }

                .lesson-links {
                    .lesson-link {
                        margin: 0 0 3px 0;
                        padding: 5px;
                        display: block;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;

                        .no-touchevents &:hover,
                        .touchevents &:active {
                            background-color: $COLOR_V3_BEIGE_LIGHTEST;

                            .link-title {
                                .link-title-link {
                                    color: $COLOR_V3_CORAL;
                                    .link-optional-tag {
                                        border-color: $COLOR_V3_CORAL;
                                    }
                                }
                            }
                        }

                        .link-title {
                            line-height: 1.2;
                            width: 100%;
                            padding: 0;

                            .link-title-link {
                                display: inline;
                                width: 100%;
                                font-size: 20px;
                                color: $COLOR_V3_CORAL_DARK;

                                .title-text {
                                    margin-inline-end: 8px;
                                }

                                .link-optional-tag {
                                    display: inline-block;
                                    position: relative;
                                    top: -3px;
                                    font-size: 10px;
                                    line-height: 1;
                                    text-transform: uppercase;
                                    border-radius: 10px;
                                    padding: 4px 7.5px;
                                    border: 1px solid $COLOR_V3_CORAL_DARK;
                                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                                }
                            }

                            &.no-title {
                                opacity: 0.9;
                            }
                        }

                        .link-url {
                            display: block;
                            width: 100%;
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                            font-size: 12px;
                            line-height: 1.5;
                            overflow-wrap: break-word;
                            word-break: break-word;
                            hyphens: auto;
                        }
                    }
                }
            }
        }
    }
}
