.cf-interactive-cards {
    display: flex;
    overflow-x: hidden;
    width: 100%;

    $buttonWidthMobile: 40px;
    $buttonWidthDesktop: 75px;
    .carousel {
        display: flex;
        white-space: nowrap; // the cards should be laid out next to each other
        z-index: 0; // behind the mask
        min-height: 60px; // room for the buttons
        transition-duration: 300ms;

        &:not(.panning) {
            transition-property: transform;
        }

        .card-wrapper {
            position: relative;
            z-index: 0; //behind buttons
            width: 100%;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            display: inline-block;
            white-space: normal;
            vertical-align: middle;
            text-align: center;

            &.room-for-buttons {
                width: calc(100% - #{2 * $buttonWidthMobile}); // between buttons
                margin: 0px $buttonWidthMobile;

                .frame-container.gte-sm & {
                    width: calc(100% - #{2 * $buttonWidthDesktop}); // between buttons
                    margin: 0px $buttonWidthDesktop;
                }
            }

            .card {
                display: inline-block;
                border-radius: 3px;

                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;

                // We only apply this to image cards with a background-color set because
                // 1) we don't need to restrict size if there's no illusion of a card with a background_color
                //  and most importantly
                // 2) we don't want to break existing images that were uploaded before https://trello.com/c/H2oBiOnj
                &.image-card.has-background {
                    // By default we are using the same width as tile prompt, or 100% if
                    // on a smaller device
                    width: 100%;

                    &.wide {
                        // If the editor selects "Wide?" then make the width simply 100%
                        max-width: 100%;
                        width: 100%;
                    }

                    &.force-card-height {
                        // If the editor selects "Force card height?" then make the height the same as
                        // tile prompt
                        height: $TILE_PROMPT_HEIGHT_SMALL_MIN;
                        .frame-container.gte-sm & {
                            height: $TILE_PROMPT_HEIGHT;
                        }
                    }

                    &.full-height {
                        height: 100% !important;
                    }
                }

                &.text-card {
                    // By default we are using the same width as tile prompt, or 100% if
                    // on a smaller device
                    width: 100%;

                    &.wide {
                        // If the editor selects "Wide?" then make the width simply 100%
                        max-width: 100%;
                        width: 100%;
                    }

                    &.force-card-height {
                        // If the editor selects "Force card height?" then make the height the same as
                        // tile prompt
                        height: $TILE_PROMPT_HEIGHT_SMALL_MIN;
                        .frame-container.gte-sm & {
                            height: $TILE_PROMPT_HEIGHT;
                        }
                    }

                    &.full-height {
                        height: 100% !important;
                    }

                    cf-text {
                        // Make sure the vertical centering is exact within the card
                        & > :first-child {
                            margin-top: 0;
                        }

                        & > :last-child {
                            margin-bottom: 0;
                        }

                        & > :only-child {
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        // Make sure that list items are not separated from their bullets / numbers
                        ul,
                        ol,
                        pre {
                            text-align: left;
                        }
                    }
                }

                .text-image-wrapper.has-background-color {
                    // Make sure the card has some padding between the edge and the content. This needs
                    // to be far enough down in the DOM that it does not interfere with the centering
                    // of the card.
                    padding: 20px;
                }
            }
        }
    }

    button.back.flat,
    button.forward.flat {
        z-index: 1; // in front of carousel
        color: rgb(202, 202, 202);
        font-size: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.75;
        padding: 0px;
        margin: 0px;
        transition: opacity 0.3s ease-in-out;
        width: $buttonWidthMobile;
        height: 100%;

        .frame-container.gte-sm & {
            font-size: 60px;
            width: $buttonWidthDesktop;
        }

        &:active {
            color: $COLOR_V3_CORAL_DARK !important;
        }

        &:hover,
        &:focus,
        &:active {
            color: rgb(202, 202, 202);
            background-color: transparent;
            box-shadow: none;
            outline: 0 !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        &:hover:enabled {
            color: rgb(202, 202, 202);
            opacity: 1;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &,
        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 0.75;
        }
    }

    button.back {
        left: 0px;
        &:before {
            @include font-awesome-icon("\f053"); // fa-chevron-left
        }

        // rtl-content overrides
        div[dir="rtl"] & {
            left: auto;
            right: 0px;
            &:before {
                @include font-awesome-icon("\f054"); // fa-chevron-right
            }
        }
    }

    button.forward {
        right: 0px;
        &:before {
            @include font-awesome-icon("\f054"); // fa-chevron-right
        }

        // rtl-content overrides
        div[dir="rtl"] & {
            right: auto;
            left: 0px;
            &:before {
                @include font-awesome-icon("\f053"); // fa-chevron-left
            }
        }
    }
}
