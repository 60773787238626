$nLineButtonTextPadding: 4px;

@function n_lines_button_line_height($fontSize) {
    @return $fontSize + 2px;
}

@function n_lines_button_height($n, $fontSize, $borderVerticalSpace: 0px) {
    $lineHeight: n_lines_button_line_height($fontSize);
    @return $n * $lineHeight + 2 * $nLineButtonTextPadding + $borderVerticalSpace;
}

@mixin n_lines_high_button($n, $fontSizeSM, $fontSizeXS, $borderVerticalSpace: 0px) {
    //$borderVerticalSpace is used when the button has a border-image set

    $lineHeightSM: $fontSizeSM + 2px;
    $lineHeightXS: $fontSizeXS + 2px;
    position: relative;

    @media (max-width: $screen-xs-max) {
        line-height: n_lines_button_line_height($fontSizeXS);
        min-height: n_lines_button_height($n, $fontSizeXS, $borderVerticalSpace);
        font-size: $fontSizeXS;
    }

    @media (min-width: $screen-sm-min) {
        line-height: n_lines_button_line_height($fontSizeSM);
        min-height: n_lines_button_height($n, $fontSizeSM, $borderVerticalSpace);
        font-size: $fontSizeSM;
    }

    .button_text {
        @media (max-width: $screen-xs-max) {
            padding: $nLineButtonTextPadding;
        }

        @media (min-width: $screen-sm-min) {
            padding: $nLineButtonTextPadding;
        }
    }
}
