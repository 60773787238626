@import "oreo/global/colors_v3";

div.delete-user-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1500;
}
div.delete-user-warning-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $COLOR_V3_WHITE;
    padding: 10px;
    border-radius: 5px;
    z-index: 1600;

    > div {
        display: flex;
        justify-content: flex-end;
        margin: 5px 5px 10px 0px;
    }
}
