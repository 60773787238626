multi-select {
    // component selector is important in order to scope `ng-valid` status

    display: block;

    select {
        // this component always simulates a placeholder state. since we can't rely on `required`, we can
        // still get away with a cheat by making all text the placeholder color. it won't affect shadow DOM.
        // see also: `front_royal_form.scss:281`
        color: $COLOR_V3_BEIGE_BEYOND_DARK !important;
    }

    .table-view {
        padding-bottom: 20px;
    }

    .selectize-refresh-placeholder {
        height: 90px;
    }

    .item {
        $arrowButtonWidth: 13px;
        $removeButtonWidth: 15px;
        position: relative;

        &.wrap {
            height: fit-content !important;
        }

        .img-icon-container[name="remove"] {
            width: $removeButtonWidth;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        .img-icon-container.arrow {
            width: $arrowButtonWidth;
            height: 12px;
            vertical-align: middle;
            img {
                vertical-align: top;
            }
        }

        .text {
            display: inline-block;
            // allow room for the delete button and arrows
            width: calc(100% - (#{2 * $arrowButtonWidth} + 18px) - (#{$removeButtonWidth} + 6px));
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.wrap {
                white-space: unset;
                text-overflow: unset;
                overflow: unset;
            }
        }

        &.disable-ordering {
            .text {
                // allow room for the delete button
                width: calc(100% - (#{$removeButtonWidth} + 6px));
            }
        }
    }

    .disabled {
        opacity: 0.5;
    }
}
