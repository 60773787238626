student-profile-list {
    display: block;
    overflow: hidden; // adds only necessary scrollbars
    position: absolute;
    top: 40px;
    bottom: 0px;
    left: 0;
    right: 0;
    padding-bottom: 50px;

    @media (min-width: $screen-sm-min) {
        top: 0;
        right: 0;
        left: 220px;
        padding: 0 10px;
        padding-bottom: 10px;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: 220px;
            left: 0;
        }
    }

    @media (min-width: 930px) {
        bottom: 0;
    }

    .pagination {
        padding: 0 10px;
        font-size: 12px;
        line-height: 29px;
        height: 30px;

        button {
            text-transform: uppercase;
        }

        @media (min-width: $screen-sm-min) {
            padding: 0;
            margin-bottom: 0;
        }
    }

    .card-list-item {
        box-shadow: 0 3px 3px $COLOR_V3_BEIGE_DARK;
        border-top: none;
        padding-right: 0;

        &:first-child {
            margin-top: 0;
        }
    }

    .cluster-location {
        width: calc(100% + 10px);
        padding: 0 10px;
        height: 35px;

        @media (min-width: $screen-sm-min) {
            margin: 0 -10px;
            width: calc(100% + 20px);
        }

        &:before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background: $COLOR_V3_CORAL;
            border: 2px solid $COLOR_V3_WHITE;
            border-radius: 50%;
            box-shadow: 0 1px 1px $COLOR_V3_MAP_BLUE_MID;
            float: left;
            margin-right: 10px;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
                margin: 0 0 0 10px;
            }
        }

        &.valar {
            &:before {
                background: $COLOR_V3_BLUE;
            }
        }

        .lat-long {
            float: left;
            line-height: 12px;
            color: $COLOR_V3_MAP_BLUE_MID;
            font-size: 12px;
            padding: 3px 0;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
            }

            h4 {
                color: $COLOR_V3_MAP_BLUE_DARK;
                margin: 0;
            }

            p {
                margin: 0;
            }

            @media (min-width: $screen-sm-min) {
                margin-right: 10px;
                padding: 7px 0;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-right: 0;
                    margin-left: 10px;
                }

                h4 {
                    margin-right: 10px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }

                h4,
                p {
                    display: inline-block;
                }
            }
        }

        button.flat.small {
            text-transform: uppercase;
            float: right;
            padding: 3px 10px 1px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: left;
            }

            @media (min-width: $screen-sm-min) {
                float: left;
                margin-top: 1px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    float: right;
                }
            }
        }
    }

    list-pagination[bottom-pagination="false"] {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 10px;
        z-index: 10;
        background: $COLOR_V3_WHITE;
        border-bottom: $SMARTLY_FORM_BORDER_STYLE;
        border-right: $SMARTLY_FORM_BORDER_STYLE;

        // rtl-language overrides
        html[dir="rtl"] & {
            border-right: none;
        }
    }

    .scroll-wrapper {
        overflow-y: auto; // adds only necessary scrollbars
        position: absolute;
        top: 30px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 10px 50px 10px;

        @media (min-width: 930px) {
            padding: 10px;
        }
    }
}
