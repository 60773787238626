@mixin fancy_checkbox($primary_color, $primary_color_dark, $font_size, $font_color) {
    .fancy_checkbox {
        display: inline-block;
        vertical-align: middle;
        width: $font_size;
        height: $font_size;

        position: relative;
        margin: $font_size auto;
        background: $primary_color_dark;
        cursor: pointer;

        label {
            width: $font_size - 4;
            height: $font_size - 4;

            cursor: pointer;
            position: absolute;
            left: 2px;
            top: 2px;
            background: $primary_color;
            &:after {
                content: "";
                width: 13px;
                height: 7px;
                position: absolute;
                top: 2px;
                left: 2px;
                border: 1px solid $font_color;
                border-top: none;
                border-right: none;
                background: transparent;
                opacity: 0;
                transform: rotate(-45deg);
            }
            &:hover::after {
                opacity: 0.3;
            }
        }
        input[type="checkbox"] {
            $checkboxPadding: 15px;
            $checkboxSize: $font_size - 2 + $checkboxPadding * 2;
            position: relative;
            width: $checkboxSize;
            height: $checkboxSize;
            padding: $checkboxPadding;
            margin-top: 0px;
            top: -$checkboxPadding;
            left: -$checkboxPadding;

            opacity: 0;
            &:checked + label:after {
                opacity: 1;
            }
        }
        margin-right: 10px;
    }
}
