.playlist-course-list {
    @media (min-width: $screen-sm-min) {
        margin-top: 2px;
    }
    // see http://codepen.io/barillax/pen/RrKwGB
    .playlist-row {
        $circleDiameterExtraSmall: 40px;
        $circleDiameterSmall: 40px;
        $circleDiameterMedium: 50px;

        $lineWidth: 4px;

        $leftOffsetExtraSmall: 25px;

        $leftDescriptionOffsetExtraSmall: 60px;
        $descriptionOffsetSmall: 15px;

        $leftPercentage: 10%;

        position: relative;
        margin-top: 15px;
        margin-bottom: 13px;

        @media (min-width: $screen-sm-min) {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .circle-wrapper {
            position: static;
        }

        // center the circle within the row
        .circle {
            top: calc(50% - #{$circleDiameterExtraSmall * 0.5});
            left: $leftOffsetExtraSmall;
            width: $circleDiameterExtraSmall;
            height: $circleDiameterExtraSmall;
            border: 0px solid transparent;
            border-radius: 50%;
            color: white;
            text-align: center;
            line-height: $circleDiameterExtraSmall;
            font-size: 20px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            z-index: 1;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: $leftOffsetExtraSmall;
            }

            @media (min-width: $screen-sm-min) {
                left: calc(#{$leftPercentage} - #{$circleDiameterSmall * 0.5});
                top: calc(50% - #{$circleDiameterSmall * 0.5});
                width: $circleDiameterSmall;
                height: $circleDiameterSmall;
                line-height: $circleDiameterSmall;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: calc(#{$leftPercentage} - #{$circleDiameterSmall * 0.5});
                }
            }

            @media (min-width: $screen-md-min) {
                left: calc(#{$leftPercentage} - #{$circleDiameterMedium * 0.5});
                top: calc(50% - #{$circleDiameterMedium * 0.5});
                width: $circleDiameterMedium;
                height: $circleDiameterMedium;
                line-height: $circleDiameterMedium;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: calc(#{$leftPercentage} - #{$circleDiameterMedium * 0.5});
                }
            }
        }

        .line {
            width: $lineWidth;
            height: 100%;
            top: calc(50% + 10px);

            @media (min-width: $screen-sm-min) {
                left: calc(#{$leftPercentage} - #{$lineWidth * 0.5});

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: calc(#{$leftPercentage} - #{$lineWidth * 0.5});
                }
            }

            @media (min-width: $screen-md-min) {
                left: calc(#{$leftPercentage} - #{$lineWidth * 0.5});

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: calc(#{$leftPercentage} - #{$lineWidth * 0.5});
                }
            }
        }

        stream-link-box {
            // remove some margin
            .playlist-track {
                margin-bottom: 0px;
                @media (min-width: $screen-sm-min) {
                    padding-bottom: 0px;
                }
                .content-box {
                    @media (min-width: $screen-sm-min) {
                        border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;
                    }
                }
            }

            // don't show the header box overlay graphic
            .header-box {
                background: none !important;

                &:after {
                    background-image: none !important;
                }
            }

            .header-box.bottom .stat-group.playlist-track-stat {
                .caption,
                .big-number,
                .fraction,
                .total,
                .date {
                    transition: color 1s;
                }
            }
            .no-touchevents &:hover,
            .no-touchevents a:hover & {
                .course-icon {
                    filter: none;
                }
            }
        }

        $notStartedColor: $COLOR_V3_BEIGE_MIDDARK;
        $inProgressColor: $COLOR_V3_PURPLE; //$COLOR_V3_BEIGE_DARKER;
        $completedColor: $COLOR_V3_CORAL; // $COLOR_V3_BEIGE_BEYOND_DARK;
        $nextUnstartedColor: $COLOR_V3_GREEN;

        .circle,
        .line {
            position: absolute;
            display: block;
            background-color: $notStartedColor;
        }

        &.complete,
        &.in_progress,
        &.next_unstarted {
            stream-link-box {
                .course-icon {
                    filter: none;
                }
            }
            .circle {
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    right: -5px;
                    top: -5px;
                    width: 20px;
                    height: 20px;
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    image-rendering: -moz-crisp-edges; /* Firefox */
                    image-rendering: -o-crisp-edges; /* Opera */
                    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                    image-rendering: crisp-edges;
                    -ms-interpolation-mode: nearest-neighbor;

                    @media (min-width: $screen-md-min) {
                        right: -5px;
                        top: -5px;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        &.followed-by-next-unstarted {
            .line {
                @include gradient-vertical($notStartedColor, $nextUnstartedColor, 30%, 80%);
            }
        }
        &.followed-by-in-progress {
            .line {
                @include gradient-vertical($notStartedColor, $inProgressColor, 30%, 80%);
            }
        }
        &.followed-by-complete {
            .line {
                @include gradient-vertical($notStartedColor, $completedColor, 30%, 80%);
            }
        }

        &.not_started {
            stream-link-box a:not(.unclickable) {
                .no-touchevents &:hover {
                    .header-box.bottom .stat-group.playlist-track-stat {
                        .caption,
                        .big-number,
                        .fraction,
                        .total,
                        .date {
                            color: $COLOR_V3_GREEN;
                        }
                    }
                }
            }
        }

        &.next_unstarted {
            .circle {
                background-color: $nextUnstartedColor;
            }

            .line {
                @include gradient-vertical($nextUnstartedColor, $notStartedColor, 0%, 70%);
            }
            &.followed-by-in-progress {
                .line {
                    @include gradient-vertical($nextUnstartedColor, $inProgressColor, 0%, 70%);
                }
            }
            &.followed-by-complete {
                .line {
                    @include gradient-vertical($nextUnstartedColor, $completedColor, 0%, 70%);
                }
            }
        }

        &.in_progress {
            .circle {
                background-color: $inProgressColor;

                &:after {
                    background-image: url("~images/progress_badge_in_progress.png");
                }
            }

            .line {
                @include gradient-vertical($inProgressColor, $notStartedColor, 0%, 70%);
            }
            &.followed-by-next-unstarted {
                .line {
                    @include gradient-vertical($inProgressColor, $nextUnstartedColor, 0%, 70%);
                }
            }
            &.followed-by-in-progress,
            &.last {
                .line {
                    @include gradient-vertical($inProgressColor, $inProgressColor, 0%, 70%);
                }
            }
            &.followed-by-complete {
                .line {
                    @include gradient-vertical($inProgressColor, $completedColor, 0%, 70%);
                }
            }
        }

        &.complete {
            stream-link-box {
                .no-touchevents &:hover,
                .no-touchevents a:hover & {
                    .header-box.bottom .stat-group.playlist-track-stat.completed {
                        .caption,
                        .big-number,
                        .fraction,
                        .total,
                        .date {
                            color: $COLOR_V3_CORAL;
                        }
                    }
                }
            }

            .circle {
                background-color: $completedColor;

                &:after {
                    background-image: url("~images/progress_badge_complete.png");
                }
            }

            .line {
                @include gradient-vertical($completedColor, $notStartedColor, 0%, 70%);
            }
            &.followed-by-next-unstarted {
                .line {
                    @include gradient-vertical($completedColor, $nextUnstartedColor, 0%, 70%);
                }
            }
            &.followed-by-in-progress {
                .line {
                    @include gradient-vertical($completedColor, $inProgressColor, 0%, 70%);
                }
            }
            &.followed-by-complete,
            &.last {
                .line {
                    @include gradient-vertical($completedColor, $completedColor, 0%, 70%);
                }
            }
        }

        &.last {
            stream-link-box {
                .content-box {
                    @media (min-width: $screen-sm-min) {
                        border-bottom: none;
                    }
                }
            }

            .line {
                display: none;

                @media (max-width: $screen-xs-max) {
                    display: block;
                    height: 100px;
                }

                @media (max-width: 350px) {
                    height: 150px;
                }
            }
        }
    }
}
