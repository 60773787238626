// Any of the "outer screens" before the player gets the .dashboard class
//
// This class overrides behavior of bootstrap container and rows to fit the V3 UI's grid,
// among other stylistic details.

// Padding variables
$appDashboardLeftRightPaddingLarge: 30px;
$appDashboardLeftRightPaddingSmall: 10px;
$appDashboardTopPaddingLarge: 30px;
$appDashboardTopPaddingSmall: 20px;

@mixin clamp-multiple-lines-fade-out($numLines: 2, $lineHeight: 17px, $bgColor: rgb(255, 255, 255)) {
    height: ($numLines + 0.75) * $lineHeight;
    margin-bottom: 0px;
    overflow: hidden;
    position: relative;

    // Fade out last line for non-webkit browsers
    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0.9 * $lineHeight;
        @include gradient-vertical(rgba($bgColor, 0), rgba($bgColor, 1));
    }
}

@mixin clamp-multiple-lines-ellipsis($numLines: 2, $lineHeight: 17px, $bgColor: rgb(255, 255, 255)) {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: $numLines * $lineHeight;
    margin-bottom: 7px;

    // Hide the little gradient
    &:after {
        display: none;
    }
}

@mixin clamp-multiple-lines($numLines: 2, $lineHeight: 17px, $bgColor: rgb(255, 255, 255)) {
    // Desktop size is fixed height
    @media (min-width: $screen-sm-min) {
        @include clamp-multiple-lines-fade-out($numLines, $lineHeight, $bgColor);
    }

    // Webkit-only multi-line ellipsis support on desktop
    @media screen and (min-width: $screen-sm-min) and (-webkit-min-device-pixel-ratio: 0) {
        @include clamp-multiple-lines-ellipsis($numLines, $lineHeight, $bgColor);
    }
}

@mixin small-course() {
    display: block;
    padding: 3px 0 0 35px;
    position: relative;
    min-height: 30px;
    margin: 3px 0;

    // rtl-language overrides
    html[dir="rtl"] & {
        padding-left: 0px;
        padding-right: 35px;
    }

    .title-text {
        font-size: 15px;
        line-height: 18px;
        display: inline-block;
    }

    .course-icon-box {
        display: block;
        overflow: visible;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0;
        width: 25px;
        max-height: 42px;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 25px;

            &.unlocked {
                display: inline-block;
                visibility: hidden;
            }

            &.locked {
                display: none;
                visibility: hidden;
            }
        }
    }

    &.disabled {
        pointer-events: none;

        span {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        .course-icon-box {
            img.unlocked {
                display: none;
                visibility: hidden;
            }
            img.locked {
                display: inline-block;
                visibility: hidden;
            }
        }
    }
}

@mixin sidebar-box($blueAnchors: false) {
    background-color: $COLOR_V3_BEIGE_LIGHTEST;

    // Horizontal Rules
    .hr-caption {
        font-size: 20px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    // General styling overrides
    h1,
    h2,
    h3,
    h4 {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    p,
    li,
    span,
    strong,
    em {
        font-size: 18px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    ul {
        padding-left: 25px;
    }

    strong {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }

    a,
    a > span {
        color: $COLOR_V3_CORAL;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 15px;

        @if $blueAnchors {
            color: $COLOR_V3_BLUE;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: $COLOR_V3_BLUE;
                position: absolute;
                bottom: 1px;
                left: 0;
            }
        }
    }

    a:not([disabled]) {
        &:hover,
        &:hover > span {
            color: lighten($COLOR_V3_CORAL, 10%);

            @if $blueAnchors {
                color: lighten($COLOR_V3_BLUE, 10%);
            }
        }
        &:active,
        &:active > span {
            color: $COLOR_V3_CORAL_DARK;

            @if $blueAnchors {
                color: $COLOR_V3_BLUE;
            }
        }
    }

    a[disabled] {
        opacity: 0.3;
    }

    // A small document that downloads a document
    .small-doc {
        display: block;
        padding-left: 35px;
        position: relative;
        min-height: 30px;
        line-height: 30px;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-left: 0px;
            padding-right: 35px;
        }

        .title-text {
            font-size: 15px;
            line-height: 18px;
            display: inline-block;
        }

        .doc-icon-box {
            display: block;
            overflow: visible;
            position: absolute;
            left: 0px;
            top: 0px;
            margin: 0;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: 0px;
            }

            img {
                position: relative;
                height: 25px;
                display: inline-block;
                visibility: hidden;
            }
        }
    }

    // A small course that links to another course
    .small-course {
        @include small-course;
    }
}

.dashboard {
    // V3 UI: Padding at top of page
    padding-top: $appDashboardTopPaddingSmall;

    // Larger padding (iPad Portrait and larger)
    @media (min-width: $screen-sm-min) {
        padding-top: $appDashboardTopPaddingLarge;
    }

    // Anchor styles
    a,
    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }

    // V3 UI: Override behavior of bootstrap containers
    //  - fluid layout at sizes less than or equal to 1024px wide
    //  - 30px gutter between columns
    .dashboard-container {
        margin-left: auto;
        margin-right: auto;

        // Override
        @media (min-width: $screen-sm-min) {
            width: auto;
        }

        // Override
        @media (min-width: $screen-md-min) {
            width: auto;
        }

        // New breakpoint: slighly larger than iPad Landscape
        @media (min-width: 1025px) {
            width: 1024px;
        }

        // Same as before
        @media (min-width: $screen-lg-min) {
            width: $container-lg;
        }
        // V3 UI: padding overrides
        // Default padding (mobile)
        padding-left: $appDashboardLeftRightPaddingSmall;
        padding-right: $appDashboardLeftRightPaddingSmall;

        // Larger padding (iPad Portrait and larger)
        @media (min-width: $screen-sm-min) {
            padding-left: $appDashboardLeftRightPaddingLarge;
            padding-right: $appDashboardLeftRightPaddingLarge;
        }
    }

    // Hexagons
    .hexagons-box {
        // On mobile, add some space above the hexagon box
        @media (max-width: $screen-xs-max) {
            margin-top: 5px;
        }

        .hexagon {
            display: inline-block;
            margin-right: 4px;
            width: 15px;
            height: 17px;
            background-image: url("~vectors/progress_indicator_beige_unfilled.svg");
            background-size: 14.95px 17px;
            background-repeat: no-repeat;

            &.completed {
                background-image: url("~vectors/progress_indicator_beige.svg");
            }
        }
    }
}

// The .dashboard class applies ONLY to the legacy v1 Angular.JS student dashboard directive and its children.
.dashboard,
// The .bookmarked-courses-container is specifically needed to render the bookmarked-completed-courses directive
// inside the sd2 React wrapper with the Tailwind base container. In the DOM, the .sorting-buttons and .topic-header
// elements won't have a parent with the .dashboard class.
.bookmarked-courses-container {
    // Sorting buttons (key terms, courses)
    .sorting-buttons {
        position: absolute;
        display: block;
        right: 0px;
        top: 0px;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: auto;
            left: 0px;
        }

        button.btn.btn-default {
            position: relative;
            border-color: $COLOR_V3_BEIGE_DARKER;
            border-width: 2px;
            border-right-width: 1px;
            color: $COLOR_V3_BEIGE_DARKER;
            background-color: $COLOR_V3_BEIGE;
            outline: none;
            width: 40px;
            height: 35px;

            &:hover {
                background-color: $COLOR_V3_BEIGE_MEDIUM;
            }

            &:last-of-type {
                border-right-width: 2px;
            }

            &.active {
                background-color: $COLOR_V3_BEIGE_DARKER;
                color: $COLOR_V3_WHITE;
                &:not(:active) {
                    box-shadow: none;
                }
            }
            i.icon {
                background-size: 100%;
                background-repeat: no-repeat;
            }

            &.toggle-flat {
                i.icon {
                    background-image: url("~vectors/toggle_flat_icon.svg");
                    width: 19px;
                    height: 17px;
                    position: absolute;
                    top: 8px;
                    left: 10px;
                }
                &.active i.icon {
                    background-image: url("~vectors/toggle_flat_icon_selected.svg");
                }
            }

            &.toggle-topic {
                i.icon {
                    background-image: url("~vectors/toggle_topic_icon.svg");
                    width: 17px;
                    height: 14px;
                    position: absolute;
                    top: 9px;
                    left: 10px;
                }
                &.active i.icon {
                    background-image: url("~vectors/toggle_topic_icon_selected.svg");
                }
            }

            &.toggle-playlist {
                i.icon {
                    background-image: url("~vectors/toggle_playlist_icon.svg");
                    width: 15px;
                    height: 19px;
                    position: absolute;
                    top: 7px;
                    left: 12px;
                }
                &.active i.icon {
                    background-image: url("~vectors/toggle_playlist_icon_selected.svg");
                }
            }

            .tooltip {
                opacity: 0;
                pointer-events: none;
                background: $COLOR_V3_DARK_GREY;
                color: $COLOR_V3_WHITE;
                border-radius: 2px;
                width: 120px;
                right: -25px;
                top: -33px;
                text-align: center;
                display: block;
                position: absolute;
                font-size: 11px;
                padding: 4px;

                &:after {
                    top: 100%;
                    right: 38px;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-top-color: $COLOR_V3_DARK_GREY;
                    border-width: 6px;
                    margin-left: -6px;
                }
            }

            .no-touchevents & {
                &:hover .tooltip {
                    @media (min-width: $screen-sm-min) {
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            top: -6px;
            button.btn.btn-default {
                padding: 3px 9px;
                i {
                    font-size: 12px;
                }
            }
        }
    }

    // list of courses grouped by topic
    .topic-header {
        position: relative;
        margin-top: 10px;
        margin-bottom: 15px;

        .topic-title {
            display: inline-block;
            color: $COLOR_V3_BEIGE_DARKER;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 24px;
            line-height: 1em;
            margin-right: 10px;
            margin-bottom: 3px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: 0px;
                margin-left: 10px;
            }
        }
        .topic-library-link {
            cursor: pointer;
            display: inline-block;
            position: relative;
            padding: 2px 5px 0px 5px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 16px;
            line-height: 20px;
            vertical-align: top;
            color: $COLOR_V3_BEIGE_DARKER;
            border: 2px solid $COLOR_V3_BEIGE_DARKER;
            top: -2px;

            [dir="rtl"] & {
                padding-bottom: 6px;
                top: -5px;
            }

            border-radius: 4px;

            &:hover {
                background: $COLOR_V3_BEIGE_DARKER;
                color: $COLOR_V3_WHITE;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-right: 75px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 75px;
                padding-right: 0px;
            }

            .topic-title {
                font-size: 16px;
            }
            .topic-library-link {
                position: absolute;
                font-size: 10px;
                line-height: 16px;
                top: -1px;
                right: 0px;
                border: 1px solid $COLOR_V3_BEIGE_DARKER;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 0px;
                    right: auto;
                }
            }
        }
    }
}
