/**
 * A few notes here...
 *  - The :before pseudo-element is used to hold a question-mark/prompt icon
 *  - The :after pseudo-element is used to hold a feedback icon (e.g.: check or X)
 *  - Some of this is overriden/extended in challenge_overlay_blank.scss and answer_list_image_hotspot.scss
 *  - Some of this may rely on ancestor CSS/HTML described in component_overlay.scss
 */

.fillable-rect-on-image {
    box-sizing: content-box;
    -moz-box-sizing: content-box;

    display: inline-block;
    border-width: 3px;
    border-style: solid;
    border-radius: 3px;
    border-color: transparent;

    padding: 0px 3px;
    opacity: 1;

    // font-size: 14px;  //safe to remove?

    transition: box-shadow 0.15s ease-out, opacity 0.15s ease-out;

    &.hidden-answer {
        opacity: 0;
    }

    &:focus {
        outline: 0;
    }

    &.image {
        padding: 0;
    }

    &.text {
        width: auto;
        border-radius: 3px;

        display: table;

        p {
            margin: 0; // no bottom margin
        }

        .fill-content-container {
            display: table-cell;
            vertical-align: middle;
        }

        &.truncated {
            width: 45px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.pending {
        .content_wrapper {
            display: block;
            visibility: hidden; // Hide content, but make it take up space so the blank outline is the right size
        }
    }

    // bring the selected item to the front, highlight it and pulse repeatedly

    &.selected {
        z-index: 1000;
        animation: subtle-pulse-repeat 1.75s ease infinite;

        // highlight the iconographic state when selected
        &:before {
            text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_V3_CORAL, 0 0 5px $COLOR_V3_CORAL;
        }

        &.correct {
            animation: none;
        }
    }

    // Flash an incorrect circle to the right of the rect on incorrect
    // Mode needs to apply .incorrect class to the fillable blank for this to work
    &.incorrect {
        overflow: visible;
        border-color: $COLOR_ANSWER_INCORRECT;
        box-shadow: 0px 0px 10px 0px rgba($COLOR_ANSWER_INCORRECT, 0.85),
            5px 5px 10px 0px rgba($COLOR_ANSWER_INCORRECT, 0.3),
            inset 0px 0px 0px 3px rgba($COLOR_ANSWER_INCORRECT, 0.85);

        &:after {
            @include font-awesome-icon("\f057"); // fa-times-circle
            @include icon-at-point($FILLABLE_RECT_SMALL_ICON_DIAMETER, $FILLABLE_RECT_SMALL_ICON_DIAMETER, 50%, 100%);
            left: auto;
            right: -#{$FILLABLE_RECT_SMALL_ICON_DIAMETER};
            color: $COLOR_ANSWER_INCORRECT;
            text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_ANSWER_INCORRECT, 0 0 5px $COLOR_ANSWER_INCORRECT;
            animation: pulse 0.2s ease;
        }
    }

    // If this is a multiple choice-like mode, e.g.: Image Hotspot, disable other choices
    // after screen is completed.
    &.disabled {
        opacity: 0;
    }

    // Iconographic representation to show before rect is filled in
    // centered vertically and to the right of the rect's natural area
    &:before {
        @include font-awesome-icon("\f059"); // fa-question-circle
        @include icon-at-point($FILLABLE_RECT_BIG_ICON_DIAMETER, $FILLABLE_RECT_BIG_ICON_DIAMETER, 50%, 100%);
        left: auto;
        right: -#{$FILLABLE_RECT_BIG_ICON_DIAMETER};
        color: $COLOR_V3_WHITE;
        text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_V3_CORAL, 0 0 5px $COLOR_V3_CORAL;
        text-decoration: none;
    }

    /* when blank is completed or answer is correct, show a little checkmark */
    &.completed,
    &.correct {
        animation: none; // disable selected pulsing when complete

        .content_wrapper {
            visibility: visible;
        }

        &:before {
            display: none; // Hide the previously displayed question mark
        }

        // complete icon centered to the right of the blank
        &:after {
            @include font-awesome-icon("\f058"); // fa-check-circle
            @include icon-at-point($FILLABLE_RECT_SMALL_ICON_DIAMETER, $FILLABLE_RECT_SMALL_ICON_DIAMETER, 50%, 100%);
            left: auto;
            right: -#{$FILLABLE_RECT_SMALL_ICON_DIAMETER};
            color: $COLOR_V3_WHITE;
            text-shadow: 1px 1px 2px $COLOR_V3_BLACK, 0 0 15px $COLOR_ANSWER_CORRECT, 0 0 5px $COLOR_ANSWER_CORRECT;
            opacity: 0; // Tricky: the animation below will pulse and display the checkmark, then hide it.
            animation: pulse-then-fade-out $ANSWER_VALIDATION_DURATION ease;
            text-decoration: none;
        }
    }

    /* if in the editor and pending, or in the player
      and completed, then show the contents */
    &.editable.pending,
    &.completed {
        font-weight: normal;
        color: $COLOR_V3_EGGPLANT;
        box-shadow: none;
        opacity: 1;
        text-align: center;
        vertical-align: top;
        overflow: visible;
        border-color: transparent;

        // Hide the iconographic state
        &:before {
            display: none;
        }

        // show the content
        .content_wrapper {
            display: block;
        }

        &.has_background {
            background-color: $COLOR_V3_WHITE;
        }
    }

    /* in the editor, do some stuff to the pending state */
    &.editable.pending {
        // disable the infinite pulsing animation when complete or in editor
        &.selected {
            animation: none;

            // highlight selected clearly
            &.pending {
                border-color: rgba($COLOR_V3_YELLOW, 0.7);
            }
        }

        // Add a border for non-selected items so they're more obviously clickable in editor
        border-color: rgba($COLOR_V3_PURPLE, 0.7);

        // Hide the complete icons
        &:after {
            display: none;
        }
    }
}
