// Override styles on the Cordova app
onboarding-hybrid-login,
onboarding-hybrid-register,
onboarding-hybrid-login,
onboarding-hybrid-forgot-password {
    .sessions {
        .form-container {
            .form-container-inner {
                border: 0px !important;
                .soc-btns .row {
                    padding: 0 5px;
                }
            }
        }
    }
}

.sessions,
.onboarding_modal__dialog .sessions,
api-error-logged-out {
    @include selectize-force-auto-width();

    .login-register-header {
        padding-top: 30px;
        margin-bottom: 30px;
        text-align: center;

        img {
            height: 27px;
        }

        @media (min-width: $screen-sm-min) {
            padding-top: 70px;
            margin-bottom: 45px;
            img {
                height: 36px;
            }
        }
    }

    .login-register-footer {
        margin: 34px 0 0;
        padding-bottom: 90px;
        text-align: center;
        p {
            @include font-size(16);
            font-weight: $FONT_WEIGHT_NORMAL;
            font-style: normal;
            color: #b5b5b5;
            a {
                color: $COLOR_V3_CORAL;

                forgot-password & {
                    color: $COLOR_V3_WHITE;
                }
            }
        }
    }

    .icp-china-footer-wrapper {
        display: none;
    }

    .sessions-header-container {
        margin-bottom: 12px;

        @media (min-width: $screen-sm) {
            margin-bottom: 37px;
        }

        h1 {
            color: rgba($COLOR_V3_WHITE, 0.95);
            text-align: center;
            display: block;
            strong {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }

        h3 {
            color: rgba($COLOR_V3_WHITE, 0.95);
            text-align: center;
            display: block;
            margin-top: 18px;
            margin-left: auto;
            margin-right: auto;
            font-weight: $FONT_WEIGHT_NORMAL;
            max-width: 359px;
        }
    }

    .sign-in-link {
        color: $COLOR_V3_CORAL;
    }

    .sign-up-form-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        max-width: 100%;

        .context-sidebar {
            max-width: 100%;
            background: #b1b1b1;
            box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0 0 2px 2px;
            padding: 24px 18px 29px 18px;
            margin: -16px 16px 16px 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            order: 1;
            justify-content: center;
            text-align: left;

            h2 {
                line-height: 30px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                font-size: 20px;
            }
        }

        @media (min-width: $screen-md-min) {
            flex-direction: row;

            .context-sidebar {
                order: 0;
                width: 294px;
                box-shadow: none;
                border-radius: 2px;
                margin: 0;
                justify-content: flex-start;
                padding: 150px 45px 30px 30px;
            }
        }
    }

    .form-container {
        width: 100%;

        &.contained {
            margin: 0 auto;
            padding: 7px;

            @media (min-width: $screen-sm-min) {
                width: 630px;
                padding: 7px 15px;
            }
        }

        &.form-submitting {
            input,
            .selectize-control > .selectize-input,
            h1,
            h2,
            .soc-btn,
            button,
            .forgot-password-link-container {
                opacity: 0.4 !important;
                pointer-events: none;
            }
        }

        .form-container-inner {
            background: $COLOR_V3_WHITE;

            &.is-success {
                border-top: 1px solid none;
                border-radius: 0 0 4px 4px;
                background-clip: padding-box;
            }

            h1 {
                @include font-size(20);
                text-align: center;
                margin-bottom: 42px;
                @media (min-width: $screen-sm-min) {
                    @include font-size(24);
                }
            }

            // the first-of-type is needed to win the specificiry competition with modals.scss
            h2,
            h2:first-of-type {
                text-align: center;
                position: relative;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-style: normal;
                @include font-size(18);
                color: $LOGIN_REGISTER_TEXT_GRAY;
            }

            // Add extra top margin to the h2 if the social buttons are above it
            .soc-btns + h2 {
                margin-top: 25px;

                @media (min-width: $screen-sm-min) {
                    margin-top: 37px;
                }
            }

            .soc-btns {
                width: 100%;
                font-size: 15px; // HACK to fix some wrapping we observed on prod

                .row {
                    margin-bottom: 6px;

                    .soc-btn-col {
                        margin-bottom: 4px;

                        @media (min-width: $screen-sm-min) {
                            &:nth-of-type(odd) {
                                padding-right: 2px;
                            }
                            &:nth-of-type(even) {
                                padding-left: 2px;
                            }
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }

                .soc-btn {
                    display: block;
                    width: 100%;
                    padding: 14px 0;
                    font-size: 16px;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    line-height: 20px;
                    color: #fff;
                    border: 0;
                    border-radius: 4px;
                    background-clip: padding-box;
                    outline: 0;
                    position: relative;

                    @media (min-width: $screen-sm-min) {
                        padding: 20px 0;
                    }

                    &:before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);

                        width: 28px;
                        height: 28px;
                        background-repeat: no-repeat;
                        background-size: 100%;
                        background-position: center;

                        @media (min-width: $screen-sm-min) {
                            width: 42px;
                            height: 42px;
                            left: 10px;
                        }
                    }

                    &.soc-no-block-fb-btn {
                        background-color: $COLOR_V3_BLUE_FACEBOOK;
                        .no-touchevents &:hover {
                            background-color: $COLOR_V3_BLUE_FACEBOOK_HOVER;
                        }
                        &:before {
                            background-size: 80%;
                            background-image: url("~vectors/facebook-icon-login.svg");
                        }
                    }
                    &.soc-no-block-g-btn {
                        background-color: $COLOR_V3_BLUE_GOOGLE;
                        .no-touchevents &:hover {
                            background-color: $COLOR_V3_BLUE_GOOGLE_HOVER;
                        }
                        &:before {
                            // icon has space around it to allow for the shadow, which we don't want
                            // so make it bigger than the container and positioned correctly
                            //background-size: 34px;
                            //background-position: center;
                            background-image: url("~vectors/google-icon-login.svg");
                        }
                    }
                    &.soc-no-block-wc-btn {
                        background-color: $COLOR_V3_BLUE_WECHAT;
                        .no-touchevents &:hover {
                            background-color: $COLOR_V3_BLUE_WECHAT_HOVER;
                        }
                        &:before {
                            background-image: url("~vectors/wechat-icon-logo.svg");
                        }
                    }
                    &.soc-no-block-a-btn {
                        background-color: $COLOR_V3_BLACK;
                        .no-touchevents &:hover {
                            background-color: $COLOR_V3_GREY_DARKER;
                        }
                        &:before {
                            background-size: 90%;
                            background-image: url("~vectors/apple-icon-login.svg");
                        }
                    }

                    span {
                        padding-left: 0px;

                        @media (min-width: $screen-sm-min) {
                            display: block;
                            padding-left: 36px;
                        }
                    }
                }

                @media (min-width: $screen-sm-min) {
                    &.es {
                        .soc-btn {
                            font-size: 12px;
                        }
                    }

                    &.ar {
                        .soc-btn {
                            font-size: 13px;
                        }
                    }
                }
            }

            .form {
                margin-top: 26px;

                .password-eyeball-wrapper {
                    clear: both;
                    position: relative;
                }

                .free-trial-link {
                    text-decoration: underline;
                    white-space: nowrap;
                }

                .form-error {
                    padding: 0px 20px;
                    color: $COLOR_V3_CORAL;
                }

                .form-group {
                    select {
                        width: 100%;
                    }
                    input,
                    select,
                    .selectize-control > .selectize-input {
                        height: 50px;
                        padding: 16px 20px 15px;
                        @include font-size(16);
                        border: 1px solid #e6e6e6;
                        border-radius: 4px;
                        background-clip: padding-box;
                        box-shadow: none;
                        color: #615a52;
                        outline: 0;

                        .ie & {
                            padding: 14px 20px 17px;
                        }

                        @media (min-width: $screen-sm-min) {
                            height: 60px;
                            padding: 18px 20px 18px;
                            @include font-size(18);

                            .ie & {
                                padding: 18px 20px 19px;
                            }
                        }
                        &:active,
                        &:focus {
                            border: 1px solid #b2b2b2;
                            box-shadow: none;
                        }
                        &.has-error,
                        &.ng-invalid:not(.ng-pristine),
                        &.ng-valid.ng-empty:not(.ng-pristine) {
                            border: 1px solid $COLOR_V3_CORAL;
                        }
                        &.is-valid:not(.ng-pristine),
                        &.ng-valid:not(.ng-pristine) {
                            border: 1px solid $COLOR_MARKETING_TURQUOISE;
                        }
                    }

                    // selectize error validation styling
                    // because the selectize element has the ng-validation on it,
                    // we need the ng-invalid classes to apply to the sibling element
                    // inserted into the dom by selectize that contains the actual input
                    selectize {
                        &.has-error,
                        &.ng-invalid:not(.ng-pristine) {
                            & + .selectize-control > .selectize-input {
                                border: 1px solid $COLOR_V3_CORAL;
                            }
                        }
                        &.is-valid:not(.ng-pristine),
                        &.ng-valid:not(.ng-pristine) {
                            & + .selectize-control > .selectize-input {
                                border: 1px solid $COLOR_MARKETING_TURQUOISE;
                            }
                        }
                    }

                    // Selectize overrides
                    .selectize-control {
                        > .selectize-dropdown {
                            text-align: left;
                        }

                        > .selectize-input {
                            height: 50px;
                            padding: 16px 20px 15px;
                            text-align: left;

                            .ie & {
                                padding: 14px 20px 17px;
                            }

                            input {
                                height: auto;
                                padding: 0;
                            }

                            @media (min-width: $screen-sm-min) {
                                height: 60px;
                                padding: 20px 20px 17px;
                                @include font-size(18);

                                .ie & {
                                    padding: 18px 20px 19px;
                                }
                            }

                            &.is-valid,
                            &.ng-valid {
                                border: 1px solid #e6e6e6;
                                &:not(.ng-pristine) {
                                    border: 1px solid $COLOR_MARKETING_TURQUOISE;
                                }
                            }
                        }
                    }

                    .form-show-password {
                        width: 20px;
                        height: 14px;
                        padding: 14px 16px;
                        position: absolute;
                        top: 25px - 14px;
                        right: 15px;
                        left: auto;
                        display: block;
                        background: url("~vectors/show-password.svg") center center no-repeat;
                        background-size: 20px 14px;
                        border: 0;
                        z-index: 1;
                        cursor: pointer;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            left: 15px;
                            right: auto;
                            text-align: right;
                        }

                        @media (min-width: $screen-sm-min) {
                            top: 30px - 14px;
                        }

                        &:active {
                            background-image: url("~vectors/show-password-active.svg");
                        }

                        .cordova & {
                            top: 30px - 14px;
                        }
                    }

                    .caption {
                        text-align: right;
                        font-size: 0.8em;
                        width: 100%;
                        display: block;
                        color: $COLOR_V3_BEIGE_DARK;
                    }
                }
            }
            .form-submit {
                width: 100%;
                padding: 18px 20px 17px;
                margin-top: 10px;
                margin-bottom: 5px;
                color: #fff;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-style: normal;
                @include font-size(18);
                background: padding-box $COLOR_V3_CORAL;
                border: 0;
                border-radius: 4px;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
                outline: 0;
                margin-top: 0;
                cursor: pointer;
                position: relative;

                @media (min-width: $screen-sm-min) {
                    padding: 20px;
                    margin-bottom: 15px;
                    @include font-size(21);
                }

                .loading-spinner {
                    display: none;
                    position: absolute;
                    right: 15px;
                    top: calc(50% - 10px);
                    @media (max-width: $screen-xs-max) {
                        right: 5px;
                    }
                }
                &.submitting {
                    .loading-spinner {
                        display: block;
                    }
                }

                &:active,
                &:focus,
                &:hover {
                    background: $COLOR_MARKETING_CORAL_HOVER;
                }

                .new-password-btn {
                    margin-top: 10px;
                }

                &[disabled] {
                    opacity: 0.4;
                    cursor: auto;
                    background: $COLOR_V3_CORAL;

                    // don't show this as disabled, since we can't detect auto-filled state
                    // we decided it was less weird to make the sign in button seem always enabled
                    sign-in-form & {
                        opacity: 1;
                    }
                }
            }

            .forgot-password-link-container {
                margin-bottom: 20px;
                display: block;
                @include font-size(16);
                text-align: left;

                // rtl-language overrides
                html[dir="rtl"] & {
                    text-align: right;
                }

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 63px;
                }

                a {
                    display: inline-block;
                    color: $COLOR_V3_CORAL;

                    @media (min-width: $screen-sm-min) {
                        float: left;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            float: right;
                        }
                    }
                }
            }

            img {
                width: 110px;
                margin-top: 18px;
                margin-bottom: 10px;

                @media (min-width: 768px) {
                    width: 130px;
                    margin-top: 23px;
                    margin-bottom: 18px;
                }
            }
            img.form-logo {
                width: 140px;
                margin: 10px auto 0px auto;
                display: none;
            }
            h1 {
                margin-bottom: 12px;
                .new-password-title {
                    margin-bottom: 48px;
                }
            }
            p.intro,
            p.reset {
                padding: 0 30px;
                margin-bottom: 34px;
                @include font-size(14);
                color: #b5b5b5;
                text-align: center;

                @media (min-width: $screen-sm-min) {
                    @include font-size(16);
                }
            }
            p.reset {
                padding: 0 50px;
                margin-bottom: 14px;
                @media (min-width: $screen-sm-min) {
                    margin-bottom: 34px;
                }
            }
        }

        .form-info {
            margin-top: 11px;
            display: block;
            @include font-size(14);
            color: #b5b5b5;
            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }
            a {
                color: $COLOR_V3_CORAL;
            }
        }
    }
}

// Cordova-only CSS adjust position and styling of forgot password form
.cordova forgot-password {
    position: absolute;
    margin-top: 0px;
    top: 50%;
    transform: translateY(-50%);

    .form-container {
        padding: 7px;
    }

    .login-register-footer {
        padding-bottom: 0px;

        .sign-in-link a {
            color: $COLOR_V3_CORAL !important;
        }
    }
}

// Cordova-only CSS to fill screen with login when we're an app on a device larger than iPhone 4
.cordova sign-in,
.cordova institution-register {
    .sessions {
        @media (min-width: $screen-sm-min) {
            position: absolute;
            margin-top: 0px;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            .login-register-footer {
                padding-bottom: 0px;
            }
        }

        .sessions-header-container {
            display: none;
        }

        // Hide outer header to save space
        .login-register-header {
            display: none;
        }

        .form-container {
            .form-container-inner {
                border-radius: 4px;

                // Show inner, non-linked logo
                img.form-logo {
                    display: block;
                }

                // Hide inner login header
                h1.login-h1 {
                    display: none;
                }
            }
        }

        @media (max-width: 767px) and (min-height: 550px) {
            .form-container {
                padding: 0px;

                .tab-pane {
                    position: static;
                }

                .form-container-inner {
                    // fill screen with inner form container
                    position: absolute;
                    top: 7px;
                    bottom: 7px;
                    left: 7px;
                    right: 7px;
                    width: calc(100% - 14px);
                    height: calc(100% - 15px);
                    min-height: 550px;

                    .form .form-group {
                        margin-bottom: 10px;
                    }

                    // position submit button at the bottom
                    .form-submit {
                        position: absolute;
                        left: 15px;
                        bottom: 40px;
                        width: calc(100% - 30px);
                    }
                }
            }

            .login-register-footer {
                position: absolute;
                display: block;
                bottom: 20px;
                left: 23px;
                margin: 0;
                padding: 0;
                height: auto !important;
                width: calc(100% - 46px);

                p {
                    height: auto;
                    margin: 0;
                    padding: 0;
                }

                a {
                    padding: 10px;
                    padding-left: 0px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
