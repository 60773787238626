.invalid-fields {
    background: $COLOR_V3_RED;
    color: $COLOR_V3_WHITE;
    margin: -15px 0 15px;
    padding: 20px;
    font-weight: $FONT_WEIGHT_SEMIBOLD;

    @media (min-width: $screen-sm-min) {
        padding: 20px 56px;
    }

    p {
        font-size: 16px;
    }

    .field {
        margin: 10px 0 0;
        height: 22px;

        @media (min-width: $screen-xs-min) {
            max-width: 50%;
        }

        span {
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            background: $COLOR_V3_WHITE;
            line-height: 22px;
            font-size: 14px;
            color: $COLOR_V3_RED;
            padding: 0 10px;
            border-radius: 11px;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            transition: 0.25s ease;

            i {
                font-size: 12px;
                margin-right: 5px;
            }

            .no-touchevents &:hover {
                box-shadow: 0 0 0 2px $COLOR_V3_RED_DARK;
                color: $COLOR_V3_RED_DARK;
            }
        }
    }
}
