settings {
    display: block;
    height: 100%; // see page-with-left-nav-and-main-box

    .application-deadline-reminder {
        text-align: center;
        margin: 0 5px;
        padding-top: 15px;
        font-size: 14px;
        color: $COLOR_V3_BEIGE_DARKEST;
    }

    .application-program-type-container {
        text-align: center;
        margin: 20px auto 10px;
        padding-top: 20px;
        color: $COLOR_V3_BEIGE_DARKEST;

        .application-program-type {
            text-align: center;
        }

        .change-application-program-type {
            font-size: 14px;
            a {
                color: $COLOR_V3_BLUE;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .main-box-header {
        a {
            color: $COLOR_V3_GREY_DARKER;
            text-decoration: underline;
        }

        .congrats-circle {
            color: $COLOR_PIXELMATTERS_GREEN;
        }

        [name="application-header-text"] + .sub-header a {
            color: $COLOR_V3_BLUE;
            text-decoration: none;
        }

        .application-status-subheader {
            // Note: Using !important because we set the color up in page-with-left-nav-and-main-box
            color: $COLOR_V3_BLACK !important;

            a {
                text-decoration: none;
                color: $COLOR_V3_CORAL;
            }
        }
    }
}
