// includes sass maps for image data used throughout onboarding
@import "image_data.scss";

$onboarding_short_device_max_height: 500px;
$color_smartly_red: rgb(255, 77, 99);

onboarding-hybrid-start {
    overflow-y: hidden !important;
}

onboarding,
onboarding-forgot-password,
onboarding-login,
onboarding-register {
    background-color: #fff;
    overflow-x: hidden;
}

onboarding,
onboarding-forgot-password,
onboarding-login,
onboarding-register,
onboarding-hybrid-start,
onboarding-hybrid-questionary,
onboarding-hybrid-register,
onboarding-hybrid-login,
onboarding-hybrid-forgot-password,
onboarding-hybrid-signed-in {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    height: auto;
    position: absolute;
    * {
        // NOTE: See override for password-eyeball-wrapper
        display: inline-block;
        box-sizing: border-box;
    }

    .sessions {
        display: block;
        * {
            display: block;
        }
        .soc-btn {
            display: inline-block;
            span {
                display: inline-block;
            }
        }
    }

    // NOTE: This is a hack for now. See the asterisk selector above.
    .password-eyeball-wrapper {
        display: block;
    }

    .full_width {
        width: 100%;
    }
    .full_height {
        height: 100%;
    }
    a,
    a:focus,
    a:active,
    a:visited {
        text-decoration: none !important;
    }
    .onboarding_hybrid_outter_wrapper {
        position: absolute;
        left: 50%;
        width: 100%;
        padding: 0px 10px;
        transform: translateX(-50%);
        min-height: 100%;
        height: auto;
    }
    .hybrid__header {
        width: 100%;
        height: 55px;
        margin: 34px 0px 25px 0px;
        .hybrid__header__logo {
            width: 55px;
            height: 55px;
            float: left;
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender-Quantic"));
            background-size: 55px 55px;
            background-repeat: no-repeat;
            background-position: center center;

            // rtl-language overrides
            html[dir="rtl"] & {
                float: right;
            }

            &.smartly {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender"));
            }

            &.miyamiya {
                @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Message-Sender-MiyaMiya"));
            }
        }
        .hybrid__header__message {
            html:not([lang="en"]) & {
                height: 70px;
            }

            height: 55px;
            width: auto;
            border-radius: 10px;
            position: relative;
            display: inline-block;
            margin-left: 10px;
            background-color: white;
            padding: 6px 17px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0px;
                margin-right: 10px;
                top: -15px;
            }

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 30px;
                left: -7px;
                width: 0px;
                height: 0;
                border-style: solid;
                border-width: 0px 0 25px 21px;
                border-color: transparent transparent white transparent;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: -7px;
                    border-width: 0px 21px 25px 0px;
                }
            }
            .hybrid__header__message__text p {
                margin: 0px;
                width: 100%;
                font-size: 14px;
                max-width: 200px;
                font-weight: $FONT_WEIGHT_MEDIUM;
                letter-spacing: -0.1px !important;
            }
            .hybrid_header__loading {
                position: absolute;
            }
            .hybrid__header__loading .point {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                margin: 25px 3px 0px 3px;
                float: left;
                background-color: black;
                opacity: 0.3;
            }
        }
    }
}

onboarding-hybrid-register {
    background-color: $color_smartly_red;
    overflow-y: scroll;
    .onboarding_hybrid_outter_wrapper {
        background-color: $color_smartly_red;
        padding-bottom: 10px;
    }
    .onboarding__form {
        box-shadow: 0px 2px 2px 0px rgba(black, 0.1);
        border-radius: 10px;
        padding: 10px 0px 0px;

        @media screen and(min-width:375px) {
            padding: 16px 10px;
        }
    }

    input:nth-of-type(3) {
        margin-bottom: 0px;
    }
}

onboarding-hybrid-register::-webkit-scrollbar {
    display: none;
}
onboarding-hybrid-login::-webkit-scrollbar {
    display: none;
}

onboarding-hybrid-login,
onboarding-hybrid-forgot-password {
    overflow: hidden;
    padding: 0px 10px;
    text-align: center;
    background-color: white;

    label {
        text-align: left;

        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: right;
        }
    }

    .onboarding_hybrid_outter_wrapper {
        background-color: white;
    }
    .onboarding__back__button {
        width: 44px;
        height: 61px;
        padding: 44px 22px 34px 12px;
        position: absolute;
        left: 0px;
        @include background-image-onboarding(map-get($ONBOARDING_IMAGES, "Back-Icon"));
        background-size: 10px 17px;
        background-repeat: no-repeat;
        background-position: center center;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
            transform: scaleX(-1);
        }
    }
    .logo {
        margin: 32px auto;

        // special handling for short devices
        @media (max-height: $onboarding_short_device_max_height) {
            margin-bottom: 0px;
        }
    }
}

onboarding-hybrid-login {
    overflow-y: scroll;
}

onboarding-hybrid-forgot-password {
    h3 {
        margin: 0px auto 12px auto !important;
    }
    p {
        font-size: 14px;
        margin-bottom: 20px;
        color: rgb(181, 181, 181);
    }
    .forgot__password__back_to_login {
        width: 260px;
        height: 46px;

        background-color: rgba(255, 77, 99, 1);
        color: white;
        outline: 0px;
        border: 0px;

        font-size: 16px;
        line-height: 46px;
        padding: 0px;
        &:active {
            background-color: rgba(255, 77, 99, 0.8);
        }
    }
}

onboarding-hybrid-signed-in {
    background-color: $color_smartly_red;

    .onboarding_hybrid_outter_wrapper {
        background-color: $color_smartly_red;
    }
    .onboarding__hybrid__signed_in__card {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        padding-bottom: 11px;
        text-align: center;

        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
        img {
            margin: 21px auto;
        }
        h3 {
            font-size: 16px;
            line-height: 22px;
            font-weight: $FONT_WEIGHT_MEDIUM;
        }
        a {
            &.blue_background {
                margin-top: 10px;
                height: 44px;
                width: 160px;
                border-radius: 22px;

                font-size: 18px;
                line-height: 44px;

                color: white;
                background-color: rgb(74, 118, 255);
                &:active {
                    opacity: 0.8;
                }
            }
            &.no_background {
                color: rgba(0, 0, 0, 0.5);
                padding: 9px 10px;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
}

@import "onboarding.hybrid.start.scss";
@import "onboarding.hybrid.questionary.scss";
@import "onboarding.hybrid.form.scss";
@import "buttons.scss";
@import "footer.scss";
@import "header.scss";
@import "images.scss";
@import "modals.scss";
@import "questionary.scss";
@import "register.scss";
