onboarding-dialog-modal {
    z-index: 9999;

    // fill the entire area
    &,
    .main-container,
    .background {
        position: absolute;
        display: block;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        position: fixed;
        background-color: rgba($COLOR_V3_BLACK, 0.5);
        z-index: 9999;
    }

    .main-container > .main {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: $COLOR_V3_WHITE;
        text-align: center;
        z-index: 10000;

        @media (min-width: 450px) {
            border-radius: 10px;
            width: 470px;
            margin: 120px auto;
            position: relative;
            top: 0px;
            left: 0px;
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 30px;
            margin-top: -18px; // half the height of the app menu for perfect vertical centering

            @media (min-width: 450px) {
                position: static;
                transform: none;
                margin-top: 0;
            }
        }

        .title {
            font-size: 24px;
            line-height: 1.1em;
            color: $COLOR_V3_BLACK;
            margin-bottom: 30px;
        }

        .image {
            margin: 0 -30px;
        }

        .body {
            color: $COLOR_V3_GREY_DARKER;
            font-size: 15px;
            line-height: 1.25;
            min-height: 54px;
            margin: 20px auto;

            @media (min-width: 450px) {
                min-height: 36px;
            }

            b {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }

        &.step-one .body {
            max-width: 300px;
        }

        &.step-two .body {
            max-width: 340px;
        }

        .dots {
            text-align: center;
            margin: 20px 0;
            padding: 0;

            li {
                display: inline-block;
                list-style: none;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0 5px;

                &.active {
                    background: $COLOR_V3_GREY_LIGHT;
                }

                &.inactive {
                    border: 1px solid $COLOR_V3_GREY_LIGHT;
                }
            }
        }

        .actions {
            margin: 20px 0;

            button,
            span {
                margin: 0 10px;
            }

            span {
                color: $COLOR_V3_GREY_DARK;
                text-decoration: underline;
                transition: 0.25s ease;
                cursor: pointer;

                &:hover {
                    color: $COLOR_V3_GREY_DARKER;
                }
            }
        }

        small {
            display: block;
            margin: 0 auto;
            width: 200px;

            @media (min-width: 450px) {
                width: 100%;
            }

            &,
            a {
                color: $COLOR_V3_GREY_DARK;
            }
        }

        small a {
            text-decoration: underline;

            &:hover {
                color: $COLOR_V3_GREY_DARKER;
            }
        }

        input[type="email"].ng-dirty.ng-invalid {
            border-color: $COLOR_V3_RED;
        }
    }
}
