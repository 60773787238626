feedback-sidebar {
    display: block;
    position: fixed;
    z-index: 100;
    top: 450px; // see also: iphonex.scss
    left: 0px;
    margin: 0;
    opacity: 0;
    transform: translate(-100px, 0px);
    animation: slide-in-left-sidebar 0.5s 0.5s ease;
    animation-fill-mode: forwards;
    width: auto;
    // The <a> tag in the feedback-sidebar element gets rotated, but the
    // feedback-sidebar element itself doesn't get rotated and doesn't seem to
    // respond to a rotation when it's added to the element's styles. This can potentially
    // result in clicks getting blocked by the user that are intended for lesson content (e.g. key term modals).
    // As a workaround, we remove its ability to be clicked, but ensure the <a> tag has
    // pointer-events set to auto, allowing it to be clicked.
    pointer-events: none;

    // rtl-language overrides
    html[dir="rtl"] & {
        left: auto;
        right: 0px;
        transform: translate(100px, 0px);
        animation: slide-in-right-sidebar 0.5s 0.5s ease;
        animation-fill-mode: forwards;

        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            right: 7px;
        }
    }

    > div {
        > a {
            display: block;
            padding: 2px 10px;
            font-size: 16px;
            transition: all 0.15s;
            transform-origin: left top;
            transform: rotate(-90deg);
            text-decoration: none !important;
            pointer-events: auto; // this needs to be here because feedback-sidebar has pointer-events set to none (see comment above)

            // rtl-language overrides
            html[dir="rtl"] & {
                transform-origin: right top;
                transform: translateY(20px) rotate(90deg);
            }

            &:hover {
                padding-top: 7px;
            }

            > span,
            > i {
                background: transparent;
                transition: all 0.15s;
            }
        }

        body:not(.valar):not(.bg-blue):not(.bg-purple):not(.bg-turquoise) & {
            > a {
                background-color: $COLOR_V3_CORAL;
                color: $COLOR_V3_WHITE;

                &:hover {
                    background-color: lighten($COLOR_V3_CORAL, 2%);
                }
            }
        }

        body.valar:not(.bg-blue):not(.bg-purple):not(.bg-turquoise) & {
            > a {
                background-color: $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;

                &:hover {
                    background-color: lighten($COLOR_V3_BLUE, 2%);
                }
            }
        }

        /* Handle styling on specially colored frames */
        body.bg-turquoise & {
            > a {
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_TURQUOISE_DARK;
            }
        }

        body.bg-blue & {
            > a {
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_BLUE_DARK;
            }
        }

        body.bg-purple & {
            > a {
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_PURPLE_DARK;
            }
        }
    }

    // Hide while sliding back to the right
    .route-leave & {
        display: none !important;
    }

    .showing-end-column & {
        display: none !important;
    }
}
