div.change-section-or-scholarship {
    display: flex;
    flex-direction: column;

    button.outline {
        width: fit-content;
        margin: 10px 15px 15px 0;
        align-self: end;
        padding: 5px;
    }

    > .section-and-scholarship {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .add-scholarship {
            align-self: end;
            margin-top: 5px;
        }

        > div {
            width: 100%;
            display: flex;

            > p {
                display: flex;
                text-transform: capitalize;
                justify-content: flex-start;
                align-items: center;
                flex: 0 0 25%;
                min-width: 85px;
                margin: 0 0 0 10px;
            }

            > div {
                display: flex;
                flex-direction: column;
                flex: 1 1 75%;
                margin: 0.5rem 0.5rem 0 0;
            }

            .remove-scholarship {
                align-self: center;
                width: 40px;
                height: 40px;
            }
        }
    }
}
