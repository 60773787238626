//
// Questionary.
//

.onboarding_questionary {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
}

// Questionary elements alignment.
.onboarding_questions,
.onboarding_actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboarding_questions {
    width: 100%;
    height: 80px;
}

.onboarding-question {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
}

.onboarding_question_container {
    position: relative;
    margin-bottom: 20px;
}

// Question.
.onboarding_question {
    top: 0;
    font-size: 17px;
    line-height: 24px;
    padding: 0;
    color: #000;
}

//
// Steps progress.
//

.onboarding_steps {
    margin-bottom: 20px;

    &.ng-enter {
        transition: opacity 0.4s 0.4s ease;
    }

    &.ng-leave {
        transition: opacity 0.4s ease;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }
}

.onboarding_step {
    position: relative;

    .onboarding_step_placeholder {
        position: absolute;
        opacity: 0;
        z-index: 1;

        transition: opacity 0.5s ease;

        &::before,
        &::after {
            left: 0;
        }

        &::before {
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES_ANIMATED, "progress-circle-active"));
            background-size: 100%;
        }

        &::after {
            @include background-image-onboarding(map-get($ONBOARDING_IMAGES_ANIMATED, "progress-connection-active"));
            background-size: 100%;
        }
    }

    &,
    .onboarding_step_placeholder {
        width: 31px;
        height: 12px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
        }

        &::before {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            transform: translate3d(0, -50%, 0);
        }

        &::after {
            width: 22px;
            height: 6px;
            transform: translate3d(-20px, -50%, 0);
        }
    }

    &::before {
        @include background-image-onboarding(map-get($ONBOARDING_IMAGES_ANIMATED, "progress-circle"));
        background-size: 100%;
    }

    &::after {
        @include background-image-onboarding(map-get($ONBOARDING_IMAGES_ANIMATED, "progress-connection"));
        background-size: 100%;
    }

    &:first-child {
        &::after,
        .onboarding_step_placeholder::after {
            display: none;
        }
    }
}

.onboarding_step__active {
    .onboarding_step_placeholder {
        opacity: 1;
    }
}

// Actions.
.onboarding_actions {
    > .btn {
        min-width: 120px;
        margin: 0 10px;
        margin-bottom: 40px;

        font-size: 18px;

        color: #000;

        background-color: #f0f0f0;

        transition: background-color 0.3s ease, color 0.3s ease;

        &-confirm:active {
            color: #fff;
            background-color: darken(#54de6f, 20%);
        }

        &-reject:active {
            color: #fff;
            background-color: darken(#ff4d63, 20%);
        }

        &-confirm:active,
        &-confirm:focus,
        &-confirm:visited,
        &-reject:active,
        &-reject:focus,
        &-reject:visited {
            box-shadow: none;
        }
    }
}

//
// Media queries.
//

// Large screens.
@media (min-width: $screen-sm-min) {
    .onboarding_questionary {
        width: 600px;
    }

    .onboarding_questions {
        height: 100px;
        margin-bottom: 40px;
    }

    .onboarding_question {
        font-size: 28px;
        line-height: 40px;
    }

    .onboarding_actions {
        > .btn {
            .no-touchevents &-confirm:hover,
            .no-touchevents &-reject:hover {
                color: #fff;
            }

            .no-touchevents &-confirm:hover {
                background-color: #54de6f;
            }

            .no-touchevents &-reject:hover {
                background-color: #ff4d63;
            }
        }
    }
}
