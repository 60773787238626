div.resume-and-links {
    // specificity needed to override the default padding
    .front-royal-form-container &.form-group.row {
        padding-inline-start: 100px;
    }

    p {
        padding-left: 0px !important;
    }

    select-with-other {
        padding-left: 0px;
        padding-right: 0px;
    }

    write-text-about {
        padding-left: 0px;
        padding-right: 0px;
    }
}

div.links-item {
    // specificity needed to override the default padding
    .front-royal-form-container &.form-group.row {
        padding-inline-start: 100px;
    }
}
