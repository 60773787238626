/*
    Ideally, no one will evet see these styles.  However, since
    we lazy load the assessment lesson finish screen styles, it
    is possible for the finish screen to show when those styles are
    not yet loaded.  This file just makes sure it doesn't lool
    absolutely terrible in that case.
*/
.assessment-lesson-end {
    .frames-gauge {
        display: none;
    }

    .score-gauge {
        display: none;
    }

    .challenges-gauge {
        text-align: center;

        .inset {
            font-size: 36px;
        }
    }
}
