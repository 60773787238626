application-status {
    display: block;

    .faq-link,
    .sub-text.desktop-message {
        color: $COLOR_V3_CORAL;
    }

    .sub-text a {
        color: $COLOR_V3_EGGPLANT;

        &:hover {
            text-decoration: underline;
        }
    }

    .form-group.row:last-of-type {
        margin-bottom: 0px;
    }

    .tuition-and-registration {
        padding: 1px 0 0 0;
        margin-top: -15px;
    }
}
