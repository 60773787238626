.ng-toast {
    // NOTE: we may wish to revisit this if we ever want click dismissal
    pointer-events: none;

    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 0;
    margin-top: $APP_HEADER_HEIGHT + 20px;
    text-align: center;
    transform: translateZ(0); // WebKit hack - http://stackoverflow.com/a/18764086/1747491

    &.ng-toast--top {
        top: 0;
        bottom: auto;

        .ng-toast__list {
            top: 0;
            bottom: auto;
        }

        &.ng-toast--center {
            .ng-toast__list {
                position: static;
            }
        }
    }

    &.ng-toast--bottom {
        top: auto;
        bottom: 0;

        .ng-toast__list {
            top: auto;
            bottom: 0;
        }

        &.ng-toast--center {
            .ng-toast__list {
                pointer-events: none;
            }

            .ng-toast__message {
                .alert {
                    pointer-events: auto;
                }
            }
        }
    }

    &.ng-toast--right {
        .ng-toast__list {
            left: auto;
            right: 0;
            margin-right: 20px;
        }

        .ng-toast__message {
            text-align: right;
        }
    }

    &.ng-toast--left {
        .ng-toast__list {
            right: auto;
            left: 0;
            margin-left: 20px;
        }

        .ng-toast__message {
            text-align: left;
        }
    }

    .ng-toast__list {
        display: inline-block;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        padding: 0;
        list-style: none;
    }

    .ng-toast__message {
        display: block;
        width: 100%;
        text-align: center;

        .alert {
            display: inline-block;
        }
    }

    .ng-enter,
    .ng-leave,
    .ng-move {
        transition: all 0.3s ease;
    }
    .ng-enter {
        left: -50px;
        opacity: 0;
    }
    .ng-enter.ng-enter-active {
        left: 0;
        opacity: 1;
    }
    .ng-leave {
        left: 0;
        opacity: 1;
    }
    .ng-leave.ng-leave-active {
        left: -50px;
        opacity: 0;
    }
    .ng-move {
        opacity: 0.5;
    }
    .ng-move.ng-move-active {
        opacity: 1;
    }
}
