playlist-map-stream-box {
    .locked-tooltip {
        // override the stream-link-box tooltip placement behavior
        .tooltip-arrow {
            left: 85px !important;
        }
    }

    .playlist-map-stream-box {
        margin: 3px;
        padding: 6px 6px 16px 6px;
        display: inline-block;
        width: calc(100% - 6px);
        max-width: 180px;
        border: 2px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.15s;

        @media (min-width: $screen-sm-min) {
            width: calc(100% - 20px);
        }

        .no-touchevents &:hover,
        .no-touchevents &:active,
        .touchevents &:active {
            border: 2px solid $COLOR_V3_BEIGE;
            background-color: rgba($COLOR_V3_BEIGE, 0.15);
        }

        &.locked {
            border: 2px solid transparent !important;
            background: none !important;
            cursor: default;
        }

        .title {
            font-size: 12px;
            line-height: 1em;

            @media (min-width: $screen-sm-min) {
                font-size: 15px;
            }
        }

        playlist-map-content-item-icon {
            .playlist-map-content-item-icon-container {
                padding: 0;
                height: 56px;
                .playlist-arc {
                    display: none;
                }
            }
        }
    }
}
