.playlist-box {
    margin: 3px;
    padding: 6px 6px 16px 6px;
    display: inline-block;
    width: calc(100% - 6px);
    max-width: 180px;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.15s;

    @media (min-width: $screen-sm-min) {
        width: calc(100% - 20px);
    }

    &.active,
    &.recommended {
        border: 2px solid $COLOR_V3_BEIGE;

        .status-row {
            visibility: visible;
        }
    }

    .no-touchevents &:hover,
    .no-touchevents &:active,
    .touchevents &:active {
        border: 2px solid $COLOR_V3_BEIGE;
        background-color: rgba($COLOR_V3_BEIGE, 0.15);
    }

    &.locked {
        // The active playlist looks weird if you apply this style
        &:not(.active) {
            border: 2px solid transparent !important;
        }

        background: none !important;
        cursor: default;
    }

    .status-row {
        display: block;
        visibility: hidden;
        min-height: 18px;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 11px;

        @media (min-width: $screen-sm-min) {
            font-size: 12px;
            min-height: 20px;
        }

        .status-label,
        .status-percent {
            display: inline-block;
        }

        .status-active {
            .status-label {
                float: left;
            }
            .status-percent {
                float: right;
            }
        }
    }

    .title {
        font-size: 12px;
        line-height: 1em;

        @media (min-width: $screen-sm-min) {
            font-size: 15px;
        }
    }
}
