.hr-caption {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    color: $COLOR_V3_BEIGE_DARKER;

    // mobile font styling
    font-size: 20px;
    font-weight: $FONT_WEIGHT_SEMIBOLD;
    line-height: 20px;

    &.has-toggles {
        @media (max-width: $screen-xs-max) {
            text-align: left;
            padding-top: 2px;
            margin-bottom: 13px;
            padding-right: 85px;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 0px;
                padding-left: 85px;
                text-align: right;
            }
        }
    }

    // Desktop font styling
    @media (min-width: $screen-sm-min) {
        font-size: 25px;
        line-height: 30px;
    }
}

hr.hr-hexagon {
    display: block;
    width: auto;
    height: 3px;
    background-color: $COLOR_V3_BEIGE_DARKER;
    border: 0px solid transparent;
    border-radius: 1.5px;

    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 30px;
    }

    &:after {
        content: "";
        background-size: 18px 18px;
        display: block;
        position: absolute;
        left: 50%;
        height: 18px;
        width: 18px;
        margin: -7.5px 0 0 -9px; // some padding internal to svg
    }

    &:after,
    &.beige-bg:after {
        background-image: url("~vectors/horizontal_rule_diamond_beige.svg");
    }

    &.white-bg:after {
        background-image: url("~vectors/horizontal_rule_diamond_beige_on_white.svg");
    }

    &.lightest-beige-bg:after {
        background-image: url("~vectors/horizontal_rule_diamond_beige_on_lightest_beige.svg");
    }
}
