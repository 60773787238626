.tuition-and-registration {
    cohort-registration-plan-selection,
    cohort-registration-no-plan-selection,
    cohort-registration-full-scholarship {
        a {
            color: $COLOR_V3_EGGPLANT;
        }

        strong {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .registration-body {
            border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            border-radius: 4px;
            padding: 20px;
            margin: 20px 0;
            overflow: hidden;
            position: relative;
            transform: translateZ(0); // work around an iOS bug: https://trello.com/c/Xk0dBGIE
            text-align: left;

            &.disabled {
                padding: 20px 20px 0 20px;

                .registration-date {
                    border-bottom: none;
                }
            }

            h3 {
                color: $COLOR_V3_GREY_DARKER;
                margin: 0 0 8px;
                font-size: 14px;
            }

            .registration-body-footer {
                .register-btn {
                    width: 100%;
                    margin: 20px 0 10px;

                    @media (min-width: $screen-sm-min) {
                        width: 210px;
                        float: right;
                        margin-top: 0;
                        white-space: normal;

                        html:lang(am) &,
                        html:lang(it) & {
                            font-size: 16px;
                        }
                    }
                }

                &.payment-view-select-plan {
                    .register-btn.continue_to_register {
                        display: flex;
                        font-size: 18px;
                        align-items: center;
                        justify-content: center;
                        white-space: normal;

                        .register-btn-icon {
                            font-size: 14px;
                        }

                        @media (min-width: $screen-sm-min) {
                            font-size: 16px;
                        }

                        [dir="rtl"] & {
                            flex-direction: row-reverse;
                        }
                    }
                }

                &.payment-view-select-payment {
                    .selected-plan-header {
                        text-align: center;
                        margin: 6px 0 17px;

                        .selected-plan {
                            color: $COLOR_V3_GREY_DARKER;
                            font-size: 14px;
                            font-weight: $FONT_WEIGHT_NORMAL;
                            margin: 0 -5px 3px;
                        }
                        .selected-plan-detail {
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                            font-size: 12px;
                        }
                    }

                    .instructions {
                        color: $COLOR_V3_GREY_DARKER;
                        font-weight: $FONT_WEIGHT_MEDIUM;
                        text-align: center;
                        font-size: 15px;
                        margin: 0 0 9px;
                    }

                    .options-container {
                        display: flex;
                        justify-content: center;
                        gap: 8px;
                        margin: 0 0 20px 0;

                        &.has-4 {
                            flex-wrap: wrap;
                            justify-content: space-around;
                            .option-container {
                                flex: 0 1 130px;
                            }
                        }

                        @media (min-width: $screen-sm-min) {
                            gap: 18px;

                            &.has-4 {
                                gap: -10px;
                                flex-wrap: nowrap;
                                justify-content: center;
                                .option-container {
                                    flex: 0 1 155px;
                                }
                            }
                        }

                        .option-container {
                            display: block;
                            flex: 0 1 155px;
                            pointer-events: all;
                            cursor: pointer;

                            input[type="radio"] {
                                visibility: hidden;
                                position: absolute;

                                & + .option-btn-container {
                                    display: block;
                                    font-size: 14px;
                                    color: $COLOR_V3_BEIGE_BEYOND_DARK;

                                    &,
                                    & > * {
                                        transition: all 0.1s ease;
                                    }

                                    .option-btn {
                                        color: inherit;
                                        display: flex;
                                        width: 100%;
                                        height: 78px;
                                        padding: 10px;
                                        margin: 0 0 7px 0;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        align-items: flex-start;
                                        border-radius: 6px;
                                        border: 2px solid $COLOR_V3_BEIGE_MIDDARK;
                                        background: $COLOR_V3_WHITE;
                                        pointer-events: all;

                                        &:focus-visible {
                                            outline: 1;
                                        }

                                        @media (min-width: $screen-sm-min) {
                                            padding: 14px;
                                            font-size: 15px;
                                        }

                                        html:lang(am) &,
                                        html:lang(es) &,
                                        html:lang(it) & {
                                            font-size: 13px;

                                            @media (min-width: $screen-sm-min) {
                                                padding: 12px;
                                            }
                                        }

                                        i.option-btn-icon {
                                            font-size: 17px;
                                            color: inherit;

                                            @media (min-width: $screen-sm-min) {
                                                font-size: 20px;
                                            }
                                        }

                                        img.option-btn-icon {
                                            height: 17px;
                                            margin-inline-end: 4px;
                                            margin-top: -5px;
                                            filter: saturate(0);

                                            @media (min-width: $screen-sm-min) {
                                                height: 20px;
                                            }
                                        }

                                        .option-btn-title {
                                            color: inherit;
                                            font-size: inherit;
                                            text-align: start;
                                            line-height: 1;
                                        }
                                    }

                                    .option-subtext {
                                        display: block;
                                        text-align: center;
                                        font-size: 10px;
                                        color: inherit;

                                        @media (min-width: $screen-sm-min) {
                                            font-size: 12px;
                                        }
                                    }
                                }

                                &:checked,
                                &:checked:hover {
                                    + .option-btn-container {
                                        color: $COLOR_V3_GREY_DARKER;

                                        .option-btn {
                                            border-color: rgba(84, 222, 111, 1);

                                            img.option-btn-icon {
                                                filter: saturate(1);
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    + .option-btn-container {
                                        color: $COLOR_V3_BEIGE_DARKEST;

                                        .option-btn {
                                            border-color: $COLOR_V3_BEIGE_DARKEST;
                                        }
                                    }
                                }
                            }

                            &.us_bank_account {
                                .option-subtext {
                                    text-transform: uppercase;
                                }
                            }

                            &.card {
                                .option-subtext {
                                    &:after {
                                        content: "\2020";
                                        vertical-align: super;
                                        font-size: 6px;
                                    }
                                }
                            }
                        }
                    }

                    .actions-container {
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;

                        .back-to-plan-selection-btn {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            border: 0;
                            background: none;
                            color: $COLOR_V3_GREY_DARKER;
                            font-size: 14px;
                            font-weight: $FONT_WEIGHT_NORMAL;
                            letter-spacing: 0.02em;

                            .btn-icon {
                                display: inline-block;
                                margin-right: 7px;
                                color: inherit;
                            }

                            .btn-text {
                                text-decoration: underline;
                                display: inline-block;
                                color: inherit;
                            }

                            [dir="rtl"] & {
                                flex-direction: row-reverse;
                            }
                        }

                        .register-btn {
                            margin: 0 0 15px 0;
                        }

                        @media (min-width: $screen-sm-min) {
                            flex-direction: row;
                            justify-content: space-between;

                            .register-btn {
                                margin: 0;
                            }

                            [dir="rtl"] & {
                                flex-direction: row-reverse;
                            }
                        }
                    }
                }
            }

            &.full-scholarship {
                .registration-body-footer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .registration-graphic {
            background: url("~vectors/scholarship_ribbon.svg") no-repeat 0 0 / 40px 54px;
            padding: 7px 0 0 55px;
            margin-bottom: 20px;
            line-height: 22px;

            @media (min-width: $screen-sm-min) {
                position: static;
                height: auto;
                float: right;
                min-height: 54px; // ensures the ribbon image doesn't get cut off at the bottom
            }

            .graphic-title {
                font-size: 20px;
                color: $COLOR_V3_GREY_DARKER;
                min-width: 160px; // ensure it doesn't get too narrow

                strong {
                    font-size: 32px;
                }
            }

            .graphic-subtitle {
                color: $COLOR_V3_BEIGE_DARKEST;
            }
        }

        .allow-profile,
        .email-our-bursar {
            margin-bottom: 0;

            font-size: 12px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            @media (min-width: $screen-sm-min) {
                width: 210px;
                float: right;
            }
        }

        .register-button-container {
            display: flex;
            flex-direction: column;

            .allow-profile {
                @media (min-width: $screen-sm-min) {
                    align-self: flex-end;
                }
            }
        }

        .allow-profile {
            align-self: center;

            label {
                padding-top: 0;
                margin-bottom: 0;
                color: $COLOR_V3_GREY_DARKER;
                font-size: 12px;

                input[type="checkbox"]:before {
                    top: -2px;
                }

                .checkbox-group-label-wrapper {
                    line-height: 16px;
                    vertical-align: middle;
                }

                &:last-child {
                    margin-top: 5px;
                }
            }
        }

        .registration-narrative {
            margin-bottom: 20px;

            @media (min-width: $screen-sm-min) {
                padding-right: 275px; // note: "graphicSubtitleKey" was changed to a longer string, so this made the graphic section wider and this value needed to be updated in order to respect that.
            }
        }

        .made-x-payments {
            text-align: center;
            color: $COLOR_V3_GREY_DARKER;
            font-size: 14px;
            border-top: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            padding: 20px;
            margin: 0 -20px; // counteract parent padding
        }

        .registration-date {
            margin: 0 -21px;
            padding: 10px 20px 5px 20px;
            background: rgba($COLOR_V3_YELLOW, 0.5);
            border: 1px solid $COLOR_V3_YELLOW;
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            width: calc(100% + 42px); // 42px: negative-margin
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: $screen-sm-max) {
                padding: 10px 0 5px 0;
            }

            span {
                width: 100%;
                color: $COLOR_V3_GREY_DARKER;
                margin-bottom: 5px;
            }

            a {
                color: inherit;
            }
        }

        .registration-body-footer {
            @extend .clearfix;
            position: relative;
            background: $COLOR_V3_BEIGE_LIGHTEST;
            margin: 0 -20px -20px;
            padding: 20px;

            .confirm-message {
                color: $COLOR_V3_GREY_DARKER;
                font-size: 14px;
                margin-bottom: 61px;
                margin-top: 14px;

                @media (min-width: $screen-sm-min) {
                    padding-right: 260px;
                    margin-top: 0px;
                    margin-bottom: -10px;
                    min-height: 41px;
                }

                &.supports-multi-plan {
                    @media (min-width: $screen-sm-min) {
                        margin-top: 30px;
                        padding-right: 0;
                        text-align: center;
                        min-height: 0;
                    }
                }
            }
        }

        .sub-registration-date {
            clear: both;
            margin: 20px -20px -20px;
            padding: 5px 20px;
            border-top: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            text-align: center;

            span {
                font-size: 14px;
                line-height: 1;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
            }
        }

        .registration-footer {
            border: none;

            .sub-text {
                font-size: 12px;
                line-height: 15px;

                &.transaction-fee-small-print {
                    &:before {
                        content: "\2020";
                        vertical-align: super;
                        font-size: 6px;
                    }
                }
            }
        }

        .sub-text.warning {
            color: $COLOR_V3_CORAL;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .payment-message {
            @extend .clearfix;
            background: $COLOR_V3_BEIGE_LIGHTEST;
            border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            border-radius: 4px;
            padding: 20px;
            margin-top: 20px;

            button {
                width: 100%;
                margin-bottom: 20px;

                @media (min-width: $screen-sm-min) {
                    width: 226px;
                    float: right;
                    margin: 0 0 0 35px;
                }
            }

            .sub-text {
                margin-bottom: 0;
            }
        }

        .plan-selection-container {
            color: $COLOR_V3_GREY_DARKER;

            &.isRegistrationTest {
                display: flex;
                flex-direction: column;
            }

            &.bottom-margin {
                margin-bottom: 20px;
            }

            @media (min-width: $screen-sm-min) {
                float: left;
                width: calc(100% - 230px); // 230px: width of register button + 20px
            }

            .plan-selection-label {
                padding: 0;
                margin-bottom: 20px;
                font-size: 14px;
                text-align: center;
                color: inherit;

                &.show-as-buttons {
                    margin-bottom: 25px;
                }

                @media (min-width: $screen-sm-min) {
                    text-align: left;
                    margin-bottom: 10px;
                }

                .explain-the-options-link-wrapper {
                    font-style: normal;
                    font-weight: $FONT_WEIGHT_NORMAL;
                    color: $COLOR_V3_GREY_DARKER;

                    @media (min-width: $screen-sm-min) {
                        padding-left: 5px;
                    }

                    a.explain-the-options-link {
                        color: inherit;
                        text-decoration: underline;
                        cursor: pointer;
                        pointer-events: auto;
                    }
                }
            }

            .plan-selection-option {
                @extend .clearfix;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0;

                &.show-as-buttons {
                    width: 100%;
                    max-width: 350px;

                    html:lang(en) & {
                        max-width: 333px;
                    }
                }

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }

                padding: 0;

                input {
                    font-size: 15px;
                    float: left;
                    margin-top: 3px;
                }

                .plan-bullet {
                    background: $COLOR_V3_BEIGE_BEYOND_DARK;
                    height: 7px;
                    width: 7px;
                    border-radius: 50%;
                    float: left;
                    margin-top: 4px;
                    margin-left: 8px;
                }

                .preferred-plan {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    margin-left: 15px;
                    padding: 8px 0 5px 0;
                    text-transform: uppercase;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;

                    background-color: $COLOR_V3_BEIGE_MIDDARK;
                    border-radius: 4px 4px 0 0;

                    &.selected {
                        background-color: $COLOR_V3_GREEN;
                        color: $COLOR_V3_WHITE;
                    }
                }

                > span {
                    display: block;
                    width: 100%;
                    padding: 0px;

                    .plan-selection-option-text {
                        display: block;
                        margin-left: 30px;
                        margin-top: 2px;

                        &.show-as-buttons {
                            margin-top: 0;
                            margin-left: 15px;
                            border: 2px solid $COLOR_V3_BEIGE_MIDDARK;
                            border-radius: 4px;
                            padding: 10px;
                            background-color: $COLOR_V3_WHITE;

                            &.upfront {
                                border-top: 0;
                                border-radius: 0 0 4px 4px;
                            }

                            &.selected {
                                border-color: $COLOR_V3_GREEN;
                            }
                        }

                        .plan-description {
                            color: $COLOR_V3_GREY_DARKER;
                            font-weight: $FONT_WEIGHT_SEMIBOLD;

                            .green-text {
                                padding: 0;
                                font-weight: $FONT_WEIGHT_SEMIBOLD;
                                color: $COLOR_V3_GREEN;
                            }
                        }

                        .plan-billing-details {
                            display: block;
                            font-size: 12px;
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        }
                    }
                }

                .full-price {
                    text-decoration: line-through;
                }

                [dir="rtl"] & {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }

    // modal
    .emba-billing-options {
        p {
            margin: 20px 0 0;
        }

        b {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_GREY_DARKER;
        }

        a {
            color: $COLOR_V3_BLUE;
            text-decoration: underline;
        }
    }

    cohort-registration-full-scholarship {
        .allow-profile {
            text-align: center;
        }
    }

    cohort-registration-plan-selection {
        .allow-profile {
            text-align: left;
        }
    }

    // directive-specific overrides
    cohort-registration-full-scholarship {
        .registration-body-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    cohort-registration-no-plan-selection {
        .register-btn {
            margin-left: auto !important; // this right-aligns the button within the flexbox
        }
    }
}
