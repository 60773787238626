@mixin icon-at-point($iconWidth, $iconHeight, $top, $left) {
    position: absolute;
    width: $iconWidth;
    height: $iconHeight;
    font-size: $iconHeight;
    line-height: $iconHeight;
    margin-left: -#{$iconWidth * 0.5};
    margin-top: -#{$iconHeight * 0.5};
    text-align: center;
    top: $top;
    left: $left;
}
