institution-register .sessions,
complete-registration .sessions {
    .form-container {
        width: 100%;
        margin: 0 auto;
        padding: 7px;

        @media (min-width: $screen-sm-min) {
            padding: 0 15px;
            width: 630px;
        }

        .nav {
            margin-top: 38px;
            padding: 0;

            display: table;

            z-index: 1;

            @media (min-width: $screen-sm-min) {
                margin-top: 35px;
            }
        }

        .form-container-inner {
            .sub-heading {
                margin-top: 8px;
                margin-bottom: 36px;
                text-align: center;
                font-weight: $FONT_WEIGHT_NORMAL;
                font-style: normal;
                @include font-size(16);
                color: $LOGIN_REGISTER_TEXT_GRAY;
            }

            .terms-privacy-notice {
                display: block;
                text-align: center;
                color: lighten($LOGIN_REGISTER_TEXT_GRAY, 25%);
                font-size: 0.9em;
                a {
                    color: lighten($LOGIN_REGISTER_TEXT_GRAY, 25%);
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                }
            }

            .form-submit {
                margin-top: 20px;
                margin-bottom: 5px;
                padding: 18px 20px 17px;
                position: relative;
                background-color: $COLOR_MARKETING_TURQUOISE;

                &:active,
                &:focus,
                &:hover {
                    background: darken($COLOR_MARKETING_TURQUOISE, 5%);
                }

                &[disabled] {
                    opacity: 0.4;
                    border-radius: 4px;
                    background: $COLOR_MARKETING_TURQUOISE;
                    @include box-shadow(0 2px 0 rgba(0, 0, 0, 0.075));

                    &:hover {
                        background: $COLOR_MARKETING_TURQUOISE;
                    }
                }
            }

            .form-info {
                margin-top: 11px;
                padding: 0 15px;

                line-height: 1.4;

                .terms-link {
                    white-space: nowrap;
                    color: #b5b5b5;
                    text-decoration: underline;
                }
            }
        }
    }

    .tab-pane {
        position: relative;

        .sign-up-sidebars {
            display: none;

            @media (min-width: 1130px) {
                display: block;
            }

            .upsell-sidebar-box {
                color: $LOGIN_REGISTER_TEXT_GRAY;
                position: absolute;
                display: block;
                width: 244px;
                height: 260px;
                padding: 18px;
                background: $COLOR_V3_WHITE;
                border: 0px solid transparent;
                border-radius: 4px;
                overflow: hidden;
                animation: fade-in 1s ease 1s;
                animation-fill-mode: both;

                // corner color
                &:before {
                    content: "";
                    border-top: 25px solid #14cca7;
                    border-right: 25px solid transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                // positioning

                &.top {
                    top: 35px;
                    &.with-payment {
                        top: -35px;
                    }
                }

                &.bottom {
                    top: 350px;
                    display: none;
                    opacity: 0;
                }

                &.left {
                    right: calc(100% + 20px);
                }

                &.right {
                    left: calc(100% + 20px);
                }

                // internal styles

                .text {
                    font-size: 13px;
                    line-height: 15px;
                    margin: 0px;
                }

                strong {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                }

                ul {
                    padding-left: 10px;
                }

                h2 {
                    color: $LOGIN_REGISTER_TEXT_GRAY;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    display: block;
                    text-align: center;
                    margin-top: -5px;
                }

                .quote {
                    font-size: 18px;
                    line-height: 22px;
                    display: block;
                    margin-bottom: 15px;
                }

                .employer-message {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    font-size: 18px;
                    line-height: 20px;
                }

                .bulbman {
                    width: 135px;
                    height: 135px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 15px;
                }

                .attribution {
                    position: relative;
                    padding-left: 65px;

                    .headshot {
                        width: 55px;
                        height: 55px;
                        display: block;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                    }

                    .fullname,
                    .occupation {
                        display: block;
                    }
                }

                .devices {
                    width: 165px;
                    display: block;
                    margin: 7px auto 20px;
                }

                .hr-sign-up-sidebars {
                    position: relative;
                    display: block;
                    width: 170px;
                    height: 1px;
                    background-color: $LOGIN_REGISTER_TEXT_GRAY;
                    border: 0px solid transparent;
                    margin: 10px auto 13px;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: calc(50% - 4px);
                        top: -3px;
                        height: 8px;
                        width: 8px;
                        border: 1px solid $LOGIN_REGISTER_TEXT_GRAY;
                        background: $COLOR_V3_WHITE;

                        transform: rotate(45deg) translateY(-0.5px);

                        .ie & {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .login-register-footer {
        padding: 46px 0 90px;
        margin: 0;
        a {
            color: $COLOR_MARKETING_TURQUOISE;
        }
    }
}
