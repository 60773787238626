button {
    // For cases with a lot of text on a button, we need to
    // push the arrow over farther to make more room.  See
    // /settings/account
    $paddingSmallScr: 5px;
    $paddingLgScr: 15px;

    //------------------------------
    // Arrrow Styles
    //------------------------------

    &.arrow-right,
    &.arrow-right-white,
    &.arrow-down,
    &.arrow-left,
    ,
    &.arrow-left-white {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            $arrowSize: 20px;
            $padding: 16px + $arrowSize + 4px; // default padding is 16px. make some more space for the arrow

            background-repeat: no-repeat;
            background-size: $arrowSize $arrowSize;
            background-position: right $paddingSmallScr top 50%;

            @media (min-width: $screen-sm-min) {
                background-position: right $paddingLgScr top 50%;
            }
        }
    }

    &.arrow-right {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-image: url("~vectors/arrow_right_beige_darker.svg");
        }
    }

    &.arrow-right-white {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-image: url("~vectors/arrow_right_white.svg");
        }
    }

    &.arrow-left {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-image: url("~vectors/arrow_left_beige_darker.svg");
        }
    }

    &.arrow-left-white {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-image: url("~vectors/arrow_left_white.svg");
        }
    }

    &.arrow-down {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-image: url("~vectors/arrow_down_beige_darker.svg");
        }
    }

    &.arrow-left,
    &.arrow-left-white {
        &,
        &:hover,
        &:active,
        &:focus,
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background-position: left $paddingSmallScr top 50%;

            @media (min-width: $screen-sm-min) {
                background-position: left $paddingLgScr top 50%;
            }
        }
    }
}
