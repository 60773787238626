$MESSAGE_FONT_SIZE: 18px;
$extraPaddingForMessageBounce: 100px;

.slide-message {
    display: none;

    // Styles shared between mobile and desktop
    .slide-message-display {
        width: 100%;
        color: $COLOR_V3_WHITE;
        position: relative;
        cursor: pointer;

        &.ng-leave,
        > .close {
            display: none;
        }

        > .message {
            user-select: none;
            display: inline-block;
            vertical-align: top;
            font-size: $MESSAGE_FONT_SIZE;
            height: auto;
            position: relative;

            p {
                line-height: normal;
            }

            // prevent the last paragraph from creating extra whitespace in the bottom of the message
            cf-text > div > p:last-of-type,
            cf-text > p:last-of-type {
                margin-bottom: 0px;
            }

            > .close-button {
                user-select: none;

                > .hex-icon {
                    position: absolute;
                    left: 7.5px;
                    top: -1.5px;
                    width: 24px;
                    height: 27px;
                    user-select: none;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    z-index: 5000;
                    background-image: url("~images/hexagon_close.png");

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        left: auto;
                        right: 7.5px;
                    }
                }
            }
        }
    }
}

.slide-message-mobile {
    // Mobile/portrait tablet styles
    @media (max-width: $screen-xs-max) {
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        z-index: 9999;

        &.ng-hide {
            display: block !important;
            animation: bounceOutUp 1s;
        }

        .slide-message-display {
            animation: bounceInDown 1s;

            > .message {
                // padding + margin allows the bounceInDown animation to display
                // correct / incorrect background color throughout
                padding-top: $extraPaddingForMessageBounce + 10px;
                margin-top: -#{$extraPaddingForMessageBounce};
                display: block;
                padding-bottom: 35px;
                padding-left: 58px;
                padding-right: 20px;
                width: 100%;
                min-height: $APP_HEADER_HEIGHT + 105px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    padding-right: 58px;
                    padding-left: 20px;
                }

                > .close-button {
                    position: absolute;
                    margin-left: -20px;
                    // center horizontally
                    margin-bottom: 0px;
                    left: 50%;
                    bottom: 0%;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        left: auto;
                        right: 50%;
                        margin-left: 0px;
                        margin-right: -20px;
                    }
                }
            }

            > .brain {
                @include brain_v3;
                width: $BRAIN_V3_WIDTH_SMALL;
                height: $BRAIN_V3_HEIGHT_SMALL;
                position: absolute;
                left: 5px;
                top: $extraPaddingForMessageBounce + 10px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 5px;
                }
            }

            &.neutral {
                > .brain {
                    background-image: $BRAIN_V3_NORMAL_MOBILE;
                }

                > .message {
                    background-color: $COLOR_V3_BLUE;
                }
            }

            &.correct {
                > .brain {
                    background-image: $BRAIN_V3_HAPPY_MOBILE;
                }

                > .message {
                    background-color: $COLOR_ANSWER_CORRECT;
                }
            }

            &.incorrect {
                > .brain {
                    background-image: $BRAIN_V3_SKEPTICAL_MOBILE;
                }

                > .message {
                    background-color: $COLOR_ANSWER_INCORRECT;
                }
            }

            &.updated {
                &.neutral > .brain {
                    animation: bounce 1s ease;
                }

                &.correct > .brain {
                    animation: bounce 1s ease;
                }

                &.incorrect > .brain {
                    animation: shake 1s ease;
                }
            }
        }
    }
}

.slide-message-desktop {
    // Desktop styles
    @media (min-width: $screen-sm-min) {
        display: block;
        position: absolute;
        right: 0px;
        bottom: 0;
        z-index: 9999;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: auto;
            left: 0px;
        }

        .slide-message-display {
            animation: bounceInUp 1s ease;

            > .message {
                padding: 15px;
                padding-top: 30px;
                // Make room for close and brain icons
                padding-bottom: 30px;
                // Symmetry with the top. Extra space will appear for continue button
                width: $SLIDE_MESSAGE_DESKTOP_WIDTH;
                min-height: 150px;
                transition: padding-bottom 0.3s ease;

                > .close-button {
                    position: absolute;
                    left: -15px;
                    top: -18px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        left: auto;
                        right: -15px;
                    }
                }

                // Add little section at bottom
                &:after {
                    content: "";
                    background-color: inherit;
                    width: 100%;
                    position: absolute;
                    display: block;
                    top: 100%;
                    left: 0;
                    height: 30px;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        left: auto;
                        right: 0px;
                    }
                }
            }

            &.hint-button-visible > .message,
            &.continue-button-visible > .message {
                padding-bottom: 30px + $CONTINUE_BUTTON_HEIGHT + $CONTINUE_BUTTON_DESKTOP_PADDING;
            }

            > .brain {
                @include brain_v3;
                width: $BRAIN_V3_WIDTH;
                height: $BRAIN_V3_HEIGHT;
                position: absolute;
                right: 5px;
                top: -18px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    right: auto;
                    left: 5px;
                }
            }

            &.neutral {
                > .brain {
                    background-image: $BRAIN_V3_NORMAL_DESKTOP;
                }

                > .message {
                    background-color: $COLOR_V3_BLUE;
                }
            }

            &.correct {
                > .brain {
                    background-image: $BRAIN_V3_HAPPY_DESKTOP;
                }

                > .message {
                    background-color: $COLOR_ANSWER_CORRECT;
                }
            }

            &.incorrect {
                > .brain {
                    background-image: $BRAIN_V3_SKEPTICAL_DESKTOP;
                }

                > .message {
                    background-color: $COLOR_ANSWER_INCORRECT;
                }
            }

            &.updated {
                &.neutral > .brain {
                    animation: bounce 1s ease;
                }

                &.correct > .brain {
                    animation: bounce 1s ease;
                }

                &.incorrect > .brain {
                    animation: shake 1s ease;
                }
            }
        }
    }
}
