career-profile-status-form {
    display: block;
    padding: 0px;
    padding-bottom: 15px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 20px;
    }

    form {
        max-width: 380px;
        margin: 30px auto 0;
        padding: 0px 8px;
    }

    select.wide {
        font-size: 14px; // just because the options are so long, we're shrinking this text a bit
    }

    button[name="save"] {
        display: block;
        margin: 20px auto 0px;
    }
}
