student-dashboard-welcome-box {
    .sidebar-box {
        @include sidebar-box(true);

        .hr-caption {
            text-transform: uppercase;
        }

        .step-headline,
        .step-description,
        p {
            display: block;
            font-size: 15px;
            line-height: 19px;
        }

        .step-headline {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .step-description:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
}
