.admin-edit-aoi-records {
    max-width: 1000px;
    margin: 0 auto;

    h3:not(:first-of-type) {
        margin-top: 30px;
    }

    h3 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bolder;
    }

    // MUI styles conflict with our form styles here
    input[type="checkbox"] {
        &:disabled {
            opacity: 0;
        }
    }

    input.MuiSelect-nativeInput {
        &:disabled {
            display: none;
        }
    }
}
