messages-button {
    // FIXME: Overwriting library css is always messy. This is for the initial quick UI work,
    // But moving to custom components and the using the SendbirdProvider
    // should allow us more fine control

    .messageWidget {
        .sendbird-conversation {
            border-top: none;
            border-right: none;
            border-bottom: none;

            .sendbird-reaction-badge--selected {
                background-color: white;
                &:not(:hover) {
                    border-color: rgb(217 214 211 / 1);
                }
            }

            .sendbird-ui-header__middle__title {
                text-wrap: wrap;
                max-height: 64px;
            }

            .sendbird-conversation__scroll-bottom-button {
                z-index: 6;
            }
        }

        .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
            html[dir="rtl"] & {
                left: unset;
            }
        }
    }

    .sendbird-ui-header__middle {
        white-space: normal;
    }

    .sendbird-image-renderer__hidden-image-loader {
        display: none;
    }

    .sendbird-dropdown__reaction-bar {
        overflow: auto;
    }

    #sendbird-emoji-list-portal {
        position: absolute;
        z-index: 102;

        .sendbird-dropdown__reaction-bar {
            html[dir="rtl"] & {
                right: unset;
            }
        }
    }

    .sendbirdProviderWrapper {
        &.open .sendbird-modal-root {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100vw;
            height: calc(100vh - 40px - 54px);

            @media (min-width: 768px) {
                width: calc(100vw - 76px);
                max-width: 948px;
                height: 75vh;
                max-height: 554px;
                top: 71.5px;
                left: calc(100vw - calc(min(948px, calc(100vw - 76px)) + 38px));
            }

            @media (min-width: 992px) {
                width: calc(100vw - 76px);
                max-width: 1024px;
                height: 85vh;
                max-height: 800px;
                top: 71.5px;
                left: calc(100vw - calc(min(1024px, calc(100vw - 76px)) + 38px));
            }

            .sendbird-modal {
                position: relative;

                .sendbird-label {
                    font-family: "ProximaNovaSoft", "AmiriWeb", sans-serif;
                }

                .sendbird-modal__content {
                    &:has(div.sendbird-modal__body) {
                        &:has(span.sendbird-label) {
                            &:has(div.sendbird-fileviewer) {
                                width: 90%;
                                height: 60%;

                                @media (min-width: 760px) {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .sendbird-modal__body {
                        .sendbird-fileviewer {
                            position: absolute;
                            border-radius: 8px;
                        }

                        div.sendbird-create-channel--scroll {
                            overflow-y: auto;

                            @media (max-width: 780px) {
                                max-height: calc(100vh - 280px);
                            }
                        }
                    }

                    .sendbird-button--danger {
                        background-color: var(--sendbird-light-error-300);
                        &:hover {
                            background-color: var(--sendbird-light-error-400);
                        }
                    }
                }

                .sendbird-modal__backdrop {
                    display: none;
                }
            }
        }
        &.closed .sendbird-modal-root {
            display: none;
        }

        .sendbird-dropdown-portal {
            .sendbird-menu-item {
                .sendbird-label {
                    font-family: "ProximaNovaSoft", "AmiriWeb", sans-serif;
                }
            }
        }
    }
}

.EmojiPickerReact.messaging-emoji-picker.epr-main {
    --epr-emoji-fullsize: 35px;
    --epr-header-padding: 10px;
    --epr-category-label-height: 20px;

    .epr-search-container {
        input {
            padding: 14px 30px;
        }
    }
    .epr-skin-tones {
        .epr-skin-tone-select {
            .epr-btn {
                // Override FrontRoyal btn styles to prevent the
                // skin tone buttons from being too wide on mobile
                font-size: 0;
            }
        }
    }
    .epr-emoji-category-label {
        height: 20px;
        font-size: 14px;
    }
}

// Used for accurately positioning the emoji picker and context menu
:root {
    --safe-area-inset-top: env(safe-area-inset-top, 0px);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
    --safe-area-inset-left: env(safe-area-inset-left, 0px);
    --safe-area-inset-right: env(safe-area-inset-right, 0px);
}
