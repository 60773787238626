.translation-link {
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        opacity: 0.5;
    }

    &:before {
        @include font-awesome-icon("\f024"); // fa-flag
    }

    &.en {
        color: $COLOR_V3_BLUE;
    }

    &.zh {
        color: $COLOR_V3_RED;
    }

    &.es {
        color: $COLOR_V3_YELLOW;
    }

    &.loading {
        animation: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
