location-autocomplete {
    input {
        width: 100%;
    }

    h5 {
        color: $COLOR_V3_CORAL;
    }
}

// ensure that the Google Autocomplete pop-up is always wide enough to use on desktop, even for smaller inputs
@media (min-width: $screen-sm-min) {
    .pac-container {
        min-width: 300px;
    }
}
