@import "oreo/global/colors_v3";

.payment-reconciliation-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .title {
        text-align: center;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            display: flex;
            align-items: flex-start;

            span {
                font-size: 14px;
                color: $COLOR_V3_GREY;

                &.label {
                    text-align: right;
                    width: 116px;
                }

                &.value {
                    flex: 1;
                }
            }
        }
    }
}
