.click-target,
.text-click-target {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    min-width: 44px;
    min-height: 44px;
    z-index: 1;
    background-color: white;
    opacity: 0;
    @include remove-outlines;

    // // for debugging
    // background-color: red;
    // border: 1px solid blue;
    // opacity: 1;

    //remove this if not on a touch device
    .no-touchevents & {
        display: none;
    }
}

.text-click-target {
    background-color: transparent;
}
