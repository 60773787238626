@import "oreo/global/colors_v3";

.collapsible-content-container {
    display: block;
    width: 100%;
    border: 2px solid $COLOR_V3_BEIGE;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgba(0, 0, 0, 0.0125);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        padding: 15px;

        i.toggle-caret {
            color: $COLOR_V3_EGGPLANT;
            transition: 0.4s transform ease-in-out;
        }

        &:hover {
            background-color: rgba($COLOR_V3_BEIGE, 0.2);
            cursor: pointer;
        }

        &.green {
            background-color: rgba($COLOR_V3_GREEN, 0.05);

            &:hover {
                background-color: rgba($COLOR_V3_GREEN, 0.1);
            }

            h4 {
                color: $COLOR_V3_GREEN_DARK;
            }
        }

        &.red {
            background-color: rgba($COLOR_V3_RED, 0.05);

            &:hover {
                background-color: rgba($COLOR_V3_RED, 0.08);
            }

            h4 {
                color: $COLOR_V3_RED_DARK;
            }
        }
    }

    .body-container {
        height: 0;
        overflow: hidden;
        min-width: fit-content;
        max-height: 0;
        transition: max-height 0.4s cubic-bezier(0, 1, 0, 1) -0.1s;
        background-color: white;

        &.expanded {
            height: auto;
            max-height: 9999px;
            transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
            transition-delay: 0s;
        }
    }

    .body-content {
        border-top: 1px solid $COLOR_V3_BEIGE;
        min-width: fit-content;
    }

    &.open {
        .header i.toggle-caret {
            transform: rotate(180deg);
        }
    }

    &.closed {
        .body-container {
            max-height: 0;
        }
    }

    &.last {
        &:first-of-type {
            border-radius: 4px;
        }

        &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            margin-top: -2px;
        }
    }

    & + .collapsible-content-container:not(:last-of-type):not(:first-of-type) {
        border-radius: 0;
        margin-top: -2px;
        margin-bottom: -2px;
    }
}
