// V3 brains

// Mobile
$BRAIN_V3_WIDTH_SMALL: 43px;
$BRAIN_V3_HEIGHT_SMALL: 30px;

$BRAIN_V3_NORMAL_MOBILE: url("~vectors/brain_normal_mobile.svg");
$BRAIN_V3_HAPPY_MOBILE: url("~vectors/brain_happy_mobile.svg");
$BRAIN_V3_SKEPTICAL_MOBILE: url("~vectors/brain_skeptical_mobile.svg");

// Desktop
$BRAIN_V3_WIDTH: 60px;
$BRAIN_V3_HEIGHT: 42px;

$BRAIN_V3_NORMAL_DESKTOP: url("~vectors/brain_normal_desktop.svg");
$BRAIN_V3_HAPPY_DESKTOP: url("~vectors/brain_happy_desktop.svg");
$BRAIN_V3_SKEPTICAL_DESKTOP: url("~vectors/brain_skeptical_desktop.svg");

@mixin brain_v3 {
    margin: 0;
    padding: 0;
    position: relative;
    width: $BRAIN_V3_WIDTH;
    height: $BRAIN_V3_HEIGHT;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: $BRAIN_V3_NORMAL_DESKTOP;
}
