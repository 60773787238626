.salary-select {
    position: relative;
    height: 48px;

    &::before {
        content: "$";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        padding-left: 12px;
        line-height: 48px;
    }

    .select-open {
        display: block;
        background-color: $COLOR_V3_WHITE;
        border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
        border-radius: 4px;
        outline: none;
        padding: 8px;
        color: $COLOR_V3_BLACK;
        font-weight: $FONT_WEIGHT_NORMAL;
        line-height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding-left: 30px;
        z-index: 3;

        &:hover {
            border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }
    }

    input[type="number"]:focus + .select-open {
        border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    .deselect-open {
        display: inline-block;
        padding: 10px;
        margin: 0;
        color: $COLOR_V3_BEIGE_DARKEST;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 2;

        &:hover {
            color: $COLOR_V3_BLACK;
        }
    }

    .front-royal-form-container & input[type="number"] {
        padding-left: 30px;
        padding-right: 30px;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    input[type="checkbox"] {
        display: none;
    }
}
