copy-text {
    display: block;
    position: relative;

    input[type="text"] {
        width: 100%;
        background: $COLOR_V3_BEIGE_LIGHT;

        &,
        .front-royal-form-container & {
            padding-right: 107px;
        }
    }

    button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: $COLOR_V3_BEIGE_BEYOND_DARK;
        border: none;
        border-radius: 4px;
        color: $COLOR_V3_WHITE;
        min-width: 98px;
        text-align: center;
        height: 38px;
        outline: none;
        transition: background 0.25s ease;

        &:hover {
            background: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        }
    }
}
