/*--------------------------------------------------------------
1.0 Button Styles
--------------------------------------------------------------*/

institution-register,
forgot-password,
sign-in {
    .btn,
    .btn:active,
    .btn:focus,
    .btn:hover {
        box-shadow: none;

        outline: 0 !important;
    }

    .btn {
        padding: 9px 18px 7px;

        border: 1px solid transparent;
        border-radius: 4px;

        &.btn-red {
            color: $COLOR_V3_WHITE;
            background: $COLOR_V3_CORAL;
            border: 1px solid $COLOR_V3_CORAL;
            box-shadow: 0 1.5px 0 rgba(0, 0, 0, 0.15);

            &:hover {
                background: $COLOR_MARKETING_CORAL_HOVER;
                border: 1px solid $COLOR_MARKETING_CORAL_HOVER;
            }
        }

        &.btn-white {
            color: $COLOR_V3_CORAL;

            background: $COLOR_V3_WHITE;
            border: 1px solid $COLOR_V3_WHITE;

            &:hover {
                background: rgba($COLOR_V3_WHITE, 0.3);
                border: 1px solid rgba(255, 255, 255, 0);
                color: $COLOR_V3_WHITE;
            }
        }

        &.btn-bordered {
            border: 1px solid $COLOR_V3_WHITE;
            color: $COLOR_V3_WHITE;

            &:hover {
                background: $COLOR_V3_WHITE;
                color: $COLOR_V3_CORAL;
            }

            &.btn-bordered-red {
                border: 1px solid $COLOR_V3_CORAL;
                color: $COLOR_V3_CORAL;

                &:hover {
                    background: $COLOR_V3_CORAL;
                    color: $COLOR_V3_WHITE;
                }
            }
        }

        &.btn-video {
            img {
                position: relative;
                top: 2px;
            }
        }
    }

    /*--------------------------------------------------------------
  2.0 Button Sizes
  --------------------------------------------------------------*/

    .btn {
        &.btn-md,
        &.btn-lg {
            padding-top: 14px;
            padding-bottom: 14px;

            @include font-size(21);
            font-weight: $FONT_WEIGHT_BOLD;
            font-style: normal;
        }

        &.btn-md {
            width: 230px;

            @media (max-width: 300px) {
                width: 100%;
            }
        }

        &.btn-lg {
            width: 230px;

            @media (min-width: 375px) {
                width: 280px;
            }

            @media (max-width: 300px) {
                width: 100%;
            }
        }
    }
}
