student-network-event-ambiguous-popover {
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $COLOR_V3_WHITE);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        pointer-events: none;
    }

    header {
        position: relative;
        width: auto;
        margin: 18px 18px 16px;
        text-align: center;
        font-family: $FONT_FAMILY;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        font-size: 20px;
        line-height: 21px;
        color: $COLOR_V3_GREY_DARKER;

        h2 {
            font-size: 20px;
        }

        i {
            position: absolute;
            top: 0;
            right: 0;
            width: 14.85px;
            height: 14.85px;
            float: right;
            color: $COLOR_V3_GREY_NOT_SO_LIGHT;
            cursor: pointer;
        }
    }

    .event-list-container {
        $eventListContainerHeight: 421px;
        height: $eventListContainerHeight; // NOTE: potentially overriden in JS for non-cordova mobile clients
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: $screen-xs-max) {
            // Remove scrollbar on mobile
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.popover.student-network-event-ambiguous-popover {
    $ambiguousPopoverWidth: 317px;
    $ambiguousPopoverHeight: 479px;
    display: none; // manual trigger
    max-width: $ambiguousPopoverWidth;
    width: $ambiguousPopoverWidth;
    height: $ambiguousPopoverHeight;
    max-height: 479px;
    box-shadow: 0 0 10px 0 rgba(92, 104, 124, 0.5);
    z-index: 9999;

    .popover-inner {
        height: 100%;

        .popover-content {
            height: 100%;
            padding: 0;
            overflow: hidden;
        }
    }

    // Full screen on mobile
    @media (max-width: $screen-xs-max) {
        position: fixed;
        max-width: calc(100% - 20px);
        max-height: calc(100% - #{$APP_MENU_MOBILE_HEIGHT} - 20px);
        left: 50% !important;
        top: calc(50% - #{$APP_MENU_MOBILE_HEIGHT * 0.5}) !important;
        transform: translateY(-50%) translateX(-50%);
        margin-left: 0px !important;

        .popover-inner {
            max-height: 100%;

            .popover-content {
                max-height: 100%;
            }
        }

        .arrow {
            display: none;
        }
    }
}
