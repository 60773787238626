progress-nav {
    display: block;
    padding: 10px;

    @media (min-width: $screen-sm-min) {
        padding: 20px 0 10px;
    }

    ul {
        display: table;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        display: table-cell;
        vertical-align: middle;
        cursor: pointer;
        position: relative;

        &:not(.percentage)::before {
            content: "";
            display: block;
            margin: 0 auto;
            width: 15px;
            height: 15px;
            background: $COLOR_V3_BEIGE_MEDIUM;
            border-radius: 50%;
            position: relative;
            z-index: 2;
            box-shadow: 0 0 0 0 rgba($COLOR_V3_WHITE, 0);
            transition: box-shadow 0.25s ease;
            font-size: 10px;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 15px;
            text-align: center;
        }

        &::after {
            content: "";
            border-bottom: 1px solid $COLOR_V3_BEIGE_MEDIUM;
            display: block;
            margin-top: -8px;
            position: relative;
            z-index: 1;
            width: 100%;
            float: left;
        }

        &:first-child::after {
            width: 50%;
            margin-left: 50%;

            [dir="rtl"] & {
                margin-left: 0;
                margin-right: 50%;
            }
        }

        &:last-child::after {
            width: 50%;

            [dir="rtl"] & {
                margin-left: 50%;
            }
        }

        &:hover::before,
        &.selected::before {
            box-shadow: 0 0 0 3px $COLOR_V3_WHITE, 0 0 0 5px $COLOR_V3_BEIGE_MEDIUM;
        }

        &:hover .tooltip {
            opacity: 1;
            transform: scale(1) translateX(-50%);
            margin-top: 0;
        }
    }

    .hide {
        display: none;
    }

    li.incomplete {
        &::before {
            background: $COLOR_V3_BLUE;
        }

        // see &.incomplete below for styling of the .percentage circle

        &:hover::before,
        &.selected::before {
            box-shadow: 0 0 0 5px rgba($COLOR_V3_BLUE, 0.25);
        }
    }

    li.complete {
        &::before {
            @include font-awesome-icon("\f00c"); // fa-check
        }
    }

    li.percentage {
        height: 31px;

        .circle {
            display: block;
            width: 31px;
            height: 31px;
            line-height: 30px;
            margin: 0 auto;
            background: $COLOR_V3_BEIGE_MEDIUM;
            border-radius: 50%;
            color: $COLOR_V3_GREY_DARKER;
            font-size: 12px;
            text-align: center;
            position: relative;
            z-index: 2;
            box-shadow: 0 0 0 0 rgba($COLOR_V3_WHITE, 0);
            transition: box-shadow 0.25s ease;

            span {
                font-size: 9px;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 14px;
                width: 41px;
                height: 41px;
                line-height: 41px;
            }
        }

        &:hover .circle,
        &.selected .circle {
            box-shadow: 0 0 0 3px $COLOR_V3_WHITE, 0 0 0 5px $COLOR_V3_BEIGE_MEDIUM;
        }

        &.incomplete {
            .circle {
                background: $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;
            }

            &:hover .circle,
            &.selected .circle {
                box-shadow: 0 0 0 5px rgba($COLOR_V3_BLUE, 0.25);
            }
        }

        &::after {
            margin-top: -16px;

            @media (min-width: $screen-sm-min) {
                margin-top: -21px;
            }
        }
    }

    .tooltip {
        position: absolute;
        top: calc(100% + 14px);
        left: 50%;
        line-height: 14px;
        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: 12px;
        text-transform: none;
        border-radius: 4px;
        opacity: 0;
        white-space: nowrap;
        margin-top: -11px;
        padding: 5px 10px !important;
        transform: scale(0) translateX(-50%);
        transition: opacity 0.25s ease, margin 0.25s ease;
        background: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        border: 1px solid white;
        color: $COLOR_V3_WHITE;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            left: 50%;
            transform: translateX(-50%);
            border: 6px solid transparent;
            border-bottom-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
            border-top: none;
        }

        &::before {
            border-width: 7px;
            border-bottom-color: $COLOR_V3_WHITE;
            top: -7px;
        }

        .touchevents & {
            display: none;
        }
    }
}
