@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.inline-spinner {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: linear-gradient(to right, $COLOR_V3_MAP_BLUE_DARK 10%, rgba($COLOR_V3_MAP_BLUE_DARK, 0) 42%);
    position: relative;
    animation: loading 1.4s infinite linear;
    transform: translateZ(0);

    &::before {
        width: 50%;
        height: 50%;
        background: $COLOR_V3_MAP_BLUE_DARK;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
    }

    &::after {
        background: $COLOR_V3_WHITE;
        width: 66%;
        height: 66%;
        border-radius: 50%;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &.blue {
        background: linear-gradient(to right, $COLOR_V3_BLUE 10%, rgba($COLOR_V3_BLUE, 0) 42%);

        &::before {
            background: $COLOR_V3_BLUE;
        }
    }
}
