@mixin sharing_button($bgColor, $textColor, $iconImgSrc) {
    font-weight: bold;
    display: inline-block;
    margin: 2px;
    border-radius: 5px;
    border: $bgColor;
    border-style: solid;
    color: $textColor;
    background: $bgColor url($iconImgSrc) 2px 3px no-repeat;
    background-size: 18px 18px, auto auto;
    padding-left: 18px;
    min-width: 90px;
    height: 30px;
    line-height: 25px;
    font-size: 17px;
    vertical-align: top;

    html[dir="rtl"] & {
        background-position: right;
    }

    @media (min-width: $screen-sm-min) {
        background-size: 30px 30px, auto auto;
        width: 150px;
        height: 40px;
        line-height: 35px;
        font-size: 25px;
    }

    @media (max-width: $screen-xs-max) {
        background-size: 18px 18px, auto auto;
        width: 90px;
        height: 30px;
        line-height: 25px;
        font-size: 17px;
    }

    cursor: pointer;
}

@mixin share_on_facebook($bgColor, $textColor) {
    @include sharing_button($bgColor, $textColor, "~vectors/facebook_alt.svg");
}

@mixin share_on_twitter($bgColor, $textColor) {
    @include sharing_button($bgColor, $textColor, "~vectors/twitter_alt.svg");
}

@mixin share_on_linkedin($bgColor, $textColor) {
    @include sharing_button($bgColor, $textColor, "~vectors/linkedin_alt.svg");
}

@mixin share_on_email($bgColor, $textColor) {
    @include sharing_button($bgColor, $textColor, "~vectors/email.svg");
}
