cohort-registration-tuition-ledger {
    .tuition-ledger {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        margin: 0 -20px;
        width: calc(100% + 40px);
        padding: 20px;

        color: $COLOR_V3_GREY_DARKER;
        font-size: 14px;
        line-height: 14px;
        border-top: 1px solid $COLOR_V3_BEIGE_MIDDARK;

        p {
            margin: 0;
        }

        .ledger-row {
            $totalAmountSectionWidth: 230px;
            display: flex;
            justify-content: flex-start;
            width: 100%;

            // when we're showing more than one .ledger-row
            &:not(:only-of-type) {
                // default bottom margin when not the last row
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }

                // extra bottom margin when the first row (only on desktop)
                @media (min-width: $screen-sm-min) {
                    &:first-of-type {
                        margin-bottom: 20px;
                    }
                }

                // extra top padding and margin for the "Total Tuition" row
                &:last-of-type {
                    .title-and-cost-or-discount {
                        border-top: 1px solid $COLOR_V3_GREY_DARKER;
                    }

                    .title-and-cost-or-discount {
                        padding-top: 15px;
                        margin-top: 5px;
                    }

                    .total-amount {
                        align-self: flex-end;
                    }
                }
            }

            &.total-tuition-row,
            &:only-of-type {
                font-weight: $FONT_WEIGHT_SEMIBOLD;

                .total-amount {
                    font-weight: $FONT_WEIGHT_NORMAL;
                }
            }

            .title-and-cost-or-discount {
                flex: 1 1 auto;
                display: flex;
                justify-content: space-between;
                max-width: calc(100% - $totalAmountSectionWidth);
            }

            .cost-or-discount {
                flex: 0 0 auto;

                span.value {
                    display: inline-block;
                    color: $COLOR_V3_GREY_DARKER; // overrides general span coloring in tuition_and_registration.scss
                    width: 65px;
                }
            }

            .total-amount {
                flex: 0 0 $totalAmountSectionWidth;
            }

            .total-amount,
            .cost-or-discount {
                text-align: right;
            }

            @media (max-width: $screen-xs-max) {
                flex-wrap: wrap;

                .total-amount {
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    margin-top: 8px;
                }

                .total-amount,
                .title-and-cost-or-discount {
                    flex: 1 0 100%;
                    max-width: 100%;
                    line-height: 15px;
                }

                .cost-or-discount {
                    white-space: nowrap;
                    align-self: flex-end;
                }
            }
        }
    }
}
