a[uib-tooltip] {
    &.superscript {
        color: inherit; // ignore default a stying
        position: relative;
        top: -0.5em;
        font-size: 0.8em;
    }
}

.tooltip {
    font-family: $FONT_FAMILY;
}
