.frame.componentized .text_image_interactive {
    $SECTION_MOBILE_BOTTOM_MARGIN: 15px;
    $SECTION_DESKTOP_BOTTOM_MARGIN: 35px;

    position: relative;

    > .section {
        position: relative;
        display: block;

        margin-bottom: $SECTION_MOBILE_BOTTOM_MARGIN;
        @media (min-width: $screen-sm-min) {
            margin-bottom: $SECTION_DESKTOP_BOTTOM_MARGIN;
        }
    }

    > .section.text {
        overflow: visible;
        height: auto;
        animation: fade-in 0.5s ease-out;

        // when taking screenshots, skip animations
        bot & {
            animation-duration: 0s;
        }

        // This style here is only necessary for old content. Before https://trello.com/c/jb0NQLmf
        // we did not add an `inline` class to inline images, and we had this
        // style here applying to inline images in the main text.
        img:not([class]) {
            height: $line-height-base * 1em;
            max-width: $MAIN_CONTENT_MIN_WIDTH;
        }

        img.display {
            // Make the margin on a display image look the same as
            // when a context image is placed below or above the text
            margin-top: $SECTION_MOBILE_BOTTOM_MARGIN;
            margin-bottom: $SECTION_MOBILE_BOTTOM_MARGIN;
            @media (min-width: $screen-sm-min) {
                margin-top: $SECTION_DESKTOP_BOTTOM_MARGIN;
                margin-bottom: $SECTION_DESKTOP_BOTTOM_MARGIN;
            }
        }

        // Need extra bottom margin if displaying a check-many caption on an image-hotspot
        &.image-hotspot-with-caption {
            margin-bottom: calc($SECTION_MOBILE_BOTTOM_MARGIN + 10px);
            @media (min-width: $screen-sm-min) {
                margin-bottom: calc($SECTION_DESKTOP_BOTTOM_MARGIN + 15px);
            }
        }
    }

    &.text-only {
        font-size: 23px;

        // Desktop
        @media (min-width: $screen-sm-min) {
            background-image: url("~vectors/frame_bg_logo_quantic.svg");
            background-size: 155px 245px;
            background-repeat: no-repeat;
            padding-left: 170px;
            min-height: 300px;

            &.valar {
                background-image: url("~vectors/frame_bg_logo_valar.svg");
            }

            &.smartly {
                background-image: url("~vectors/frame_bg_logo.svg");
            }

            // rtl-content overrides
            div[dir="rtl"] & {
                padding-left: 0px;
                padding-right: 170px;
                background-position: right top;
            }
        }

        // Mobile
        .frame-container.lte-xs & {
            font-size: 20px;
        }
    }

    // interactive images, like matching board, matching tile, etc, may need to be taller,
    // and we need the lesson content to stretch to include what's inside of them
    > .section.context-image-first,
    .section.context-image-second {
        max-height: $MAIN_IMAGE_HEIGHT;

        // The image is an inline element, so we use text-align to put it in the center.
        // This might not work if we ever had something other than just an image component
        // in here, but that is not an issue right now.
        //
        // We want to do this rather than using a display:block element with margin: 0px auto
        // because we want the zoom-link to be exactly the same size as the image.  In the past,
        // the link stretched the full width of the frame, regardless how wide the image was. This
        // was confusing and annoying when tryng to touch-scroll on mobile.
        text-align: center;

        .zoom-link {
            cursor: pointer;

            > cf-ui-component > cf-image > img {
                .frame-container.gte-sm & {
                    max-height: $MAIN_IMAGE_HEIGHT;
                    max-width: $MAIN_CONTENT_MIN_WIDTH;
                }
                .frame-container.lte-xs & {
                    max-height: $MAIN_IMAGE_HEIGHT_SMALL_MIN;
                    max-width: 100%;
                }
            }

            &.short > cf-ui-component > cf-image > img {
                @media (min-width: $screen-sm-min) {
                    max-height: $MAIN_IMAGE_SHORT_HEIGHT;
                }
                .frame-container.lte-xs & {
                    max-height: $MAIN_IMAGE_SHORT_HEIGHT_SMALL_MIN;
                }
            }
        }
    }

    // Right now, this is only used for the smartly poll header.  Center it
    > .section.header {
        text-align: center;

        img {
            display: inline;
        }
    }

    > .section {
        // allow section content to exceed bounds (re: this or that) but not create scrollbars
        overflow: visible;
    }

    > .section.context-image-first,
    .section.context-image-second,
    .section.interactive-image {
        min-height: $MAIN_IMAGE_MIN_HEIGHT;
        width: 100%;

        animation: fade-in 0.5s ease-out;

        // when taking screenshots, skip animations
        bot & {
            animation-duration: 0s;
        }
    }

    // zoom-container is only visible if inside a modal.  See below
    .zoom-link {
        border-bottom-width: 0px;

        &:active {
            img {
                transform: scale(0.95);
                @include transition-transform(0.1s ease-out);
            }
        }
    }
}
