student-network-event-list-item {
    $thumbnail-standard-width: 73px;
    $thumbnail-standard-margin-right: 13px;

    .list-item-container {
        width: 100%;
        min-height: 148px;
        padding: 10px;
    }

    div.flex-row {
        display: flex;
        flex-direction: row;
    }

    div.flex-column {
        display: flex;
        flex-direction: column;
    }

    .thumbnail {
        vertical-align: top;
        margin-inline-end: $thumbnail-standard-margin-right;
        border: 0px solid transparent;
        border-radius: 8px;
        width: $thumbnail-standard-width;
        height: 54px;
        background-color: $COLOR_V3_MAP_BLUE_MID;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // HACK: this is duplicative of the above, but it prevents a
            // a weird effect in Safari where the image punches through the
            // the border radius of the surrounding thumbnail for a tick
            border: 0px solid transparent;
            border-radius: 8px;
        }
    }

    span {
        font-family: $FONT_FAMILY;
        font-weight: $FONT_WEIGHT_NORMAL;
    }

    .details-flex-container {
        display: flex;
        max-width: calc(
            100% - #{$thumbnail-standard-width} - #{$thumbnail-standard-margin-right}
        ); // 100% - list item padding - img width - img margin
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 13px;

        span.title {
            width: 100%;
            display: block;
            color: black;
            font-size: 18px;
            line-height: 18px;
            overflow: hidden;
            margin-top: 4px;
            margin-bottom: 8px;
        }

        span.subtitle {
            width: 100%;
            display: block;
            color: $COLOR_V3_GREY_DARKER;
            font-size: 13px;
        }
    }

    .footer-flex-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: 23px;

        img {
            margin-top: auto;
            margin-inline-end: 8px;
            margin-bottom: auto;
            margin-inline-start: 0px;
        }

        span.type {
            display: block;
            color: $COLOR_V3_GREY_DARKER;
            font-size: 14px;
            line-height: 23px;
            margin: auto 0px;
        }

        button {
            $button-width: 86px;

            margin-inline-start: auto; // right-align in flexbox
            width: $button-width;
            height: 30px;
            background-color: $COLOR_V3_CORAL;
            outline: none;
            border-radius: 4px;
            border: none;
            position: relative;

            &.anonymized {
                background-color: $COLOR_V3_MAP_BLUE_DARK;
                cursor: not-allowed;
            }

            &:hover .tooltip {
                opacity: 1;
                transform: scale(1) translateX(-50%) translateY(calc(-100% - 8px));

                html[dir="rtl"] & {
                    transform: scale(1) translateX(0%) translateY(calc(-100% - 8px));
                }
            }

            .tooltip {
                background-color: $COLOR_V3_MAP_BLUE_DARK;
                color: $COLOR_V3_WHITE;
                position: absolute;
                top: 0px;
                left: -50%;
                line-height: 14px;
                border-radius: 4px;
                opacity: 0;
                padding: 5px 10px;
                transform: scale(0) translateX(-50%);
                transition: opacity 0.25s ease;
                width: 300%;

                html[dir="rtl"] & {
                    left: 0px;
                }

                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -6px;
                    right: calc(#{$button-width} / 2);
                    transform: translateX(50%) rotate(180deg);
                    border: 6px solid transparent;
                    border-bottom-color: $COLOR_V3_MAP_BLUE_DARK;
                    border-top: none;

                    html[dir="rtl"] & {
                        right: unset;
                        left: calc(#{$button-width} / 2);
                        transform: translateX(-50%) rotate(180deg);
                    }
                }

                &::before {
                    border-width: 7px;
                    border-bottom-color: $COLOR_V3_WHITE;
                    top: -7px;
                }
            }

            span {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                line-height: 30px;
                font-size: 14px;
                text-transform: uppercase;
                color: $COLOR_V3_WHITE;
            }
        }
    }

    // student-network-event-list-items displayed in the recommended
    // events sidebar have a larger/wider size/footprint on desktop than
    // all other student-network-event-list-items (regardless of viewport
    // size).
    // NOTE: this style only gets applied on desktop viewports.
    &.wide {
        .list-item-container {
            min-height: 137px;
            margin-bottom: 10px;
            padding: 10px 20px;

            .thumbnail {
                width: 144px;
                height: 107px;
                margin-right: 20px;
            }

            .details-flex-container {
                max-width: 273px;
                width: 273px;
                margin-bottom: 8px;

                span {
                    width: 100%;

                    &.title {
                        margin-top: 8px;
                    }

                    &.subtitle {
                        font-size: 14px;
                        line-height: 14px;
                        margin-bottom: 7px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .footer-flex-container {
                max-width: unset;
                width: 273px;
                margin-bottom: unset;
                margin-left: auto;
            }
        }
    }
}
