.student-network-recommended-event-list-filters {
    $cancel-button-width: 25%;
    $gutter-width: 4px;

    header {
        text-align: center;
        margin-bottom: 8px;

        h2 {
            color: $COLOR_V3_MAP_BLUE_DARK;
            display: inline-block;
            font-weight: $FONT_WEIGHT_BOLD;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
            margin: 0px 6px 0px 0px;
        }

        a {
            font-size: 12px;
            line-height: 13px;
            color: $COLOR_V3_MAP_BLUE_DARK;

            &:hover {
                color: $COLOR_V3_MAP_BLUE_MID;
                text-decoration: none;
            }
        }
    }

    .top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: $gutter-width;
        width: 100%;
        height: 34px;
        overflow: visible;

        &.filters-open {
            .tabs-container {
                width: calc(100% - #{$cancel-button-width});

                > div {
                    width: 49.5%;
                }
            }
        }

        .tabs-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            border-radius: 5px;
            width: 100%;
            overflow: hidden;

            div {
                display: flex;
                min-width: 0;
                flex: 1;

                a {
                    display: block;
                    white-space: nowrap;
                    width: 100%;
                    height: 34px;
                    line-height: 34px;
                    padding: 0 6px;
                    background: $COLOR_V3_MAP_BLUE_MID;
                    color: white;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    html:lang(es) & {
                        font-size: 12px;
                    }

                    &.selected {
                        background: $COLOR_V3_MAP_BLUE_DARK;
                    }

                    &.left {
                        border-radius: 5px 0 0 5px;
                        margin-inline-end: 1px;

                        [dir="rtl"] & {
                            border-radius: 0 5px 5px 0;
                        }
                    }

                    &.right {
                        border-radius: 0 5px 5px 0;
                        margin-inline-start: 1px;

                        [dir="rtl"] & {
                            border-radius: 5px 0 0 5px;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        button.advanced-search {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
            width: 40px;
            border-radius: 5px;
            border: none;
            background: rgba($COLOR_V3_MAP_BLUE_MID, 0.41);
            color: $COLOR_V3_MAP_BLUE_DARK;
            font-size: 14px;
            font-weight: $FONT_WEIGHT_MEDIUM;
            > img {
                width: 16px;
            }

            &:focus {
                outline: none;
            }

            @media (min-width: $screen-sm-min) {
                width: calc(25% - 2.5px);
            }
        }

        button.cancel {
            background: $COLOR_V3_CORAL;
            color: $COLOR_V3_WHITE;
            display: flex;
            width: calc($cancel-button-width - 2.5px);

            > div {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin: auto;

                i {
                    font-size: 18px;
                    margin-right: 7px;
                }
            }
        }
    }

    .filters-container {
        .filter-container {
            font-weight: $FONT_WEIGHT_NORMAL;
            margin-top: 5px;

            @media (min-width: $screen-sm-min) {
                width: calc(100% - #{$cancel-button-width} - #{$gutter-width});
            }

            location-autocomplete,
            multi-select {
                height: 34px;
            }

            select,
            input {
                @include placeholder($COLOR_V3_MAP_BLUE_DARK);
                background-color: $COLOR_V3_MAP_BLUE_MIDLIGHT;
                border: 1px solid $COLOR_V3_MAP_BLUE_MIDLIGHT;
                border-radius: 5px;
                color: $COLOR_V3_MAP_BLUE_DARK !important;
                line-height: 1;
                padding-left: 12px;
                height: 34px;
                width: 100%;

                &:focus {
                    background-color: lighten($COLOR_V3_MAP_BLUE_MIDLIGHT, 5%);
                    border: 1px solid $COLOR_V3_MAP_BLUE_MID;
                }
            }

            &.event-type-filter-container {
                position: relative;

                select {
                    // The text inside of the select is slightly inset from the left padding,
                    // so we reduce the padding on just the select so that the placeholder
                    // text aligns with the placeholder text in the other filter inputs.
                    padding-left: 9px;
                    background-image: none;
                }

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                    pointer-events: none;
                }
            }
        }

        .filter-options {
            margin: 10px 0px;
            padding: 0px;

            li {
                list-style: none;
                display: inline-block;
                background: $COLOR_V3_WHITE;
                padding: 2px 16px;
                border: 1px solid $COLOR_V3_MAP_BLUE_MID;
                color: $COLOR_V3_MAP_BLUE_MID;
                font-size: 12px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 3px;
                position: relative;

                &.removable {
                    padding: 2px 23px 2px 7px;
                }

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 7px;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
                    }
                }
            }
        }
    }
}
