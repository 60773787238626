.page-with-just-main-box-800,
.page-with-just-main-box-634 {
    .main-box {
        background-color: $COLOR_V3_WHITE;
        width: 100%;
        min-height: 100%;
        border-radius: 4px;
        padding-bottom: $MAIN_BOX_PADDING_BOTTOM_MOBILE + $APP_MENU_MOBILE_HEIGHT;
        position: relative;

        @media (min-width: $screen-sm-min) {
            max-width: 800px; // see .page-with-just-main-box-634 override below
            min-height: 480px;
            margin: 40px auto $MAIN_BOX_MARGIN_BOTTOM_DESKTOP;
            padding-bottom: $MAIN_BOX_PADDING_BOTTOM_DESKTOP;
        }
    }
}
.page-with-just-main-box-634 .main-box {
    @media (min-width: $screen-sm-min) {
        max-width: 634px;
        min-height: 480px;
    }
}
