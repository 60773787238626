student-dashboard-completed-courses {
    .stream-completed-wrapper:last-of-type {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 20px;
        }

        @media (min-width: $screen-sm-min) {
            margin-bottom: 30px;
        }
    }

    .generic-message {
        display: block;
        position: relative;
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
    }

    .certificate-generic {
        position: relative;
        background-image: url("~images/certificate_light_quantic.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin: 0 auto;

        &.valar {
            background-image: url("~images/certificate_light_valar.png");
        }

        &.smartly {
            background-image: url("~images/certificate_light.png");
        }

        img {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }

        @media (max-width: $screen-xs-max) {
            width: 167px;
            height: 129px;
            margin: 0 auto;

            img {
                width: 80px;
                height: 80px;
            }
        }

        @media (min-width: $screen-sm-min) {
            width: 246px;
            height: 190px;

            img {
                width: 117px;
            }
        }
    }

    .show-all-btn {
        display: block;
        width: 176px;
        margin: 0 auto;
    }
}
