//
// Buttons.
//

onboarding,
onboarding-register {
    $COLOR_BLUE: #4a76ff;

    .onboarding_actions {
        // Transitions.
        &.ng-enter {
            transition: opacity 0.5s ease;
        }

        &.ng-leave {
            transition: opacity 0.5s ease;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }

    .btn {
        @include remove-outlines;
        padding: 10px 30px;
        font-size: 16px;

        transition: all 0.3s ease;
    }

    .btn-round {
        border-radius: 100px;
    }

    .btn-information {
        background-image: none;
        background-color: $COLOR_BLUE;
        color: #fff;
        border: 0;

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: #fff;
            background-color: #3848ac;
        }
    }
}
