partial-screen-dialog-modal {
    z-index: 9999;

    // fill the entire area
    &,
    .main-container,
    .background {
        position: absolute;
        display: block;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        position: fixed;
        background-color: rgba($COLOR_V3_BLACK, 0.5);
    }

    .main-container > .main {
        display: block;
        height: auto;
        background-color: $COLOR_V3_WHITE;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        z-index: 10000;

        width: calc(100% - 30px);
        position: absolute;
        top: calc(50% - 40px);
        left: 15px;
        transform: translateY(-50%);

        @media (min-width: 450px) {
            width: 420px;
            margin: 120px auto;
            position: relative;
            top: 0px;
            left: 0px;
            transform: none;
        }

        .title {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 24px;
            line-height: 1.1em;
            color: $COLOR_V3_BLACK;
            margin-bottom: 20px;
        }

        .body {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;

            @media (min-width: $screen-sm-min) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        button {
            margin-bottom: 5px;
            border-radius: 100px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
