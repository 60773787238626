.front-royal-footer {
    transform: translateZ(0);

    $boxShadowDimensions: 0px -1px 4px 0px;

    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0px;
    height: 0px;
    z-index: $FRONT_ROYAL_FOOTER_Z_INDEX;
    background-color: red;

    // Disable the footer if we don't meet the minimum required height
    // This should only happen in landscape mode on Android during compose interactions
    @media (max-height: $MIN_REQUIRED_HEIGHT) {
        visibility: hidden; // "display: none" does not appear to always work, whereas "visibility: hidden" does
    }

    transition-duration: $SP_PAGE_TRANSITION_DURATION;

    .content-wrapper {
        margin: 0 auto;
        position: relative;
        height: 0px;
    }

    .continue-button-wrapper {
        display: block;
        position: absolute;
        width: $CONTINUE_BUTTON_WIDTH;
        bottom: $CONTINUE_BUTTON_DESKTOP_PADDING;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: $CONTINUE_BUTTON_DESKTOP_PADDING;
            right: auto;
        }

        h2 {
            line-height: $FRONT_ROYAL_FOOTER_LINE_HEIGHT;
            margin: 0px;
        }

        button {
            min-height: $CONTINUE_BUTTON_HEIGHT;
            width: 100%;
            display: block;
            padding-left: 0px;
            padding-right: 0px;
        }

        @media (max-width: $screen-xs-max) {
            h2 {
                font-size: 18px;
            }
        }
    }

    .left-buttons-wrapper {
        // We use the $CONTINUE_BUTTON_DESKTOP_PADDING so that the bottom of the
        // button will be inline with the bottom of the continue button on desktop.
        // Here, this same padding is used also in mobile. We don't override it.
        $PADDING: $CONTINUE_BUTTON_DESKTOP_PADDING;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        position: absolute;
        gap: 10px;
        left: 0px;
        bottom: 0px;
        z-index: 10;
        padding: $PADDING;

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }

        .launch-bot-button-wrapper {
            position: relative;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: $CONTINUE_BUTTON_HEIGHT;
            }
        }

        .launch-bot-button {
            // In this case we only attach footer-buttons-animation to ng-enter so that it doesn't re-activate
            // when other animations are removed (see the stuff around showing-continue-button below
            &.ng-enter {
                @include footer-buttons-animation();
            }
            color: $COLOR_V3_CORAL;
            background-color: $COLOR_V3_WHITE;
            border: 1px solid $COLOR_V3_BEIGE_DARK;
            box-shadow: none;
            font-size: 18px;
            min-height: $CONTINUE_BUTTON_HEIGHT;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            position: absolute;
            top: 0px;
            left: 0px;
            white-space: nowrap; // this is needed because the button is absolutely positioned, to make sure it stretches instead of wrapping
            text-transform: none;

            @media (max-width: $screen-xs-max) {
                width: 100%;

                // In mobile, the launch-bot-button is positioned at the bottom of the screen, and gets pushed
                // up when the continue button is shown. (In show_frames_player.html, the continue button is
                // the dynamic-node directive attached to buttonDirectiveName.)
                //
                // The following animations are used to line up the movements of the launch-bot-button and the frame button.
                // See more detailed descriptions of them in _buttons.scss

                // The continue button is being added, and the launch-bot-button is being pushed up
                //
                // We need the .ng-enter entry for extra specificity so we will override the animation set in
                //   the ng-enter above. This is important when closing the bot window on mobile with the continue
                //   button already visible. See https://trello.com/c/cgRt9cQU
                &.showing-continue-button,
                &.showing-continue-button.ng-enter {
                    animation: shadowContinueButton 1s ease forwards;
                }

                // The continue button is being removed, but the launch-bot-button is staying
                // (ng-animate adds the showing-continue-button-remove class for us)
                &.showing-continue-button-remove {
                    animation: shadowedContinueButtonRemoved 1s ease;
                }

                // The continue button and the launch-bot-button are both being removed
                &.showing-continue-button.ng-leave,
                &.showing-continue-button-remove.ng-leave {
                    animation: bounceOutDownWithContinueButton 1s ease;
                }
            }

            &.ng-leave {
                animation: bounceOutDown 1s ease;
            }

            i {
                margin-inline-end: 0.2em;
            }

            &:hover {
                color: $COLOR_V3_WHITE;
                background-color: $COLOR_V3_CORAL;
            }

            body.bg-turquoise &,
            body.bg-purple &,
            body.bg-blue & {
                background: transparent;
                color: $COLOR_V3_WHITE;
                &:hover {
                    background: $COLOR_V3_WHITE;
                }
            }

            body.bg-turquoise &:hover {
                color: $COLOR_V3_TURQUOISE_DARK;
            }

            body.bg-purple &:hover {
                color: $COLOR_V3_PURPLE_DARK;
            }

            body.bg-blue &:hover {
                color: $COLOR_V3_BLUE_DARK;
            }

            body.valar:not(.bg-turquoise):not(.bg-purple):not(.bg-blue) & {
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_BLUE;
                &:hover {
                    background-color: $COLOR_V3_BLUE;
                    color: $COLOR_V3_WHITE;
                }
            }
            // rtl-language overrides
            html[dir="rtl"] & {
                left: auto;
                right: 0px;
            }
        }

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: 0px;
        }
    }

    .continue-button-wrapper {
        right: $CONTINUE_BUTTON_DESKTOP_PADDING;
        z-index: 10000; // Above the messages

        // on completion screens on desktop, move button to the middle
        // (on completion screens on mobile, it looks just like
        // the continue button in the frame)
        &.completion-screen-continue-button {
            @media (min-width: $screen-sm-min) {
                width: $CONTINUE_BUTTON_WIDTH_COMPLETION_SCREENS;
                max-width: 100%;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;

                html[dir="rtl"] & {
                    left: auto;
                    right: 50%;
                    transform: translateX(50%);
                }
            }
        }

        &.stream-completed-continue-button {
            min-height: 55px;

            @media (max-width: $screen-xs-max) {
                left: 0px;
                right: 0px;
                bottom: 0px;
                button {
                    border-radius: 0px;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 0;
                right: 0;
            }

            button {
                border: none;
                border-radius: 0px;
                position: relative;

                // Green slab that floats below the button so that when it bounces up into place,
                // there's no gap below the button.
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: auto;
                    height: 50px;
                    background-color: $COLOR_V3_GREEN;
                }
            }
        }

        button {
            @include footer-buttons-animation();
            outline: none;
            text-transform: uppercase;
        }

        front-royal-spinner .wrapper {
            margin-top: 12px;
        }
    }

    // this used to be the default in flat buttons, but no longer.
    // See the finish screen for a lesson where the next lesson has
    // a long title.  Or see screenshot at https://trello.com/c/vPqYLuss/1413-bug-wrapping-in-lesson-finish-screen
    button.flat {
        white-space: normal;
    }
}

// Semi-hackish: slide the footer over to the left to center it under the content in the editor
.editor .front-royal-footer {
    left: #{$EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH - $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH};
}
