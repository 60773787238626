//-----------------------------------
// Completion Screen Backgrounds
//-----------------------------------

body.bg-completion-blue #sp-page {
    background-color: $COLOR_V3_BLUE_COMPLETION !important;
    color: $COLOR_V3_WHITE;
}

//-----------------------------------
// Player Backgrounds
//-----------------------------------

$defaultExitHoverBackground: rgba(0, 0, 0, 0.35);

@mixin background-style(
    $backgroundStyle,
    $textColor,
    $headerColor,
    $exitHoverTextColor,
    $exitHoverColor,
    $indicatorBackgroundUrl,
    $backgroundSize: 379px 216px
) {
    color: $textColor;

    blockquote {
        padding: 8px 8px;
        margin: 0 0 8px;
        border: none;
        border-left: 4px solid rgba($textColor, 0.15);
        background-color: rgba($textColor, 0.05);

        p {
            font-size: $FONT_SIZE_CONTENT * 0.9;
            color: rgba($textColor, 0.7);
        }

        @media (max-width: $screen-xs-max) {
            &,
            p {
                font-size: $FONT_SIZE_CONTENT_SCREEN_SMALL_MIN * 0.9;
            }
        }
    }

    // rtl-content overrides
    div[dir="rtl"] blockquote {
        border-left: none;
        border-right: 4px solid rgba($textColor, 0.15);
    }

    #sp-page,
    .modal.fullscreen.zoom .modal-content {
        @if str-index(#{$backgroundStyle}, "url") {
            background-image: $backgroundStyle;
        } @else {
            background-color: $backgroundStyle;
        }

        background-size: $backgroundSize; // dimensions are 748x431, which is retina res (50% size for sharpness)
    }

    .app-header .player-header {
        background-color: $headerColor;
        color: $COLOR_V3_WHITE; // ever change?

        .brand.home-link.alternate {
            .exit-text {
                background-color: $exitHoverTextColor;
            }
            .no-touchevents &:hover {
                background-color: rgba($exitHoverColor, 0.35);
            }
            .no-touchevents &:active,
            .touchevents &:active {
                background-color: $exitHoverColor;
            }

            // use special back icon that works on any of our colors
            .icon.home {
                background-image: url("~vectors/home_back_large_alternate_colors.svg");
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0.35;
            }
        }

        .progress-container .indicator {
            background-image: $indicatorBackgroundUrl;
        }
    }
}

/*
    NOTE: ALL OF THESE IMAGES ARE PRELOADED IN /image_preloader.scss. MAKE SURE TO
    UPDATE THAT FILE IF ANY OF THESE URLS CHANGE
*/
body.bg-white {
    @include background-style(
        null,
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_CORAL,
        $COLOR_V3_CORAL_DARK,
        $COLOR_V3_CORAL_HOVER,
        url("~vectors/progress_indicator_dark_coral.svg")
    );
}

body.bg-white.valar {
    @include background-style(
        null,
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_BLUE,
        $COLOR_V3_BLUE_DARK,
        $COLOR_V3_BLUE_HOVER,
        url("~vectors/progress_indicator_dark_blue.svg")
    );
}

body.bg-beige {
    @include background-style(
        $COLOR_V3_BEIGE,
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_CORAL,
        $COLOR_V3_CORAL_DARK,
        $COLOR_V3_CORAL_HOVER,
        url("~vectors/progress_indicator_dark_coral.svg")
    );
}

body.bg-beige.valar {
    @include background-style(
        $COLOR_V3_BEIGE,
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_BLUE,
        $COLOR_V3_BLUE_DARK,
        $COLOR_V3_BLUE_HOVER,
        url("~vectors/progress_indicator_dark_blue.svg")
    );
}

body.bg-purple {
    @include background-style(
        url("~images/pattern_purple_repeat.png"),
        $COLOR_V3_WHITE,
        $COLOR_V3_PURPLE,
        $defaultExitHoverBackground,
        $COLOR_V3_PURPLE_HOVER,
        url("~vectors/progress_indicator_dark_purple.svg")
    );
}

body.bg-turquoise {
    @include background-style(
        url("~images/pattern_turquoise_repeat.png"),
        $COLOR_V3_WHITE,
        $COLOR_V3_TURQUOISE,
        $defaultExitHoverBackground,
        $COLOR_V3_TURQUOISE_HOVER,
        url("~vectors/progress_indicator_dark_turquoise.svg")
    );
}

body.bg-blue {
    @include background-style(
        url("~images/pattern_blue_repeat.png"),
        $COLOR_V3_WHITE,
        $COLOR_V3_BLUE,
        $defaultExitHoverBackground,
        $COLOR_V3_BLUE_HOVER,
        url("~vectors/progress_indicator_dark_blue.svg")
    );
}

body.bg-completion-blue {
    @include background-style(
        url("~images/pattern_completionblue_repeat.png"),
        $COLOR_V3_WHITE,
        $COLOR_V3_BLUE_COMPLETION,
        $defaultExitHoverBackground,
        $COLOR_V3_BLUE_HOVER,
        url("~vectors/progress_indicator_dark_blue.svg")
    );
}

body.bg-beige-pattern {
    @include background-style(
        url("~images/pattern_beige_repeat.png"),
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_CORAL,
        $COLOR_V3_CORAL_DARK,
        $COLOR_V3_CORAL_HOVER,
        url("~vectors/progress_indicator_dark_coral.svg")
    );
}

body.bg-beige-pattern.valar {
    @include background-style(
        url("~images/pattern_beige_repeat.png"),
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_BLUE,
        $COLOR_V3_BLUE_DARK,
        $COLOR_V3_BLUE_HOVER,
        url("~vectors/progress_indicator_dark_coral.svg")
    );
}

body.bg-demo-pattern {
    @include background-style(
        url("~images/demo_background.jpg"),
        $COLOR_V3_EGGPLANT,
        $COLOR_V3_CORAL,
        $COLOR_V3_CORAL_DARK,
        $COLOR_V3_CORAL_HOVER,
        url("~vectors/progress_indicator_dark_coral.svg"),
        364px 277px
    );
}

// see iphonex.scss for overrides that key off these classes to ensure it looks good on iPhone X
