// default values

$ribbon-bg: $COLOR_V3_BEIGE_MIDDARK;
$ribbon-bg-hover: $COLOR_V3_BEIGE_BEYOND_DARK;
$ribbon-bg-enabled: $COLOR_V3_BLUE;
$ribbon-tooltip-bg: $COLOR_V3_DARK_GREY;

$ribbon-w-narrow: 28px;
$ribbon-tail-narrow: 13px;

$ribbon-w-wide: 40px;
$ribbon-tail-wide: 14px;
$ribbon-tail-wide-short: 12px;

@mixin bookmark-ribbon--icon-coloring($color, $width, $shortHeight) {
    .icon:after,
    .icon:hover:after {
        border-left: $width*0.5 solid $color;
        border-right: $width*0.5 solid $color;
        border-bottom: $shortHeight solid transparent;
    }

    @media (min-width: $screen-lg-min) {
        .icon:after,
        .icon:hover:after {
            border-left: $width*0.5 solid $color;
            border-right: $width*0.5 solid $color;
            border-bottom: $ribbon-tail-wide solid transparent;
        }
    }
}

.bookmark-ribbon {
    padding: 0 0 $ribbon-tail-narrow 0;
    position: absolute;
    right: 7px;
    top: -4px;
    width: $ribbon-w-narrow;
    z-index: 1;
    cursor: pointer;

    // rtl-language overrides
    html[dir="rtl"] & {
        left: 7px;
        right: auto;
    }

    .tooltip {
        opacity: 0;
        pointer-events: none;
        background: $ribbon-tooltip-bg;
        color: $COLOR_V3_WHITE;
        border-radius: 2px;
        width: 120px;
        right: -41px;
        top: -33px;
        text-align: center;
        position: absolute;
        font-size: 11px;
        padding: 4px;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: -41px;
            right: auto;
        }

        &:after {
            top: 100%;
            right: 49px;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: $ribbon-tooltip-bg;
            border-width: 6px;
            margin-left: -6px;

            // rtl-language overrides
            html[dir="rtl"] & {
                left: 49px;
                right: auto;
                margin-left: 0px;
                margin-right: -6px;
            }
        }

        &.remove {
            width: 140px;
            right: -25px;

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: -25px;
            }

            &:after {
                right: 33px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 33px;
                    right: auto;
                }
            }
        }
    }

    .icon {
        background: $ribbon-bg;
        color: #fff;
        padding: 8px 0;
        text-align: center;
        width: $ribbon-w-narrow;

        img {
            width: 16px;
        }

        &:hover {
            background: $ribbon-bg-hover;

            &:after {
                border-left: $ribbon-w-narrow*0.5 solid $ribbon-bg-hover;
                border-right: $ribbon-w-narrow*0.5 solid $ribbon-bg-hover;
            }
        }
    }

    .icon:after {
        position: absolute;
        border-left: $ribbon-w-narrow*0.5 solid $ribbon-bg;
        border-right: $ribbon-w-narrow*0.5 solid $ribbon-bg;
        border-bottom: $ribbon-tail-narrow solid transparent;
        bottom: 0;
        content: "";
        height: 0;
        left: 0;
        width: 0;
    }

    &:hover {
        .tooltip {
            opacity: 1;
        }
    }

    // enabled overrides
    &.enabled {
        .icon {
            background: $ribbon-bg-enabled;
        }

        .icon:after {
            border-left: $ribbon-w-narrow*0.5 solid $ribbon-bg-enabled;
            border-right: $ribbon-w-narrow*0.5 solid $ribbon-bg-enabled;
        }
    }

    &.wide.dark-coral,
    &.wide.dark-blue {
        padding: 0 0 $ribbon-tail-wide-short;
        width: $ribbon-tail-wide;
        right: 36px;

        .icon,
        .icon:hover {
            width: $ribbon-w-wide;
            img {
                width: 20px;
            }
        }

        @media (min-width: $screen-lg-min) {
            padding: 0 0 $ribbon-tail-wide;
        }
    }

    &.dark-coral {
        .icon,
        .icon:hover {
            background: $COLOR_V3_CORAL;
        }

        @include bookmark-ribbon--icon-coloring($COLOR_V3_CORAL, $ribbon-w-narrow, $ribbon-tail-narrow);
        &.wide {
            @include bookmark-ribbon--icon-coloring($COLOR_V3_CORAL, $ribbon-w-wide, $ribbon-tail-wide-short);
        }

        button:hover & {
            .icon,
            .icon:hover {
                background: $COLOR_V3_CORAL_DARK;
            }

            @include bookmark-ribbon--icon-coloring($COLOR_V3_CORAL_DARK, $ribbon-w-narrow, $ribbon-tail-narrow);
            &.wide {
                @include bookmark-ribbon--icon-coloring($COLOR_V3_CORAL_DARK, $ribbon-w-wide, $ribbon-tail-wide-short);
            }
        }
    }

    &.dark-blue {
        .icon,
        .icon:hover {
            background: $COLOR_V3_BLUE;
        }

        @include bookmark-ribbon--icon-coloring($COLOR_V3_BLUE, $ribbon-w-narrow, $ribbon-tail-narrow);
        &.wide {
            @include bookmark-ribbon--icon-coloring($COLOR_V3_BLUE, $ribbon-w-wide, $ribbon-tail-wide-short);
        }

        button:hover & {
            .icon,
            .icon:hover {
                background: $COLOR_V3_BLUE_DARK;
            }

            @include bookmark-ribbon--icon-coloring($COLOR_V3_BLUE_DARK, $ribbon-w-narrow, $ribbon-tail-narrow);
            &.wide {
                @include bookmark-ribbon--icon-coloring($COLOR_V3_BLUE_DARK, $ribbon-w-wide, $ribbon-tail-wide-short);
            }
        }
    }
}
