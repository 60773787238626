student-network-event-type-filters {
    color: $COLOR_V3_GREY_DARKER;
    z-index: 9999;

    // fill the entire area
    &,
    .main-container,
    .background {
        position: absolute;
        display: block;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        background-color: rgba($COLOR_V3_BLACK, 0.5);
    }

    .main-container > .main {
        display: block;
        height: auto;
        background-color: $COLOR_V3_WHITE;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        z-index: 10000;

        box-shadow: 0px 0px 10px rgba($COLOR_V3_MAP_BLUE_DARK, 0.5);

        width: calc(100% - 30px);
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);

        @media (min-width: 450px) {
            max-width: 300px;
            left: 50%;
            top: calc(50% - 40px);
            transform: translateY(-50%) translateX(-50%);
        }

        .title {
            color: $COLOR_V3_GREY_DARKER;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 20px;
            line-height: 1.1em;
            margin-bottom: 20px;
            margin-top: 8px;
        }

        .close {
            $distance-from-side-of-container: 10px;

            color: $COLOR_V3_GREY_DARKER;
            position: absolute;
            right: $distance-from-side-of-container;
            top: 10px;
            font-size: 20px;
            padding: 10px;
            opacity: 0.7;

            html[dir="rtl"] & {
                right: unset;
                left: $distance-from-side-of-container;
            }

            &:after {
                background-size: 15px;
                height: 15px;
                width: 15px;
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .event-type-filters-container {
        .event-type-filter {
            $side-padding: 20px;
            $event-type-image-size: 23px;

            border: 1px solid rgba($COLOR_V3_MAP_BLUE_MID, 0.5);
            border-radius: 8px;
            cursor: pointer;
            height: 54px;
            margin-bottom: 8px;
            padding: 0px $side-padding;
            position: relative;

            &:last-of-type {
                margin-bottom: 0px;
            }

            &.selected {
                background-color: #f7f7fa;
                border: 1px solid rgba($COLOR_V3_MAP_BLUE_MID, 1);
            }

            img {
                height: $event-type-image-size;
                width: $event-type-image-size;
                position: absolute;
                top: 50%;
                left: $side-padding;
                transform: translateY(-50%);
            }

            .event-type {
                $distance-from-side-of-container: $side-padding + $event-type-image-size + 14px;

                font-size: 16px;
                position: absolute;
                left: $distance-from-side-of-container;
                top: 50%;
                transform: translateY(-50%);
                text-align: left;

                html[dir="rtl"] & {
                    left: unset;
                    right: $distance-from-side-of-container;
                }
            }

            i {
                color: $COLOR_V3_MAP_BLUE_MID;
                position: absolute;
                right: $side-padding;
                top: 50%;
                transform: translateY(-50%);

                html[dir="rtl"] & {
                    right: unset;
                    left: $side-padding;
                }
            }
        }
    }

    button {
        margin-top: 15px;
    }
}
