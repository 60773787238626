student-network {
    &,
    > div,
    > div > .world,
    > div > .world > network-map,
    > div > .world > network-amap {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    > div > .world > network-map,
    > div > .world > network-amap {
        height: calc(100% - 35px);

        .hide-mobile-menu & {
            height: 100%;
        }

        @media (min-width: $screen-sm-min) {
            height: 100%;
        }
    }
}
