.timezone-autocomplete {
    .MuiInputBase-root {
        height: 56px;

        input[type="text"].MuiInputBase-input {
            border: none;
            height: auto;

            &:focus {
                border: none;
            }
        }
    }
}
